/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PlaceSelectionFindRequest {

  /**
   * The association of the selection
   */
  association?: string;

  /**
   * The id of the entity that the place selection should be created for
   */
  entityId?: string;

  /**
   * The type of entity that the place selection should be created for
   */
  entityType?: PlaceSelectionFindRequest.EntityTypeEnum;

  constructor({
    
  /**
   * The association of the selection
   */
  association, 
    
  /**
   * The id of the entity that the place selection should be created for
   */
  entityId, 
    
  /**
   * The type of entity that the place selection should be created for
   */
  entityType
  }: {
    
  /**
   * The association of the selection
   */
  association?: string;
    
  /**
   * The id of the entity that the place selection should be created for
   */
  entityId?: string;
    
  /**
   * The type of entity that the place selection should be created for
   */
  entityType?: PlaceSelectionFindRequest.EntityTypeEnum;
  } = {}) {
    this.association = association;
    this.entityId = entityId;
    this.entityType = entityType;
  }
}

export namespace PlaceSelectionFindRequest {

  export type EntityTypeEnum = 'Artwork' | 'Resource';
  export const EntityTypeEnum = {
      Artwork: 'Artwork' as EntityTypeEnum,
      Resource: 'Resource' as EntityTypeEnum,
  }
  export const EntityTypeEnumValues = [
      EntityTypeEnum.Artwork, 
      EntityTypeEnum.Resource
  ];
}


/**
 * PlaceSelectionFindRequest form builder
 *
 */
export class PlaceSelectionFindRequestFormBuilder extends AbstractFormBuilder<PlaceSelectionFindRequestFormBuilder> {

  className = 'PlaceSelectionFindRequest';

  abstractFormBuilder!: AbstractFormBuilder<PlaceSelectionFindRequest>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PlaceSelectionFindRequest {
    return new PlaceSelectionFindRequest();
  }

  public association(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('association', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAssociation() {
    super.removeField('association');
    return this;
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityType() {
    super.removeField('entityType');
    return this;
  }


  public all(): PlaceSelectionFindRequestFormBuilder {
    return this
    .association() 
    .entityId() 
    .entityType() 
;
  }
}

