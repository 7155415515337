/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PaginationQuery {
  page?: number;
  pageSize?: number;

  constructor({
      page, 
      pageSize
  }: {
      page?: number;
      pageSize?: number;
  } = {}) {
    this.page = page;
    this.pageSize = pageSize;
  }
}

export namespace PaginationQuery {

}


/**
 * PaginationQuery form builder
 *
 */
export class PaginationQueryFormBuilder extends AbstractFormBuilder<PaginationQueryFormBuilder> {

  className = 'PaginationQuery';

  abstractFormBuilder!: AbstractFormBuilder<PaginationQuery>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PaginationQuery {
    return new PaginationQuery();
  }

  public page(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('page', [undefined, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPage() {
    super.removeField('page');
    return this;
  }

  public pageSize(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pageSize', [undefined, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPageSize() {
    super.removeField('pageSize');
    return this;
  }


  public all(): PaginationQueryFormBuilder {
    return this
    .page() 
    .pageSize() 
;
  }
}

