/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PublicPublicationQueryFilters {
  allFields?: string;
  artist?: Agent;
  artists?: Array<Agent>;
  id?: string;
  pubDate?: string;
  pubTitle?: string;
  pubType?: PublicPublicationQueryFilters.PubTypeEnum;

  constructor({
      allFields, 
      artist, 
      artists, 
      id, 
      pubDate, 
      pubTitle, 
      pubType
  }: {
      allFields?: string;
      artist?: Agent;
      artists?: Array<Agent>;
      id?: string;
      pubDate?: string;
      pubTitle?: string;
      pubType?: PublicPublicationQueryFilters.PubTypeEnum;
  } = {}) {
    this.allFields = allFields;
    this.artist = artist;
    this.artists = artists;
    this.id = id;
    this.pubDate = pubDate;
    this.pubTitle = pubTitle;
    this.pubType = pubType;
  }
}

export namespace PublicPublicationQueryFilters {

  export type PubTypeEnum = 'Monographs' | 'General Literature' | 'Essays' | 'Articles' | 'Dissertations';
  export const PubTypeEnum = {
      Monographs: 'Monographs' as PubTypeEnum,
      GeneralLiterature: 'General Literature' as PubTypeEnum,
      Essays: 'Essays' as PubTypeEnum,
      Articles: 'Articles' as PubTypeEnum,
      Dissertations: 'Dissertations' as PubTypeEnum,
  }
  export const PubTypeEnumValues = [
      PubTypeEnum.Monographs, 
      PubTypeEnum.GeneralLiterature, 
      PubTypeEnum.Essays, 
      PubTypeEnum.Articles, 
      PubTypeEnum.Dissertations
  ];
}


/**
 * PublicPublicationQueryFilters form builder
 *
 */
export class PublicPublicationQueryFiltersFormBuilder extends AbstractFormBuilder<PublicPublicationQueryFiltersFormBuilder> {

  className = 'PublicPublicationQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PublicPublicationQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PublicPublicationQueryFilters {
    return new PublicPublicationQueryFilters();
  }

  public allFields(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('allFields', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAllFields() {
    super.removeField('allFields');
    return this;
  }


  public artist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtist() {
    super.removeField('artist');
    return this;
  }


  public artists(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artists', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtists() {
    super.removeField('artists');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public pubDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubDate() {
    super.removeField('pubDate');
    return this;
  }

  public pubTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubTitle() {
    super.removeField('pubTitle');
    return this;
  }

  public pubType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubType() {
    super.removeField('pubType');
    return this;
  }


  public all(): PublicPublicationQueryFiltersFormBuilder {
    return this
    .allFields() 
    .id() 
    .pubDate() 
    .pubTitle() 
    .pubType() 
;
  }
}

