/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ConditionCheckQueryFilters {
  agentSelections?: Array<AgentSelection>;
  conditionCheckEndDate?: DateQuery;
  conditionCheckMethod?: ConditionCheckQueryFilters.ConditionCheckMethodEnum;
  conditionCheckReason?: ConditionCheckQueryFilters.ConditionCheckReasonEnum;
  conditionCheckRefNo?: string;
  conditionCheckStartDate?: DateQuery;
  conditionCheckStatus?: ConditionCheckQueryFilters.ConditionCheckStatusEnum;
  conditionCheckStatusDate?: DateQuery;
  conditionCheckTitle?: string;
  id?: string;

  constructor({
      agentSelections, 
      conditionCheckEndDate, 
      conditionCheckMethod, 
      conditionCheckReason, 
      conditionCheckRefNo, 
      conditionCheckStartDate, 
      conditionCheckStatus, 
      conditionCheckStatusDate, 
      conditionCheckTitle, 
      id
  }: {
      agentSelections?: Array<AgentSelection>;
      conditionCheckEndDate?: DateQuery;
      conditionCheckMethod?: ConditionCheckQueryFilters.ConditionCheckMethodEnum;
      conditionCheckReason?: ConditionCheckQueryFilters.ConditionCheckReasonEnum;
      conditionCheckRefNo?: string;
      conditionCheckStartDate?: DateQuery;
      conditionCheckStatus?: ConditionCheckQueryFilters.ConditionCheckStatusEnum;
      conditionCheckStatusDate?: DateQuery;
      conditionCheckTitle?: string;
      id?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.conditionCheckEndDate = conditionCheckEndDate;
    this.conditionCheckMethod = conditionCheckMethod;
    this.conditionCheckReason = conditionCheckReason;
    this.conditionCheckRefNo = conditionCheckRefNo;
    this.conditionCheckStartDate = conditionCheckStartDate;
    this.conditionCheckStatus = conditionCheckStatus;
    this.conditionCheckStatusDate = conditionCheckStatusDate;
    this.conditionCheckTitle = conditionCheckTitle;
    this.id = id;
  }
}

export namespace ConditionCheckQueryFilters {

  export type ConditionCheckMethodEnum = 'observed' | 'x-rayed';
  export const ConditionCheckMethodEnum = {
      Observed: 'observed' as ConditionCheckMethodEnum,
      XRayed: 'x-rayed' as ConditionCheckMethodEnum,
  }
  export const ConditionCheckMethodEnumValues = [
      ConditionCheckMethodEnum.Observed, 
      ConditionCheckMethodEnum.XRayed
  ];
  export type ConditionCheckReasonEnum = 'loan in' | 'loan out' | 'damaged in transit' | 'conservation' | 'acquisition';
  export const ConditionCheckReasonEnum = {
      LoanIn: 'loan in' as ConditionCheckReasonEnum,
      LoanOut: 'loan out' as ConditionCheckReasonEnum,
      DamagedInTransit: 'damaged in transit' as ConditionCheckReasonEnum,
      Conservation: 'conservation' as ConditionCheckReasonEnum,
      Acquisition: 'acquisition' as ConditionCheckReasonEnum,
  }
  export const ConditionCheckReasonEnumValues = [
      ConditionCheckReasonEnum.LoanIn, 
      ConditionCheckReasonEnum.LoanOut, 
      ConditionCheckReasonEnum.DamagedInTransit, 
      ConditionCheckReasonEnum.Conservation, 
      ConditionCheckReasonEnum.Acquisition
  ];
  export type ConditionCheckStatusEnum = 'To Do' | 'Pending' | 'In-Progress' | 'Complete';
  export const ConditionCheckStatusEnum = {
      ToDo: 'To Do' as ConditionCheckStatusEnum,
      Pending: 'Pending' as ConditionCheckStatusEnum,
      InProgress: 'In-Progress' as ConditionCheckStatusEnum,
      Complete: 'Complete' as ConditionCheckStatusEnum,
  }
  export const ConditionCheckStatusEnumValues = [
      ConditionCheckStatusEnum.ToDo, 
      ConditionCheckStatusEnum.Pending, 
      ConditionCheckStatusEnum.InProgress, 
      ConditionCheckStatusEnum.Complete
  ];
}


/**
 * ConditionCheckQueryFilters form builder
 *
 */
export class ConditionCheckQueryFiltersFormBuilder extends AbstractFormBuilder<ConditionCheckQueryFiltersFormBuilder> {

  className = 'ConditionCheckQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ConditionCheckQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ConditionCheckQueryFilters {
    return new ConditionCheckQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public conditionCheckEndDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conditionCheckEndDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConditionCheckEndDate() {
    super.removeField('conditionCheckEndDate');
    return this;
  }

  public conditionCheckMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckMethod() {
    super.removeField('conditionCheckMethod');
    return this;
  }

  public conditionCheckReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckReason() {
    super.removeField('conditionCheckReason');
    return this;
  }

  public conditionCheckRefNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckRefNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckRefNo() {
    super.removeField('conditionCheckRefNo');
    return this;
  }


  public conditionCheckStartDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conditionCheckStartDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConditionCheckStartDate() {
    super.removeField('conditionCheckStartDate');
    return this;
  }

  public conditionCheckStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckStatus() {
    super.removeField('conditionCheckStatus');
    return this;
  }


  public conditionCheckStatusDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conditionCheckStatusDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConditionCheckStatusDate() {
    super.removeField('conditionCheckStatusDate');
    return this;
  }

  public conditionCheckTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckTitle() {
    super.removeField('conditionCheckTitle');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public all(): ConditionCheckQueryFiltersFormBuilder {
    return this
    .conditionCheckMethod() 
    .conditionCheckReason() 
    .conditionCheckRefNo() 
    .conditionCheckStatus() 
    .conditionCheckTitle() 
    .id() 
;
  }
}

