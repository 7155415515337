/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PlaceSelectionQueryFilters {

  /**
   * DEPRECATED: Replaced by "artists" filter property.
   *
   * @deprecated
   */
  artist?: Agent;
  artists?: Array<Agent>;
  association?: PlaceSelectionQueryFilters.AssociationEnum;
  entityRefType?: PlaceSelectionQueryFilters.EntityRefTypeEnum;
  placeName?: string;

  constructor({
    
  /**
   * DEPRECATED: Replaced by "artists" filter property.
   *
   * @deprecated
   */
  artist, 
      artists, 
      association, 
      entityRefType, 
      placeName
  }: {
    
  /**
   * DEPRECATED: Replaced by "artists" filter property.
   *
   * @deprecated
   */
  artist?: Agent;
      artists?: Array<Agent>;
      association?: PlaceSelectionQueryFilters.AssociationEnum;
      entityRefType?: PlaceSelectionQueryFilters.EntityRefTypeEnum;
      placeName?: string;
  } = {}) {
    this.artist = artist;
    this.artists = artists;
    this.association = association;
    this.entityRefType = entityRefType;
    this.placeName = placeName;
  }
}

export namespace PlaceSelectionQueryFilters {

  export type AssociationEnum = 'Created at' | 'Built' | 'Used' | 'Found' | 'Depicts';
  export const AssociationEnum = {
      CreatedAt: 'Created at' as AssociationEnum,
      Built: 'Built' as AssociationEnum,
      Used: 'Used' as AssociationEnum,
      Found: 'Found' as AssociationEnum,
      Depicts: 'Depicts' as AssociationEnum,
  }
  export const AssociationEnumValues = [
      AssociationEnum.CreatedAt, 
      AssociationEnum.Built, 
      AssociationEnum.Used, 
      AssociationEnum.Found, 
      AssociationEnum.Depicts
  ];
  export type EntityRefTypeEnum = 'Artwork' | 'Resource';
  export const EntityRefTypeEnum = {
      Artwork: 'Artwork' as EntityRefTypeEnum,
      Resource: 'Resource' as EntityRefTypeEnum,
  }
  export const EntityRefTypeEnumValues = [
      EntityRefTypeEnum.Artwork, 
      EntityRefTypeEnum.Resource
  ];
}


/**
 * PlaceSelectionQueryFilters form builder
 *
 */
export class PlaceSelectionQueryFiltersFormBuilder extends AbstractFormBuilder<PlaceSelectionQueryFiltersFormBuilder> {

  className = 'PlaceSelectionQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PlaceSelectionQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PlaceSelectionQueryFilters {
    return new PlaceSelectionQueryFilters();
  }


  public artist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtist() {
    super.removeField('artist');
    return this;
  }


  public artists(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artists', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtists() {
    super.removeField('artists');
    return this;
  }

  public association(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('association', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAssociation() {
    super.removeField('association');
    return this;
  }

  public entityRefType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityRefType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityRefType() {
    super.removeField('entityRefType');
    return this;
  }

  public placeName(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('placeName', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPlaceName() {
    super.removeField('placeName');
    return this;
  }


  public all(): PlaceSelectionQueryFiltersFormBuilder {
    return this
    .association() 
    .entityRefType() 
    .placeName() 
;
  }
}

