/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtEventType {
  id?: string;
  parentId?: string;
  text?: string;

  constructor({
      id, 
      parentId, 
      text
  }: {
      id?: string;
      parentId?: string;
      text?: string;
  } = {}) {
    this.id = id;
    this.parentId = parentId;
    this.text = text;
  }
}

export namespace ArtEventType {

}


/**
 * ArtEventType form builder
 *
 */
export class ArtEventTypeFormBuilder extends AbstractFormBuilder<ArtEventTypeFormBuilder> {

  className = 'ArtEventType';

  abstractFormBuilder!: AbstractFormBuilder<ArtEventType>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtEventType {
    return new ArtEventType();
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public parentId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentId() {
    super.removeField('parentId');
    return this;
  }

  public text(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('text', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutText() {
    super.removeField('text');
    return this;
  }


  public all(): ArtEventTypeFormBuilder {
    return this
    .id() 
    .parentId() 
    .text() 
;
  }
}

