/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncValidatorFn, FormControl, ValidatorFn, Validators } from '@angular/forms';

export class NamedFormControl extends FormControl {
  required = false;
  requiredTrue = false;

  constructor(
    public name: string,
    formState?: any,
    validators?: ValidatorFn[],
    asyncValidators?: AsyncValidatorFn[],
  ) {
    super(formState, validators, asyncValidators);
    this.required = (validators &&
      validators.filter((validator) => validator === Validators.required).length > 0) as boolean;
    this.requiredTrue = (validators &&
      validators.filter((validator) => validator === Validators.requiredTrue).length > 0) as boolean;
  }

  updateFromSource(
    source: any | undefined,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ): NamedFormControl {
    this.setValue(source, options);
    return this;
  }
}
