/* tslint:disable */
/* eslint-disable */
import { AgentRole, AgentRoleFormBuilder } from '../models/agent-role';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentSelectionFindRequest {

  /**
   * The role of the selection
   */
  agentRole?: AgentRole;

  /**
   * The id of the entity that the agent selection should be created for
   */
  entityId?: string;

  /**
   * The type of entity that the agent selection should be created for
   */
  entityType?: AgentSelectionFindRequest.EntityTypeEnum;

  constructor({
    
  /**
   * The role of the selection
   */
  agentRole, 
    
  /**
   * The id of the entity that the agent selection should be created for
   */
  entityId, 
    
  /**
   * The type of entity that the agent selection should be created for
   */
  entityType
  }: {
    
  /**
   * The role of the selection
   */
  agentRole?: AgentRole;
    
  /**
   * The id of the entity that the agent selection should be created for
   */
  entityId?: string;
    
  /**
   * The type of entity that the agent selection should be created for
   */
  entityType?: AgentSelectionFindRequest.EntityTypeEnum;
  } = {}) {
    this.agentRole = agentRole;
    this.entityId = entityId;
    this.entityType = entityType;
  }
}

export namespace AgentSelectionFindRequest {

  export type EntityTypeEnum = 'Agent' | 'Artwork' | 'ArtEvent' | 'Auction' | 'Exhibition' | 'ExhibitionVenue' | 'Publication' | 'Committee' | 'Resource' | 'ObjectEntry' | 'ObjectExit' | 'AcquisitionInformation' | 'DisposalInformation' | 'AuditInformation' | 'Marking' | 'MovementInformation' | 'Loan' | 'CollectionsReview' | 'Place' | 'Location' | 'ConditionCheck' | 'Conservation' | 'InsuranceInformation';
  export const EntityTypeEnum = {
      Agent: 'Agent' as EntityTypeEnum,
      Artwork: 'Artwork' as EntityTypeEnum,
      ArtEvent: 'ArtEvent' as EntityTypeEnum,
      Auction: 'Auction' as EntityTypeEnum,
      Exhibition: 'Exhibition' as EntityTypeEnum,
      ExhibitionVenue: 'ExhibitionVenue' as EntityTypeEnum,
      Publication: 'Publication' as EntityTypeEnum,
      Committee: 'Committee' as EntityTypeEnum,
      Resource: 'Resource' as EntityTypeEnum,
      ObjectEntry: 'ObjectEntry' as EntityTypeEnum,
      ObjectExit: 'ObjectExit' as EntityTypeEnum,
      AcquisitionInformation: 'AcquisitionInformation' as EntityTypeEnum,
      DisposalInformation: 'DisposalInformation' as EntityTypeEnum,
      AuditInformation: 'AuditInformation' as EntityTypeEnum,
      Marking: 'Marking' as EntityTypeEnum,
      MovementInformation: 'MovementInformation' as EntityTypeEnum,
      Loan: 'Loan' as EntityTypeEnum,
      CollectionsReview: 'CollectionsReview' as EntityTypeEnum,
      Place: 'Place' as EntityTypeEnum,
      Location: 'Location' as EntityTypeEnum,
      ConditionCheck: 'ConditionCheck' as EntityTypeEnum,
      Conservation: 'Conservation' as EntityTypeEnum,
      InsuranceInformation: 'InsuranceInformation' as EntityTypeEnum,
  }
  export const EntityTypeEnumValues = [
      EntityTypeEnum.Agent, 
      EntityTypeEnum.Artwork, 
      EntityTypeEnum.ArtEvent, 
      EntityTypeEnum.Auction, 
      EntityTypeEnum.Exhibition, 
      EntityTypeEnum.ExhibitionVenue, 
      EntityTypeEnum.Publication, 
      EntityTypeEnum.Committee, 
      EntityTypeEnum.Resource, 
      EntityTypeEnum.ObjectEntry, 
      EntityTypeEnum.ObjectExit, 
      EntityTypeEnum.AcquisitionInformation, 
      EntityTypeEnum.DisposalInformation, 
      EntityTypeEnum.AuditInformation, 
      EntityTypeEnum.Marking, 
      EntityTypeEnum.MovementInformation, 
      EntityTypeEnum.Loan, 
      EntityTypeEnum.CollectionsReview, 
      EntityTypeEnum.Place, 
      EntityTypeEnum.Location, 
      EntityTypeEnum.ConditionCheck, 
      EntityTypeEnum.Conservation, 
      EntityTypeEnum.InsuranceInformation
  ];
}


/**
 * AgentSelectionFindRequest form builder
 *
 */
export class AgentSelectionFindRequestFormBuilder extends AbstractFormBuilder<AgentSelectionFindRequestFormBuilder> {

  className = 'AgentSelectionFindRequest';

  abstractFormBuilder!: AbstractFormBuilder<AgentSelectionFindRequest>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentSelectionFindRequest {
    return new AgentSelectionFindRequest();
  }


  public agentRole(
    builder: AgentRoleFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentRole', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentRole() {
    super.removeField('agentRole');
    return this;
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityType() {
    super.removeField('entityType');
    return this;
  }


  public all(): AgentSelectionFindRequestFormBuilder {
    return this
    .entityId() 
    .entityType() 
;
  }
}

