/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Permission {
  accessMethod?: Permission.AccessMethodEnum;
  agentId?: string;

  constructor({
      accessMethod, 
      agentId
  }: {
      accessMethod?: Permission.AccessMethodEnum;
      agentId?: string;
  } = {}) {
    this.accessMethod = accessMethod;
    this.agentId = agentId;
  }
}

export namespace Permission {

  export type AccessMethodEnum = 'r' | 'w';
  export const AccessMethodEnum = {
      R: 'r' as AccessMethodEnum,
      W: 'w' as AccessMethodEnum,
  }
  export const AccessMethodEnumValues = [
      AccessMethodEnum.R, 
      AccessMethodEnum.W
  ];
}


/**
 * Permission form builder
 *
 */
export class PermissionFormBuilder extends AbstractFormBuilder<PermissionFormBuilder> {

  className = 'Permission';

  abstractFormBuilder!: AbstractFormBuilder<Permission>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Permission {
    return new Permission();
  }

  public accessMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('accessMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAccessMethod() {
    super.removeField('accessMethod');
    return this;
  }

  public agentId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentId() {
    super.removeField('agentId');
    return this;
  }


  public all(): PermissionFormBuilder {
    return this
    .accessMethod() 
    .agentId() 
;
  }
}

