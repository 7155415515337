/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtworkBatchReportRequest {
  artIds?: Array<string>;
  type?: ArtworkBatchReportRequest.TypeEnum;

  constructor({
      artIds, 
      type
  }: {
      artIds?: Array<string>;
      type?: ArtworkBatchReportRequest.TypeEnum;
  } = {}) {
    this.artIds = artIds;
    this.type = type;
  }
}

export namespace ArtworkBatchReportRequest {

  export type TypeEnum = 'full_report' | 'notice';
  export const TypeEnum = {
      FullReport: 'full_report' as TypeEnum,
      Notice: 'notice' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.FullReport, 
      TypeEnum.Notice
  ];
}


/**
 * ArtworkBatchReportRequest form builder
 *
 */
export class ArtworkBatchReportRequestFormBuilder extends AbstractFormBuilder<ArtworkBatchReportRequestFormBuilder> {

  className = 'ArtworkBatchReportRequest';

  abstractFormBuilder!: AbstractFormBuilder<ArtworkBatchReportRequest>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtworkBatchReportRequest {
    return new ArtworkBatchReportRequest();
  }

  public artIds(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artIds', [undefined, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtIds() {
    super.removeField('artIds');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }


  public all(): ArtworkBatchReportRequestFormBuilder {
    return this
    .artIds() 
    .type() 
;
  }
}

