/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncValidatorFn, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { NamedFormArray } from './named-form-array';
import { NamedFormControl } from './named-form-control';
import { NamedFormGroup } from './named-form-group';

export type NamedFormControlTypes = NamedFormControl | NamedFormGroup | NamedFormArray;

export interface BuilderValidatorConfig {
  syncValidator?: ValidatorFn;
  asyncValidator?: AsyncValidatorFn;
  syncArrayValidator?: ValidatorFn;
  asyncArrayValidator?: AsyncValidatorFn;
}

export interface BuilderAndConfig<T> {
  builder: AbstractFormBuilder<T>;
  asArray?: boolean;
  validatorConfig: BuilderValidatorConfig;
}

export abstract class AbstractFormBuilder<T> {
  abstract className: string;

  asyncValidatorFn!: AsyncValidatorFn;
  validatorFn!: ValidatorFn;
  validators!: Validators;

  abstract formBuilder: FormBuilder;

  public fields: { [key in keyof T]?: any } = {};

  protected addField(name: keyof T, config: any): void {
    this.fields[name] = config;
  }

  protected removeField(name: keyof T): void {
    this.fields[name] = undefined;
  }

  abstract all(): AbstractFormBuilder<T>;

  reset(): AbstractFormBuilder<T> {
    this.fields = {};
    return this;
  }

  public build(
    prefix = this.className,
    asArray = false,
    validatorConfig: BuilderValidatorConfig = {},
  ): NamedFormGroup | NamedFormArray {
    if (asArray) {
      return new NamedFormArray(
        prefix,
        () => {
          return this._buildFormGroup(
            this.formBuilder,
            prefix,
            validatorConfig.syncValidator,
            validatorConfig.asyncValidator,
          );
        },
        [],
        validatorConfig.syncArrayValidator,
        validatorConfig.asyncArrayValidator,
      );
    } else {
      return this._buildFormGroup(
        this.formBuilder,
        prefix,
        validatorConfig.syncValidator,
        validatorConfig.asyncValidator,
      );
    }
  }

  _buildFormGroup(
    formBuilder: FormBuilder,
    prefix = '',
    syncValidator?: ValidatorFn,
    asyncValidator?: AsyncValidatorFn,
  ): NamedFormGroup {
    const controls = (Object.keys(this.fields) as [keyof T])
      .filter((key: keyof T) => this.fields[key])
      .reduce(
        (acc, key: keyof T) => {
          const newPrefix = prefix + (prefix.length > 0 ? '.' : '') + (key as string);
          const controlConfig = this.fields[key];
          if (!((controlConfig as [keyof T]) instanceof Array)) {
            const builderAndConfig = controlConfig as BuilderAndConfig<T>;
            acc[key] = builderAndConfig.builder.build(
              newPrefix,
              builderAndConfig.asArray,
              builderAndConfig.validatorConfig,
            ) as NamedFormGroup;
          } else {
            acc[key] = new NamedFormControl(newPrefix, controlConfig[0], controlConfig[1], controlConfig[2]);
          }
          return acc;
        },
        {} as { [key in keyof T]: NamedFormControl | NamedFormGroup },
      );
    return new NamedFormGroup(prefix, controls, syncValidator, asyncValidator);
  }
}
