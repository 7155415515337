/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ObjectExitQueryFilters {
  agentSelections?: Array<AgentSelection>;
  deliveryDate?: DateQuery;
  exitAuthorisationDate?: DateQuery;
  exitDate?: DateQuery;
  exitMethod?: ObjectExitQueryFilters.ExitMethodEnum;
  exitNumber?: string;
  exitReason?: ObjectExitQueryFilters.ExitReasonEnum;
  exitStatus?: ObjectExitQueryFilters.ExitStatusEnum;
  exitTitle?: string;
  expectedReturnDate?: DateQuery;
  expectedReturnMethod?: ObjectExitQueryFilters.ExpectedReturnMethodEnum;
  id?: string;
  returnDate?: DateQuery;

  constructor({
      agentSelections, 
      deliveryDate, 
      exitAuthorisationDate, 
      exitDate, 
      exitMethod, 
      exitNumber, 
      exitReason, 
      exitStatus, 
      exitTitle, 
      expectedReturnDate, 
      expectedReturnMethod, 
      id, 
      returnDate
  }: {
      agentSelections?: Array<AgentSelection>;
      deliveryDate?: DateQuery;
      exitAuthorisationDate?: DateQuery;
      exitDate?: DateQuery;
      exitMethod?: ObjectExitQueryFilters.ExitMethodEnum;
      exitNumber?: string;
      exitReason?: ObjectExitQueryFilters.ExitReasonEnum;
      exitStatus?: ObjectExitQueryFilters.ExitStatusEnum;
      exitTitle?: string;
      expectedReturnDate?: DateQuery;
      expectedReturnMethod?: ObjectExitQueryFilters.ExpectedReturnMethodEnum;
      id?: string;
      returnDate?: DateQuery;
  } = {}) {
    this.agentSelections = agentSelections;
    this.deliveryDate = deliveryDate;
    this.exitAuthorisationDate = exitAuthorisationDate;
    this.exitDate = exitDate;
    this.exitMethod = exitMethod;
    this.exitNumber = exitNumber;
    this.exitReason = exitReason;
    this.exitStatus = exitStatus;
    this.exitTitle = exitTitle;
    this.expectedReturnDate = expectedReturnDate;
    this.expectedReturnMethod = expectedReturnMethod;
    this.id = id;
    this.returnDate = returnDate;
  }
}

export namespace ObjectExitQueryFilters {

  export type ExitMethodEnum = 'collected by shipper' | 'in-person' | 'by package';
  export const ExitMethodEnum = {
      CollectedByShipper: 'collected by shipper' as ExitMethodEnum,
      InPerson: 'in-person' as ExitMethodEnum,
      ByPackage: 'by package' as ExitMethodEnum,
  }
  export const ExitMethodEnumValues = [
      ExitMethodEnum.CollectedByShipper, 
      ExitMethodEnum.InPerson, 
      ExitMethodEnum.ByPackage
  ];
  export type ExitReasonEnum = 'exhibition' | 'disposal' | 'use';
  export const ExitReasonEnum = {
      Exhibition: 'exhibition' as ExitReasonEnum,
      Disposal: 'disposal' as ExitReasonEnum,
      Use: 'use' as ExitReasonEnum,
  }
  export const ExitReasonEnumValues = [
      ExitReasonEnum.Exhibition, 
      ExitReasonEnum.Disposal, 
      ExitReasonEnum.Use
  ];
  export type ExitStatusEnum = 'To do' | 'Pending' | 'In-progress' | 'Complete';
  export const ExitStatusEnum = {
      ToDo: 'To do' as ExitStatusEnum,
      Pending: 'Pending' as ExitStatusEnum,
      InProgress: 'In-progress' as ExitStatusEnum,
      Complete: 'Complete' as ExitStatusEnum,
  }
  export const ExitStatusEnumValues = [
      ExitStatusEnum.ToDo, 
      ExitStatusEnum.Pending, 
      ExitStatusEnum.InProgress, 
      ExitStatusEnum.Complete
  ];
  export type ExpectedReturnMethodEnum = 'courier' | 'registered post' | 'hand delivery' | 'in-person';
  export const ExpectedReturnMethodEnum = {
      Courier: 'courier' as ExpectedReturnMethodEnum,
      RegisteredPost: 'registered post' as ExpectedReturnMethodEnum,
      HandDelivery: 'hand delivery' as ExpectedReturnMethodEnum,
      InPerson: 'in-person' as ExpectedReturnMethodEnum,
  }
  export const ExpectedReturnMethodEnumValues = [
      ExpectedReturnMethodEnum.Courier, 
      ExpectedReturnMethodEnum.RegisteredPost, 
      ExpectedReturnMethodEnum.HandDelivery, 
      ExpectedReturnMethodEnum.InPerson
  ];
}


/**
 * ObjectExitQueryFilters form builder
 *
 */
export class ObjectExitQueryFiltersFormBuilder extends AbstractFormBuilder<ObjectExitQueryFiltersFormBuilder> {

  className = 'ObjectExitQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ObjectExitQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ObjectExitQueryFilters {
    return new ObjectExitQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public deliveryDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('deliveryDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDeliveryDate() {
    super.removeField('deliveryDate');
    return this;
  }


  public exitAuthorisationDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exitAuthorisationDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExitAuthorisationDate() {
    super.removeField('exitAuthorisationDate');
    return this;
  }


  public exitDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exitDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExitDate() {
    super.removeField('exitDate');
    return this;
  }

  public exitMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitMethod() {
    super.removeField('exitMethod');
    return this;
  }

  public exitNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitNumber() {
    super.removeField('exitNumber');
    return this;
  }

  public exitReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitReason() {
    super.removeField('exitReason');
    return this;
  }

  public exitStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitStatus() {
    super.removeField('exitStatus');
    return this;
  }

  public exitTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitTitle() {
    super.removeField('exitTitle');
    return this;
  }


  public expectedReturnDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('expectedReturnDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExpectedReturnDate() {
    super.removeField('expectedReturnDate');
    return this;
  }

  public expectedReturnMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('expectedReturnMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExpectedReturnMethod() {
    super.removeField('expectedReturnMethod');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public returnDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('returnDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutReturnDate() {
    super.removeField('returnDate');
    return this;
  }


  public all(): ObjectExitQueryFiltersFormBuilder {
    return this
    .exitMethod() 
    .exitNumber() 
    .exitReason() 
    .exitStatus() 
    .exitTitle() 
    .expectedReturnMethod() 
    .id() 
;
  }
}

