/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ConditionCheck {
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  conditionCheckEndDate?: string;
  conditionCheckMethod?: ConditionCheck.ConditionCheckMethodEnum;
  conditionCheckReason?: ConditionCheck.ConditionCheckReasonEnum;
  conditionCheckRefNo?: string;
  conditionCheckStartDate?: string;
  conditionCheckStatus?: ConditionCheck.ConditionCheckStatusEnum;
  conditionCheckStatusDate?: string;
  conditionCheckTitle?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;

  constructor({
      agentSelections, 
      artEvents, 
      conditionCheckEndDate, 
      conditionCheckMethod, 
      conditionCheckReason, 
      conditionCheckRefNo, 
      conditionCheckStartDate, 
      conditionCheckStatus, 
      conditionCheckStatusDate, 
      conditionCheckTitle, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      lastModifier, 
      modifiedBy
  }: {
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      conditionCheckEndDate?: string;
      conditionCheckMethod?: ConditionCheck.ConditionCheckMethodEnum;
      conditionCheckReason?: ConditionCheck.ConditionCheckReasonEnum;
      conditionCheckRefNo?: string;
      conditionCheckStartDate?: string;
      conditionCheckStatus?: ConditionCheck.ConditionCheckStatusEnum;
      conditionCheckStatusDate?: string;
      conditionCheckTitle?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.conditionCheckEndDate = conditionCheckEndDate;
    this.conditionCheckMethod = conditionCheckMethod;
    this.conditionCheckReason = conditionCheckReason;
    this.conditionCheckRefNo = conditionCheckRefNo;
    this.conditionCheckStartDate = conditionCheckStartDate;
    this.conditionCheckStatus = conditionCheckStatus;
    this.conditionCheckStatusDate = conditionCheckStatusDate;
    this.conditionCheckTitle = conditionCheckTitle;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
  }
}

export namespace ConditionCheck {

  export type ConditionCheckMethodEnum = 'observed' | 'x-rayed';
  export const ConditionCheckMethodEnum = {
      Observed: 'observed' as ConditionCheckMethodEnum,
      XRayed: 'x-rayed' as ConditionCheckMethodEnum,
  }
  export const ConditionCheckMethodEnumValues = [
      ConditionCheckMethodEnum.Observed, 
      ConditionCheckMethodEnum.XRayed
  ];
  export type ConditionCheckReasonEnum = 'loan in' | 'loan out' | 'damaged in transit' | 'conservation' | 'acquisition';
  export const ConditionCheckReasonEnum = {
      LoanIn: 'loan in' as ConditionCheckReasonEnum,
      LoanOut: 'loan out' as ConditionCheckReasonEnum,
      DamagedInTransit: 'damaged in transit' as ConditionCheckReasonEnum,
      Conservation: 'conservation' as ConditionCheckReasonEnum,
      Acquisition: 'acquisition' as ConditionCheckReasonEnum,
  }
  export const ConditionCheckReasonEnumValues = [
      ConditionCheckReasonEnum.LoanIn, 
      ConditionCheckReasonEnum.LoanOut, 
      ConditionCheckReasonEnum.DamagedInTransit, 
      ConditionCheckReasonEnum.Conservation, 
      ConditionCheckReasonEnum.Acquisition
  ];
  export type ConditionCheckStatusEnum = 'To Do' | 'Pending' | 'In-Progress' | 'Complete';
  export const ConditionCheckStatusEnum = {
      ToDo: 'To Do' as ConditionCheckStatusEnum,
      Pending: 'Pending' as ConditionCheckStatusEnum,
      InProgress: 'In-Progress' as ConditionCheckStatusEnum,
      Complete: 'Complete' as ConditionCheckStatusEnum,
  }
  export const ConditionCheckStatusEnumValues = [
      ConditionCheckStatusEnum.ToDo, 
      ConditionCheckStatusEnum.Pending, 
      ConditionCheckStatusEnum.InProgress, 
      ConditionCheckStatusEnum.Complete
  ];
}


/**
 * ConditionCheck form builder
 *
 */
export class ConditionCheckFormBuilder extends AbstractFormBuilder<ConditionCheckFormBuilder> {

  className = 'ConditionCheck';

  abstractFormBuilder!: AbstractFormBuilder<ConditionCheck>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ConditionCheck {
    return new ConditionCheck();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }

  public conditionCheckEndDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckEndDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckEndDate() {
    super.removeField('conditionCheckEndDate');
    return this;
  }

  public conditionCheckMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckMethod() {
    super.removeField('conditionCheckMethod');
    return this;
  }

  public conditionCheckReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckReason() {
    super.removeField('conditionCheckReason');
    return this;
  }

  public conditionCheckRefNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckRefNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckRefNo() {
    super.removeField('conditionCheckRefNo');
    return this;
  }

  public conditionCheckStartDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckStartDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckStartDate() {
    super.removeField('conditionCheckStartDate');
    return this;
  }

  public conditionCheckStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckStatus() {
    super.removeField('conditionCheckStatus');
    return this;
  }

  public conditionCheckStatusDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckStatusDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckStatusDate() {
    super.removeField('conditionCheckStatusDate');
    return this;
  }

  public conditionCheckTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckTitle() {
    super.removeField('conditionCheckTitle');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public all(): ConditionCheckFormBuilder {
    return this
    .conditionCheckEndDate() 
    .conditionCheckMethod() 
    .conditionCheckReason() 
    .conditionCheckRefNo() 
    .conditionCheckStartDate() 
    .conditionCheckStatus() 
    .conditionCheckStatusDate() 
    .conditionCheckTitle() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .modifiedBy() 
;
  }
}

