/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { Price, PriceFormBuilder } from '../models/price';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class DisposalInformation {
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  deaccessionDate?: string;
  disposalAuthorisationDate?: string;
  disposalDate?: string;
  disposalMethod?: DisposalInformation.DisposalMethodEnum;
  disposalProvisos?: string;
  disposalReason?: string;
  disposalTitle?: string;
  groupDisposalPrice?: Price;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  newObjectNumber?: string;
  refNo?: string;

  constructor({
      agentSelections, 
      artEvents, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      deaccessionDate, 
      disposalAuthorisationDate, 
      disposalDate, 
      disposalMethod, 
      disposalProvisos, 
      disposalReason, 
      disposalTitle, 
      groupDisposalPrice, 
      id, 
      lastModifier, 
      modifiedBy, 
      newObjectNumber, 
      refNo
  }: {
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      deaccessionDate?: string;
      disposalAuthorisationDate?: string;
      disposalDate?: string;
      disposalMethod?: DisposalInformation.DisposalMethodEnum;
      disposalProvisos?: string;
      disposalReason?: string;
      disposalTitle?: string;
      groupDisposalPrice?: Price;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      newObjectNumber?: string;
      refNo?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.deaccessionDate = deaccessionDate;
    this.disposalAuthorisationDate = disposalAuthorisationDate;
    this.disposalDate = disposalDate;
    this.disposalMethod = disposalMethod;
    this.disposalProvisos = disposalProvisos;
    this.disposalReason = disposalReason;
    this.disposalTitle = disposalTitle;
    this.groupDisposalPrice = groupDisposalPrice;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.newObjectNumber = newObjectNumber;
    this.refNo = refNo;
  }
}

export namespace DisposalInformation {

  export type DisposalMethodEnum = 'transferred' | 'destroyed' | 'sold';
  export const DisposalMethodEnum = {
      Transferred: 'transferred' as DisposalMethodEnum,
      Destroyed: 'destroyed' as DisposalMethodEnum,
      Sold: 'sold' as DisposalMethodEnum,
  }
  export const DisposalMethodEnumValues = [
      DisposalMethodEnum.Transferred, 
      DisposalMethodEnum.Destroyed, 
      DisposalMethodEnum.Sold
  ];
}


/**
 * DisposalInformation form builder
 *
 */
export class DisposalInformationFormBuilder extends AbstractFormBuilder<DisposalInformationFormBuilder> {

  className = 'DisposalInformation';

  abstractFormBuilder!: AbstractFormBuilder<DisposalInformation>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): DisposalInformation {
    return new DisposalInformation();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public deaccessionDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deaccessionDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeaccessionDate() {
    super.removeField('deaccessionDate');
    return this;
  }

  public disposalAuthorisationDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('disposalAuthorisationDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisposalAuthorisationDate() {
    super.removeField('disposalAuthorisationDate');
    return this;
  }

  public disposalDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('disposalDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisposalDate() {
    super.removeField('disposalDate');
    return this;
  }

  public disposalMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('disposalMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisposalMethod() {
    super.removeField('disposalMethod');
    return this;
  }

  public disposalProvisos(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('disposalProvisos', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisposalProvisos() {
    super.removeField('disposalProvisos');
    return this;
  }

  public disposalReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('disposalReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisposalReason() {
    super.removeField('disposalReason');
    return this;
  }

  public disposalTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('disposalTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisposalTitle() {
    super.removeField('disposalTitle');
    return this;
  }


  public groupDisposalPrice(
    builder: PriceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('groupDisposalPrice', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutGroupDisposalPrice() {
    super.removeField('groupDisposalPrice');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public newObjectNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('newObjectNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNewObjectNumber() {
    super.removeField('newObjectNumber');
    return this;
  }

  public refNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNo() {
    super.removeField('refNo');
    return this;
  }


  public all(): DisposalInformationFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .deaccessionDate() 
    .disposalAuthorisationDate() 
    .disposalDate() 
    .disposalMethod() 
    .disposalProvisos() 
    .disposalReason() 
    .disposalTitle() 
    .id() 
    .modifiedBy() 
    .newObjectNumber() 
    .refNo() 
;
  }
}

