/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class LogicalQueryOption {

  /**
   * The list of filters to apply
   */
  filters?: Array<string>;

  /**
   * The mode for the logical query
   */
  mode?: LogicalQueryOption.ModeEnum;

  constructor({
    
  /**
   * The list of filters to apply
   */
  filters, 
    
  /**
   * The mode for the logical query
   */
  mode
  }: {
    
  /**
   * The list of filters to apply
   */
  filters?: Array<string>;
    
  /**
   * The mode for the logical query
   */
  mode?: LogicalQueryOption.ModeEnum;
  } = {}) {
    this.filters = filters;
    this.mode = mode;
  }
}

export namespace LogicalQueryOption {

  export type ModeEnum = 'All' | 'Any' | 'Not';
  export const ModeEnum = {
      All: 'All' as ModeEnum,
      Any: 'Any' as ModeEnum,
      Not: 'Not' as ModeEnum,
  }
  export const ModeEnumValues = [
      ModeEnum.All, 
      ModeEnum.Any, 
      ModeEnum.Not
  ];
}


/**
 * LogicalQueryOption form builder
 *
 */
export class LogicalQueryOptionFormBuilder extends AbstractFormBuilder<LogicalQueryOptionFormBuilder> {

  className = 'LogicalQueryOption';

  abstractFormBuilder!: AbstractFormBuilder<LogicalQueryOption>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): LogicalQueryOption {
    return new LogicalQueryOption();
  }

  public filters(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('filters', [undefined, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFilters() {
    super.removeField('filters');
    return this;
  }

  public mode(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mode', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMode() {
    super.removeField('mode');
    return this;
  }


  public all(): LogicalQueryOptionFormBuilder {
    return this
    .filters() 
    .mode() 
;
  }
}

