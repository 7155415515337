/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class CollectionsReview {
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  collectionsReviewAuthorizationDate?: string;
  collectionsReviewEndDate?: string;
  collectionsReviewMethod?: CollectionsReview.CollectionsReviewMethodEnum;
  collectionsReviewReason?: CollectionsReview.CollectionsReviewReasonEnum;
  collectionsReviewRefNo?: string;
  collectionsReviewStartDate?: string;
  collectionsReviewStatus?: CollectionsReview.CollectionsReviewStatusEnum;
  collectionsReviewStatusDate?: string;
  collectionsReviewTitle?: string;
  collectionsReviewType?: CollectionsReview.CollectionsReviewTypeEnum;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;

  constructor({
      agentSelections, 
      artEvents, 
      collectionsReviewAuthorizationDate, 
      collectionsReviewEndDate, 
      collectionsReviewMethod, 
      collectionsReviewReason, 
      collectionsReviewRefNo, 
      collectionsReviewStartDate, 
      collectionsReviewStatus, 
      collectionsReviewStatusDate, 
      collectionsReviewTitle, 
      collectionsReviewType, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      lastModifier, 
      modifiedBy
  }: {
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      collectionsReviewAuthorizationDate?: string;
      collectionsReviewEndDate?: string;
      collectionsReviewMethod?: CollectionsReview.CollectionsReviewMethodEnum;
      collectionsReviewReason?: CollectionsReview.CollectionsReviewReasonEnum;
      collectionsReviewRefNo?: string;
      collectionsReviewStartDate?: string;
      collectionsReviewStatus?: CollectionsReview.CollectionsReviewStatusEnum;
      collectionsReviewStatusDate?: string;
      collectionsReviewTitle?: string;
      collectionsReviewType?: CollectionsReview.CollectionsReviewTypeEnum;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.collectionsReviewAuthorizationDate = collectionsReviewAuthorizationDate;
    this.collectionsReviewEndDate = collectionsReviewEndDate;
    this.collectionsReviewMethod = collectionsReviewMethod;
    this.collectionsReviewReason = collectionsReviewReason;
    this.collectionsReviewRefNo = collectionsReviewRefNo;
    this.collectionsReviewStartDate = collectionsReviewStartDate;
    this.collectionsReviewStatus = collectionsReviewStatus;
    this.collectionsReviewStatusDate = collectionsReviewStatusDate;
    this.collectionsReviewTitle = collectionsReviewTitle;
    this.collectionsReviewType = collectionsReviewType;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
  }
}

export namespace CollectionsReview {

  export type CollectionsReviewMethodEnum = 'Sample' | 'High level' | 'Group';
  export const CollectionsReviewMethodEnum = {
      Sample: 'Sample' as CollectionsReviewMethodEnum,
      HighLevel: 'High level' as CollectionsReviewMethodEnum,
      Group: 'Group' as CollectionsReviewMethodEnum,
  }
  export const CollectionsReviewMethodEnumValues = [
      CollectionsReviewMethodEnum.Sample, 
      CollectionsReviewMethodEnum.HighLevel, 
      CollectionsReviewMethodEnum.Group
  ];
  export type CollectionsReviewReasonEnum = 'store rationalisation' | 'collection rationalisation' | 'information improvement' | 'condition survey';
  export const CollectionsReviewReasonEnum = {
      StoreRationalisation: 'store rationalisation' as CollectionsReviewReasonEnum,
      CollectionRationalisation: 'collection rationalisation' as CollectionsReviewReasonEnum,
      InformationImprovement: 'information improvement' as CollectionsReviewReasonEnum,
      ConditionSurvey: 'condition survey' as CollectionsReviewReasonEnum,
  }
  export const CollectionsReviewReasonEnumValues = [
      CollectionsReviewReasonEnum.StoreRationalisation, 
      CollectionsReviewReasonEnum.CollectionRationalisation, 
      CollectionsReviewReasonEnum.InformationImprovement, 
      CollectionsReviewReasonEnum.ConditionSurvey
  ];
  export type CollectionsReviewStatusEnum = 'To Do' | 'Pending' | 'In-Progress' | 'Complete';
  export const CollectionsReviewStatusEnum = {
      ToDo: 'To Do' as CollectionsReviewStatusEnum,
      Pending: 'Pending' as CollectionsReviewStatusEnum,
      InProgress: 'In-Progress' as CollectionsReviewStatusEnum,
      Complete: 'Complete' as CollectionsReviewStatusEnum,
  }
  export const CollectionsReviewStatusEnumValues = [
      CollectionsReviewStatusEnum.ToDo, 
      CollectionsReviewStatusEnum.Pending, 
      CollectionsReviewStatusEnum.InProgress, 
      CollectionsReviewStatusEnum.Complete
  ];
  export type CollectionsReviewTypeEnum = 'Condition' | 'Significance' | 'Rationalisation';
  export const CollectionsReviewTypeEnum = {
      Condition: 'Condition' as CollectionsReviewTypeEnum,
      Significance: 'Significance' as CollectionsReviewTypeEnum,
      Rationalisation: 'Rationalisation' as CollectionsReviewTypeEnum,
  }
  export const CollectionsReviewTypeEnumValues = [
      CollectionsReviewTypeEnum.Condition, 
      CollectionsReviewTypeEnum.Significance, 
      CollectionsReviewTypeEnum.Rationalisation
  ];
}


/**
 * CollectionsReview form builder
 *
 */
export class CollectionsReviewFormBuilder extends AbstractFormBuilder<CollectionsReviewFormBuilder> {

  className = 'CollectionsReview';

  abstractFormBuilder!: AbstractFormBuilder<CollectionsReview>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): CollectionsReview {
    return new CollectionsReview();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }

  public collectionsReviewAuthorizationDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewAuthorizationDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewAuthorizationDate() {
    super.removeField('collectionsReviewAuthorizationDate');
    return this;
  }

  public collectionsReviewEndDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewEndDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewEndDate() {
    super.removeField('collectionsReviewEndDate');
    return this;
  }

  public collectionsReviewMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewMethod() {
    super.removeField('collectionsReviewMethod');
    return this;
  }

  public collectionsReviewReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewReason() {
    super.removeField('collectionsReviewReason');
    return this;
  }

  public collectionsReviewRefNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewRefNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewRefNo() {
    super.removeField('collectionsReviewRefNo');
    return this;
  }

  public collectionsReviewStartDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewStartDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewStartDate() {
    super.removeField('collectionsReviewStartDate');
    return this;
  }

  public collectionsReviewStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewStatus() {
    super.removeField('collectionsReviewStatus');
    return this;
  }

  public collectionsReviewStatusDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewStatusDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewStatusDate() {
    super.removeField('collectionsReviewStatusDate');
    return this;
  }

  public collectionsReviewTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewTitle() {
    super.removeField('collectionsReviewTitle');
    return this;
  }

  public collectionsReviewType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewType() {
    super.removeField('collectionsReviewType');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public all(): CollectionsReviewFormBuilder {
    return this
    .collectionsReviewAuthorizationDate() 
    .collectionsReviewEndDate() 
    .collectionsReviewMethod() 
    .collectionsReviewReason() 
    .collectionsReviewRefNo() 
    .collectionsReviewStartDate() 
    .collectionsReviewStatus() 
    .collectionsReviewStatusDate() 
    .collectionsReviewTitle() 
    .collectionsReviewType() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .modifiedBy() 
;
  }
}

