/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { Artwork, ArtworkFormBuilder } from '../models/artwork';
import { Publication, PublicationFormBuilder } from '../models/publication';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Auction {
  agentSelections?: Array<AgentSelection>;
  artworks?: Array<Artwork>;
  aucDateEdtf?: string;
  aucDateMax?: string;
  aucDateMin?: string;
  aucDateText?: string;
  aucDateUseTextFlag?: boolean;
  aucRoom?: string;
  aucShortTitle?: string;
  aucTitle?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  lastModifier?: User;
  masterFlag?: Auction.MasterFlagEnum;
  modifiedBy?: string;
  numberOfHits?: number;
  publication?: Publication;
  questionableFlag?: boolean;
  replacementId?: string;
  slug?: string;
  status?: Auction.StatusEnum;
  visibilityFlag?: boolean;

  constructor({
      agentSelections, 
      artworks, 
      aucDateEdtf, 
      aucDateMax, 
      aucDateMin, 
      aucDateText, 
      aucDateUseTextFlag, 
      aucRoom, 
      aucShortTitle, 
      aucTitle, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      lastModifier, 
      masterFlag, 
      modifiedBy, 
      numberOfHits, 
      publication, 
      questionableFlag, 
      replacementId, 
      slug, 
      status, 
      visibilityFlag
  }: {
      agentSelections?: Array<AgentSelection>;
      artworks?: Array<Artwork>;
      aucDateEdtf?: string;
      aucDateMax?: string;
      aucDateMin?: string;
      aucDateText?: string;
      aucDateUseTextFlag?: boolean;
      aucRoom?: string;
      aucShortTitle?: string;
      aucTitle?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      lastModifier?: User;
      masterFlag?: Auction.MasterFlagEnum;
      modifiedBy?: string;
      numberOfHits?: number;
      publication?: Publication;
      questionableFlag?: boolean;
      replacementId?: string;
      slug?: string;
      status?: Auction.StatusEnum;
      visibilityFlag?: boolean;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artworks = artworks;
    this.aucDateEdtf = aucDateEdtf;
    this.aucDateMax = aucDateMax;
    this.aucDateMin = aucDateMin;
    this.aucDateText = aucDateText;
    this.aucDateUseTextFlag = aucDateUseTextFlag;
    this.aucRoom = aucRoom;
    this.aucShortTitle = aucShortTitle;
    this.aucTitle = aucTitle;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.lastModifier = lastModifier;
    this.masterFlag = masterFlag;
    this.modifiedBy = modifiedBy;
    this.numberOfHits = numberOfHits;
    this.publication = publication;
    this.questionableFlag = questionableFlag;
    this.replacementId = replacementId;
    this.slug = slug;
    this.status = status;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace Auction {

  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Auction form builder
 *
 */
export class AuctionFormBuilder extends AbstractFormBuilder<AuctionFormBuilder> {

  className = 'Auction';

  abstractFormBuilder!: AbstractFormBuilder<Auction>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Auction {
    return new Auction();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artworks(
    builder: ArtworkFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artworks', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtworks() {
    super.removeField('artworks');
    return this;
  }

  public aucDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucDateEdtf() {
    super.removeField('aucDateEdtf');
    return this;
  }

  public aucDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucDateMax() {
    super.removeField('aucDateMax');
    return this;
  }

  public aucDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucDateMin() {
    super.removeField('aucDateMin');
    return this;
  }

  public aucDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucDateText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucDateText() {
    super.removeField('aucDateText');
    return this;
  }

  public aucDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucDateUseTextFlag() {
    super.removeField('aucDateUseTextFlag');
    return this;
  }

  public aucRoom(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucRoom', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucRoom() {
    super.removeField('aucRoom');
    return this;
  }

  public aucShortTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucShortTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucShortTitle() {
    super.removeField('aucShortTitle');
    return this;
  }

  public aucTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucTitle() {
    super.removeField('aucTitle');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public numberOfHits(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('numberOfHits', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNumberOfHits() {
    super.removeField('numberOfHits');
    return this;
  }


  public publication(
    builder: PublicationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('publication', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPublication() {
    super.removeField('publication');
    return this;
  }

  public questionableFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('questionableFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutQuestionableFlag() {
    super.removeField('questionableFlag');
    return this;
  }

  public replacementId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('replacementId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReplacementId() {
    super.removeField('replacementId');
    return this;
  }

  public slug(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('slug', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSlug() {
    super.removeField('slug');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): AuctionFormBuilder {
    return this
    .aucDateEdtf() 
    .aucDateMax() 
    .aucDateMin() 
    .aucDateText() 
    .aucDateUseTextFlag() 
    .aucRoom() 
    .aucShortTitle() 
    .aucTitle() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .masterFlag() 
    .modifiedBy() 
    .numberOfHits() 
    .questionableFlag() 
    .replacementId() 
    .slug() 
    .status() 
    .visibilityFlag() 
;
  }
}

