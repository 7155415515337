/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class UserSettings {
  taskAssignmentNotification?: boolean;
  taskDailyDigest?: boolean;
  taskWeeklyDigest?: boolean;

  constructor({
      taskAssignmentNotification, 
      taskDailyDigest, 
      taskWeeklyDigest
  }: {
      taskAssignmentNotification?: boolean;
      taskDailyDigest?: boolean;
      taskWeeklyDigest?: boolean;
  } = {}) {
    this.taskAssignmentNotification = taskAssignmentNotification;
    this.taskDailyDigest = taskDailyDigest;
    this.taskWeeklyDigest = taskWeeklyDigest;
  }
}

export namespace UserSettings {

}


/**
 * UserSettings form builder
 *
 */
export class UserSettingsFormBuilder extends AbstractFormBuilder<UserSettingsFormBuilder> {

  className = 'UserSettings';

  abstractFormBuilder!: AbstractFormBuilder<UserSettings>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): UserSettings {
    return new UserSettings();
  }

  public taskAssignmentNotification(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('taskAssignmentNotification', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTaskAssignmentNotification() {
    super.removeField('taskAssignmentNotification');
    return this;
  }

  public taskDailyDigest(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('taskDailyDigest', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTaskDailyDigest() {
    super.removeField('taskDailyDigest');
    return this;
  }

  public taskWeeklyDigest(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('taskWeeklyDigest', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTaskWeeklyDigest() {
    super.removeField('taskWeeklyDigest');
    return this;
  }


  public all(): UserSettingsFormBuilder {
    return this
    .taskAssignmentNotification() 
    .taskDailyDigest() 
    .taskWeeklyDigest() 
;
  }
}

