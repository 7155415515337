/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class MarkingQueryFilters {
  agentSelections?: Array<AgentSelection>;
  aucId?: string;
  exhId?: string;
  globalId?: string;
  id?: string;
  markingType?: MarkingQueryFilters.MarkingTypeEnum;
  name?: string;
  period?: DateQuery;
  researchStatus?: MarkingQueryFilters.ResearchStatusEnum;
  technique?: string;
  visibilityFlag?: boolean;

  constructor({
      agentSelections, 
      aucId, 
      exhId, 
      globalId, 
      id, 
      markingType, 
      name, 
      period, 
      researchStatus, 
      technique, 
      visibilityFlag
  }: {
      agentSelections?: Array<AgentSelection>;
      aucId?: string;
      exhId?: string;
      globalId?: string;
      id?: string;
      markingType?: MarkingQueryFilters.MarkingTypeEnum;
      name?: string;
      period?: DateQuery;
      researchStatus?: MarkingQueryFilters.ResearchStatusEnum;
      technique?: string;
      visibilityFlag?: boolean;
  } = {}) {
    this.agentSelections = agentSelections;
    this.aucId = aucId;
    this.exhId = exhId;
    this.globalId = globalId;
    this.id = id;
    this.markingType = markingType;
    this.name = name;
    this.period = period;
    this.researchStatus = researchStatus;
    this.technique = technique;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace MarkingQueryFilters {

  export type MarkingTypeEnum = 'branding stamp' | 'ink stamp' | 'embossing stamp' | 'label' | 'plate' | 'tag' | 'watermark' | 'wax seal';
  export const MarkingTypeEnum = {
      BrandingStamp: 'branding stamp' as MarkingTypeEnum,
      InkStamp: 'ink stamp' as MarkingTypeEnum,
      EmbossingStamp: 'embossing stamp' as MarkingTypeEnum,
      Label: 'label' as MarkingTypeEnum,
      Plate: 'plate' as MarkingTypeEnum,
      Tag: 'tag' as MarkingTypeEnum,
      Watermark: 'watermark' as MarkingTypeEnum,
      WaxSeal: 'wax seal' as MarkingTypeEnum,
  }
  export const MarkingTypeEnumValues = [
      MarkingTypeEnum.BrandingStamp, 
      MarkingTypeEnum.InkStamp, 
      MarkingTypeEnum.EmbossingStamp, 
      MarkingTypeEnum.Label, 
      MarkingTypeEnum.Plate, 
      MarkingTypeEnum.Tag, 
      MarkingTypeEnum.Watermark, 
      MarkingTypeEnum.WaxSeal
  ];
  export type ResearchStatusEnum = 'unidentified' | 'identified' | 'unidentifiable';
  export const ResearchStatusEnum = {
      Unidentified: 'unidentified' as ResearchStatusEnum,
      Identified: 'identified' as ResearchStatusEnum,
      Unidentifiable: 'unidentifiable' as ResearchStatusEnum,
  }
  export const ResearchStatusEnumValues = [
      ResearchStatusEnum.Unidentified, 
      ResearchStatusEnum.Identified, 
      ResearchStatusEnum.Unidentifiable
  ];
}


/**
 * MarkingQueryFilters form builder
 *
 */
export class MarkingQueryFiltersFormBuilder extends AbstractFormBuilder<MarkingQueryFiltersFormBuilder> {

  className = 'MarkingQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<MarkingQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): MarkingQueryFilters {
    return new MarkingQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public aucId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucId() {
    super.removeField('aucId');
    return this;
  }

  public exhId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhId() {
    super.removeField('exhId');
    return this;
  }

  public globalId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('globalId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGlobalId() {
    super.removeField('globalId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public markingType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('markingType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMarkingType() {
    super.removeField('markingType');
    return this;
  }

  public name(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('name', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutName() {
    super.removeField('name');
    return this;
  }


  public period(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('period', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPeriod() {
    super.removeField('period');
    return this;
  }

  public researchStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('researchStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutResearchStatus() {
    super.removeField('researchStatus');
    return this;
  }

  public technique(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('technique', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTechnique() {
    super.removeField('technique');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): MarkingQueryFiltersFormBuilder {
    return this
    .aucId() 
    .exhId() 
    .globalId() 
    .id() 
    .markingType() 
    .name() 
    .researchStatus() 
    .technique() 
    .visibilityFlag() 
;
  }
}

