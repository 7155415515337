/* tslint:disable */
/* eslint-disable */
import { Place, PlaceFormBuilder } from '../models/place';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentAddress {
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  displayName?: string;
  id?: string;
  lastModifier?: User;
  latitude?: number;
  longitude?: number;
  modifiedBy?: string;
  place?: Place;
  primary?: boolean;
  renderedText?: string;
  zipcode?: string;

  constructor({
      address1, 
      address2, 
      address3, 
      address4, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      displayName, 
      id, 
      lastModifier, 
      latitude, 
      longitude, 
      modifiedBy, 
      place, 
      primary, 
      renderedText, 
      zipcode
  }: {
      address1?: string;
      address2?: string;
      address3?: string;
      address4?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      displayName?: string;
      id?: string;
      lastModifier?: User;
      latitude?: number;
      longitude?: number;
      modifiedBy?: string;
      place?: Place;
      primary?: boolean;
      renderedText?: string;
      zipcode?: string;
  } = {}) {
    this.address1 = address1;
    this.address2 = address2;
    this.address3 = address3;
    this.address4 = address4;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.displayName = displayName;
    this.id = id;
    this.lastModifier = lastModifier;
    this.latitude = latitude;
    this.longitude = longitude;
    this.modifiedBy = modifiedBy;
    this.place = place;
    this.primary = primary;
    this.renderedText = renderedText;
    this.zipcode = zipcode;
  }
}

export namespace AgentAddress {

}


/**
 * AgentAddress form builder
 *
 */
export class AgentAddressFormBuilder extends AbstractFormBuilder<AgentAddressFormBuilder> {

  className = 'AgentAddress';

  abstractFormBuilder!: AbstractFormBuilder<AgentAddress>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentAddress {
    return new AgentAddress();
  }

  public address1(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('address1', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAddress1() {
    super.removeField('address1');
    return this;
  }

  public address2(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('address2', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAddress2() {
    super.removeField('address2');
    return this;
  }

  public address3(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('address3', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAddress3() {
    super.removeField('address3');
    return this;
  }

  public address4(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('address4', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAddress4() {
    super.removeField('address4');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public displayName(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('displayName', [null, [Validators.minLength(3), Validators.maxLength(255), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisplayName() {
    super.removeField('displayName');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public latitude(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('latitude', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLatitude() {
    super.removeField('latitude');
    return this;
  }

  public longitude(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('longitude', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLongitude() {
    super.removeField('longitude');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public place(
    builder: PlaceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('place', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlace() {
    super.removeField('place');
    return this;
  }

  public primary(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('primary', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPrimary() {
    super.removeField('primary');
    return this;
  }

  public renderedText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('renderedText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRenderedText() {
    super.removeField('renderedText');
    return this;
  }

  public zipcode(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('zipcode', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutZipcode() {
    super.removeField('zipcode');
    return this;
  }


  public all(): AgentAddressFormBuilder {
    return this
    .address1() 
    .address2() 
    .address3() 
    .address4() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .displayName() 
    .id() 
    .latitude() 
    .longitude() 
    .modifiedBy() 
    .primary() 
    .renderedText() 
    .zipcode() 
;
  }
}

