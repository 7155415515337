/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Place {
  agentSelections?: Array<AgentSelection>;
  altCoordinates?: string;
  altCoordinatesQualifier?: string;
  altCoordinatesType?: Place.AltCoordinatesTypeEnum;
  city?: string;
  continent?: string;
  country?: string;
  countryDisplayFlag?: boolean;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  description?: string;
  district?: string;
  environmentalDetails?: string;
  featureDate?: string;
  featureName?: string;
  featureType?: string;
  gettyParentTgnId?: number;
  gettyTgnId?: string;
  gettyType?: string;
  hits?: number;
  id?: string;
  lastModifier?: User;
  latitude?: number;
  longitude?: number;
  masterFlag?: Place.MasterFlagEnum;
  modifiedBy?: string;
  parent?: number;
  place?: string;
  placeStatus?: string;
  placeSystem?: string;
  prioNo?: number;
  refNum?: string;
  refNumType?: string;
  region?: string;
  regionDisplayFlag?: boolean;
  renderedText?: string;
  replacementId?: string;
  status?: Place.StatusEnum;
  type?: Place.TypeEnum;
  typeDetail?: string;
  useDateEnd?: string;
  useDateStart?: string;

  constructor({
      agentSelections, 
      altCoordinates, 
      altCoordinatesQualifier, 
      altCoordinatesType, 
      city, 
      continent, 
      country, 
      countryDisplayFlag, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      description, 
      district, 
      environmentalDetails, 
      featureDate, 
      featureName, 
      featureType, 
      gettyParentTgnId, 
      gettyTgnId, 
      gettyType, 
      hits, 
      id, 
      lastModifier, 
      latitude, 
      longitude, 
      masterFlag, 
      modifiedBy, 
      parent, 
      place, 
      placeStatus, 
      placeSystem, 
      prioNo, 
      refNum, 
      refNumType, 
      region, 
      regionDisplayFlag, 
      renderedText, 
      replacementId, 
      status, 
      type, 
      typeDetail, 
      useDateEnd, 
      useDateStart
  }: {
      agentSelections?: Array<AgentSelection>;
      altCoordinates?: string;
      altCoordinatesQualifier?: string;
      altCoordinatesType?: Place.AltCoordinatesTypeEnum;
      city?: string;
      continent?: string;
      country?: string;
      countryDisplayFlag?: boolean;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      description?: string;
      district?: string;
      environmentalDetails?: string;
      featureDate?: string;
      featureName?: string;
      featureType?: string;
      gettyParentTgnId?: number;
      gettyTgnId?: string;
      gettyType?: string;
      hits?: number;
      id?: string;
      lastModifier?: User;
      latitude?: number;
      longitude?: number;
      masterFlag?: Place.MasterFlagEnum;
      modifiedBy?: string;
      parent?: number;
      place?: string;
      placeStatus?: string;
      placeSystem?: string;
      prioNo?: number;
      refNum?: string;
      refNumType?: string;
      region?: string;
      regionDisplayFlag?: boolean;
      renderedText?: string;
      replacementId?: string;
      status?: Place.StatusEnum;
      type?: Place.TypeEnum;
      typeDetail?: string;
      useDateEnd?: string;
      useDateStart?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.altCoordinates = altCoordinates;
    this.altCoordinatesQualifier = altCoordinatesQualifier;
    this.altCoordinatesType = altCoordinatesType;
    this.city = city;
    this.continent = continent;
    this.country = country;
    this.countryDisplayFlag = countryDisplayFlag;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.description = description;
    this.district = district;
    this.environmentalDetails = environmentalDetails;
    this.featureDate = featureDate;
    this.featureName = featureName;
    this.featureType = featureType;
    this.gettyParentTgnId = gettyParentTgnId;
    this.gettyTgnId = gettyTgnId;
    this.gettyType = gettyType;
    this.hits = hits;
    this.id = id;
    this.lastModifier = lastModifier;
    this.latitude = latitude;
    this.longitude = longitude;
    this.masterFlag = masterFlag;
    this.modifiedBy = modifiedBy;
    this.parent = parent;
    this.place = place;
    this.placeStatus = placeStatus;
    this.placeSystem = placeSystem;
    this.prioNo = prioNo;
    this.refNum = refNum;
    this.refNumType = refNumType;
    this.region = region;
    this.regionDisplayFlag = regionDisplayFlag;
    this.renderedText = renderedText;
    this.replacementId = replacementId;
    this.status = status;
    this.type = type;
    this.typeDetail = typeDetail;
    this.useDateEnd = useDateEnd;
    this.useDateStart = useDateStart;
  }
}

export namespace Place {

  export type AltCoordinatesTypeEnum = 'LL' | 'NGR' | 'UTM' | 'Altitude Depth';
  export const AltCoordinatesTypeEnum = {
      LL: 'LL' as AltCoordinatesTypeEnum,
      NGR: 'NGR' as AltCoordinatesTypeEnum,
      UTM: 'UTM' as AltCoordinatesTypeEnum,
      AltitudeDepth: 'Altitude Depth' as AltCoordinatesTypeEnum,
  }
  export const AltCoordinatesTypeEnumValues = [
      AltCoordinatesTypeEnum.LL, 
      AltCoordinatesTypeEnum.NGR, 
      AltCoordinatesTypeEnum.UTM, 
      AltCoordinatesTypeEnum.AltitudeDepth
  ];
  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
  export type TypeEnum = 'district' | 'city' | 'region' | 'country' | 'other';
  export const TypeEnum = {
      District: 'district' as TypeEnum,
      City: 'city' as TypeEnum,
      Region: 'region' as TypeEnum,
      Country: 'country' as TypeEnum,
      Other: 'other' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.District, 
      TypeEnum.City, 
      TypeEnum.Region, 
      TypeEnum.Country, 
      TypeEnum.Other
  ];
}


/**
 * Place form builder
 *
 */
export class PlaceFormBuilder extends AbstractFormBuilder<PlaceFormBuilder> {

  className = 'Place';

  abstractFormBuilder!: AbstractFormBuilder<Place>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Place {
    return new Place();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public altCoordinates(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('altCoordinates', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAltCoordinates() {
    super.removeField('altCoordinates');
    return this;
  }

  public altCoordinatesQualifier(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('altCoordinatesQualifier', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAltCoordinatesQualifier() {
    super.removeField('altCoordinatesQualifier');
    return this;
  }

  public altCoordinatesType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('altCoordinatesType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAltCoordinatesType() {
    super.removeField('altCoordinatesType');
    return this;
  }

  public city(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('city', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCity() {
    super.removeField('city');
    return this;
  }

  public continent(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('continent', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContinent() {
    super.removeField('continent');
    return this;
  }

  public country(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('country', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCountry() {
    super.removeField('country');
    return this;
  }

  public countryDisplayFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('countryDisplayFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCountryDisplayFlag() {
    super.removeField('countryDisplayFlag');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public district(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('district', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDistrict() {
    super.removeField('district');
    return this;
  }

  public environmentalDetails(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('environmentalDetails', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEnvironmentalDetails() {
    super.removeField('environmentalDetails');
    return this;
  }

  public featureDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('featureDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFeatureDate() {
    super.removeField('featureDate');
    return this;
  }

  public featureName(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('featureName', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFeatureName() {
    super.removeField('featureName');
    return this;
  }

  public featureType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('featureType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFeatureType() {
    super.removeField('featureType');
    return this;
  }

  public gettyParentTgnId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyParentTgnId', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyParentTgnId() {
    super.removeField('gettyParentTgnId');
    return this;
  }

  public gettyTgnId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyTgnId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyTgnId() {
    super.removeField('gettyTgnId');
    return this;
  }

  public gettyType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyType() {
    super.removeField('gettyType');
    return this;
  }

  public hits(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('hits', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHits() {
    super.removeField('hits');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public latitude(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('latitude', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLatitude() {
    super.removeField('latitude');
    return this;
  }

  public longitude(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('longitude', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLongitude() {
    super.removeField('longitude');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public parent(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parent', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParent() {
    super.removeField('parent');
    return this;
  }

  public place(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('place', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPlace() {
    super.removeField('place');
    return this;
  }

  public placeStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('placeStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPlaceStatus() {
    super.removeField('placeStatus');
    return this;
  }

  public placeSystem(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('placeSystem', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPlaceSystem() {
    super.removeField('placeSystem');
    return this;
  }

  public prioNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('prioNo', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPrioNo() {
    super.removeField('prioNo');
    return this;
  }

  public refNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNum() {
    super.removeField('refNum');
    return this;
  }

  public refNumType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNumType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNumType() {
    super.removeField('refNumType');
    return this;
  }

  public region(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('region', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRegion() {
    super.removeField('region');
    return this;
  }

  public regionDisplayFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('regionDisplayFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRegionDisplayFlag() {
    super.removeField('regionDisplayFlag');
    return this;
  }

  public renderedText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('renderedText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRenderedText() {
    super.removeField('renderedText');
    return this;
  }

  public replacementId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('replacementId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReplacementId() {
    super.removeField('replacementId');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }

  public typeDetail(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('typeDetail', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTypeDetail() {
    super.removeField('typeDetail');
    return this;
  }

  public useDateEnd(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('useDateEnd', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUseDateEnd() {
    super.removeField('useDateEnd');
    return this;
  }

  public useDateStart(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('useDateStart', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUseDateStart() {
    super.removeField('useDateStart');
    return this;
  }


  public all(): PlaceFormBuilder {
    return this
    .altCoordinates() 
    .altCoordinatesQualifier() 
    .altCoordinatesType() 
    .city() 
    .continent() 
    .country() 
    .countryDisplayFlag() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .description() 
    .district() 
    .environmentalDetails() 
    .featureDate() 
    .featureName() 
    .featureType() 
    .gettyParentTgnId() 
    .gettyTgnId() 
    .gettyType() 
    .hits() 
    .id() 
    .latitude() 
    .longitude() 
    .masterFlag() 
    .modifiedBy() 
    .parent() 
    .place() 
    .placeStatus() 
    .placeSystem() 
    .prioNo() 
    .refNum() 
    .refNumType() 
    .region() 
    .regionDisplayFlag() 
    .renderedText() 
    .replacementId() 
    .status() 
    .type() 
    .typeDetail() 
    .useDateEnd() 
    .useDateStart() 
;
  }
}

