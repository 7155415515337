/* tslint:disable */
/* eslint-disable */
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class GlobalWebConfig {
  active?: boolean;
  config?: any;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  description?: string;
  disableDeployment?: boolean;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;

  constructor({
      active, 
      config, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      description, 
      disableDeployment, 
      id, 
      lastModifier, 
      modifiedBy
  }: {
      active?: boolean;
      config?: any;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      description?: string;
      disableDeployment?: boolean;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
  } = {}) {
    this.active = active;
    this.config = config;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.description = description;
    this.disableDeployment = disableDeployment;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
  }
}

export namespace GlobalWebConfig {

}


/**
 * GlobalWebConfig form builder
 *
 */
export class GlobalWebConfigFormBuilder extends AbstractFormBuilder<GlobalWebConfigFormBuilder> {

  className = 'GlobalWebConfig';

  abstractFormBuilder!: AbstractFormBuilder<GlobalWebConfig>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): GlobalWebConfig {
    return new GlobalWebConfig();
  }

  public active(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('active', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutActive() {
    super.removeField('active');
    return this;
  }

  public config(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('config', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConfig() {
    super.removeField('config');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public disableDeployment(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('disableDeployment', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisableDeployment() {
    super.removeField('disableDeployment');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public all(): GlobalWebConfigFormBuilder {
    return this
    .active() 
    .config() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .description() 
    .disableDeployment() 
    .id() 
    .modifiedBy() 
;
  }
}

