/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { Artwork, ArtworkFormBuilder } from '../models/artwork';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { Auction, AuctionFormBuilder } from '../models/auction';
import { Exhibition, ExhibitionFormBuilder } from '../models/exhibition';
import { Publication, PublicationFormBuilder } from '../models/publication';
import { Resource, ResourceFormBuilder } from '../models/resource';
import { Task, TaskFormBuilder } from '../models/task';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class TaskQueryResponseData {
  agent?: Agent;
  artEvent?: ArtworkEvent;
  artwork?: Artwork;
  auction?: Auction;
  entityType?: TaskQueryResponseData.EntityTypeEnum;
  exhibition?: Exhibition;
  publication?: Publication;
  resource?: Resource;
  task?: Task;

  constructor({
      agent, 
      artEvent, 
      artwork, 
      auction, 
      entityType, 
      exhibition, 
      publication, 
      resource, 
      task
  }: {
      agent?: Agent;
      artEvent?: ArtworkEvent;
      artwork?: Artwork;
      auction?: Auction;
      entityType?: TaskQueryResponseData.EntityTypeEnum;
      exhibition?: Exhibition;
      publication?: Publication;
      resource?: Resource;
      task?: Task;
  } = {}) {
    this.agent = agent;
    this.artEvent = artEvent;
    this.artwork = artwork;
    this.auction = auction;
    this.entityType = entityType;
    this.exhibition = exhibition;
    this.publication = publication;
    this.resource = resource;
    this.task = task;
  }
}

export namespace TaskQueryResponseData {

  export type EntityTypeEnum = 'Artwork' | 'Auction' | 'Agent' | 'ArtEvent' | 'Exhibition' | 'Publication' | 'Resource' | 'ArtworksInArtSet' | 'ArtworksByArtist';
  export const EntityTypeEnum = {
      Artwork: 'Artwork' as EntityTypeEnum,
      Auction: 'Auction' as EntityTypeEnum,
      Agent: 'Agent' as EntityTypeEnum,
      ArtEvent: 'ArtEvent' as EntityTypeEnum,
      Exhibition: 'Exhibition' as EntityTypeEnum,
      Publication: 'Publication' as EntityTypeEnum,
      Resource: 'Resource' as EntityTypeEnum,
      ArtworksInArtSet: 'ArtworksInArtSet' as EntityTypeEnum,
      ArtworksByArtist: 'ArtworksByArtist' as EntityTypeEnum,
  }
  export const EntityTypeEnumValues = [
      EntityTypeEnum.Artwork, 
      EntityTypeEnum.Auction, 
      EntityTypeEnum.Agent, 
      EntityTypeEnum.ArtEvent, 
      EntityTypeEnum.Exhibition, 
      EntityTypeEnum.Publication, 
      EntityTypeEnum.Resource, 
      EntityTypeEnum.ArtworksInArtSet, 
      EntityTypeEnum.ArtworksByArtist
  ];
}


/**
 * TaskQueryResponseData form builder
 *
 */
export class TaskQueryResponseDataFormBuilder extends AbstractFormBuilder<TaskQueryResponseDataFormBuilder> {

  className = 'TaskQueryResponseData';

  abstractFormBuilder!: AbstractFormBuilder<TaskQueryResponseData>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): TaskQueryResponseData {
    return new TaskQueryResponseData();
  }


  public agent(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agent', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgent() {
    super.removeField('agent');
    return this;
  }


  public artEvent(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvent', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvent() {
    super.removeField('artEvent');
    return this;
  }


  public artwork(
    builder: ArtworkFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artwork', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtwork() {
    super.removeField('artwork');
    return this;
  }


  public auction(
    builder: AuctionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('auction', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAuction() {
    super.removeField('auction');
    return this;
  }

  public entityType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityType() {
    super.removeField('entityType');
    return this;
  }


  public exhibition(
    builder: ExhibitionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exhibition', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExhibition() {
    super.removeField('exhibition');
    return this;
  }


  public publication(
    builder: PublicationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('publication', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPublication() {
    super.removeField('publication');
    return this;
  }


  public resource(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('resource', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutResource() {
    super.removeField('resource');
    return this;
  }


  public task(
    builder: TaskFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('task', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutTask() {
    super.removeField('task');
    return this;
  }


  public all(): TaskQueryResponseDataFormBuilder {
    return this
    .entityType() 
;
  }
}

