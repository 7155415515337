/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { IndexingTerm, IndexingTermFormBuilder } from '../models/indexing-term';
import { OtherId, OtherIdFormBuilder } from '../models/other-id';
import { Place, PlaceFormBuilder } from '../models/place';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PublicResourceQueryFilters {
  agents?: Array<AgentSelection>;
  allFields?: string;
  containsMediaFlag?: boolean;
  creationDate?: DateQuery;
  creationDateEdtf?: string;
  /** @deprecated */genres?: Array<IndexingTerm>;
  hasPreviewImageFlag?: boolean;
  id?: string;
  includeArchived?: boolean;
  indexingTerms?: Array<IndexingTerm>;
  levelOfDescription?: PublicResourceQueryFilters.LevelOfDescriptionEnum;
  otherIds?: Array<OtherId>;
  places?: Array<Place>;
  /** @deprecated */subjects?: Array<IndexingTerm>;
  title?: string;

  constructor({
      agents, 
      allFields, 
      containsMediaFlag, 
      creationDate, 
      creationDateEdtf, 
      /** @deprecated */genres, 
      hasPreviewImageFlag, 
      id, 
      includeArchived, 
      indexingTerms, 
      levelOfDescription, 
      otherIds, 
      places, 
      /** @deprecated */subjects, 
      title
  }: {
      agents?: Array<AgentSelection>;
      allFields?: string;
      containsMediaFlag?: boolean;
      creationDate?: DateQuery;
      creationDateEdtf?: string;
      /** @deprecated */genres?: Array<IndexingTerm>;
      hasPreviewImageFlag?: boolean;
      id?: string;
      includeArchived?: boolean;
      indexingTerms?: Array<IndexingTerm>;
      levelOfDescription?: PublicResourceQueryFilters.LevelOfDescriptionEnum;
      otherIds?: Array<OtherId>;
      places?: Array<Place>;
      /** @deprecated */subjects?: Array<IndexingTerm>;
      title?: string;
  } = {}) {
    this.agents = agents;
    this.allFields = allFields;
    this.containsMediaFlag = containsMediaFlag;
    this.creationDate = creationDate;
    this.creationDateEdtf = creationDateEdtf;
    this.genres = genres;
    this.hasPreviewImageFlag = hasPreviewImageFlag;
    this.id = id;
    this.includeArchived = includeArchived;
    this.indexingTerms = indexingTerms;
    this.levelOfDescription = levelOfDescription;
    this.otherIds = otherIds;
    this.places = places;
    this.subjects = subjects;
    this.title = title;
  }
}

export namespace PublicResourceQueryFilters {

  export type LevelOfDescriptionEnum = 'Collection' | 'Series' | 'File' | 'Item';
  export const LevelOfDescriptionEnum = {
      Collection: 'Collection' as LevelOfDescriptionEnum,
      Series: 'Series' as LevelOfDescriptionEnum,
      File: 'File' as LevelOfDescriptionEnum,
      Item: 'Item' as LevelOfDescriptionEnum,
  }
  export const LevelOfDescriptionEnumValues = [
      LevelOfDescriptionEnum.Collection, 
      LevelOfDescriptionEnum.Series, 
      LevelOfDescriptionEnum.File, 
      LevelOfDescriptionEnum.Item
  ];
}


/**
 * PublicResourceQueryFilters form builder
 *
 */
export class PublicResourceQueryFiltersFormBuilder extends AbstractFormBuilder<PublicResourceQueryFiltersFormBuilder> {

  className = 'PublicResourceQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PublicResourceQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PublicResourceQueryFilters {
    return new PublicResourceQueryFilters();
  }


  public agents(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgents() {
    super.removeField('agents');
    return this;
  }

  public allFields(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('allFields', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAllFields() {
    super.removeField('allFields');
    return this;
  }

  public containsMediaFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('containsMediaFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContainsMediaFlag() {
    super.removeField('containsMediaFlag');
    return this;
  }


  public creationDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creationDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreationDate() {
    super.removeField('creationDate');
    return this;
  }

  public creationDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('creationDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreationDateEdtf() {
    super.removeField('creationDateEdtf');
    return this;
  }


  public genres(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('genres', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutGenres() {
    super.removeField('genres');
    return this;
  }

  public hasPreviewImageFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('hasPreviewImageFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHasPreviewImageFlag() {
    super.removeField('hasPreviewImageFlag');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public includeArchived(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('includeArchived', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIncludeArchived() {
    super.removeField('includeArchived');
    return this;
  }


  public indexingTerms(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTerms', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTerms() {
    super.removeField('indexingTerms');
    return this;
  }

  public levelOfDescription(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('levelOfDescription', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLevelOfDescription() {
    super.removeField('levelOfDescription');
    return this;
  }


  public otherIds(
    builder: OtherIdFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('otherIds', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOtherIds() {
    super.removeField('otherIds');
    return this;
  }


  public places(
    builder: PlaceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('places', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlaces() {
    super.removeField('places');
    return this;
  }


  public subjects(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('subjects', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSubjects() {
    super.removeField('subjects');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }


  public all(): PublicResourceQueryFiltersFormBuilder {
    return this
    .allFields() 
    .containsMediaFlag() 
    .creationDateEdtf() 
    .hasPreviewImageFlag() 
    .id() 
    .includeArchived() 
    .levelOfDescription() 
    .title() 
;
  }
}

