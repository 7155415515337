import { IEnvironment } from './environment.interfaces';
export const environment: IEnvironment = {
  "id": "dv",
  "name": "Development",
  "production": false,
  "language": "en",
  "cat": {
    "apiUrl": "https://api.dv.navigatingart.de"
  },
  "publicDisplay": {
    "enabled": false,
    "url": "https://pd.dv.navigatingart.de",
  },
  "media": {
    "imageExtensions": ["jpg","jpeg","png","gif","svg","webp","tif","tiff","heic","heif","jfif"],
    "attachmentExtensions": ["pdf","doc","docx","xls","xlsx","ppt","pptx","eps","psd","heic","zip","rar","eps","pcd","pct"],
  },
  "cognito": {
    "region": "us-east-1",
    "userPoolId": "us-east-1_OGyVBNmSx",
    "clientId": "6tsio5k2vlddueucdc4499guv0",
  },
  "s3": {
    "albumBucketName": '11168766193313965950-upload',
    "bucketRegion": 'us-east-1',
    "identityPoolId": 'us-east-1:eda52478-8662-4161-abe6-d65b7cbc96aa'
  },
  "sentry": {
    "enabled": true,
    "environment": "dv",
    "dsn": "https://d8efdb356c4147b68c6e106ca48d741d@o570661.ingest.sentry.io/5719814",
    "debug": false,
    "attachStacktrace": true,
    "tracesSampleRate": 1
  },
  "cmEnabled": true,
  "aiEnabled": true,
};


import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
