/* tslint:disable */
/* eslint-disable */
import { Media, MediaFormBuilder } from '../models/media';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtworkMedia {
  comment?: string;
  id?: string;
  media?: Media;
  primary?: boolean;
  refType?: ArtworkMedia.RefTypeEnum;
  seqNum?: number;

  constructor({
      comment, 
      id, 
      media, 
      primary, 
      refType, 
      seqNum
  }: {
      comment?: string;
      id?: string;
      media?: Media;
      primary?: boolean;
      refType?: ArtworkMedia.RefTypeEnum;
      seqNum?: number;
  } = {}) {
    this.comment = comment;
    this.id = id;
    this.media = media;
    this.primary = primary;
    this.refType = refType;
    this.seqNum = seqNum;
  }
}

export namespace ArtworkMedia {

  export type RefTypeEnum = 'preview' | 'related';
  export const RefTypeEnum = {
      Preview: 'preview' as RefTypeEnum,
      Related: 'related' as RefTypeEnum,
  }
  export const RefTypeEnumValues = [
      RefTypeEnum.Preview, 
      RefTypeEnum.Related
  ];
}


/**
 * ArtworkMedia form builder
 *
 */
export class ArtworkMediaFormBuilder extends AbstractFormBuilder<ArtworkMediaFormBuilder> {

  className = 'ArtworkMedia';

  abstractFormBuilder!: AbstractFormBuilder<ArtworkMedia>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtworkMedia {
    return new ArtworkMedia();
  }

  public comment(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('comment', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutComment() {
    super.removeField('comment');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public media(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('media', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMedia() {
    super.removeField('media');
    return this;
  }

  public primary(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('primary', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPrimary() {
    super.removeField('primary');
    return this;
  }

  public refType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefType() {
    super.removeField('refType');
    return this;
  }

  public seqNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seqNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeqNum() {
    super.removeField('seqNum');
    return this;
  }


  public all(): ArtworkMediaFormBuilder {
    return this
    .comment() 
    .id() 
    .primary() 
    .refType() 
    .seqNum() 
;
  }
}

