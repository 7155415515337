/* tslint:disable */
/* eslint-disable */
import { AgentAddress, AgentAddressFormBuilder } from '../models/agent-address';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentAddressMapping {
  action?: AgentAddressMapping.ActionEnum;
  duplicate?: AgentAddress;
  master?: AgentAddress;

  constructor({
      action, 
      duplicate, 
      master
  }: {
      action?: AgentAddressMapping.ActionEnum;
      duplicate?: AgentAddress;
      master?: AgentAddress;
  } = {}) {
    this.action = action;
    this.duplicate = duplicate;
    this.master = master;
  }
}

export namespace AgentAddressMapping {

  export type ActionEnum = 'replace' | 'delete' | 'add';
  export const ActionEnum = {
      Replace: 'replace' as ActionEnum,
      Delete: 'delete' as ActionEnum,
      Add: 'add' as ActionEnum,
  }
  export const ActionEnumValues = [
      ActionEnum.Replace, 
      ActionEnum.Delete, 
      ActionEnum.Add
  ];
}


/**
 * AgentAddressMapping form builder
 *
 */
export class AgentAddressMappingFormBuilder extends AbstractFormBuilder<AgentAddressMappingFormBuilder> {

  className = 'AgentAddressMapping';

  abstractFormBuilder!: AbstractFormBuilder<AgentAddressMapping>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentAddressMapping {
    return new AgentAddressMapping();
  }

  public action(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('action', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAction() {
    super.removeField('action');
    return this;
  }


  public duplicate(
    builder: AgentAddressFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('duplicate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDuplicate() {
    super.removeField('duplicate');
    return this;
  }


  public master(
    builder: AgentAddressFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('master', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMaster() {
    super.removeField('master');
    return this;
  }


  public all(): AgentAddressMappingFormBuilder {
    return this
    .action() 
;
  }
}

