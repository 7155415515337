/* tslint:disable */
/* eslint-disable */
import { Artwork, ArtworkFormBuilder } from '../models/artwork';
import { Marking, MarkingFormBuilder } from '../models/marking';
import { Media, MediaFormBuilder } from '../models/media';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class MarkingSelection {
  artwork?: Artwork;
  comment?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  image?: Media;
  lastModifier?: User;
  marking?: Marking;
  markingCondition?: string;
  markingLocation?: MarkingSelection.MarkingLocationEnum;
  modifiedBy?: string;
  status?: MarkingSelection.StatusEnum;
  visibilityFlag?: boolean;

  constructor({
      artwork, 
      comment, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      image, 
      lastModifier, 
      marking, 
      markingCondition, 
      markingLocation, 
      modifiedBy, 
      status, 
      visibilityFlag
  }: {
      artwork?: Artwork;
      comment?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      image?: Media;
      lastModifier?: User;
      marking?: Marking;
      markingCondition?: string;
      markingLocation?: MarkingSelection.MarkingLocationEnum;
      modifiedBy?: string;
      status?: MarkingSelection.StatusEnum;
      visibilityFlag?: boolean;
  } = {}) {
    this.artwork = artwork;
    this.comment = comment;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.image = image;
    this.lastModifier = lastModifier;
    this.marking = marking;
    this.markingCondition = markingCondition;
    this.markingLocation = markingLocation;
    this.modifiedBy = modifiedBy;
    this.status = status;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace MarkingSelection {

  export type MarkingLocationEnum = 'on canvas' | 'on stretcher' | 'on frame' | 'on backing board';
  export const MarkingLocationEnum = {
      OnCanvas: 'on canvas' as MarkingLocationEnum,
      OnStretcher: 'on stretcher' as MarkingLocationEnum,
      OnFrame: 'on frame' as MarkingLocationEnum,
      OnBackingBoard: 'on backing board' as MarkingLocationEnum,
  }
  export const MarkingLocationEnumValues = [
      MarkingLocationEnum.OnCanvas, 
      MarkingLocationEnum.OnStretcher, 
      MarkingLocationEnum.OnFrame, 
      MarkingLocationEnum.OnBackingBoard
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * MarkingSelection form builder
 *
 */
export class MarkingSelectionFormBuilder extends AbstractFormBuilder<MarkingSelectionFormBuilder> {

  className = 'MarkingSelection';

  abstractFormBuilder!: AbstractFormBuilder<MarkingSelection>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): MarkingSelection {
    return new MarkingSelection();
  }


  public artwork(
    builder: ArtworkFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artwork', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtwork() {
    super.removeField('artwork');
    return this;
  }

  public comment(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('comment', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutComment() {
    super.removeField('comment');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public image(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('image', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutImage() {
    super.removeField('image');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }


  public marking(
    builder: MarkingFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('marking', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMarking() {
    super.removeField('marking');
    return this;
  }

  public markingCondition(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('markingCondition', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMarkingCondition() {
    super.removeField('markingCondition');
    return this;
  }

  public markingLocation(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('markingLocation', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMarkingLocation() {
    super.removeField('markingLocation');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): MarkingSelectionFormBuilder {
    return this
    .comment() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .markingCondition() 
    .markingLocation() 
    .modifiedBy() 
    .status() 
    .visibilityFlag() 
;
  }
}

