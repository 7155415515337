/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ObjectEntryQueryFilters {
  agentSelections?: Array<AgentSelection>;
  entryDate?: DateQuery;
  entryMethod?: ObjectEntryQueryFilters.EntryMethodEnum;
  entryReason?: ObjectEntryQueryFilters.EntryReasonEnum;
  entryStatus?: ObjectEntryQueryFilters.EntryStatusEnum;
  entryTitle?: string;
  id?: string;
  returnDate?: DateQuery;

  constructor({
      agentSelections, 
      entryDate, 
      entryMethod, 
      entryReason, 
      entryStatus, 
      entryTitle, 
      id, 
      returnDate
  }: {
      agentSelections?: Array<AgentSelection>;
      entryDate?: DateQuery;
      entryMethod?: ObjectEntryQueryFilters.EntryMethodEnum;
      entryReason?: ObjectEntryQueryFilters.EntryReasonEnum;
      entryStatus?: ObjectEntryQueryFilters.EntryStatusEnum;
      entryTitle?: string;
      id?: string;
      returnDate?: DateQuery;
  } = {}) {
    this.agentSelections = agentSelections;
    this.entryDate = entryDate;
    this.entryMethod = entryMethod;
    this.entryReason = entryReason;
    this.entryStatus = entryStatus;
    this.entryTitle = entryTitle;
    this.id = id;
    this.returnDate = returnDate;
  }
}

export namespace ObjectEntryQueryFilters {

  export type EntryMethodEnum = 'In-person' | 'Via post' | 'Via courier' | 'Found on doorstep';
  export const EntryMethodEnum = {
      InPerson: 'In-person' as EntryMethodEnum,
      ViaPost: 'Via post' as EntryMethodEnum,
      ViaCourier: 'Via courier' as EntryMethodEnum,
      FoundOnDoorstep: 'Found on doorstep' as EntryMethodEnum,
  }
  export const EntryMethodEnumValues = [
      EntryMethodEnum.InPerson, 
      EntryMethodEnum.ViaPost, 
      EntryMethodEnum.ViaCourier, 
      EntryMethodEnum.FoundOnDoorstep
  ];
  export type EntryReasonEnum = 'Enquiry' | 'Commission' | 'Loan';
  export const EntryReasonEnum = {
      Enquiry: 'Enquiry' as EntryReasonEnum,
      Commission: 'Commission' as EntryReasonEnum,
      Loan: 'Loan' as EntryReasonEnum,
  }
  export const EntryReasonEnumValues = [
      EntryReasonEnum.Enquiry, 
      EntryReasonEnum.Commission, 
      EntryReasonEnum.Loan
  ];
  export type EntryStatusEnum = 'Todo' | 'Pending' | 'In-Progress' | 'Complete';
  export const EntryStatusEnum = {
      Todo: 'Todo' as EntryStatusEnum,
      Pending: 'Pending' as EntryStatusEnum,
      InProgress: 'In-Progress' as EntryStatusEnum,
      Complete: 'Complete' as EntryStatusEnum,
  }
  export const EntryStatusEnumValues = [
      EntryStatusEnum.Todo, 
      EntryStatusEnum.Pending, 
      EntryStatusEnum.InProgress, 
      EntryStatusEnum.Complete
  ];
}


/**
 * ObjectEntryQueryFilters form builder
 *
 */
export class ObjectEntryQueryFiltersFormBuilder extends AbstractFormBuilder<ObjectEntryQueryFiltersFormBuilder> {

  className = 'ObjectEntryQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ObjectEntryQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ObjectEntryQueryFilters {
    return new ObjectEntryQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public entryDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('entryDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutEntryDate() {
    super.removeField('entryDate');
    return this;
  }

  public entryMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryMethod() {
    super.removeField('entryMethod');
    return this;
  }

  public entryReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryReason() {
    super.removeField('entryReason');
    return this;
  }

  public entryStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryStatus() {
    super.removeField('entryStatus');
    return this;
  }

  public entryTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryTitle() {
    super.removeField('entryTitle');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public returnDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('returnDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutReturnDate() {
    super.removeField('returnDate');
    return this;
  }


  public all(): ObjectEntryQueryFiltersFormBuilder {
    return this
    .entryMethod() 
    .entryReason() 
    .entryStatus() 
    .entryTitle() 
    .id() 
;
  }
}

