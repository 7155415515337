/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PublicIndexingTermQueryFilters {
  gettyAatId?: string;
  searchString?: string;
  type?: PublicIndexingTermQueryFilters.TypeEnum;

  constructor({
      gettyAatId, 
      searchString, 
      type
  }: {
      gettyAatId?: string;
      searchString?: string;
      type?: PublicIndexingTermQueryFilters.TypeEnum;
  } = {}) {
    this.gettyAatId = gettyAatId;
    this.searchString = searchString;
    this.type = type;
  }
}

export namespace PublicIndexingTermQueryFilters {

  export type TypeEnum = 'Genre' | 'Subject' | 'Color' | 'Technique';
  export const TypeEnum = {
      Genre: 'Genre' as TypeEnum,
      Subject: 'Subject' as TypeEnum,
      Color: 'Color' as TypeEnum,
      Technique: 'Technique' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.Genre, 
      TypeEnum.Subject, 
      TypeEnum.Color, 
      TypeEnum.Technique
  ];
}


/**
 * PublicIndexingTermQueryFilters form builder
 *
 */
export class PublicIndexingTermQueryFiltersFormBuilder extends AbstractFormBuilder<PublicIndexingTermQueryFiltersFormBuilder> {

  className = 'PublicIndexingTermQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PublicIndexingTermQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PublicIndexingTermQueryFilters {
    return new PublicIndexingTermQueryFilters();
  }

  public gettyAatId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyAatId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyAatId() {
    super.removeField('gettyAatId');
    return this;
  }

  public searchString(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchString', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchString() {
    super.removeField('searchString');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }


  public all(): PublicIndexingTermQueryFiltersFormBuilder {
    return this
    .gettyAatId() 
    .searchString() 
    .type() 
;
  }
}

