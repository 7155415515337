/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PlaceQueryFilters {
  agentSelections?: Array<AgentSelection>;
  city?: string;
  country?: string;
  district?: string;
  featureDate?: DateQuery;
  featureName?: string;
  featureType?: string;
  gettyTgnId?: Array<string>;
  id?: string;
  place?: string;
  refNum?: string;
  refNumType?: string;
  region?: string;
  searchString?: string;
  status?: string;
  type?: PlaceQueryFilters.TypeEnum;
  typeDetail?: string;

  constructor({
      agentSelections, 
      city, 
      country, 
      district, 
      featureDate, 
      featureName, 
      featureType, 
      gettyTgnId, 
      id, 
      place, 
      refNum, 
      refNumType, 
      region, 
      searchString, 
      status, 
      type, 
      typeDetail
  }: {
      agentSelections?: Array<AgentSelection>;
      city?: string;
      country?: string;
      district?: string;
      featureDate?: DateQuery;
      featureName?: string;
      featureType?: string;
      gettyTgnId?: Array<string>;
      id?: string;
      place?: string;
      refNum?: string;
      refNumType?: string;
      region?: string;
      searchString?: string;
      status?: string;
      type?: PlaceQueryFilters.TypeEnum;
      typeDetail?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.city = city;
    this.country = country;
    this.district = district;
    this.featureDate = featureDate;
    this.featureName = featureName;
    this.featureType = featureType;
    this.gettyTgnId = gettyTgnId;
    this.id = id;
    this.place = place;
    this.refNum = refNum;
    this.refNumType = refNumType;
    this.region = region;
    this.searchString = searchString;
    this.status = status;
    this.type = type;
    this.typeDetail = typeDetail;
  }
}

export namespace PlaceQueryFilters {

  export type TypeEnum = 'district' | 'city' | 'region' | 'country' | 'other';
  export const TypeEnum = {
      District: 'district' as TypeEnum,
      City: 'city' as TypeEnum,
      Region: 'region' as TypeEnum,
      Country: 'country' as TypeEnum,
      Other: 'other' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.District, 
      TypeEnum.City, 
      TypeEnum.Region, 
      TypeEnum.Country, 
      TypeEnum.Other
  ];
}


/**
 * PlaceQueryFilters form builder
 *
 */
export class PlaceQueryFiltersFormBuilder extends AbstractFormBuilder<PlaceQueryFiltersFormBuilder> {

  className = 'PlaceQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PlaceQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PlaceQueryFilters {
    return new PlaceQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public city(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('city', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCity() {
    super.removeField('city');
    return this;
  }

  public country(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('country', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCountry() {
    super.removeField('country');
    return this;
  }

  public district(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('district', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDistrict() {
    super.removeField('district');
    return this;
  }


  public featureDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('featureDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutFeatureDate() {
    super.removeField('featureDate');
    return this;
  }

  public featureName(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('featureName', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFeatureName() {
    super.removeField('featureName');
    return this;
  }

  public featureType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('featureType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFeatureType() {
    super.removeField('featureType');
    return this;
  }

  public gettyTgnId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyTgnId', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyTgnId() {
    super.removeField('gettyTgnId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public place(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('place', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPlace() {
    super.removeField('place');
    return this;
  }

  public refNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNum() {
    super.removeField('refNum');
    return this;
  }

  public refNumType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNumType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNumType() {
    super.removeField('refNumType');
    return this;
  }

  public region(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('region', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRegion() {
    super.removeField('region');
    return this;
  }

  public searchString(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchString', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchString() {
    super.removeField('searchString');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }

  public typeDetail(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('typeDetail', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTypeDetail() {
    super.removeField('typeDetail');
    return this;
  }


  public all(): PlaceQueryFiltersFormBuilder {
    return this
    .city() 
    .country() 
    .district() 
    .featureName() 
    .featureType() 
    .gettyTgnId() 
    .id() 
    .place() 
    .refNum() 
    .refNumType() 
    .region() 
    .searchString() 
    .status() 
    .type() 
    .typeDetail() 
;
  }
}

