/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AgentAddress, AgentAddressFormBuilder } from '../models/agent-address';
import { AgentOtherName, AgentOtherNameFormBuilder } from '../models/agent-other-name';
import { AgentRole, AgentRoleFormBuilder } from '../models/agent-role';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentSelection {
  address?: AgentAddress;
  agent?: Agent;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  dealerFlag?: boolean;
  forcedDisplayFlag?: boolean;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  otherName?: AgentOtherName;
  privateCollectionFlag?: boolean;
  questionableFlag?: boolean;
  role?: AgentRole;
  seqNum?: number;
  status?: AgentSelection.StatusEnum;
  visibilityFlag?: boolean;

  constructor({
      address, 
      agent, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      dealerFlag, 
      forcedDisplayFlag, 
      id, 
      lastModifier, 
      modifiedBy, 
      otherName, 
      privateCollectionFlag, 
      questionableFlag, 
      role, 
      seqNum, 
      status, 
      visibilityFlag
  }: {
      address?: AgentAddress;
      agent?: Agent;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      dealerFlag?: boolean;
      forcedDisplayFlag?: boolean;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      otherName?: AgentOtherName;
      privateCollectionFlag?: boolean;
      questionableFlag?: boolean;
      role?: AgentRole;
      seqNum?: number;
      status?: AgentSelection.StatusEnum;
      visibilityFlag?: boolean;
  } = {}) {
    this.address = address;
    this.agent = agent;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.dealerFlag = dealerFlag;
    this.forcedDisplayFlag = forcedDisplayFlag;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.otherName = otherName;
    this.privateCollectionFlag = privateCollectionFlag;
    this.questionableFlag = questionableFlag;
    this.role = role;
    this.seqNum = seqNum;
    this.status = status;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace AgentSelection {

  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * AgentSelection form builder
 *
 */
export class AgentSelectionFormBuilder extends AbstractFormBuilder<AgentSelectionFormBuilder> {

  className = 'AgentSelection';

  abstractFormBuilder!: AbstractFormBuilder<AgentSelection>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentSelection {
    return new AgentSelection();
  }


  public address(
    builder: AgentAddressFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('address', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAddress() {
    super.removeField('address');
    return this;
  }


  public agent(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agent', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgent() {
    super.removeField('agent');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public dealerFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dealerFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDealerFlag() {
    super.removeField('dealerFlag');
    return this;
  }

  public forcedDisplayFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('forcedDisplayFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutForcedDisplayFlag() {
    super.removeField('forcedDisplayFlag');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public otherName(
    builder: AgentOtherNameFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('otherName', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOtherName() {
    super.removeField('otherName');
    return this;
  }

  public privateCollectionFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('privateCollectionFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPrivateCollectionFlag() {
    super.removeField('privateCollectionFlag');
    return this;
  }

  public questionableFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('questionableFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutQuestionableFlag() {
    super.removeField('questionableFlag');
    return this;
  }


  public role(
    builder: AgentRoleFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('role', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRole() {
    super.removeField('role');
    return this;
  }

  public seqNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seqNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeqNum() {
    super.removeField('seqNum');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): AgentSelectionFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .dealerFlag() 
    .forcedDisplayFlag() 
    .id() 
    .modifiedBy() 
    .privateCollectionFlag() 
    .questionableFlag() 
    .seqNum() 
    .status() 
    .visibilityFlag() 
;
  }
}

