/* tslint:disable */
/* eslint-disable */
import { AgentOtherName, AgentOtherNameFormBuilder } from '../models/agent-other-name';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentOtherNameMapping {
  action?: AgentOtherNameMapping.ActionEnum;
  duplicate?: AgentOtherName;
  master?: AgentOtherName;

  constructor({
      action, 
      duplicate, 
      master
  }: {
      action?: AgentOtherNameMapping.ActionEnum;
      duplicate?: AgentOtherName;
      master?: AgentOtherName;
  } = {}) {
    this.action = action;
    this.duplicate = duplicate;
    this.master = master;
  }
}

export namespace AgentOtherNameMapping {

  export type ActionEnum = 'replace' | 'delete' | 'add';
  export const ActionEnum = {
      Replace: 'replace' as ActionEnum,
      Delete: 'delete' as ActionEnum,
      Add: 'add' as ActionEnum,
  }
  export const ActionEnumValues = [
      ActionEnum.Replace, 
      ActionEnum.Delete, 
      ActionEnum.Add
  ];
}


/**
 * AgentOtherNameMapping form builder
 *
 */
export class AgentOtherNameMappingFormBuilder extends AbstractFormBuilder<AgentOtherNameMappingFormBuilder> {

  className = 'AgentOtherNameMapping';

  abstractFormBuilder!: AbstractFormBuilder<AgentOtherNameMapping>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentOtherNameMapping {
    return new AgentOtherNameMapping();
  }

  public action(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('action', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAction() {
    super.removeField('action');
    return this;
  }


  public duplicate(
    builder: AgentOtherNameFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('duplicate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDuplicate() {
    super.removeField('duplicate');
    return this;
  }


  public master(
    builder: AgentOtherNameFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('master', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMaster() {
    super.removeField('master');
    return this;
  }


  public all(): AgentOtherNameMappingFormBuilder {
    return this
    .action() 
;
  }
}

