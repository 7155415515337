/* tslint:disable */
/* eslint-disable */
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentContact {
  addressId?: string;
  contactType?: AgentContact.ContactTypeEnum;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  description?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  value?: string;

  constructor({
      addressId, 
      contactType, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      description, 
      id, 
      lastModifier, 
      modifiedBy, 
      value
  }: {
      addressId?: string;
      contactType?: AgentContact.ContactTypeEnum;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      description?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      value?: string;
  } = {}) {
    this.addressId = addressId;
    this.contactType = contactType;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.description = description;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.value = value;
  }
}

export namespace AgentContact {

  export type ContactTypeEnum = 'Email' | 'Fax' | 'Phone (mobile)' | 'Phone (office)' | 'Phone (residential)' | 'Web';
  export const ContactTypeEnum = {
      Email: 'Email' as ContactTypeEnum,
      Fax: 'Fax' as ContactTypeEnum,
      PhoneMobile: 'Phone (mobile)' as ContactTypeEnum,
      PhoneOffice: 'Phone (office)' as ContactTypeEnum,
      PhoneResidential: 'Phone (residential)' as ContactTypeEnum,
      Web: 'Web' as ContactTypeEnum,
  }
  export const ContactTypeEnumValues = [
      ContactTypeEnum.Email, 
      ContactTypeEnum.Fax, 
      ContactTypeEnum.PhoneMobile, 
      ContactTypeEnum.PhoneOffice, 
      ContactTypeEnum.PhoneResidential, 
      ContactTypeEnum.Web
  ];
}


/**
 * AgentContact form builder
 *
 */
export class AgentContactFormBuilder extends AbstractFormBuilder<AgentContactFormBuilder> {

  className = 'AgentContact';

  abstractFormBuilder!: AbstractFormBuilder<AgentContact>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentContact {
    return new AgentContact();
  }

  public addressId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('addressId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAddressId() {
    super.removeField('addressId');
    return this;
  }

  public contactType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('contactType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContactType() {
    super.removeField('contactType');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public value(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('value', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutValue() {
    super.removeField('value');
    return this;
  }


  public all(): AgentContactFormBuilder {
    return this
    .addressId() 
    .contactType() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .description() 
    .id() 
    .modifiedBy() 
    .value() 
;
  }
}

