/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PublicPlaceQueryFilters {
  allFields?: string;
  city?: string;
  country?: string;
  district?: string;
  gettyTgnId?: string;
  id?: string;
  region?: string;
  type?: PublicPlaceQueryFilters.TypeEnum;
  typeDetail?: string;

  constructor({
      allFields, 
      city, 
      country, 
      district, 
      gettyTgnId, 
      id, 
      region, 
      type, 
      typeDetail
  }: {
      allFields?: string;
      city?: string;
      country?: string;
      district?: string;
      gettyTgnId?: string;
      id?: string;
      region?: string;
      type?: PublicPlaceQueryFilters.TypeEnum;
      typeDetail?: string;
  } = {}) {
    this.allFields = allFields;
    this.city = city;
    this.country = country;
    this.district = district;
    this.gettyTgnId = gettyTgnId;
    this.id = id;
    this.region = region;
    this.type = type;
    this.typeDetail = typeDetail;
  }
}

export namespace PublicPlaceQueryFilters {

  export type TypeEnum = 'district' | 'city' | 'region' | 'country' | 'other';
  export const TypeEnum = {
      District: 'district' as TypeEnum,
      City: 'city' as TypeEnum,
      Region: 'region' as TypeEnum,
      Country: 'country' as TypeEnum,
      Other: 'other' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.District, 
      TypeEnum.City, 
      TypeEnum.Region, 
      TypeEnum.Country, 
      TypeEnum.Other
  ];
}


/**
 * PublicPlaceQueryFilters form builder
 *
 */
export class PublicPlaceQueryFiltersFormBuilder extends AbstractFormBuilder<PublicPlaceQueryFiltersFormBuilder> {

  className = 'PublicPlaceQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PublicPlaceQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PublicPlaceQueryFilters {
    return new PublicPlaceQueryFilters();
  }

  public allFields(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('allFields', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAllFields() {
    super.removeField('allFields');
    return this;
  }

  public city(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('city', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCity() {
    super.removeField('city');
    return this;
  }

  public country(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('country', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCountry() {
    super.removeField('country');
    return this;
  }

  public district(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('district', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDistrict() {
    super.removeField('district');
    return this;
  }

  public gettyTgnId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyTgnId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyTgnId() {
    super.removeField('gettyTgnId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public region(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('region', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRegion() {
    super.removeField('region');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }

  public typeDetail(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('typeDetail', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTypeDetail() {
    super.removeField('typeDetail');
    return this;
  }


  public all(): PublicPlaceQueryFiltersFormBuilder {
    return this
    .allFields() 
    .city() 
    .country() 
    .district() 
    .gettyTgnId() 
    .id() 
    .region() 
    .type() 
    .typeDetail() 
;
  }
}

