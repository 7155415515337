/* tslint:disable */
/* eslint-disable */
import { Place, PlaceFormBuilder } from '../models/place';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PlaceSelection {
  association?: PlaceSelection.AssociationEnum;
  context?: string;
  contextDateEdtf?: string;
  contextDateMax?: string;
  contextDateMin?: string;
  contextDateText?: string;
  contextDateUseTextFlag?: boolean;
  contextLevel?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  place?: Place;
  position?: string;
  seqNum?: number;
  status?: PlaceSelection.StatusEnum;
  visibilityFlag?: boolean;

  constructor({
      association, 
      context, 
      contextDateEdtf, 
      contextDateMax, 
      contextDateMin, 
      contextDateText, 
      contextDateUseTextFlag, 
      contextLevel, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      lastModifier, 
      modifiedBy, 
      place, 
      position, 
      seqNum, 
      status, 
      visibilityFlag
  }: {
      association?: PlaceSelection.AssociationEnum;
      context?: string;
      contextDateEdtf?: string;
      contextDateMax?: string;
      contextDateMin?: string;
      contextDateText?: string;
      contextDateUseTextFlag?: boolean;
      contextLevel?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      place?: Place;
      position?: string;
      seqNum?: number;
      status?: PlaceSelection.StatusEnum;
      visibilityFlag?: boolean;
  } = {}) {
    this.association = association;
    this.context = context;
    this.contextDateEdtf = contextDateEdtf;
    this.contextDateMax = contextDateMax;
    this.contextDateMin = contextDateMin;
    this.contextDateText = contextDateText;
    this.contextDateUseTextFlag = contextDateUseTextFlag;
    this.contextLevel = contextLevel;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.place = place;
    this.position = position;
    this.seqNum = seqNum;
    this.status = status;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace PlaceSelection {

  export type AssociationEnum = 'Created at' | 'Built' | 'Used' | 'Found' | 'Depicts';
  export const AssociationEnum = {
      CreatedAt: 'Created at' as AssociationEnum,
      Built: 'Built' as AssociationEnum,
      Used: 'Used' as AssociationEnum,
      Found: 'Found' as AssociationEnum,
      Depicts: 'Depicts' as AssociationEnum,
  }
  export const AssociationEnumValues = [
      AssociationEnum.CreatedAt, 
      AssociationEnum.Built, 
      AssociationEnum.Used, 
      AssociationEnum.Found, 
      AssociationEnum.Depicts
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * PlaceSelection form builder
 *
 */
export class PlaceSelectionFormBuilder extends AbstractFormBuilder<PlaceSelectionFormBuilder> {

  className = 'PlaceSelection';

  abstractFormBuilder!: AbstractFormBuilder<PlaceSelection>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PlaceSelection {
    return new PlaceSelection();
  }

  public association(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('association', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAssociation() {
    super.removeField('association');
    return this;
  }

  public context(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('context', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContext() {
    super.removeField('context');
    return this;
  }

  public contextDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('contextDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContextDateEdtf() {
    super.removeField('contextDateEdtf');
    return this;
  }

  public contextDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('contextDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContextDateMax() {
    super.removeField('contextDateMax');
    return this;
  }

  public contextDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('contextDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContextDateMin() {
    super.removeField('contextDateMin');
    return this;
  }

  public contextDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('contextDateText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContextDateText() {
    super.removeField('contextDateText');
    return this;
  }

  public contextDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('contextDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContextDateUseTextFlag() {
    super.removeField('contextDateUseTextFlag');
    return this;
  }

  public contextLevel(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('contextLevel', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContextLevel() {
    super.removeField('contextLevel');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public place(
    builder: PlaceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('place', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlace() {
    super.removeField('place');
    return this;
  }

  public position(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('position', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPosition() {
    super.removeField('position');
    return this;
  }

  public seqNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seqNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeqNum() {
    super.removeField('seqNum');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): PlaceSelectionFormBuilder {
    return this
    .association() 
    .context() 
    .contextDateEdtf() 
    .contextDateMax() 
    .contextDateMin() 
    .contextDateText() 
    .contextDateUseTextFlag() 
    .contextLevel() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .modifiedBy() 
    .position() 
    .seqNum() 
    .status() 
    .visibilityFlag() 
;
  }
}

