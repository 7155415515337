/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class SortOption {

  /**
   * sort direction
   */
  direction?: SortOption.DirectionEnum;

  /**
   * the name of the property to sort by
   */
  option?: string;

  constructor({
    
  /**
   * sort direction
   */
  direction, 
    
  /**
   * the name of the property to sort by
   */
  option
  }: {
    
  /**
   * sort direction
   */
  direction?: SortOption.DirectionEnum;
    
  /**
   * the name of the property to sort by
   */
  option?: string;
  } = {}) {
    this.direction = direction;
    this.option = option;
  }
}

export namespace SortOption {

  export type DirectionEnum = 'ASC' | 'DESC';
  export const DirectionEnum = {
      ASC: 'ASC' as DirectionEnum,
      DESC: 'DESC' as DirectionEnum,
  }
  export const DirectionEnumValues = [
      DirectionEnum.ASC, 
      DirectionEnum.DESC
  ];
}


/**
 * SortOption form builder
 *
 */
export class SortOptionFormBuilder extends AbstractFormBuilder<SortOptionFormBuilder> {

  className = 'SortOption';

  abstractFormBuilder!: AbstractFormBuilder<SortOption>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): SortOption {
    return new SortOption();
  }

  public direction(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('direction', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDirection() {
    super.removeField('direction');
    return this;
  }

  public option(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('option', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOption() {
    super.removeField('option');
    return this;
  }


  public all(): SortOptionFormBuilder {
    return this
    .direction() 
    .option() 
;
  }
}

