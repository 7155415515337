/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn } from '@angular/forms';
import { NamedFormControl } from './named-form-control';
import { NamedFormGroup } from './named-form-group';

export class NamedFormArray extends FormArray {
  constructor(
    public name: string,
    private createControlFactory: (assignedIndex: number) => NamedFormControl | NamedFormGroup,
    controls: AbstractControl[],
    syncValidator?: ValidatorFn | ValidatorFn[] | AbstractControlOptions,
    asyncValidator?: AsyncValidatorFn,
  ) {
    super(controls, syncValidator, asyncValidator);
  }

  public override push(control: any = null): NamedFormGroup {
    if (control) {
      super.push(control);
      return control;
    } else {
      const ctrl = this.create();
      super.push(ctrl);
      return ctrl as NamedFormGroup;
    }
  }

  public create() {
    /* istanbul ignore next */
    return this.createControlFactory.apply(() => this, [this.controls.length]);
  }

  updateFromSource(source: any, hard = false): NamedFormArray {
    if (source !== undefined && source !== null && !Array.isArray(source)) {
      throw new Error(`${JSON.stringify(source)} is not of type array as required for NamedFormArray ${this.name}`);
    }

    const sourceArray = source instanceof Array ? source : hard ? [] : null;
    if (sourceArray === null) {
      throw new Error(`Trying to update NamedFormArray from non-array ${source}`);
    }

    if (hard) {
      while (this.controls.length > sourceArray.length) {
        this.removeAt(this.controls.length - 1);
      }
    }

    const toBePushed: Array<AbstractControl> = [];
    for (let i = 0; i < sourceArray.length; i++) {
      if (i >= this.controls.length) {
        const newCtrl = this.create();
        newCtrl.updateFromSource(sourceArray[i], hard);
        toBePushed.push(newCtrl);
        newCtrl.setParent(this);
      } else {
        const updatable = this.controls[i] as NamedFormControl;
        updatable.updateFromSource(sourceArray[i]);
      }
    }
    // push all in one bunch to change listeners will not get triggered on every push:
    this.controls = this.controls.concat(toBePushed);
    if (toBePushed.length) {
      this.updateValueAndValidity();
    }
    return this;
  }
}
