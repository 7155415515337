/* tslint:disable */
/* eslint-disable */
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class DashboardDataQueryFilters {
  datapoints?: Array<'auction_sales_catalogues_live' | 'auction_sales_catalogues_not_live' | 'auction_sales_catalogues_total' | 'auction_sales_catalogues_private_scans' | 'auction_sales_catalogues_worldcat' | 'auction_sales_catalogues_not_worldcat' | 'publication_linked_scans' | 'publication_unlinked_scans' | 'publication_total' | 'archive_media_ocr' | 'archive_media_no_ocr' | 'archive_collections_public' | 'archive_collections_private' | 'archive_collections_total' | 'archive_media_extent_by_collection' | 'archive_media_extent_total' | 'archive_media_count_by_collection' | 'archive_media_count_total' | 'archive_media_ocr_by_collection' | 'archive_media_no_ocr_by_collection' | 'archive_media_unlinked_by_collection' | 'archive_media_unlinked_total' | 'archive_media_linked_by_collection' | 'archive_media_linked_total' | 'artworks_total' | 'artworks_total_by_artist' | 'artworks_total_by_artset' | 'artworks_published' | 'artworks_published_by_artist' | 'artworks_published_by_artset' | 'artworks_unpublished' | 'artworks_unpublished_by_artist' | 'artworks_unpublished_by_artset' | 'artworks_private' | 'artworks_private_by_artist' | 'artworks_private_by_artset' | 'artworks_included' | 'artworks_included_by_artist' | 'artworks_included_by_artset' | 'artworks_pending' | 'artworks_pending_by_artist' | 'artworks_pending_by_artset' | 'artworks_not_included' | 'artworks_not_included_by_artist' | 'artworks_not_included_by_artset' | 'artworks_examined' | 'artworks_examined_by_artist' | 'artworks_examined_by_artset' | 'art_event_examination_total_examinations' | 'art_event_examination_total_examinations_by_artist' | 'art_event_examination_total_examinations_by_artset' | 'art_event_examination_total_verification_wo_exam' | 'art_event_examination_total_verification_wo_exam_by_artist' | 'art_event_examination_total_verification_wo_exam_by_artset'>;
  entityId?: string;
  lastN?: number;
  timestamp?: DateQuery;

  constructor({
      datapoints, 
      entityId, 
      lastN, 
      timestamp
  }: {
      datapoints?: Array<'auction_sales_catalogues_live' | 'auction_sales_catalogues_not_live' | 'auction_sales_catalogues_total' | 'auction_sales_catalogues_private_scans' | 'auction_sales_catalogues_worldcat' | 'auction_sales_catalogues_not_worldcat' | 'publication_linked_scans' | 'publication_unlinked_scans' | 'publication_total' | 'archive_media_ocr' | 'archive_media_no_ocr' | 'archive_collections_public' | 'archive_collections_private' | 'archive_collections_total' | 'archive_media_extent_by_collection' | 'archive_media_extent_total' | 'archive_media_count_by_collection' | 'archive_media_count_total' | 'archive_media_ocr_by_collection' | 'archive_media_no_ocr_by_collection' | 'archive_media_unlinked_by_collection' | 'archive_media_unlinked_total' | 'archive_media_linked_by_collection' | 'archive_media_linked_total' | 'artworks_total' | 'artworks_total_by_artist' | 'artworks_total_by_artset' | 'artworks_published' | 'artworks_published_by_artist' | 'artworks_published_by_artset' | 'artworks_unpublished' | 'artworks_unpublished_by_artist' | 'artworks_unpublished_by_artset' | 'artworks_private' | 'artworks_private_by_artist' | 'artworks_private_by_artset' | 'artworks_included' | 'artworks_included_by_artist' | 'artworks_included_by_artset' | 'artworks_pending' | 'artworks_pending_by_artist' | 'artworks_pending_by_artset' | 'artworks_not_included' | 'artworks_not_included_by_artist' | 'artworks_not_included_by_artset' | 'artworks_examined' | 'artworks_examined_by_artist' | 'artworks_examined_by_artset' | 'art_event_examination_total_examinations' | 'art_event_examination_total_examinations_by_artist' | 'art_event_examination_total_examinations_by_artset' | 'art_event_examination_total_verification_wo_exam' | 'art_event_examination_total_verification_wo_exam_by_artist' | 'art_event_examination_total_verification_wo_exam_by_artset'>;
      entityId?: string;
      lastN?: number;
      timestamp?: DateQuery;
  } = {}) {
    this.datapoints = datapoints;
    this.entityId = entityId;
    this.lastN = lastN;
    this.timestamp = timestamp;
  }
}

export namespace DashboardDataQueryFilters {

  export type DatapointsEnum = 'auction_sales_catalogues_live' | 'auction_sales_catalogues_not_live' | 'auction_sales_catalogues_total' | 'auction_sales_catalogues_private_scans' | 'auction_sales_catalogues_worldcat' | 'auction_sales_catalogues_not_worldcat' | 'publication_linked_scans' | 'publication_unlinked_scans' | 'publication_total' | 'archive_media_ocr' | 'archive_media_no_ocr' | 'archive_collections_public' | 'archive_collections_private' | 'archive_collections_total' | 'archive_media_extent_by_collection' | 'archive_media_extent_total' | 'archive_media_count_by_collection' | 'archive_media_count_total' | 'archive_media_ocr_by_collection' | 'archive_media_no_ocr_by_collection' | 'archive_media_unlinked_by_collection' | 'archive_media_unlinked_total' | 'archive_media_linked_by_collection' | 'archive_media_linked_total' | 'artworks_total' | 'artworks_total_by_artist' | 'artworks_total_by_artset' | 'artworks_published' | 'artworks_published_by_artist' | 'artworks_published_by_artset' | 'artworks_unpublished' | 'artworks_unpublished_by_artist' | 'artworks_unpublished_by_artset' | 'artworks_private' | 'artworks_private_by_artist' | 'artworks_private_by_artset' | 'artworks_included' | 'artworks_included_by_artist' | 'artworks_included_by_artset' | 'artworks_pending' | 'artworks_pending_by_artist' | 'artworks_pending_by_artset' | 'artworks_not_included' | 'artworks_not_included_by_artist' | 'artworks_not_included_by_artset' | 'artworks_examined' | 'artworks_examined_by_artist' | 'artworks_examined_by_artset' | 'art_event_examination_total_examinations' | 'art_event_examination_total_examinations_by_artist' | 'art_event_examination_total_examinations_by_artset' | 'art_event_examination_total_verification_wo_exam' | 'art_event_examination_total_verification_wo_exam_by_artist' | 'art_event_examination_total_verification_wo_exam_by_artset'
  export const DatapointsEnum = {
      AuctionSalesCataloguesLive: 'auction_sales_catalogues_live' as DatapointsEnum,
      AuctionSalesCataloguesNotLive: 'auction_sales_catalogues_not_live' as DatapointsEnum,
      AuctionSalesCataloguesTotal: 'auction_sales_catalogues_total' as DatapointsEnum,
      AuctionSalesCataloguesPrivateScans: 'auction_sales_catalogues_private_scans' as DatapointsEnum,
      AuctionSalesCataloguesWorldcat: 'auction_sales_catalogues_worldcat' as DatapointsEnum,
      AuctionSalesCataloguesNotWorldcat: 'auction_sales_catalogues_not_worldcat' as DatapointsEnum,
      PublicationLinkedScans: 'publication_linked_scans' as DatapointsEnum,
      PublicationUnlinkedScans: 'publication_unlinked_scans' as DatapointsEnum,
      PublicationTotal: 'publication_total' as DatapointsEnum,
      ArchiveMediaOcr: 'archive_media_ocr' as DatapointsEnum,
      ArchiveMediaNoOcr: 'archive_media_no_ocr' as DatapointsEnum,
      ArchiveCollectionsPublic: 'archive_collections_public' as DatapointsEnum,
      ArchiveCollectionsPrivate: 'archive_collections_private' as DatapointsEnum,
      ArchiveCollectionsTotal: 'archive_collections_total' as DatapointsEnum,
      ArchiveMediaExtentByCollection: 'archive_media_extent_by_collection' as DatapointsEnum,
      ArchiveMediaExtentTotal: 'archive_media_extent_total' as DatapointsEnum,
      ArchiveMediaCountByCollection: 'archive_media_count_by_collection' as DatapointsEnum,
      ArchiveMediaCountTotal: 'archive_media_count_total' as DatapointsEnum,
      ArchiveMediaOcrByCollection: 'archive_media_ocr_by_collection' as DatapointsEnum,
      ArchiveMediaNoOcrByCollection: 'archive_media_no_ocr_by_collection' as DatapointsEnum,
      ArchiveMediaUnlinkedByCollection: 'archive_media_unlinked_by_collection' as DatapointsEnum,
      ArchiveMediaUnlinkedTotal: 'archive_media_unlinked_total' as DatapointsEnum,
      ArchiveMediaLinkedByCollection: 'archive_media_linked_by_collection' as DatapointsEnum,
      ArchiveMediaLinkedTotal: 'archive_media_linked_total' as DatapointsEnum,
      ArtworksTotal: 'artworks_total' as DatapointsEnum,
      ArtworksTotalByArtist: 'artworks_total_by_artist' as DatapointsEnum,
      ArtworksTotalByArtset: 'artworks_total_by_artset' as DatapointsEnum,
      ArtworksPublished: 'artworks_published' as DatapointsEnum,
      ArtworksPublishedByArtist: 'artworks_published_by_artist' as DatapointsEnum,
      ArtworksPublishedByArtset: 'artworks_published_by_artset' as DatapointsEnum,
      ArtworksUnpublished: 'artworks_unpublished' as DatapointsEnum,
      ArtworksUnpublishedByArtist: 'artworks_unpublished_by_artist' as DatapointsEnum,
      ArtworksUnpublishedByArtset: 'artworks_unpublished_by_artset' as DatapointsEnum,
      ArtworksPrivate: 'artworks_private' as DatapointsEnum,
      ArtworksPrivateByArtist: 'artworks_private_by_artist' as DatapointsEnum,
      ArtworksPrivateByArtset: 'artworks_private_by_artset' as DatapointsEnum,
      ArtworksIncluded: 'artworks_included' as DatapointsEnum,
      ArtworksIncludedByArtist: 'artworks_included_by_artist' as DatapointsEnum,
      ArtworksIncludedByArtset: 'artworks_included_by_artset' as DatapointsEnum,
      ArtworksPending: 'artworks_pending' as DatapointsEnum,
      ArtworksPendingByArtist: 'artworks_pending_by_artist' as DatapointsEnum,
      ArtworksPendingByArtset: 'artworks_pending_by_artset' as DatapointsEnum,
      ArtworksNotIncluded: 'artworks_not_included' as DatapointsEnum,
      ArtworksNotIncludedByArtist: 'artworks_not_included_by_artist' as DatapointsEnum,
      ArtworksNotIncludedByArtset: 'artworks_not_included_by_artset' as DatapointsEnum,
      ArtworksExamined: 'artworks_examined' as DatapointsEnum,
      ArtworksExaminedByArtist: 'artworks_examined_by_artist' as DatapointsEnum,
      ArtworksExaminedByArtset: 'artworks_examined_by_artset' as DatapointsEnum,
      ArtEventExaminationTotalExaminations: 'art_event_examination_total_examinations' as DatapointsEnum,
      ArtEventExaminationTotalExaminationsByArtist: 'art_event_examination_total_examinations_by_artist' as DatapointsEnum,
      ArtEventExaminationTotalExaminationsByArtset: 'art_event_examination_total_examinations_by_artset' as DatapointsEnum,
      ArtEventExaminationTotalVerificationWoExam: 'art_event_examination_total_verification_wo_exam' as DatapointsEnum,
      ArtEventExaminationTotalVerificationWoExamByArtist: 'art_event_examination_total_verification_wo_exam_by_artist' as DatapointsEnum,
      ArtEventExaminationTotalVerificationWoExamByArtset: 'art_event_examination_total_verification_wo_exam_by_artset' as DatapointsEnum,
  }
  export const DatapointsEnumValues = [
      DatapointsEnum.AuctionSalesCataloguesLive, 
      DatapointsEnum.AuctionSalesCataloguesNotLive, 
      DatapointsEnum.AuctionSalesCataloguesTotal, 
      DatapointsEnum.AuctionSalesCataloguesPrivateScans, 
      DatapointsEnum.AuctionSalesCataloguesWorldcat, 
      DatapointsEnum.AuctionSalesCataloguesNotWorldcat, 
      DatapointsEnum.PublicationLinkedScans, 
      DatapointsEnum.PublicationUnlinkedScans, 
      DatapointsEnum.PublicationTotal, 
      DatapointsEnum.ArchiveMediaOcr, 
      DatapointsEnum.ArchiveMediaNoOcr, 
      DatapointsEnum.ArchiveCollectionsPublic, 
      DatapointsEnum.ArchiveCollectionsPrivate, 
      DatapointsEnum.ArchiveCollectionsTotal, 
      DatapointsEnum.ArchiveMediaExtentByCollection, 
      DatapointsEnum.ArchiveMediaExtentTotal, 
      DatapointsEnum.ArchiveMediaCountByCollection, 
      DatapointsEnum.ArchiveMediaCountTotal, 
      DatapointsEnum.ArchiveMediaOcrByCollection, 
      DatapointsEnum.ArchiveMediaNoOcrByCollection, 
      DatapointsEnum.ArchiveMediaUnlinkedByCollection, 
      DatapointsEnum.ArchiveMediaUnlinkedTotal, 
      DatapointsEnum.ArchiveMediaLinkedByCollection, 
      DatapointsEnum.ArchiveMediaLinkedTotal, 
      DatapointsEnum.ArtworksTotal, 
      DatapointsEnum.ArtworksTotalByArtist, 
      DatapointsEnum.ArtworksTotalByArtset, 
      DatapointsEnum.ArtworksPublished, 
      DatapointsEnum.ArtworksPublishedByArtist, 
      DatapointsEnum.ArtworksPublishedByArtset, 
      DatapointsEnum.ArtworksUnpublished, 
      DatapointsEnum.ArtworksUnpublishedByArtist, 
      DatapointsEnum.ArtworksUnpublishedByArtset, 
      DatapointsEnum.ArtworksPrivate, 
      DatapointsEnum.ArtworksPrivateByArtist, 
      DatapointsEnum.ArtworksPrivateByArtset, 
      DatapointsEnum.ArtworksIncluded, 
      DatapointsEnum.ArtworksIncludedByArtist, 
      DatapointsEnum.ArtworksIncludedByArtset, 
      DatapointsEnum.ArtworksPending, 
      DatapointsEnum.ArtworksPendingByArtist, 
      DatapointsEnum.ArtworksPendingByArtset, 
      DatapointsEnum.ArtworksNotIncluded, 
      DatapointsEnum.ArtworksNotIncludedByArtist, 
      DatapointsEnum.ArtworksNotIncludedByArtset, 
      DatapointsEnum.ArtworksExamined, 
      DatapointsEnum.ArtworksExaminedByArtist, 
      DatapointsEnum.ArtworksExaminedByArtset, 
      DatapointsEnum.ArtEventExaminationTotalExaminations, 
      DatapointsEnum.ArtEventExaminationTotalExaminationsByArtist, 
      DatapointsEnum.ArtEventExaminationTotalExaminationsByArtset, 
      DatapointsEnum.ArtEventExaminationTotalVerificationWoExam, 
      DatapointsEnum.ArtEventExaminationTotalVerificationWoExamByArtist, 
      DatapointsEnum.ArtEventExaminationTotalVerificationWoExamByArtset
  ];
}


/**
 * DashboardDataQueryFilters form builder
 *
 */
export class DashboardDataQueryFiltersFormBuilder extends AbstractFormBuilder<DashboardDataQueryFiltersFormBuilder> {

  className = 'DashboardDataQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<DashboardDataQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): DashboardDataQueryFilters {
    return new DashboardDataQueryFilters();
  }

  public datapoints(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('datapoints', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDatapoints() {
    super.removeField('datapoints');
    return this;
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public lastN(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('lastN', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLastN() {
    super.removeField('lastN');
    return this;
  }


  public timestamp(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('timestamp', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutTimestamp() {
    super.removeField('timestamp');
    return this;
  }


  public all(): DashboardDataQueryFiltersFormBuilder {
    return this
    .datapoints() 
    .entityId() 
    .lastN() 
;
  }
}

