/* tslint:disable */
/* eslint-disable */
import { Media, MediaFormBuilder } from '../models/media';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Essay {
  author?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  previewMedia?: Media;
  seqNum?: number;
  status?: Essay.StatusEnum;
  subTitle?: string;
  teaser?: string;
  text?: string;
  title?: string;
  visibilityFlag?: boolean;

  constructor({
      author, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      lastModifier, 
      modifiedBy, 
      previewMedia, 
      seqNum, 
      status, 
      subTitle, 
      teaser, 
      text, 
      title, 
      visibilityFlag
  }: {
      author?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      previewMedia?: Media;
      seqNum?: number;
      status?: Essay.StatusEnum;
      subTitle?: string;
      teaser?: string;
      text?: string;
      title?: string;
      visibilityFlag?: boolean;
  } = {}) {
    this.author = author;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.previewMedia = previewMedia;
    this.seqNum = seqNum;
    this.status = status;
    this.subTitle = subTitle;
    this.teaser = teaser;
    this.text = text;
    this.title = title;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace Essay {

  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Essay form builder
 *
 */
export class EssayFormBuilder extends AbstractFormBuilder<EssayFormBuilder> {

  className = 'Essay';

  abstractFormBuilder!: AbstractFormBuilder<Essay>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Essay {
    return new Essay();
  }

  public author(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('author', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuthor() {
    super.removeField('author');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public previewMedia(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('previewMedia', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPreviewMedia() {
    super.removeField('previewMedia');
    return this;
  }

  public seqNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seqNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeqNum() {
    super.removeField('seqNum');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public subTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('subTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSubTitle() {
    super.removeField('subTitle');
    return this;
  }

  public teaser(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('teaser', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTeaser() {
    super.removeField('teaser');
    return this;
  }

  public text(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('text', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutText() {
    super.removeField('text');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): EssayFormBuilder {
    return this
    .author() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .modifiedBy() 
    .seqNum() 
    .status() 
    .subTitle() 
    .teaser() 
    .text() 
    .title() 
    .visibilityFlag() 
;
  }
}

