/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentSelectionQueryResponseEntry {
  entityId?: string;
  entityTitle?: string;
  entityType?: AgentSelectionQueryResponseEntry.EntityTypeEnum;
  eventTypeId?: string;
  eventTypeName?: string;
  parentEntityId?: string;
  selection?: AgentSelection;

  constructor({
      entityId, 
      entityTitle, 
      entityType, 
      eventTypeId, 
      eventTypeName, 
      parentEntityId, 
      selection
  }: {
      entityId?: string;
      entityTitle?: string;
      entityType?: AgentSelectionQueryResponseEntry.EntityTypeEnum;
      eventTypeId?: string;
      eventTypeName?: string;
      parentEntityId?: string;
      selection?: AgentSelection;
  } = {}) {
    this.entityId = entityId;
    this.entityTitle = entityTitle;
    this.entityType = entityType;
    this.eventTypeId = eventTypeId;
    this.eventTypeName = eventTypeName;
    this.parentEntityId = parentEntityId;
    this.selection = selection;
  }
}

export namespace AgentSelectionQueryResponseEntry {

  export type EntityTypeEnum = 'Agent' | 'Artwork' | 'ArtEvent' | 'Auction' | 'Exhibition' | 'ExhibitionVenue' | 'Publication' | 'Committee' | 'Resource' | 'ObjectEntry' | 'ObjectExit' | 'AcquisitionInformation' | 'DisposalInformation' | 'AuditInformation' | 'Marking' | 'MovementInformation' | 'Loan' | 'CollectionsReview' | 'Place' | 'Location' | 'ConditionCheck' | 'Conservation' | 'InsuranceInformation';
  export const EntityTypeEnum = {
      Agent: 'Agent' as EntityTypeEnum,
      Artwork: 'Artwork' as EntityTypeEnum,
      ArtEvent: 'ArtEvent' as EntityTypeEnum,
      Auction: 'Auction' as EntityTypeEnum,
      Exhibition: 'Exhibition' as EntityTypeEnum,
      ExhibitionVenue: 'ExhibitionVenue' as EntityTypeEnum,
      Publication: 'Publication' as EntityTypeEnum,
      Committee: 'Committee' as EntityTypeEnum,
      Resource: 'Resource' as EntityTypeEnum,
      ObjectEntry: 'ObjectEntry' as EntityTypeEnum,
      ObjectExit: 'ObjectExit' as EntityTypeEnum,
      AcquisitionInformation: 'AcquisitionInformation' as EntityTypeEnum,
      DisposalInformation: 'DisposalInformation' as EntityTypeEnum,
      AuditInformation: 'AuditInformation' as EntityTypeEnum,
      Marking: 'Marking' as EntityTypeEnum,
      MovementInformation: 'MovementInformation' as EntityTypeEnum,
      Loan: 'Loan' as EntityTypeEnum,
      CollectionsReview: 'CollectionsReview' as EntityTypeEnum,
      Place: 'Place' as EntityTypeEnum,
      Location: 'Location' as EntityTypeEnum,
      ConditionCheck: 'ConditionCheck' as EntityTypeEnum,
      Conservation: 'Conservation' as EntityTypeEnum,
      InsuranceInformation: 'InsuranceInformation' as EntityTypeEnum,
  }
  export const EntityTypeEnumValues = [
      EntityTypeEnum.Agent, 
      EntityTypeEnum.Artwork, 
      EntityTypeEnum.ArtEvent, 
      EntityTypeEnum.Auction, 
      EntityTypeEnum.Exhibition, 
      EntityTypeEnum.ExhibitionVenue, 
      EntityTypeEnum.Publication, 
      EntityTypeEnum.Committee, 
      EntityTypeEnum.Resource, 
      EntityTypeEnum.ObjectEntry, 
      EntityTypeEnum.ObjectExit, 
      EntityTypeEnum.AcquisitionInformation, 
      EntityTypeEnum.DisposalInformation, 
      EntityTypeEnum.AuditInformation, 
      EntityTypeEnum.Marking, 
      EntityTypeEnum.MovementInformation, 
      EntityTypeEnum.Loan, 
      EntityTypeEnum.CollectionsReview, 
      EntityTypeEnum.Place, 
      EntityTypeEnum.Location, 
      EntityTypeEnum.ConditionCheck, 
      EntityTypeEnum.Conservation, 
      EntityTypeEnum.InsuranceInformation
  ];
}


/**
 * AgentSelectionQueryResponseEntry form builder
 *
 */
export class AgentSelectionQueryResponseEntryFormBuilder extends AbstractFormBuilder<AgentSelectionQueryResponseEntryFormBuilder> {

  className = 'AgentSelectionQueryResponseEntry';

  abstractFormBuilder!: AbstractFormBuilder<AgentSelectionQueryResponseEntry>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentSelectionQueryResponseEntry {
    return new AgentSelectionQueryResponseEntry();
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityTitle', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityTitle() {
    super.removeField('entityTitle');
    return this;
  }

  public entityType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityType() {
    super.removeField('entityType');
    return this;
  }

  public eventTypeId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('eventTypeId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEventTypeId() {
    super.removeField('eventTypeId');
    return this;
  }

  public eventTypeName(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('eventTypeName', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEventTypeName() {
    super.removeField('eventTypeName');
    return this;
  }

  public parentEntityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentEntityId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentEntityId() {
    super.removeField('parentEntityId');
    return this;
  }


  public selection(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('selection', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSelection() {
    super.removeField('selection');
    return this;
  }


  public all(): AgentSelectionQueryResponseEntryFormBuilder {
    return this
    .entityId() 
    .entityTitle() 
    .entityType() 
    .eventTypeId() 
    .eventTypeName() 
    .parentEntityId() 
;
  }
}

