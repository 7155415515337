/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AuctionQueryFilters {
  agentSelections?: Array<AgentSelection>;
  aucDate?: DateQuery;
  aucDateEdtf?: string;
  aucShortTitle?: string;
  aucTitle?: string;
  city?: Array<string>;
  country?: Array<string>;
  createdBy?: string;
  hasPublication?: boolean;
  id?: string;
  inArtSet?: string;
  masterFlag?: AuctionQueryFilters.MasterFlagEnum;
  modifiedBy?: string;
  publicationFairUseFlag?: boolean;
  publicationMediaAvailable?: boolean;
  relatedToArtist?: Agent;
  searchString?: string;
  status?: AuctionQueryFilters.StatusEnum;
  visibilityFlag?: boolean;

  constructor({
      agentSelections, 
      aucDate, 
      aucDateEdtf, 
      aucShortTitle, 
      aucTitle, 
      city, 
      country, 
      createdBy, 
      hasPublication, 
      id, 
      inArtSet, 
      masterFlag, 
      modifiedBy, 
      publicationFairUseFlag, 
      publicationMediaAvailable, 
      relatedToArtist, 
      searchString, 
      status, 
      visibilityFlag
  }: {
      agentSelections?: Array<AgentSelection>;
      aucDate?: DateQuery;
      aucDateEdtf?: string;
      aucShortTitle?: string;
      aucTitle?: string;
      city?: Array<string>;
      country?: Array<string>;
      createdBy?: string;
      hasPublication?: boolean;
      id?: string;
      inArtSet?: string;
      masterFlag?: AuctionQueryFilters.MasterFlagEnum;
      modifiedBy?: string;
      publicationFairUseFlag?: boolean;
      publicationMediaAvailable?: boolean;
      relatedToArtist?: Agent;
      searchString?: string;
      status?: AuctionQueryFilters.StatusEnum;
      visibilityFlag?: boolean;
  } = {}) {
    this.agentSelections = agentSelections;
    this.aucDate = aucDate;
    this.aucDateEdtf = aucDateEdtf;
    this.aucShortTitle = aucShortTitle;
    this.aucTitle = aucTitle;
    this.city = city;
    this.country = country;
    this.createdBy = createdBy;
    this.hasPublication = hasPublication;
    this.id = id;
    this.inArtSet = inArtSet;
    this.masterFlag = masterFlag;
    this.modifiedBy = modifiedBy;
    this.publicationFairUseFlag = publicationFairUseFlag;
    this.publicationMediaAvailable = publicationMediaAvailable;
    this.relatedToArtist = relatedToArtist;
    this.searchString = searchString;
    this.status = status;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace AuctionQueryFilters {

  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * AuctionQueryFilters form builder
 *
 */
export class AuctionQueryFiltersFormBuilder extends AbstractFormBuilder<AuctionQueryFiltersFormBuilder> {

  className = 'AuctionQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<AuctionQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AuctionQueryFilters {
    return new AuctionQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public aucDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('aucDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAucDate() {
    super.removeField('aucDate');
    return this;
  }

  public aucDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucDateEdtf() {
    super.removeField('aucDateEdtf');
    return this;
  }

  public aucShortTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucShortTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucShortTitle() {
    super.removeField('aucShortTitle');
    return this;
  }

  public aucTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucTitle() {
    super.removeField('aucTitle');
    return this;
  }

  public city(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('city', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCity() {
    super.removeField('city');
    return this;
  }

  public country(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('country', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCountry() {
    super.removeField('country');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }

  public hasPublication(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('hasPublication', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHasPublication() {
    super.removeField('hasPublication');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public inArtSet(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inArtSet', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInArtSet() {
    super.removeField('inArtSet');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public publicationFairUseFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('publicationFairUseFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPublicationFairUseFlag() {
    super.removeField('publicationFairUseFlag');
    return this;
  }

  public publicationMediaAvailable(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('publicationMediaAvailable', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPublicationMediaAvailable() {
    super.removeField('publicationMediaAvailable');
    return this;
  }


  public relatedToArtist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('relatedToArtist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRelatedToArtist() {
    super.removeField('relatedToArtist');
    return this;
  }

  public searchString(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchString', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchString() {
    super.removeField('searchString');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): AuctionQueryFiltersFormBuilder {
    return this
    .aucDateEdtf() 
    .aucShortTitle() 
    .aucTitle() 
    .city() 
    .country() 
    .createdBy() 
    .hasPublication() 
    .id() 
    .inArtSet() 
    .masterFlag() 
    .modifiedBy() 
    .publicationFairUseFlag() 
    .publicationMediaAvailable() 
    .searchString() 
    .status() 
    .visibilityFlag() 
;
  }
}

