/* tslint:disable */
/* eslint-disable */
import { AgentAddress, AgentAddressFormBuilder } from '../models/agent-address';
import { AgentContact, AgentContactFormBuilder } from '../models/agent-contact';
import { AgentOtherName, AgentOtherNameFormBuilder } from '../models/agent-other-name';
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Agent {
  addresses?: Array<AgentAddress>;
  agentCulture?: string;
  agentFunction?: string;
  agentGroup?: string;
  agentNationality?: string;
  /** @deprecated */agentOccupancy?: string;
  agentOccupation?: string;
  agentSchool?: string;
  agentSelections?: Array<AgentSelection>;
  agentType?: Agent.AgentTypeEnum;
  artistDateText?: string;
  artistFlag?: boolean;
  atlId?: number;
  birthDateEdtf?: string;
  birthDateMax?: string;
  birthDateMin?: string;
  birthDateText?: string;
  birthDateUseTextFlag?: boolean;
  birthPlace?: string;
  contacts?: Array<AgentContact>;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  deathDateEdtf?: string;
  deathDateMax?: string;
  deathDateMin?: string;
  deathDateText?: string;
  deathDateUseTextFlag?: boolean;
  deathPlace?: string;
  description?: string;
  displayName?: string;
  /** @deprecated */dissolutionDate?: string;
  /** @deprecated */foundationDate?: string;
  foundationPlace?: string;
  gender?: Agent.GenderEnum;
  gettyUlanId?: string;
  gndId?: string;
  id?: string;
  initials?: string;
  lastModifier?: User;
  linguisticGroup?: string;
  masterFlag?: Agent.MasterFlagEnum;
  mdaCode?: string;
  modifiedBy?: string;
  otherNames?: Array<AgentOtherName>;
  primaryResId?: string;
  refNo?: string;
  replacementId?: string;
  salutation?: string;
  status?: Agent.StatusEnum;
  title?: Agent.TitleEnum;
  wikidataId?: string;

  constructor({
      addresses, 
      agentCulture, 
      agentFunction, 
      agentGroup, 
      agentNationality, 
      /** @deprecated */agentOccupancy, 
      agentOccupation, 
      agentSchool, 
      agentSelections, 
      agentType, 
      artistDateText, 
      artistFlag, 
      atlId, 
      birthDateEdtf, 
      birthDateMax, 
      birthDateMin, 
      birthDateText, 
      birthDateUseTextFlag, 
      birthPlace, 
      contacts, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      deathDateEdtf, 
      deathDateMax, 
      deathDateMin, 
      deathDateText, 
      deathDateUseTextFlag, 
      deathPlace, 
      description, 
      displayName, 
      /** @deprecated */dissolutionDate, 
      /** @deprecated */foundationDate, 
      foundationPlace, 
      gender, 
      gettyUlanId, 
      gndId, 
      id, 
      initials, 
      lastModifier, 
      linguisticGroup, 
      masterFlag, 
      mdaCode, 
      modifiedBy, 
      otherNames, 
      primaryResId, 
      refNo, 
      replacementId, 
      salutation, 
      status, 
      title, 
      wikidataId
  }: {
      addresses?: Array<AgentAddress>;
      agentCulture?: string;
      agentFunction?: string;
      agentGroup?: string;
      agentNationality?: string;
      /** @deprecated */agentOccupancy?: string;
      agentOccupation?: string;
      agentSchool?: string;
      agentSelections?: Array<AgentSelection>;
      agentType?: Agent.AgentTypeEnum;
      artistDateText?: string;
      artistFlag?: boolean;
      atlId?: number;
      birthDateEdtf?: string;
      birthDateMax?: string;
      birthDateMin?: string;
      birthDateText?: string;
      birthDateUseTextFlag?: boolean;
      birthPlace?: string;
      contacts?: Array<AgentContact>;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      deathDateEdtf?: string;
      deathDateMax?: string;
      deathDateMin?: string;
      deathDateText?: string;
      deathDateUseTextFlag?: boolean;
      deathPlace?: string;
      description?: string;
      displayName?: string;
      /** @deprecated */dissolutionDate?: string;
      /** @deprecated */foundationDate?: string;
      foundationPlace?: string;
      gender?: Agent.GenderEnum;
      gettyUlanId?: string;
      gndId?: string;
      id?: string;
      initials?: string;
      lastModifier?: User;
      linguisticGroup?: string;
      masterFlag?: Agent.MasterFlagEnum;
      mdaCode?: string;
      modifiedBy?: string;
      otherNames?: Array<AgentOtherName>;
      primaryResId?: string;
      refNo?: string;
      replacementId?: string;
      salutation?: string;
      status?: Agent.StatusEnum;
      title?: Agent.TitleEnum;
      wikidataId?: string;
  } = {}) {
    this.addresses = addresses;
    this.agentCulture = agentCulture;
    this.agentFunction = agentFunction;
    this.agentGroup = agentGroup;
    this.agentNationality = agentNationality;
    this.agentOccupancy = agentOccupancy;
    this.agentOccupation = agentOccupation;
    this.agentSchool = agentSchool;
    this.agentSelections = agentSelections;
    this.agentType = agentType;
    this.artistDateText = artistDateText;
    this.artistFlag = artistFlag;
    this.atlId = atlId;
    this.birthDateEdtf = birthDateEdtf;
    this.birthDateMax = birthDateMax;
    this.birthDateMin = birthDateMin;
    this.birthDateText = birthDateText;
    this.birthDateUseTextFlag = birthDateUseTextFlag;
    this.birthPlace = birthPlace;
    this.contacts = contacts;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.deathDateEdtf = deathDateEdtf;
    this.deathDateMax = deathDateMax;
    this.deathDateMin = deathDateMin;
    this.deathDateText = deathDateText;
    this.deathDateUseTextFlag = deathDateUseTextFlag;
    this.deathPlace = deathPlace;
    this.description = description;
    this.displayName = displayName;
    this.dissolutionDate = dissolutionDate;
    this.foundationDate = foundationDate;
    this.foundationPlace = foundationPlace;
    this.gender = gender;
    this.gettyUlanId = gettyUlanId;
    this.gndId = gndId;
    this.id = id;
    this.initials = initials;
    this.lastModifier = lastModifier;
    this.linguisticGroup = linguisticGroup;
    this.masterFlag = masterFlag;
    this.mdaCode = mdaCode;
    this.modifiedBy = modifiedBy;
    this.otherNames = otherNames;
    this.primaryResId = primaryResId;
    this.refNo = refNo;
    this.replacementId = replacementId;
    this.salutation = salutation;
    this.status = status;
    this.title = title;
    this.wikidataId = wikidataId;
  }
}

export namespace Agent {

  export type AgentTypeEnum = 'Person' | 'Family' | 'Corporate Body' | 'People' | 'Unknown';
  export const AgentTypeEnum = {
      Person: 'Person' as AgentTypeEnum,
      Family: 'Family' as AgentTypeEnum,
      CorporateBody: 'Corporate Body' as AgentTypeEnum,
      People: 'People' as AgentTypeEnum,
      Unknown: 'Unknown' as AgentTypeEnum,
  }
  export const AgentTypeEnumValues = [
      AgentTypeEnum.Person, 
      AgentTypeEnum.Family, 
      AgentTypeEnum.CorporateBody, 
      AgentTypeEnum.People, 
      AgentTypeEnum.Unknown
  ];
  export type GenderEnum = 'M' | 'F' | 'U';
  export const GenderEnum = {
      M: 'M' as GenderEnum,
      F: 'F' as GenderEnum,
      U: 'U' as GenderEnum,
  }
  export const GenderEnumValues = [
      GenderEnum.M, 
      GenderEnum.F, 
      GenderEnum.U
  ];
  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
  export type TitleEnum = 'Sir' | 'Reverend' | 'Prof. Dr.' | 'Dr.';
  export const TitleEnum = {
      Sir: 'Sir' as TitleEnum,
      Reverend: 'Reverend' as TitleEnum,
      ProfDr: 'Prof. Dr.' as TitleEnum,
      Dr: 'Dr.' as TitleEnum,
  }
  export const TitleEnumValues = [
      TitleEnum.Sir, 
      TitleEnum.Reverend, 
      TitleEnum.ProfDr, 
      TitleEnum.Dr
  ];
}


/**
 * Agent form builder
 *
 */
export class AgentFormBuilder extends AbstractFormBuilder<AgentFormBuilder> {

  className = 'Agent';

  abstractFormBuilder!: AbstractFormBuilder<Agent>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Agent {
    return new Agent();
  }


  public addresses(
    builder: AgentAddressFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('addresses', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAddresses() {
    super.removeField('addresses');
    return this;
  }

  public agentCulture(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentCulture', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentCulture() {
    super.removeField('agentCulture');
    return this;
  }

  public agentFunction(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentFunction', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentFunction() {
    super.removeField('agentFunction');
    return this;
  }

  public agentGroup(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentGroup', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentGroup() {
    super.removeField('agentGroup');
    return this;
  }

  public agentNationality(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentNationality', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentNationality() {
    super.removeField('agentNationality');
    return this;
  }

  public agentOccupancy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentOccupancy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentOccupancy() {
    super.removeField('agentOccupancy');
    return this;
  }

  public agentOccupation(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentOccupation', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentOccupation() {
    super.removeField('agentOccupation');
    return this;
  }

  public agentSchool(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentSchool', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentSchool() {
    super.removeField('agentSchool');
    return this;
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public agentType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentType() {
    super.removeField('agentType');
    return this;
  }

  public artistDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artistDateText', [null, [Validators.maxLength(80), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtistDateText() {
    super.removeField('artistDateText');
    return this;
  }

  public artistFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artistFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtistFlag() {
    super.removeField('artistFlag');
    return this;
  }

  public atlId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('atlId', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAtlId() {
    super.removeField('atlId');
    return this;
  }

  public birthDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('birthDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBirthDateEdtf() {
    super.removeField('birthDateEdtf');
    return this;
  }

  public birthDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('birthDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBirthDateMax() {
    super.removeField('birthDateMax');
    return this;
  }

  public birthDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('birthDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBirthDateMin() {
    super.removeField('birthDateMin');
    return this;
  }

  public birthDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('birthDateText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBirthDateText() {
    super.removeField('birthDateText');
    return this;
  }

  public birthDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('birthDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBirthDateUseTextFlag() {
    super.removeField('birthDateUseTextFlag');
    return this;
  }

  public birthPlace(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('birthPlace', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBirthPlace() {
    super.removeField('birthPlace');
    return this;
  }


  public contacts(
    builder: AgentContactFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('contacts', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutContacts() {
    super.removeField('contacts');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public deathDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deathDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeathDateEdtf() {
    super.removeField('deathDateEdtf');
    return this;
  }

  public deathDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deathDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeathDateMax() {
    super.removeField('deathDateMax');
    return this;
  }

  public deathDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deathDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeathDateMin() {
    super.removeField('deathDateMin');
    return this;
  }

  public deathDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deathDateText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeathDateText() {
    super.removeField('deathDateText');
    return this;
  }

  public deathDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deathDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeathDateUseTextFlag() {
    super.removeField('deathDateUseTextFlag');
    return this;
  }

  public deathPlace(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deathPlace', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeathPlace() {
    super.removeField('deathPlace');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public displayName(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('displayName', [null, [Validators.minLength(1), Validators.maxLength(128), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisplayName() {
    super.removeField('displayName');
    return this;
  }

  public dissolutionDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dissolutionDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDissolutionDate() {
    super.removeField('dissolutionDate');
    return this;
  }

  public foundationDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('foundationDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFoundationDate() {
    super.removeField('foundationDate');
    return this;
  }

  public foundationPlace(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('foundationPlace', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFoundationPlace() {
    super.removeField('foundationPlace');
    return this;
  }

  public gender(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gender', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGender() {
    super.removeField('gender');
    return this;
  }

  public gettyUlanId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyUlanId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyUlanId() {
    super.removeField('gettyUlanId');
    return this;
  }

  public gndId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gndId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGndId() {
    super.removeField('gndId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public initials(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('initials', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInitials() {
    super.removeField('initials');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public linguisticGroup(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('linguisticGroup', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLinguisticGroup() {
    super.removeField('linguisticGroup');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public mdaCode(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mdaCode', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMdaCode() {
    super.removeField('mdaCode');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public otherNames(
    builder: AgentOtherNameFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('otherNames', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOtherNames() {
    super.removeField('otherNames');
    return this;
  }

  public primaryResId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('primaryResId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPrimaryResId() {
    super.removeField('primaryResId');
    return this;
  }

  public refNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNo() {
    super.removeField('refNo');
    return this;
  }

  public replacementId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('replacementId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReplacementId() {
    super.removeField('replacementId');
    return this;
  }

  public salutation(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('salutation', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSalutation() {
    super.removeField('salutation');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }

  public wikidataId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('wikidataId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutWikidataId() {
    super.removeField('wikidataId');
    return this;
  }


  public all(): AgentFormBuilder {
    return this
    .agentCulture() 
    .agentFunction() 
    .agentGroup() 
    .agentNationality() 
    .agentOccupancy() 
    .agentOccupation() 
    .agentSchool() 
    .agentType() 
    .artistDateText() 
    .artistFlag() 
    .atlId() 
    .birthDateEdtf() 
    .birthDateMax() 
    .birthDateMin() 
    .birthDateText() 
    .birthDateUseTextFlag() 
    .birthPlace() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .deathDateEdtf() 
    .deathDateMax() 
    .deathDateMin() 
    .deathDateText() 
    .deathDateUseTextFlag() 
    .deathPlace() 
    .description() 
    .displayName() 
    .dissolutionDate() 
    .foundationDate() 
    .foundationPlace() 
    .gender() 
    .gettyUlanId() 
    .gndId() 
    .id() 
    .initials() 
    .linguisticGroup() 
    .masterFlag() 
    .mdaCode() 
    .modifiedBy() 
    .primaryResId() 
    .refNo() 
    .replacementId() 
    .salutation() 
    .status() 
    .title() 
    .wikidataId() 
;
  }
}

