/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { Publication, PublicationFormBuilder } from '../models/publication';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ExhibitionVenue {
  agentSelections?: Array<AgentSelection>;
  /** @deprecated */bib?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  exhVDateEdtf?: string;
  exhVDateMax?: string;
  exhVDateMin?: string;
  exhVDateText?: string;
  exhVDateUseTextFlag?: boolean;
  exhVPlannedEndDateEdtf?: string;
  exhVenueTitle?: string;
  exhVenueTitleOriginal?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  publication?: Publication;
  status?: ExhibitionVenue.StatusEnum;
  visibilityFlag?: boolean;

  constructor({
      agentSelections, 
      /** @deprecated */bib, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      exhVDateEdtf, 
      exhVDateMax, 
      exhVDateMin, 
      exhVDateText, 
      exhVDateUseTextFlag, 
      exhVPlannedEndDateEdtf, 
      exhVenueTitle, 
      exhVenueTitleOriginal, 
      id, 
      lastModifier, 
      modifiedBy, 
      publication, 
      status, 
      visibilityFlag
  }: {
      agentSelections?: Array<AgentSelection>;
      /** @deprecated */bib?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      exhVDateEdtf?: string;
      exhVDateMax?: string;
      exhVDateMin?: string;
      exhVDateText?: string;
      exhVDateUseTextFlag?: boolean;
      exhVPlannedEndDateEdtf?: string;
      exhVenueTitle?: string;
      exhVenueTitleOriginal?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      publication?: Publication;
      status?: ExhibitionVenue.StatusEnum;
      visibilityFlag?: boolean;
  } = {}) {
    this.agentSelections = agentSelections;
    this.bib = bib;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.exhVDateEdtf = exhVDateEdtf;
    this.exhVDateMax = exhVDateMax;
    this.exhVDateMin = exhVDateMin;
    this.exhVDateText = exhVDateText;
    this.exhVDateUseTextFlag = exhVDateUseTextFlag;
    this.exhVPlannedEndDateEdtf = exhVPlannedEndDateEdtf;
    this.exhVenueTitle = exhVenueTitle;
    this.exhVenueTitleOriginal = exhVenueTitleOriginal;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.publication = publication;
    this.status = status;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace ExhibitionVenue {

  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * ExhibitionVenue form builder
 *
 */
export class ExhibitionVenueFormBuilder extends AbstractFormBuilder<ExhibitionVenueFormBuilder> {

  className = 'ExhibitionVenue';

  abstractFormBuilder!: AbstractFormBuilder<ExhibitionVenue>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ExhibitionVenue {
    return new ExhibitionVenue();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public bib(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('bib', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBib() {
    super.removeField('bib');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public exhVDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVDateEdtf() {
    super.removeField('exhVDateEdtf');
    return this;
  }

  public exhVDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVDateMax() {
    super.removeField('exhVDateMax');
    return this;
  }

  public exhVDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVDateMin() {
    super.removeField('exhVDateMin');
    return this;
  }

  public exhVDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVDateText', [null, [Validators.maxLength(80), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVDateText() {
    super.removeField('exhVDateText');
    return this;
  }

  public exhVDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVDateUseTextFlag() {
    super.removeField('exhVDateUseTextFlag');
    return this;
  }

  public exhVPlannedEndDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVPlannedEndDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVPlannedEndDateEdtf() {
    super.removeField('exhVPlannedEndDateEdtf');
    return this;
  }

  public exhVenueTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVenueTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVenueTitle() {
    super.removeField('exhVenueTitle');
    return this;
  }

  public exhVenueTitleOriginal(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVenueTitleOriginal', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVenueTitleOriginal() {
    super.removeField('exhVenueTitleOriginal');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public publication(
    builder: PublicationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('publication', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPublication() {
    super.removeField('publication');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ExhibitionVenueFormBuilder {
    return this
    .bib() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .exhVDateEdtf() 
    .exhVDateMax() 
    .exhVDateMin() 
    .exhVDateText() 
    .exhVDateUseTextFlag() 
    .exhVPlannedEndDateEdtf() 
    .exhVenueTitle() 
    .exhVenueTitleOriginal() 
    .id() 
    .modifiedBy() 
    .status() 
    .visibilityFlag() 
;
  }
}

