/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { EnvironmentMeasureQueryFilters, EnvironmentMeasureQueryFiltersFormBuilder } from '../models/environment-measure-query-filters';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class LocationQueryFilters {
  agentSelections?: Array<AgentSelection>;
  conditionInformationDate?: DateQuery;
  createdBy?: string;
  dateCreated?: string;
  dateModified?: string;
  environmentMeasures?: Array<EnvironmentMeasureQueryFilters>;
  id?: string;
  modifiedBy?: string;
  parentId?: string;
  refNo?: string;
  title?: string;
  type?: LocationQueryFilters.TypeEnum;

  constructor({
      agentSelections, 
      conditionInformationDate, 
      createdBy, 
      dateCreated, 
      dateModified, 
      environmentMeasures, 
      id, 
      modifiedBy, 
      parentId, 
      refNo, 
      title, 
      type
  }: {
      agentSelections?: Array<AgentSelection>;
      conditionInformationDate?: DateQuery;
      createdBy?: string;
      dateCreated?: string;
      dateModified?: string;
      environmentMeasures?: Array<EnvironmentMeasureQueryFilters>;
      id?: string;
      modifiedBy?: string;
      parentId?: string;
      refNo?: string;
      title?: string;
      type?: LocationQueryFilters.TypeEnum;
  } = {}) {
    this.agentSelections = agentSelections;
    this.conditionInformationDate = conditionInformationDate;
    this.createdBy = createdBy;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.environmentMeasures = environmentMeasures;
    this.id = id;
    this.modifiedBy = modifiedBy;
    this.parentId = parentId;
    this.refNo = refNo;
    this.title = title;
    this.type = type;
  }
}

export namespace LocationQueryFilters {

  export type TypeEnum = 'Building' | 'Storage Room' | 'Shelf' | 'Box';
  export const TypeEnum = {
      Building: 'Building' as TypeEnum,
      StorageRoom: 'Storage Room' as TypeEnum,
      Shelf: 'Shelf' as TypeEnum,
      Box: 'Box' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.Building, 
      TypeEnum.StorageRoom, 
      TypeEnum.Shelf, 
      TypeEnum.Box
  ];
}


/**
 * LocationQueryFilters form builder
 *
 */
export class LocationQueryFiltersFormBuilder extends AbstractFormBuilder<LocationQueryFiltersFormBuilder> {

  className = 'LocationQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<LocationQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): LocationQueryFilters {
    return new LocationQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public conditionInformationDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conditionInformationDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConditionInformationDate() {
    super.removeField('conditionInformationDate');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }


  public environmentMeasures(
    builder: EnvironmentMeasureQueryFiltersFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('environmentMeasures', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutEnvironmentMeasures() {
    super.removeField('environmentMeasures');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public parentId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentId() {
    super.removeField('parentId');
    return this;
  }

  public refNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNo() {
    super.removeField('refNo');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }


  public all(): LocationQueryFiltersFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .modifiedBy() 
    .parentId() 
    .refNo() 
    .title() 
    .type() 
;
  }
}

