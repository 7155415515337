/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Price {
  acquisitionInformationId?: string;
  artEventId?: string;
  denomination?: string;
  disposalInformationId?: string;
  id?: string;
  insuranceInformationId?: string;
  type?: Price.TypeEnum;
  value?: number;

  constructor({
      acquisitionInformationId, 
      artEventId, 
      denomination, 
      disposalInformationId, 
      id, 
      insuranceInformationId, 
      type, 
      value
  }: {
      acquisitionInformationId?: string;
      artEventId?: string;
      denomination?: string;
      disposalInformationId?: string;
      id?: string;
      insuranceInformationId?: string;
      type?: Price.TypeEnum;
      value?: number;
  } = {}) {
    this.acquisitionInformationId = acquisitionInformationId;
    this.artEventId = artEventId;
    this.denomination = denomination;
    this.disposalInformationId = disposalInformationId;
    this.id = id;
    this.insuranceInformationId = insuranceInformationId;
    this.type = type;
    this.value = value;
  }
}

export namespace Price {

  export type TypeEnum = 'Group Purchase Price' | 'Group Disposal Price' | 'Object Offer Price' | 'Object Purchaser Offer Price' | 'Object Purchase Price' | 'Original Object Purchase Price' | 'Funding' | 'Object Disposal Price' | 'Insurance Value';
  export const TypeEnum = {
      GroupPurchasePrice: 'Group Purchase Price' as TypeEnum,
      GroupDisposalPrice: 'Group Disposal Price' as TypeEnum,
      ObjectOfferPrice: 'Object Offer Price' as TypeEnum,
      ObjectPurchaserOfferPrice: 'Object Purchaser Offer Price' as TypeEnum,
      ObjectPurchasePrice: 'Object Purchase Price' as TypeEnum,
      OriginalObjectPurchasePrice: 'Original Object Purchase Price' as TypeEnum,
      Funding: 'Funding' as TypeEnum,
      ObjectDisposalPrice: 'Object Disposal Price' as TypeEnum,
      InsuranceValue: 'Insurance Value' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.GroupPurchasePrice, 
      TypeEnum.GroupDisposalPrice, 
      TypeEnum.ObjectOfferPrice, 
      TypeEnum.ObjectPurchaserOfferPrice, 
      TypeEnum.ObjectPurchasePrice, 
      TypeEnum.OriginalObjectPurchasePrice, 
      TypeEnum.Funding, 
      TypeEnum.ObjectDisposalPrice, 
      TypeEnum.InsuranceValue
  ];
}


/**
 * Price form builder
 *
 */
export class PriceFormBuilder extends AbstractFormBuilder<PriceFormBuilder> {

  className = 'Price';

  abstractFormBuilder!: AbstractFormBuilder<Price>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Price {
    return new Price();
  }

  public acquisitionInformationId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('acquisitionInformationId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAcquisitionInformationId() {
    super.removeField('acquisitionInformationId');
    return this;
  }

  public artEventId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artEventId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtEventId() {
    super.removeField('artEventId');
    return this;
  }

  public denomination(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('denomination', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDenomination() {
    super.removeField('denomination');
    return this;
  }

  public disposalInformationId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('disposalInformationId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisposalInformationId() {
    super.removeField('disposalInformationId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public insuranceInformationId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('insuranceInformationId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInsuranceInformationId() {
    super.removeField('insuranceInformationId');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }

  public value(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('value', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutValue() {
    super.removeField('value');
    return this;
  }


  public all(): PriceFormBuilder {
    return this
    .acquisitionInformationId() 
    .artEventId() 
    .denomination() 
    .disposalInformationId() 
    .id() 
    .insuranceInformationId() 
    .type() 
    .value() 
;
  }
}

