/* tslint:disable */
/* eslint-disable */
import { Task, TaskFormBuilder } from '../models/task';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class TaskCreateRequest {

  /**
   * The id of the entity that the task should be created for
   */
  entityId?: string;

  /**
   * The type of entity that the task should be created for
   */
  entityType?: TaskCreateRequest.EntityTypeEnum;

  /**
   * The task model that should be created
   */
  task?: Task;

  constructor({
    
  /**
   * The id of the entity that the task should be created for
   */
  entityId, 
    
  /**
   * The type of entity that the task should be created for
   */
  entityType, 
    
  /**
   * The task model that should be created
   */
  task
  }: {
    
  /**
   * The id of the entity that the task should be created for
   */
  entityId?: string;
    
  /**
   * The type of entity that the task should be created for
   */
  entityType?: TaskCreateRequest.EntityTypeEnum;
    
  /**
   * The task model that should be created
   */
  task?: Task;
  } = {}) {
    this.entityId = entityId;
    this.entityType = entityType;
    this.task = task;
  }
}

export namespace TaskCreateRequest {

  export type EntityTypeEnum = 'Agent' | 'Artwork' | 'ArtEvent' | 'Auction' | 'Exhibition' | 'ExhibitionVenue' | 'Publication' | 'Resource';
  export const EntityTypeEnum = {
      Agent: 'Agent' as EntityTypeEnum,
      Artwork: 'Artwork' as EntityTypeEnum,
      ArtEvent: 'ArtEvent' as EntityTypeEnum,
      Auction: 'Auction' as EntityTypeEnum,
      Exhibition: 'Exhibition' as EntityTypeEnum,
      ExhibitionVenue: 'ExhibitionVenue' as EntityTypeEnum,
      Publication: 'Publication' as EntityTypeEnum,
      Resource: 'Resource' as EntityTypeEnum,
  }
  export const EntityTypeEnumValues = [
      EntityTypeEnum.Agent, 
      EntityTypeEnum.Artwork, 
      EntityTypeEnum.ArtEvent, 
      EntityTypeEnum.Auction, 
      EntityTypeEnum.Exhibition, 
      EntityTypeEnum.ExhibitionVenue, 
      EntityTypeEnum.Publication, 
      EntityTypeEnum.Resource
  ];
}


/**
 * TaskCreateRequest form builder
 *
 */
export class TaskCreateRequestFormBuilder extends AbstractFormBuilder<TaskCreateRequestFormBuilder> {

  className = 'TaskCreateRequest';

  abstractFormBuilder!: AbstractFormBuilder<TaskCreateRequest>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): TaskCreateRequest {
    return new TaskCreateRequest();
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityType() {
    super.removeField('entityType');
    return this;
  }


  public task(
    builder: TaskFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('task', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutTask() {
    super.removeField('task');
    return this;
  }


  public all(): TaskCreateRequestFormBuilder {
    return this
    .entityId() 
    .entityType() 
;
  }
}

