/* tslint:disable */
/* eslint-disable */
import { Media, MediaFormBuilder } from '../models/media';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtworkSet {
  artworks?: Array<ArtworkSet>;
  description?: string;
  id?: string;
  numArtworks?: number;
  previewMedia?: Media;
  prio?: number;
  title?: string;
  type?: string;
  yearMax?: string;
  yearMin?: string;

  constructor({
      artworks, 
      description, 
      id, 
      numArtworks, 
      previewMedia, 
      prio, 
      title, 
      type, 
      yearMax, 
      yearMin
  }: {
      artworks?: Array<ArtworkSet>;
      description?: string;
      id?: string;
      numArtworks?: number;
      previewMedia?: Media;
      prio?: number;
      title?: string;
      type?: string;
      yearMax?: string;
      yearMin?: string;
  } = {}) {
    this.artworks = artworks;
    this.description = description;
    this.id = id;
    this.numArtworks = numArtworks;
    this.previewMedia = previewMedia;
    this.prio = prio;
    this.title = title;
    this.type = type;
    this.yearMax = yearMax;
    this.yearMin = yearMin;
  }
}

export namespace ArtworkSet {

}


/**
 * ArtworkSet form builder
 *
 */
export class ArtworkSetFormBuilder extends AbstractFormBuilder<ArtworkSetFormBuilder> {

  className = 'ArtworkSet';

  abstractFormBuilder!: AbstractFormBuilder<ArtworkSet>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtworkSet {
    return new ArtworkSet();
  }


  public artworks(
    builder: ArtworkSetFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artworks', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtworks() {
    super.removeField('artworks');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public numArtworks(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('numArtworks', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNumArtworks() {
    super.removeField('numArtworks');
    return this;
  }


  public previewMedia(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('previewMedia', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPreviewMedia() {
    super.removeField('previewMedia');
    return this;
  }

  public prio(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('prio', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPrio() {
    super.removeField('prio');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }

  public yearMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('yearMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutYearMax() {
    super.removeField('yearMax');
    return this;
  }

  public yearMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('yearMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutYearMin() {
    super.removeField('yearMin');
    return this;
  }


  public all(): ArtworkSetFormBuilder {
    return this
    .description() 
    .id() 
    .numArtworks() 
    .prio() 
    .title() 
    .type() 
    .yearMax() 
    .yearMin() 
;
  }
}

