/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ChangelogEntry {
  dateModified?: string;
  entity?: ChangelogEntry.EntityEnum;
  entityId?: string;
  id?: string;
  modifiedBy?: string;
  newValues?: any;
  oldValues?: any;
  operation?: ChangelogEntry.OperationEnum;
  relId?: string;

  constructor({
      dateModified, 
      entity, 
      entityId, 
      id, 
      modifiedBy, 
      newValues, 
      oldValues, 
      operation, 
      relId
  }: {
      dateModified?: string;
      entity?: ChangelogEntry.EntityEnum;
      entityId?: string;
      id?: string;
      modifiedBy?: string;
      newValues?: any;
      oldValues?: any;
      operation?: ChangelogEntry.OperationEnum;
      relId?: string;
  } = {}) {
    this.dateModified = dateModified;
    this.entity = entity;
    this.entityId = entityId;
    this.id = id;
    this.modifiedBy = modifiedBy;
    this.newValues = newValues;
    this.oldValues = oldValues;
    this.operation = operation;
    this.relId = relId;
  }
}

export namespace ChangelogEntry {

  export type EntityEnum = 'agent' | 'agent_address' | 'agent_contact' | 'agent_other_name' | 'agent_ref' | 'art' | 'art_event' | 'art_event_detail' | 'art_set' | 'art_title' | 'auction' | 'audit_information' | 'committee' | 'conservation' | 'essay' | 'exh_venue' | 'exhibit' | 'inscription' | 'insurance_information' | 'loan' | 'media' | 'movement_information' | 'note' | 'object_entry' | 'object_exit' | 'other_id' | 'place' | 'price' | 'publication' | 'resource' | 'resource_relationship';
  export const EntityEnum = {
      Agent: 'agent' as EntityEnum,
      AgentAddress: 'agent_address' as EntityEnum,
      AgentContact: 'agent_contact' as EntityEnum,
      AgentOtherName: 'agent_other_name' as EntityEnum,
      AgentRef: 'agent_ref' as EntityEnum,
      Art: 'art' as EntityEnum,
      ArtEvent: 'art_event' as EntityEnum,
      ArtEventDetail: 'art_event_detail' as EntityEnum,
      ArtSet: 'art_set' as EntityEnum,
      ArtTitle: 'art_title' as EntityEnum,
      Auction: 'auction' as EntityEnum,
      AuditInformation: 'audit_information' as EntityEnum,
      Committee: 'committee' as EntityEnum,
      Conservation: 'conservation' as EntityEnum,
      Essay: 'essay' as EntityEnum,
      ExhVenue: 'exh_venue' as EntityEnum,
      Exhibit: 'exhibit' as EntityEnum,
      Inscription: 'inscription' as EntityEnum,
      InsuranceInformation: 'insurance_information' as EntityEnum,
      Loan: 'loan' as EntityEnum,
      Media: 'media' as EntityEnum,
      MovementInformation: 'movement_information' as EntityEnum,
      Note: 'note' as EntityEnum,
      ObjectEntry: 'object_entry' as EntityEnum,
      ObjectExit: 'object_exit' as EntityEnum,
      OtherId: 'other_id' as EntityEnum,
      Place: 'place' as EntityEnum,
      Price: 'price' as EntityEnum,
      Publication: 'publication' as EntityEnum,
      Resource: 'resource' as EntityEnum,
      ResourceRelationship: 'resource_relationship' as EntityEnum,
  }
  export const EntityEnumValues = [
      EntityEnum.Agent, 
      EntityEnum.AgentAddress, 
      EntityEnum.AgentContact, 
      EntityEnum.AgentOtherName, 
      EntityEnum.AgentRef, 
      EntityEnum.Art, 
      EntityEnum.ArtEvent, 
      EntityEnum.ArtEventDetail, 
      EntityEnum.ArtSet, 
      EntityEnum.ArtTitle, 
      EntityEnum.Auction, 
      EntityEnum.AuditInformation, 
      EntityEnum.Committee, 
      EntityEnum.Conservation, 
      EntityEnum.Essay, 
      EntityEnum.ExhVenue, 
      EntityEnum.Exhibit, 
      EntityEnum.Inscription, 
      EntityEnum.InsuranceInformation, 
      EntityEnum.Loan, 
      EntityEnum.Media, 
      EntityEnum.MovementInformation, 
      EntityEnum.Note, 
      EntityEnum.ObjectEntry, 
      EntityEnum.ObjectExit, 
      EntityEnum.OtherId, 
      EntityEnum.Place, 
      EntityEnum.Price, 
      EntityEnum.Publication, 
      EntityEnum.Resource, 
      EntityEnum.ResourceRelationship
  ];
  export type OperationEnum = 'INSERT' | 'UPDATE' | 'DELETE';
  export const OperationEnum = {
      INSERT: 'INSERT' as OperationEnum,
      UPDATE: 'UPDATE' as OperationEnum,
      DELETE: 'DELETE' as OperationEnum,
  }
  export const OperationEnumValues = [
      OperationEnum.INSERT, 
      OperationEnum.UPDATE, 
      OperationEnum.DELETE
  ];
}


/**
 * ChangelogEntry form builder
 *
 */
export class ChangelogEntryFormBuilder extends AbstractFormBuilder<ChangelogEntryFormBuilder> {

  className = 'ChangelogEntry';

  abstractFormBuilder!: AbstractFormBuilder<ChangelogEntry>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ChangelogEntry {
    return new ChangelogEntry();
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public entity(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entity', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntity() {
    super.removeField('entity');
    return this;
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public newValues(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('newValues', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNewValues() {
    super.removeField('newValues');
    return this;
  }

  public oldValues(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('oldValues', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOldValues() {
    super.removeField('oldValues');
    return this;
  }

  public operation(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('operation', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOperation() {
    super.removeField('operation');
    return this;
  }

  public relId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('relId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRelId() {
    super.removeField('relId');
    return this;
  }


  public all(): ChangelogEntryFormBuilder {
    return this
    .dateModified() 
    .entity() 
    .entityId() 
    .id() 
    .modifiedBy() 
    .newValues() 
    .oldValues() 
    .operation() 
    .relId() 
;
  }
}

