/* tslint:disable */
/* eslint-disable */
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentOtherName {
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  nameDetail?: string;
  /** @deprecated */nameType?: AgentOtherName.NameTypeEnum;
  value?: string;

  constructor({
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      lastModifier, 
      modifiedBy, 
      nameDetail, 
      /** @deprecated */nameType, 
      value
  }: {
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      nameDetail?: string;
      /** @deprecated */nameType?: AgentOtherName.NameTypeEnum;
      value?: string;
  } = {}) {
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.nameDetail = nameDetail;
    this.nameType = nameType;
    this.value = value;
  }
}

export namespace AgentOtherName {

  export type NameTypeEnum = 'Abbreviation' | 'aka Name' | 'Authorized Form of Name' | 'First Name' | 'Former Name' | 'Identifier' | 'Index Name' | 'Last Name' | 'LC Name' | 'Maiden Name' | 'Married Name' | 'Middle Name' | 'Preferred Name' | 'Private Collection' | 'Dealer';
  export const NameTypeEnum = {
      Abbreviation: 'Abbreviation' as NameTypeEnum,
      AkaName: 'aka Name' as NameTypeEnum,
      AuthorizedFormOfName: 'Authorized Form of Name' as NameTypeEnum,
      FirstName: 'First Name' as NameTypeEnum,
      FormerName: 'Former Name' as NameTypeEnum,
      Identifier: 'Identifier' as NameTypeEnum,
      IndexName: 'Index Name' as NameTypeEnum,
      LastName: 'Last Name' as NameTypeEnum,
      LcName: 'LC Name' as NameTypeEnum,
      MaidenName: 'Maiden Name' as NameTypeEnum,
      MarriedName: 'Married Name' as NameTypeEnum,
      MiddleName: 'Middle Name' as NameTypeEnum,
      PreferredName: 'Preferred Name' as NameTypeEnum,
      PrivateCollection: 'Private Collection' as NameTypeEnum,
      Dealer: 'Dealer' as NameTypeEnum,
  }
  export const NameTypeEnumValues = [
      NameTypeEnum.Abbreviation, 
      NameTypeEnum.AkaName, 
      NameTypeEnum.AuthorizedFormOfName, 
      NameTypeEnum.FirstName, 
      NameTypeEnum.FormerName, 
      NameTypeEnum.Identifier, 
      NameTypeEnum.IndexName, 
      NameTypeEnum.LastName, 
      NameTypeEnum.LcName, 
      NameTypeEnum.MaidenName, 
      NameTypeEnum.MarriedName, 
      NameTypeEnum.MiddleName, 
      NameTypeEnum.PreferredName, 
      NameTypeEnum.PrivateCollection, 
      NameTypeEnum.Dealer
  ];
}


/**
 * AgentOtherName form builder
 *
 */
export class AgentOtherNameFormBuilder extends AbstractFormBuilder<AgentOtherNameFormBuilder> {

  className = 'AgentOtherName';

  abstractFormBuilder!: AbstractFormBuilder<AgentOtherName>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentOtherName {
    return new AgentOtherName();
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public nameDetail(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('nameDetail', [null, [Validators.maxLength(150), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNameDetail() {
    super.removeField('nameDetail');
    return this;
  }

  public nameType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('nameType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNameType() {
    super.removeField('nameType');
    return this;
  }

  public value(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('value', [null, [Validators.maxLength(150), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutValue() {
    super.removeField('value');
    return this;
  }


  public all(): AgentOtherNameFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .modifiedBy() 
    .nameDetail() 
    .nameType() 
    .value() 
;
  }
}

