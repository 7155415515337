/* tslint:disable */
/* eslint-disable */
import { PlaceSelection, PlaceSelectionFormBuilder } from '../models/place-selection';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PlaceSelectionCreateRequest {

  /**
   * The id of the entity that the place selection should be created for
   */
  entityId?: string;

  /**
   * The type of entity that the place selection should be created for
   */
  entityType?: PlaceSelectionCreateRequest.EntityTypeEnum;

  /**
   * The place selection model that should be created
   */
  placeSelection?: PlaceSelection;

  constructor({
    
  /**
   * The id of the entity that the place selection should be created for
   */
  entityId, 
    
  /**
   * The type of entity that the place selection should be created for
   */
  entityType, 
    
  /**
   * The place selection model that should be created
   */
  placeSelection
  }: {
    
  /**
   * The id of the entity that the place selection should be created for
   */
  entityId?: string;
    
  /**
   * The type of entity that the place selection should be created for
   */
  entityType?: PlaceSelectionCreateRequest.EntityTypeEnum;
    
  /**
   * The place selection model that should be created
   */
  placeSelection?: PlaceSelection;
  } = {}) {
    this.entityId = entityId;
    this.entityType = entityType;
    this.placeSelection = placeSelection;
  }
}

export namespace PlaceSelectionCreateRequest {

  export type EntityTypeEnum = 'Artwork' | 'Resource';
  export const EntityTypeEnum = {
      Artwork: 'Artwork' as EntityTypeEnum,
      Resource: 'Resource' as EntityTypeEnum,
  }
  export const EntityTypeEnumValues = [
      EntityTypeEnum.Artwork, 
      EntityTypeEnum.Resource
  ];
}


/**
 * PlaceSelectionCreateRequest form builder
 *
 */
export class PlaceSelectionCreateRequestFormBuilder extends AbstractFormBuilder<PlaceSelectionCreateRequestFormBuilder> {

  className = 'PlaceSelectionCreateRequest';

  abstractFormBuilder!: AbstractFormBuilder<PlaceSelectionCreateRequest>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PlaceSelectionCreateRequest {
    return new PlaceSelectionCreateRequest();
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityType() {
    super.removeField('entityType');
    return this;
  }


  public placeSelection(
    builder: PlaceSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('placeSelection', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlaceSelection() {
    super.removeField('placeSelection');
    return this;
  }


  public all(): PlaceSelectionCreateRequestFormBuilder {
    return this
    .entityId() 
    .entityType() 
;
  }
}

