/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { IndexingTerm, IndexingTermFormBuilder } from '../models/indexing-term';
import { OtherId, OtherIdFormBuilder } from '../models/other-id';
import { Place, PlaceFormBuilder } from '../models/place';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ResourceQueryFilters {
  agents?: Array<AgentSelection>;
  allFields?: string;
  box?: string;
  containsMediaFlag?: boolean;
  createdBy?: string;
  creationDateEdtf?: string;
  folder?: string;
  /** @deprecated */genres?: Array<IndexingTerm>;
  hasPreviewImageFlag?: boolean;
  id?: string;
  includeArchived?: boolean;
  indexingTerms?: Array<IndexingTerm>;
  levelOfDescription?: ResourceQueryFilters.LevelOfDescriptionEnum;
  modifiedBy?: string;
  otherIds?: Array<OtherId>;
  parentId?: string;
  parentIdRecursive?: string;
  places?: Array<Place>;
  status?: ResourceQueryFilters.StatusEnum;
  /** @deprecated */subjects?: Array<IndexingTerm>;
  title?: string;
  visibilityFlag?: boolean;

  constructor({
      agents, 
      allFields, 
      box, 
      containsMediaFlag, 
      createdBy, 
      creationDateEdtf, 
      folder, 
      /** @deprecated */genres, 
      hasPreviewImageFlag, 
      id, 
      includeArchived, 
      indexingTerms, 
      levelOfDescription, 
      modifiedBy, 
      otherIds, 
      parentId, 
      parentIdRecursive, 
      places, 
      status, 
      /** @deprecated */subjects, 
      title, 
      visibilityFlag
  }: {
      agents?: Array<AgentSelection>;
      allFields?: string;
      box?: string;
      containsMediaFlag?: boolean;
      createdBy?: string;
      creationDateEdtf?: string;
      folder?: string;
      /** @deprecated */genres?: Array<IndexingTerm>;
      hasPreviewImageFlag?: boolean;
      id?: string;
      includeArchived?: boolean;
      indexingTerms?: Array<IndexingTerm>;
      levelOfDescription?: ResourceQueryFilters.LevelOfDescriptionEnum;
      modifiedBy?: string;
      otherIds?: Array<OtherId>;
      parentId?: string;
      parentIdRecursive?: string;
      places?: Array<Place>;
      status?: ResourceQueryFilters.StatusEnum;
      /** @deprecated */subjects?: Array<IndexingTerm>;
      title?: string;
      visibilityFlag?: boolean;
  } = {}) {
    this.agents = agents;
    this.allFields = allFields;
    this.box = box;
    this.containsMediaFlag = containsMediaFlag;
    this.createdBy = createdBy;
    this.creationDateEdtf = creationDateEdtf;
    this.folder = folder;
    this.genres = genres;
    this.hasPreviewImageFlag = hasPreviewImageFlag;
    this.id = id;
    this.includeArchived = includeArchived;
    this.indexingTerms = indexingTerms;
    this.levelOfDescription = levelOfDescription;
    this.modifiedBy = modifiedBy;
    this.otherIds = otherIds;
    this.parentId = parentId;
    this.parentIdRecursive = parentIdRecursive;
    this.places = places;
    this.status = status;
    this.subjects = subjects;
    this.title = title;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace ResourceQueryFilters {

  export type LevelOfDescriptionEnum = 'Collection' | 'Series' | 'File' | 'Item';
  export const LevelOfDescriptionEnum = {
      Collection: 'Collection' as LevelOfDescriptionEnum,
      Series: 'Series' as LevelOfDescriptionEnum,
      File: 'File' as LevelOfDescriptionEnum,
      Item: 'Item' as LevelOfDescriptionEnum,
  }
  export const LevelOfDescriptionEnumValues = [
      LevelOfDescriptionEnum.Collection, 
      LevelOfDescriptionEnum.Series, 
      LevelOfDescriptionEnum.File, 
      LevelOfDescriptionEnum.Item
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * ResourceQueryFilters form builder
 *
 */
export class ResourceQueryFiltersFormBuilder extends AbstractFormBuilder<ResourceQueryFiltersFormBuilder> {

  className = 'ResourceQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ResourceQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ResourceQueryFilters {
    return new ResourceQueryFilters();
  }


  public agents(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgents() {
    super.removeField('agents');
    return this;
  }

  public allFields(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('allFields', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAllFields() {
    super.removeField('allFields');
    return this;
  }

  public box(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('box', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBox() {
    super.removeField('box');
    return this;
  }

  public containsMediaFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('containsMediaFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutContainsMediaFlag() {
    super.removeField('containsMediaFlag');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }

  public creationDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('creationDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreationDateEdtf() {
    super.removeField('creationDateEdtf');
    return this;
  }

  public folder(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('folder', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFolder() {
    super.removeField('folder');
    return this;
  }


  public genres(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('genres', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutGenres() {
    super.removeField('genres');
    return this;
  }

  public hasPreviewImageFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('hasPreviewImageFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHasPreviewImageFlag() {
    super.removeField('hasPreviewImageFlag');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public includeArchived(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('includeArchived', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIncludeArchived() {
    super.removeField('includeArchived');
    return this;
  }


  public indexingTerms(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTerms', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTerms() {
    super.removeField('indexingTerms');
    return this;
  }

  public levelOfDescription(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('levelOfDescription', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLevelOfDescription() {
    super.removeField('levelOfDescription');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public otherIds(
    builder: OtherIdFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('otherIds', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOtherIds() {
    super.removeField('otherIds');
    return this;
  }

  public parentId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentId() {
    super.removeField('parentId');
    return this;
  }

  public parentIdRecursive(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentIdRecursive', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentIdRecursive() {
    super.removeField('parentIdRecursive');
    return this;
  }


  public places(
    builder: PlaceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('places', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlaces() {
    super.removeField('places');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }


  public subjects(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('subjects', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSubjects() {
    super.removeField('subjects');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ResourceQueryFiltersFormBuilder {
    return this
    .allFields() 
    .box() 
    .containsMediaFlag() 
    .createdBy() 
    .creationDateEdtf() 
    .folder() 
    .hasPreviewImageFlag() 
    .id() 
    .includeArchived() 
    .levelOfDescription() 
    .modifiedBy() 
    .parentId() 
    .parentIdRecursive() 
    .status() 
    .title() 
    .visibilityFlag() 
;
  }
}

