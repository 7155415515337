/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { IndexingTerm, IndexingTermFormBuilder } from '../models/indexing-term';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PublicationQueryFilters {
  agentSelections?: Array<AgentSelection>;
  /** @deprecated */collVol?: string;
  /** @deprecated */collVolNum?: string;
  createdBy?: string;
  fairUseFlag?: boolean;
  grandParentTitle?: string;
  id?: string;
  inArtSet?: string;
  indexingTerm?: Array<IndexingTerm>;
  isbn?: string;
  issNo?: string;
  masterFlag?: PublicationQueryFilters.MasterFlagEnum;
  mediaAvailable?: boolean;
  modifiedBy?: string;
  oclc?: string;
  parentTitle?: string;
  pubDate?: DateQuery;
  pubDateEdtf?: string;
  pubNum?: string;
  pubTitle?: string;
  pubType?: Array<string>;
  relatedToArtist?: Agent;
  searchString?: string;
  status?: PublicationQueryFilters.StatusEnum;
  /** @deprecated */subjects?: Array<IndexingTerm>;
  textSearch?: string;
  visibilityFlag?: boolean;
  volume?: string;

  constructor({
      agentSelections, 
      /** @deprecated */collVol, 
      /** @deprecated */collVolNum, 
      createdBy, 
      fairUseFlag, 
      grandParentTitle, 
      id, 
      inArtSet, 
      indexingTerm, 
      isbn, 
      issNo, 
      masterFlag, 
      mediaAvailable, 
      modifiedBy, 
      oclc, 
      parentTitle, 
      pubDate, 
      pubDateEdtf, 
      pubNum, 
      pubTitle, 
      pubType, 
      relatedToArtist, 
      searchString, 
      status, 
      /** @deprecated */subjects, 
      textSearch, 
      visibilityFlag, 
      volume
  }: {
      agentSelections?: Array<AgentSelection>;
      /** @deprecated */collVol?: string;
      /** @deprecated */collVolNum?: string;
      createdBy?: string;
      fairUseFlag?: boolean;
      grandParentTitle?: string;
      id?: string;
      inArtSet?: string;
      indexingTerm?: Array<IndexingTerm>;
      isbn?: string;
      issNo?: string;
      masterFlag?: PublicationQueryFilters.MasterFlagEnum;
      mediaAvailable?: boolean;
      modifiedBy?: string;
      oclc?: string;
      parentTitle?: string;
      pubDate?: DateQuery;
      pubDateEdtf?: string;
      pubNum?: string;
      pubTitle?: string;
      pubType?: Array<string>;
      relatedToArtist?: Agent;
      searchString?: string;
      status?: PublicationQueryFilters.StatusEnum;
      /** @deprecated */subjects?: Array<IndexingTerm>;
      textSearch?: string;
      visibilityFlag?: boolean;
      volume?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.collVol = collVol;
    this.collVolNum = collVolNum;
    this.createdBy = createdBy;
    this.fairUseFlag = fairUseFlag;
    this.grandParentTitle = grandParentTitle;
    this.id = id;
    this.inArtSet = inArtSet;
    this.indexingTerm = indexingTerm;
    this.isbn = isbn;
    this.issNo = issNo;
    this.masterFlag = masterFlag;
    this.mediaAvailable = mediaAvailable;
    this.modifiedBy = modifiedBy;
    this.oclc = oclc;
    this.parentTitle = parentTitle;
    this.pubDate = pubDate;
    this.pubDateEdtf = pubDateEdtf;
    this.pubNum = pubNum;
    this.pubTitle = pubTitle;
    this.pubType = pubType;
    this.relatedToArtist = relatedToArtist;
    this.searchString = searchString;
    this.status = status;
    this.subjects = subjects;
    this.textSearch = textSearch;
    this.visibilityFlag = visibilityFlag;
    this.volume = volume;
  }
}

export namespace PublicationQueryFilters {

  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * PublicationQueryFilters form builder
 *
 */
export class PublicationQueryFiltersFormBuilder extends AbstractFormBuilder<PublicationQueryFiltersFormBuilder> {

  className = 'PublicationQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PublicationQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PublicationQueryFilters {
    return new PublicationQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public collVol(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collVol', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollVol() {
    super.removeField('collVol');
    return this;
  }

  public collVolNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collVolNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollVolNum() {
    super.removeField('collVolNum');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }

  public fairUseFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('fairUseFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFairUseFlag() {
    super.removeField('fairUseFlag');
    return this;
  }

  public grandParentTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('grandParentTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGrandParentTitle() {
    super.removeField('grandParentTitle');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public inArtSet(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inArtSet', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInArtSet() {
    super.removeField('inArtSet');
    return this;
  }


  public indexingTerm(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTerm', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTerm() {
    super.removeField('indexingTerm');
    return this;
  }

  public isbn(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('isbn', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIsbn() {
    super.removeField('isbn');
    return this;
  }

  public issNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('issNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIssNo() {
    super.removeField('issNo');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public mediaAvailable(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mediaAvailable', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMediaAvailable() {
    super.removeField('mediaAvailable');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public oclc(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('oclc', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOclc() {
    super.removeField('oclc');
    return this;
  }

  public parentTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentTitle() {
    super.removeField('parentTitle');
    return this;
  }


  public pubDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('pubDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPubDate() {
    super.removeField('pubDate');
    return this;
  }

  public pubDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubDateEdtf() {
    super.removeField('pubDateEdtf');
    return this;
  }

  public pubNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubNum() {
    super.removeField('pubNum');
    return this;
  }

  public pubTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubTitle() {
    super.removeField('pubTitle');
    return this;
  }

  public pubType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubType', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubType() {
    super.removeField('pubType');
    return this;
  }


  public relatedToArtist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('relatedToArtist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRelatedToArtist() {
    super.removeField('relatedToArtist');
    return this;
  }

  public searchString(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchString', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchString() {
    super.removeField('searchString');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }


  public subjects(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('subjects', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSubjects() {
    super.removeField('subjects');
    return this;
  }

  public textSearch(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('textSearch', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTextSearch() {
    super.removeField('textSearch');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }

  public volume(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('volume', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVolume() {
    super.removeField('volume');
    return this;
  }


  public all(): PublicationQueryFiltersFormBuilder {
    return this
    .collVol() 
    .collVolNum() 
    .createdBy() 
    .fairUseFlag() 
    .grandParentTitle() 
    .id() 
    .inArtSet() 
    .isbn() 
    .issNo() 
    .masterFlag() 
    .mediaAvailable() 
    .modifiedBy() 
    .oclc() 
    .parentTitle() 
    .pubDateEdtf() 
    .pubNum() 
    .pubTitle() 
    .pubType() 
    .searchString() 
    .status() 
    .textSearch() 
    .visibilityFlag() 
    .volume() 
;
  }
}

