/* tslint:disable */
/* eslint-disable */
import { AcquisitionInformation, AcquisitionInformationFormBuilder } from '../models/acquisition-information';
import { Auction, AuctionFormBuilder } from '../models/auction';
import { AuditInformation, AuditInformationFormBuilder } from '../models/audit-information';
import { CollectionsReview, CollectionsReviewFormBuilder } from '../models/collections-review';
import { ConditionCheck, ConditionCheckFormBuilder } from '../models/condition-check';
import { Conservation, ConservationFormBuilder } from '../models/conservation';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { DisposalInformation, DisposalInformationFormBuilder } from '../models/disposal-information';
import { Exhibition, ExhibitionFormBuilder } from '../models/exhibition';
import { ExhibitionVenue, ExhibitionVenueFormBuilder } from '../models/exhibition-venue';
import { InsuranceInformation, InsuranceInformationFormBuilder } from '../models/insurance-information';
import { Loan, LoanFormBuilder } from '../models/loan';
import { Location, LocationFormBuilder } from '../models/location';
import { MovementInformation, MovementInformationFormBuilder } from '../models/movement-information';
import { ObjectEntry, ObjectEntryFormBuilder } from '../models/object-entry';
import { ObjectExit, ObjectExitFormBuilder } from '../models/object-exit';
import { PriceQueryFilters, PriceQueryFiltersFormBuilder } from '../models/price-query-filters';
import { Publication, PublicationFormBuilder } from '../models/publication';
import { Resource, ResourceFormBuilder } from '../models/resource';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtworkEventQueryFilters {
  acquisitionInformation?: AcquisitionInformation;
  artId?: string;
  auction?: Auction;
  auditInformation?: AuditInformation;
  collectionsReview?: CollectionsReview;
  conditionCheck?: ConditionCheck;
  conservation?: Conservation;
  disposalInformation?: DisposalInformation;
  eventDate?: DateQuery;
  eventType?: Array<string>;
  exhibition?: Exhibition;
  id?: string;
  insuranceInformation?: InsuranceInformation;
  loan?: Loan;
  movementInformation?: MovementInformation;
  movementTargetLocation?: Location;
  objectEntry?: ObjectEntry;
  objectExit?: ObjectExit;
  price?: PriceQueryFilters;
  publication?: Publication;
  resource?: Resource;
  status?: Array<string>;
  venue?: ExhibitionVenue;
  visibilityFlag?: boolean;

  constructor({
      acquisitionInformation, 
      artId, 
      auction, 
      auditInformation, 
      collectionsReview, 
      conditionCheck, 
      conservation, 
      disposalInformation, 
      eventDate, 
      eventType, 
      exhibition, 
      id, 
      insuranceInformation, 
      loan, 
      movementInformation, 
      movementTargetLocation, 
      objectEntry, 
      objectExit, 
      price, 
      publication, 
      resource, 
      status, 
      venue, 
      visibilityFlag
  }: {
      acquisitionInformation?: AcquisitionInformation;
      artId?: string;
      auction?: Auction;
      auditInformation?: AuditInformation;
      collectionsReview?: CollectionsReview;
      conditionCheck?: ConditionCheck;
      conservation?: Conservation;
      disposalInformation?: DisposalInformation;
      eventDate?: DateQuery;
      eventType?: Array<string>;
      exhibition?: Exhibition;
      id?: string;
      insuranceInformation?: InsuranceInformation;
      loan?: Loan;
      movementInformation?: MovementInformation;
      movementTargetLocation?: Location;
      objectEntry?: ObjectEntry;
      objectExit?: ObjectExit;
      price?: PriceQueryFilters;
      publication?: Publication;
      resource?: Resource;
      status?: Array<string>;
      venue?: ExhibitionVenue;
      visibilityFlag?: boolean;
  } = {}) {
    this.acquisitionInformation = acquisitionInformation;
    this.artId = artId;
    this.auction = auction;
    this.auditInformation = auditInformation;
    this.collectionsReview = collectionsReview;
    this.conditionCheck = conditionCheck;
    this.conservation = conservation;
    this.disposalInformation = disposalInformation;
    this.eventDate = eventDate;
    this.eventType = eventType;
    this.exhibition = exhibition;
    this.id = id;
    this.insuranceInformation = insuranceInformation;
    this.loan = loan;
    this.movementInformation = movementInformation;
    this.movementTargetLocation = movementTargetLocation;
    this.objectEntry = objectEntry;
    this.objectExit = objectExit;
    this.price = price;
    this.publication = publication;
    this.resource = resource;
    this.status = status;
    this.venue = venue;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace ArtworkEventQueryFilters {

}


/**
 * ArtworkEventQueryFilters form builder
 *
 */
export class ArtworkEventQueryFiltersFormBuilder extends AbstractFormBuilder<ArtworkEventQueryFiltersFormBuilder> {

  className = 'ArtworkEventQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ArtworkEventQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtworkEventQueryFilters {
    return new ArtworkEventQueryFilters();
  }


  public acquisitionInformation(
    builder: AcquisitionInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('acquisitionInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAcquisitionInformation() {
    super.removeField('acquisitionInformation');
    return this;
  }

  public artId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtId() {
    super.removeField('artId');
    return this;
  }


  public auction(
    builder: AuctionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('auction', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAuction() {
    super.removeField('auction');
    return this;
  }


  public auditInformation(
    builder: AuditInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('auditInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAuditInformation() {
    super.removeField('auditInformation');
    return this;
  }


  public collectionsReview(
    builder: CollectionsReviewFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('collectionsReview', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCollectionsReview() {
    super.removeField('collectionsReview');
    return this;
  }


  public conditionCheck(
    builder: ConditionCheckFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conditionCheck', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConditionCheck() {
    super.removeField('conditionCheck');
    return this;
  }


  public conservation(
    builder: ConservationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conservation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConservation() {
    super.removeField('conservation');
    return this;
  }


  public disposalInformation(
    builder: DisposalInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('disposalInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDisposalInformation() {
    super.removeField('disposalInformation');
    return this;
  }


  public eventDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('eventDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutEventDate() {
    super.removeField('eventDate');
    return this;
  }

  public eventType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('eventType', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEventType() {
    super.removeField('eventType');
    return this;
  }


  public exhibition(
    builder: ExhibitionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exhibition', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExhibition() {
    super.removeField('exhibition');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public insuranceInformation(
    builder: InsuranceInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('insuranceInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutInsuranceInformation() {
    super.removeField('insuranceInformation');
    return this;
  }


  public loan(
    builder: LoanFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('loan', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLoan() {
    super.removeField('loan');
    return this;
  }


  public movementInformation(
    builder: MovementInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('movementInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMovementInformation() {
    super.removeField('movementInformation');
    return this;
  }


  public movementTargetLocation(
    builder: LocationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('movementTargetLocation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMovementTargetLocation() {
    super.removeField('movementTargetLocation');
    return this;
  }


  public objectEntry(
    builder: ObjectEntryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('objectEntry', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutObjectEntry() {
    super.removeField('objectEntry');
    return this;
  }


  public objectExit(
    builder: ObjectExitFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('objectExit', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutObjectExit() {
    super.removeField('objectExit');
    return this;
  }


  public price(
    builder: PriceQueryFiltersFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('price', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPrice() {
    super.removeField('price');
    return this;
  }


  public publication(
    builder: PublicationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('publication', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPublication() {
    super.removeField('publication');
    return this;
  }


  public resource(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('resource', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutResource() {
    super.removeField('resource');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }


  public venue(
    builder: ExhibitionVenueFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('venue', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutVenue() {
    super.removeField('venue');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ArtworkEventQueryFiltersFormBuilder {
    return this
    .artId() 
    .eventType() 
    .id() 
    .status() 
    .visibilityFlag() 
;
  }
}

