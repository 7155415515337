/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { Price, PriceFormBuilder } from '../models/price';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AcquisitionInformation {
  accessionDate?: string;
  acquisitionAuthorisationDate?: string;
  acquisitionDate?: string;
  acquisitionMethod?: AcquisitionInformation.AcquisitionMethodEnum;
  acquisitionNumber?: string;
  acquisitionReason?: string;
  acquisitionTitle?: string;
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  funding?: Price;
  fundingSourceProvisos?: string;
  groupPurchasePrice?: Price;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  provisos?: string;
  totNumber?: string;

  constructor({
      accessionDate, 
      acquisitionAuthorisationDate, 
      acquisitionDate, 
      acquisitionMethod, 
      acquisitionNumber, 
      acquisitionReason, 
      acquisitionTitle, 
      agentSelections, 
      artEvents, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      funding, 
      fundingSourceProvisos, 
      groupPurchasePrice, 
      id, 
      lastModifier, 
      modifiedBy, 
      provisos, 
      totNumber
  }: {
      accessionDate?: string;
      acquisitionAuthorisationDate?: string;
      acquisitionDate?: string;
      acquisitionMethod?: AcquisitionInformation.AcquisitionMethodEnum;
      acquisitionNumber?: string;
      acquisitionReason?: string;
      acquisitionTitle?: string;
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      funding?: Price;
      fundingSourceProvisos?: string;
      groupPurchasePrice?: Price;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      provisos?: string;
      totNumber?: string;
  } = {}) {
    this.accessionDate = accessionDate;
    this.acquisitionAuthorisationDate = acquisitionAuthorisationDate;
    this.acquisitionDate = acquisitionDate;
    this.acquisitionMethod = acquisitionMethod;
    this.acquisitionNumber = acquisitionNumber;
    this.acquisitionReason = acquisitionReason;
    this.acquisitionTitle = acquisitionTitle;
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.funding = funding;
    this.fundingSourceProvisos = fundingSourceProvisos;
    this.groupPurchasePrice = groupPurchasePrice;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.provisos = provisos;
    this.totNumber = totNumber;
  }
}

export namespace AcquisitionInformation {

  export type AcquisitionMethodEnum = 'gift' | 'purchase';
  export const AcquisitionMethodEnum = {
      Gift: 'gift' as AcquisitionMethodEnum,
      Purchase: 'purchase' as AcquisitionMethodEnum,
  }
  export const AcquisitionMethodEnumValues = [
      AcquisitionMethodEnum.Gift, 
      AcquisitionMethodEnum.Purchase
  ];
}


/**
 * AcquisitionInformation form builder
 *
 */
export class AcquisitionInformationFormBuilder extends AbstractFormBuilder<AcquisitionInformationFormBuilder> {

  className = 'AcquisitionInformation';

  abstractFormBuilder!: AbstractFormBuilder<AcquisitionInformation>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AcquisitionInformation {
    return new AcquisitionInformation();
  }

  public accessionDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('accessionDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAccessionDate() {
    super.removeField('accessionDate');
    return this;
  }

  public acquisitionAuthorisationDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('acquisitionAuthorisationDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAcquisitionAuthorisationDate() {
    super.removeField('acquisitionAuthorisationDate');
    return this;
  }

  public acquisitionDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('acquisitionDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAcquisitionDate() {
    super.removeField('acquisitionDate');
    return this;
  }

  public acquisitionMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('acquisitionMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAcquisitionMethod() {
    super.removeField('acquisitionMethod');
    return this;
  }

  public acquisitionNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('acquisitionNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAcquisitionNumber() {
    super.removeField('acquisitionNumber');
    return this;
  }

  public acquisitionReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('acquisitionReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAcquisitionReason() {
    super.removeField('acquisitionReason');
    return this;
  }

  public acquisitionTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('acquisitionTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAcquisitionTitle() {
    super.removeField('acquisitionTitle');
    return this;
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }


  public funding(
    builder: PriceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('funding', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutFunding() {
    super.removeField('funding');
    return this;
  }

  public fundingSourceProvisos(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('fundingSourceProvisos', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFundingSourceProvisos() {
    super.removeField('fundingSourceProvisos');
    return this;
  }


  public groupPurchasePrice(
    builder: PriceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('groupPurchasePrice', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutGroupPurchasePrice() {
    super.removeField('groupPurchasePrice');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public provisos(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('provisos', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutProvisos() {
    super.removeField('provisos');
    return this;
  }

  public totNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('totNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTotNumber() {
    super.removeField('totNumber');
    return this;
  }


  public all(): AcquisitionInformationFormBuilder {
    return this
    .accessionDate() 
    .acquisitionAuthorisationDate() 
    .acquisitionDate() 
    .acquisitionMethod() 
    .acquisitionNumber() 
    .acquisitionReason() 
    .acquisitionTitle() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .fundingSourceProvisos() 
    .id() 
    .modifiedBy() 
    .provisos() 
    .totNumber() 
;
  }
}

