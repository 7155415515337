/* tslint:disable */
/* eslint-disable */
import { Permission, PermissionFormBuilder } from '../models/permission';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class User {
  active?: boolean;

  /**
   * same as "id"
   */
  cognitoId?: string;
  cognitoStatus?: string;
  dateCreated?: string;
  email?: string;
  emailVerified?: boolean;
  firstname?: string;
  groups?: Array<string>;
  id?: string;
  lastModifiedDate?: string;
  lastname?: string;
  mfaEnabled?: boolean;
  password?: string;
  permissions?: Array<Permission>;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  userStatus?: string;
  username?: string;

  constructor({
      active, 
    
  /**
   * same as "id"
   */
  cognitoId, 
      cognitoStatus, 
      dateCreated, 
      email, 
      emailVerified, 
      firstname, 
      groups, 
      id, 
      lastModifiedDate, 
      lastname, 
      mfaEnabled, 
      password, 
      permissions, 
      phoneNumber, 
      phoneNumberVerified, 
      userStatus, 
      username
  }: {
      active?: boolean;
    
  /**
   * same as "id"
   */
  cognitoId?: string;
      cognitoStatus?: string;
      dateCreated?: string;
      email?: string;
      emailVerified?: boolean;
      firstname?: string;
      groups?: Array<string>;
      id?: string;
      lastModifiedDate?: string;
      lastname?: string;
      mfaEnabled?: boolean;
      password?: string;
      permissions?: Array<Permission>;
      phoneNumber?: string;
      phoneNumberVerified?: boolean;
      userStatus?: string;
      username?: string;
  } = {}) {
    this.active = active;
    this.cognitoId = cognitoId;
    this.cognitoStatus = cognitoStatus;
    this.dateCreated = dateCreated;
    this.email = email;
    this.emailVerified = emailVerified;
    this.firstname = firstname;
    this.groups = groups;
    this.id = id;
    this.lastModifiedDate = lastModifiedDate;
    this.lastname = lastname;
    this.mfaEnabled = mfaEnabled;
    this.password = password;
    this.permissions = permissions;
    this.phoneNumber = phoneNumber;
    this.phoneNumberVerified = phoneNumberVerified;
    this.userStatus = userStatus;
    this.username = username;
  }
}

export namespace User {

}


/**
 * User form builder
 *
 */
export class UserFormBuilder extends AbstractFormBuilder<UserFormBuilder> {

  className = 'User';

  abstractFormBuilder!: AbstractFormBuilder<User>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): User {
    return new User();
  }

  public active(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('active', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutActive() {
    super.removeField('active');
    return this;
  }

  public cognitoId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('cognitoId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCognitoId() {
    super.removeField('cognitoId');
    return this;
  }

  public cognitoStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('cognitoStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCognitoStatus() {
    super.removeField('cognitoStatus');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public email(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('email', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEmail() {
    super.removeField('email');
    return this;
  }

  public emailVerified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('emailVerified', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEmailVerified() {
    super.removeField('emailVerified');
    return this;
  }

  public firstname(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('firstname', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFirstname() {
    super.removeField('firstname');
    return this;
  }

  public groups(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('groups', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGroups() {
    super.removeField('groups');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public lastModifiedDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('lastModifiedDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLastModifiedDate() {
    super.removeField('lastModifiedDate');
    return this;
  }

  public lastname(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('lastname', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLastname() {
    super.removeField('lastname');
    return this;
  }

  public mfaEnabled(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mfaEnabled', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMfaEnabled() {
    super.removeField('mfaEnabled');
    return this;
  }

  public password(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('password', [null, [Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/), Validators.minLength(8), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPassword() {
    super.removeField('password');
    return this;
  }


  public permissions(
    builder: PermissionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('permissions', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPermissions() {
    super.removeField('permissions');
    return this;
  }

  public phoneNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('phoneNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPhoneNumber() {
    super.removeField('phoneNumber');
    return this;
  }

  public phoneNumberVerified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('phoneNumberVerified', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPhoneNumberVerified() {
    super.removeField('phoneNumberVerified');
    return this;
  }

  public userStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('userStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUserStatus() {
    super.removeField('userStatus');
    return this;
  }

  public username(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('username', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUsername() {
    super.removeField('username');
    return this;
  }


  public all(): UserFormBuilder {
    return this
    .active() 
    .cognitoId() 
    .cognitoStatus() 
    .dateCreated() 
    .email() 
    .emailVerified() 
    .firstname() 
    .groups() 
    .id() 
    .lastModifiedDate() 
    .lastname() 
    .mfaEnabled() 
    .password() 
    .phoneNumber() 
    .phoneNumberVerified() 
    .userStatus() 
    .username() 
;
  }
}

