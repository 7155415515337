/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { ArtworkMedia, ArtworkMediaFormBuilder } from '../models/artwork-media';
import { ArtworkTitle, ArtworkTitleFormBuilder } from '../models/artwork-title';
import { IndexingTerm, IndexingTermFormBuilder } from '../models/indexing-term';
import { Inscription, InscriptionFormBuilder } from '../models/inscription';
import { Location, LocationFormBuilder } from '../models/location';
import { Media, MediaFormBuilder } from '../models/media';
import { Medium, MediumFormBuilder } from '../models/medium';
import { Note, NoteFormBuilder } from '../models/note';
import { OtherId, OtherIdFormBuilder } from '../models/other-id';
import { Resource, ResourceFormBuilder } from '../models/resource';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Artwork {
  /** @deprecated */additionalPreviewMedia?: Array<Media>;
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  artist?: Agent;
  artistType?: Artwork.ArtistTypeEnum;
  attestNum?: string;
  catNo?: string;
  createdBy?: string;
  creationEventId?: string;
  creator?: User;
  currentLocation?: Location;
  dateCreated?: string;
  dateModified?: string;
  detCreationYearEdtf?: string;
  detCreationYearMax?: string;
  detCreationYearMin?: string;
  detCreationYearText?: string;
  detCreationYearUseTextFlag?: boolean;
  detDimDepth?: number;
  detDimDepthIn?: number;
  detDimHeight?: number;
  detDimHeightIn?: number;
  /** @deprecated */detDimMetricIsCalculatedFlag?: boolean;
  detDimShapeText?: string;
  detDimWidth?: number;
  detDimWidthIn?: number;
  detTitle?: string;
  dimensionEventId?: string;
  dossierNo?: string;
  essay?: string;
  essayTeaser?: string;
  id?: string;
  inclusion?: Artwork.InclusionEnum;
  indexingTerms?: Array<IndexingTerm>;
  inscriptionRenderedText?: string;
  inscriptions?: Array<Inscription>;
  lastModifier?: User;
  lastPublishedDate?: string;
  masterFlag?: Artwork.MasterFlagEnum;
  /** @deprecated */media?: Media;
  medium?: Medium;
  mediumEventId?: string;
  modifiedBy?: string;
  normalLocation?: Location;
  notes?: Array<Note>;
  objectCategory?: Artwork.ObjectCategoryEnum;
  otherIds?: Array<OtherId>;
  plancheFragFlag?: boolean;
  plancheOrigFlag?: boolean;
  previousLocation?: Location;
  refNum?: string;
  referenceMedia?: Array<ArtworkMedia>;
  replacementId?: string;
  resId?: string;
  resource?: Resource;
  seqNum?: number;
  seriesType?: Artwork.SeriesTypeEnum;
  slug?: string;
  status?: Artwork.StatusEnum;
  /** @deprecated */titleEventId?: string;
  titles?: Array<ArtworkTitle>;
  visibilityFlag?: boolean;

  constructor({
      /** @deprecated */additionalPreviewMedia, 
      agentSelections, 
      artEvents, 
      artist, 
      artistType, 
      attestNum, 
      catNo, 
      createdBy, 
      creationEventId, 
      creator, 
      currentLocation, 
      dateCreated, 
      dateModified, 
      detCreationYearEdtf, 
      detCreationYearMax, 
      detCreationYearMin, 
      detCreationYearText, 
      detCreationYearUseTextFlag, 
      detDimDepth, 
      detDimDepthIn, 
      detDimHeight, 
      detDimHeightIn, 
      /** @deprecated */detDimMetricIsCalculatedFlag, 
      detDimShapeText, 
      detDimWidth, 
      detDimWidthIn, 
      detTitle, 
      dimensionEventId, 
      dossierNo, 
      essay, 
      essayTeaser, 
      id, 
      inclusion, 
      indexingTerms, 
      inscriptionRenderedText, 
      inscriptions, 
      lastModifier, 
      lastPublishedDate, 
      masterFlag, 
      /** @deprecated */media, 
      medium, 
      mediumEventId, 
      modifiedBy, 
      normalLocation, 
      notes, 
      objectCategory, 
      otherIds, 
      plancheFragFlag, 
      plancheOrigFlag, 
      previousLocation, 
      refNum, 
      referenceMedia, 
      replacementId, 
      resId, 
      resource, 
      seqNum, 
      seriesType, 
      slug, 
      status, 
      /** @deprecated */titleEventId, 
      titles, 
      visibilityFlag
  }: {
      /** @deprecated */additionalPreviewMedia?: Array<Media>;
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      artist?: Agent;
      artistType?: Artwork.ArtistTypeEnum;
      attestNum?: string;
      catNo?: string;
      createdBy?: string;
      creationEventId?: string;
      creator?: User;
      currentLocation?: Location;
      dateCreated?: string;
      dateModified?: string;
      detCreationYearEdtf?: string;
      detCreationYearMax?: string;
      detCreationYearMin?: string;
      detCreationYearText?: string;
      detCreationYearUseTextFlag?: boolean;
      detDimDepth?: number;
      detDimDepthIn?: number;
      detDimHeight?: number;
      detDimHeightIn?: number;
      /** @deprecated */detDimMetricIsCalculatedFlag?: boolean;
      detDimShapeText?: string;
      detDimWidth?: number;
      detDimWidthIn?: number;
      detTitle?: string;
      dimensionEventId?: string;
      dossierNo?: string;
      essay?: string;
      essayTeaser?: string;
      id?: string;
      inclusion?: Artwork.InclusionEnum;
      indexingTerms?: Array<IndexingTerm>;
      inscriptionRenderedText?: string;
      inscriptions?: Array<Inscription>;
      lastModifier?: User;
      lastPublishedDate?: string;
      masterFlag?: Artwork.MasterFlagEnum;
      /** @deprecated */media?: Media;
      medium?: Medium;
      mediumEventId?: string;
      modifiedBy?: string;
      normalLocation?: Location;
      notes?: Array<Note>;
      objectCategory?: Artwork.ObjectCategoryEnum;
      otherIds?: Array<OtherId>;
      plancheFragFlag?: boolean;
      plancheOrigFlag?: boolean;
      previousLocation?: Location;
      refNum?: string;
      referenceMedia?: Array<ArtworkMedia>;
      replacementId?: string;
      resId?: string;
      resource?: Resource;
      seqNum?: number;
      seriesType?: Artwork.SeriesTypeEnum;
      slug?: string;
      status?: Artwork.StatusEnum;
      /** @deprecated */titleEventId?: string;
      titles?: Array<ArtworkTitle>;
      visibilityFlag?: boolean;
  } = {}) {
    this.additionalPreviewMedia = additionalPreviewMedia;
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.artist = artist;
    this.artistType = artistType;
    this.attestNum = attestNum;
    this.catNo = catNo;
    this.createdBy = createdBy;
    this.creationEventId = creationEventId;
    this.creator = creator;
    this.currentLocation = currentLocation;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.detCreationYearEdtf = detCreationYearEdtf;
    this.detCreationYearMax = detCreationYearMax;
    this.detCreationYearMin = detCreationYearMin;
    this.detCreationYearText = detCreationYearText;
    this.detCreationYearUseTextFlag = detCreationYearUseTextFlag;
    this.detDimDepth = detDimDepth;
    this.detDimDepthIn = detDimDepthIn;
    this.detDimHeight = detDimHeight;
    this.detDimHeightIn = detDimHeightIn;
    this.detDimMetricIsCalculatedFlag = detDimMetricIsCalculatedFlag;
    this.detDimShapeText = detDimShapeText;
    this.detDimWidth = detDimWidth;
    this.detDimWidthIn = detDimWidthIn;
    this.detTitle = detTitle;
    this.dimensionEventId = dimensionEventId;
    this.dossierNo = dossierNo;
    this.essay = essay;
    this.essayTeaser = essayTeaser;
    this.id = id;
    this.inclusion = inclusion;
    this.indexingTerms = indexingTerms;
    this.inscriptionRenderedText = inscriptionRenderedText;
    this.inscriptions = inscriptions;
    this.lastModifier = lastModifier;
    this.lastPublishedDate = lastPublishedDate;
    this.masterFlag = masterFlag;
    this.media = media;
    this.medium = medium;
    this.mediumEventId = mediumEventId;
    this.modifiedBy = modifiedBy;
    this.normalLocation = normalLocation;
    this.notes = notes;
    this.objectCategory = objectCategory;
    this.otherIds = otherIds;
    this.plancheFragFlag = plancheFragFlag;
    this.plancheOrigFlag = plancheOrigFlag;
    this.previousLocation = previousLocation;
    this.refNum = refNum;
    this.referenceMedia = referenceMedia;
    this.replacementId = replacementId;
    this.resId = resId;
    this.resource = resource;
    this.seqNum = seqNum;
    this.seriesType = seriesType;
    this.slug = slug;
    this.status = status;
    this.titleEventId = titleEventId;
    this.titles = titles;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace Artwork {

  export type ArtistTypeEnum = 'Actual' | 'After' | 'and Assistants' | 'Attributed to' | 'Manner of' | 'Questionable' | 'School of' | 'Studio of' | 'Style of' | 'Unrelated' | 'Workshop of';
  export const ArtistTypeEnum = {
      Actual: 'Actual' as ArtistTypeEnum,
      After: 'After' as ArtistTypeEnum,
      AndAssistants: 'and Assistants' as ArtistTypeEnum,
      AttributedTo: 'Attributed to' as ArtistTypeEnum,
      MannerOf: 'Manner of' as ArtistTypeEnum,
      Questionable: 'Questionable' as ArtistTypeEnum,
      SchoolOf: 'School of' as ArtistTypeEnum,
      StudioOf: 'Studio of' as ArtistTypeEnum,
      StyleOf: 'Style of' as ArtistTypeEnum,
      Unrelated: 'Unrelated' as ArtistTypeEnum,
      WorkshopOf: 'Workshop of' as ArtistTypeEnum,
  }
  export const ArtistTypeEnumValues = [
      ArtistTypeEnum.Actual, 
      ArtistTypeEnum.After, 
      ArtistTypeEnum.AndAssistants, 
      ArtistTypeEnum.AttributedTo, 
      ArtistTypeEnum.MannerOf, 
      ArtistTypeEnum.Questionable, 
      ArtistTypeEnum.SchoolOf, 
      ArtistTypeEnum.StudioOf, 
      ArtistTypeEnum.StyleOf, 
      ArtistTypeEnum.Unrelated, 
      ArtistTypeEnum.WorkshopOf
  ];
  export type InclusionEnum = 'pending' | 'not-included' | 'included';
  export const InclusionEnum = {
      Pending: 'pending' as InclusionEnum,
      NotIncluded: 'not-included' as InclusionEnum,
      Included: 'included' as InclusionEnum,
  }
  export const InclusionEnumValues = [
      InclusionEnum.Pending, 
      InclusionEnum.NotIncluded, 
      InclusionEnum.Included
  ];
  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type ObjectCategoryEnum = 'Painting' | 'Drawing' | 'Sculpture' | 'Graphic' | 'Relief' | 'Plastic' | 'Stele' | 'Maquette' | 'Print' | 'Medal' | 'Coin' | 'Lithographs' | 'Tapestries' | 'Textiles' | '3D Print' | 'Photograph';
  export const ObjectCategoryEnum = {
      Painting: 'Painting' as ObjectCategoryEnum,
      Drawing: 'Drawing' as ObjectCategoryEnum,
      Sculpture: 'Sculpture' as ObjectCategoryEnum,
      Graphic: 'Graphic' as ObjectCategoryEnum,
      Relief: 'Relief' as ObjectCategoryEnum,
      Plastic: 'Plastic' as ObjectCategoryEnum,
      Stele: 'Stele' as ObjectCategoryEnum,
      Maquette: 'Maquette' as ObjectCategoryEnum,
      Print: 'Print' as ObjectCategoryEnum,
      Medal: 'Medal' as ObjectCategoryEnum,
      Coin: 'Coin' as ObjectCategoryEnum,
      Lithographs: 'Lithographs' as ObjectCategoryEnum,
      Tapestries: 'Tapestries' as ObjectCategoryEnum,
      Textiles: 'Textiles' as ObjectCategoryEnum,
      _3DPrint: '3D Print' as ObjectCategoryEnum,
      Photograph: 'Photograph' as ObjectCategoryEnum,
  }
  export const ObjectCategoryEnumValues = [
      ObjectCategoryEnum.Painting, 
      ObjectCategoryEnum.Drawing, 
      ObjectCategoryEnum.Sculpture, 
      ObjectCategoryEnum.Graphic, 
      ObjectCategoryEnum.Relief, 
      ObjectCategoryEnum.Plastic, 
      ObjectCategoryEnum.Stele, 
      ObjectCategoryEnum.Maquette, 
      ObjectCategoryEnum.Print, 
      ObjectCategoryEnum.Medal, 
      ObjectCategoryEnum.Coin, 
      ObjectCategoryEnum.Lithographs, 
      ObjectCategoryEnum.Tapestries, 
      ObjectCategoryEnum.Textiles, 
      ObjectCategoryEnum._3DPrint, 
      ObjectCategoryEnum.Photograph
  ];
  export type SeriesTypeEnum = 'Edition' | 'Unique' | 'Series';
  export const SeriesTypeEnum = {
      Edition: 'Edition' as SeriesTypeEnum,
      Unique: 'Unique' as SeriesTypeEnum,
      Series: 'Series' as SeriesTypeEnum,
  }
  export const SeriesTypeEnumValues = [
      SeriesTypeEnum.Edition, 
      SeriesTypeEnum.Unique, 
      SeriesTypeEnum.Series
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Artwork form builder
 *
 */
export class ArtworkFormBuilder extends AbstractFormBuilder<ArtworkFormBuilder> {

  className = 'Artwork';

  abstractFormBuilder!: AbstractFormBuilder<Artwork>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Artwork {
    return new Artwork();
  }


  public additionalPreviewMedia(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('additionalPreviewMedia', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAdditionalPreviewMedia() {
    super.removeField('additionalPreviewMedia');
    return this;
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }


  public artist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtist() {
    super.removeField('artist');
    return this;
  }

  public artistType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artistType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtistType() {
    super.removeField('artistType');
    return this;
  }

  public attestNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('attestNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAttestNum() {
    super.removeField('attestNum');
    return this;
  }

  public catNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('catNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCatNo() {
    super.removeField('catNo');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }

  public creationEventId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('creationEventId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreationEventId() {
    super.removeField('creationEventId');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }


  public currentLocation(
    builder: LocationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('currentLocation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCurrentLocation() {
    super.removeField('currentLocation');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public detCreationYearEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearEdtf() {
    super.removeField('detCreationYearEdtf');
    return this;
  }

  public detCreationYearMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearMax() {
    super.removeField('detCreationYearMax');
    return this;
  }

  public detCreationYearMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearMin() {
    super.removeField('detCreationYearMin');
    return this;
  }

  public detCreationYearText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearText() {
    super.removeField('detCreationYearText');
    return this;
  }

  public detCreationYearUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearUseTextFlag() {
    super.removeField('detCreationYearUseTextFlag');
    return this;
  }

  public detDimDepth(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimDepth', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimDepth() {
    super.removeField('detDimDepth');
    return this;
  }

  public detDimDepthIn(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimDepthIn', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimDepthIn() {
    super.removeField('detDimDepthIn');
    return this;
  }

  public detDimHeight(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimHeight', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimHeight() {
    super.removeField('detDimHeight');
    return this;
  }

  public detDimHeightIn(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimHeightIn', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimHeightIn() {
    super.removeField('detDimHeightIn');
    return this;
  }

  public detDimMetricIsCalculatedFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimMetricIsCalculatedFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimMetricIsCalculatedFlag() {
    super.removeField('detDimMetricIsCalculatedFlag');
    return this;
  }

  public detDimShapeText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimShapeText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimShapeText() {
    super.removeField('detDimShapeText');
    return this;
  }

  public detDimWidth(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimWidth', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimWidth() {
    super.removeField('detDimWidth');
    return this;
  }

  public detDimWidthIn(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimWidthIn', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimWidthIn() {
    super.removeField('detDimWidthIn');
    return this;
  }

  public detTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetTitle() {
    super.removeField('detTitle');
    return this;
  }

  public dimensionEventId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dimensionEventId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDimensionEventId() {
    super.removeField('dimensionEventId');
    return this;
  }

  public dossierNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dossierNo', [null, [Validators.maxLength(20), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDossierNo() {
    super.removeField('dossierNo');
    return this;
  }

  public essay(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('essay', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEssay() {
    super.removeField('essay');
    return this;
  }

  public essayTeaser(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('essayTeaser', [null, [Validators.maxLength(500), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEssayTeaser() {
    super.removeField('essayTeaser');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public inclusion(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inclusion', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInclusion() {
    super.removeField('inclusion');
    return this;
  }


  public indexingTerms(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTerms', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTerms() {
    super.removeField('indexingTerms');
    return this;
  }

  public inscriptionRenderedText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inscriptionRenderedText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInscriptionRenderedText() {
    super.removeField('inscriptionRenderedText');
    return this;
  }


  public inscriptions(
    builder: InscriptionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('inscriptions', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutInscriptions() {
    super.removeField('inscriptions');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public lastPublishedDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('lastPublishedDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLastPublishedDate() {
    super.removeField('lastPublishedDate');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }


  public media(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('media', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMedia() {
    super.removeField('media');
    return this;
  }


  public medium(
    builder: MediumFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('medium', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMedium() {
    super.removeField('medium');
    return this;
  }

  public mediumEventId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mediumEventId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMediumEventId() {
    super.removeField('mediumEventId');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public normalLocation(
    builder: LocationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('normalLocation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutNormalLocation() {
    super.removeField('normalLocation');
    return this;
  }


  public notes(
    builder: NoteFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('notes', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutNotes() {
    super.removeField('notes');
    return this;
  }

  public objectCategory(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('objectCategory', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutObjectCategory() {
    super.removeField('objectCategory');
    return this;
  }


  public otherIds(
    builder: OtherIdFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('otherIds', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOtherIds() {
    super.removeField('otherIds');
    return this;
  }

  public plancheFragFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('plancheFragFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPlancheFragFlag() {
    super.removeField('plancheFragFlag');
    return this;
  }

  public plancheOrigFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('plancheOrigFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPlancheOrigFlag() {
    super.removeField('plancheOrigFlag');
    return this;
  }


  public previousLocation(
    builder: LocationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('previousLocation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPreviousLocation() {
    super.removeField('previousLocation');
    return this;
  }

  public refNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNum() {
    super.removeField('refNum');
    return this;
  }


  public referenceMedia(
    builder: ArtworkMediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('referenceMedia', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutReferenceMedia() {
    super.removeField('referenceMedia');
    return this;
  }

  public replacementId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('replacementId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReplacementId() {
    super.removeField('replacementId');
    return this;
  }

  public resId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('resId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutResId() {
    super.removeField('resId');
    return this;
  }


  public resource(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('resource', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutResource() {
    super.removeField('resource');
    return this;
  }

  public seqNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seqNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeqNum() {
    super.removeField('seqNum');
    return this;
  }

  public seriesType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seriesType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeriesType() {
    super.removeField('seriesType');
    return this;
  }

  public slug(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('slug', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSlug() {
    super.removeField('slug');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public titleEventId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('titleEventId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitleEventId() {
    super.removeField('titleEventId');
    return this;
  }


  public titles(
    builder: ArtworkTitleFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('titles', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutTitles() {
    super.removeField('titles');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ArtworkFormBuilder {
    return this
    .artistType() 
    .attestNum() 
    .catNo() 
    .createdBy() 
    .creationEventId() 
    .dateCreated() 
    .dateModified() 
    .detCreationYearEdtf() 
    .detCreationYearMax() 
    .detCreationYearMin() 
    .detCreationYearText() 
    .detCreationYearUseTextFlag() 
    .detDimDepth() 
    .detDimDepthIn() 
    .detDimHeight() 
    .detDimHeightIn() 
    .detDimMetricIsCalculatedFlag() 
    .detDimShapeText() 
    .detDimWidth() 
    .detDimWidthIn() 
    .detTitle() 
    .dimensionEventId() 
    .dossierNo() 
    .essay() 
    .essayTeaser() 
    .id() 
    .inclusion() 
    .inscriptionRenderedText() 
    .lastPublishedDate() 
    .masterFlag() 
    .mediumEventId() 
    .modifiedBy() 
    .objectCategory() 
    .plancheFragFlag() 
    .plancheOrigFlag() 
    .refNum() 
    .replacementId() 
    .resId() 
    .seqNum() 
    .seriesType() 
    .slug() 
    .status() 
    .titleEventId() 
    .visibilityFlag() 
;
  }
}

