/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class MovementQueryFilters {
  agentSelections?: Array<AgentSelection>;
  id?: string;
  movementAuthorizationDate?: DateQuery;
  movementMethod?: MovementQueryFilters.MovementMethodEnum;
  movementNumber?: string;
  movementReason?: MovementQueryFilters.MovementReasonEnum;
  movementStatus?: MovementQueryFilters.MovementStatusEnum;
  movementTitle?: string;
  plannedRemovalDate?: DateQuery;
  removalDate?: DateQuery;

  constructor({
      agentSelections, 
      id, 
      movementAuthorizationDate, 
      movementMethod, 
      movementNumber, 
      movementReason, 
      movementStatus, 
      movementTitle, 
      plannedRemovalDate, 
      removalDate
  }: {
      agentSelections?: Array<AgentSelection>;
      id?: string;
      movementAuthorizationDate?: DateQuery;
      movementMethod?: MovementQueryFilters.MovementMethodEnum;
      movementNumber?: string;
      movementReason?: MovementQueryFilters.MovementReasonEnum;
      movementStatus?: MovementQueryFilters.MovementStatusEnum;
      movementTitle?: string;
      plannedRemovalDate?: DateQuery;
      removalDate?: DateQuery;
  } = {}) {
    this.agentSelections = agentSelections;
    this.id = id;
    this.movementAuthorizationDate = movementAuthorizationDate;
    this.movementMethod = movementMethod;
    this.movementNumber = movementNumber;
    this.movementReason = movementReason;
    this.movementStatus = movementStatus;
    this.movementTitle = movementTitle;
    this.plannedRemovalDate = plannedRemovalDate;
    this.removalDate = removalDate;
  }
}

export namespace MovementQueryFilters {

  export type MovementMethodEnum = 'Handcarried' | 'Trolley';
  export const MovementMethodEnum = {
      Handcarried: 'Handcarried' as MovementMethodEnum,
      Trolley: 'Trolley' as MovementMethodEnum,
  }
  export const MovementMethodEnumValues = [
      MovementMethodEnum.Handcarried, 
      MovementMethodEnum.Trolley
  ];
  export type MovementReasonEnum = 'Conservation' | 'Loan-out' | 'Exhibition';
  export const MovementReasonEnum = {
      Conservation: 'Conservation' as MovementReasonEnum,
      LoanOut: 'Loan-out' as MovementReasonEnum,
      Exhibition: 'Exhibition' as MovementReasonEnum,
  }
  export const MovementReasonEnumValues = [
      MovementReasonEnum.Conservation, 
      MovementReasonEnum.LoanOut, 
      MovementReasonEnum.Exhibition
  ];
  export type MovementStatusEnum = 'Todo' | 'Pending' | 'In-Progress' | 'Complete';
  export const MovementStatusEnum = {
      Todo: 'Todo' as MovementStatusEnum,
      Pending: 'Pending' as MovementStatusEnum,
      InProgress: 'In-Progress' as MovementStatusEnum,
      Complete: 'Complete' as MovementStatusEnum,
  }
  export const MovementStatusEnumValues = [
      MovementStatusEnum.Todo, 
      MovementStatusEnum.Pending, 
      MovementStatusEnum.InProgress, 
      MovementStatusEnum.Complete
  ];
}


/**
 * MovementQueryFilters form builder
 *
 */
export class MovementQueryFiltersFormBuilder extends AbstractFormBuilder<MovementQueryFiltersFormBuilder> {

  className = 'MovementQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<MovementQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): MovementQueryFilters {
    return new MovementQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public movementAuthorizationDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('movementAuthorizationDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMovementAuthorizationDate() {
    super.removeField('movementAuthorizationDate');
    return this;
  }

  public movementMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('movementMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMovementMethod() {
    super.removeField('movementMethod');
    return this;
  }

  public movementNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('movementNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMovementNumber() {
    super.removeField('movementNumber');
    return this;
  }

  public movementReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('movementReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMovementReason() {
    super.removeField('movementReason');
    return this;
  }

  public movementStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('movementStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMovementStatus() {
    super.removeField('movementStatus');
    return this;
  }

  public movementTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('movementTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMovementTitle() {
    super.removeField('movementTitle');
    return this;
  }


  public plannedRemovalDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('plannedRemovalDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlannedRemovalDate() {
    super.removeField('plannedRemovalDate');
    return this;
  }


  public removalDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('removalDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRemovalDate() {
    super.removeField('removalDate');
    return this;
  }


  public all(): MovementQueryFiltersFormBuilder {
    return this
    .id() 
    .movementMethod() 
    .movementNumber() 
    .movementReason() 
    .movementStatus() 
    .movementTitle() 
;
  }
}

