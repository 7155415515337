/* tslint:disable */
/* eslint-disable */
import { IndexingTermName, IndexingTermNameFormBuilder } from '../models/indexing-term-name';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class IndexingTerm {
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  description?: string;
  gettyAatId?: string;
  id?: string;
  lastModifier?: User;
  masterFlag?: IndexingTerm.MasterFlagEnum;
  modifiedBy?: string;
  /** @deprecated */name?: string;
  names?: Array<IndexingTermName>;
  replacementId?: string;
  type?: IndexingTerm.TypeEnum;

  constructor({
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      description, 
      gettyAatId, 
      id, 
      lastModifier, 
      masterFlag, 
      modifiedBy, 
      /** @deprecated */name, 
      names, 
      replacementId, 
      type
  }: {
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      description?: string;
      gettyAatId?: string;
      id?: string;
      lastModifier?: User;
      masterFlag?: IndexingTerm.MasterFlagEnum;
      modifiedBy?: string;
      /** @deprecated */name?: string;
      names?: Array<IndexingTermName>;
      replacementId?: string;
      type?: IndexingTerm.TypeEnum;
  } = {}) {
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.description = description;
    this.gettyAatId = gettyAatId;
    this.id = id;
    this.lastModifier = lastModifier;
    this.masterFlag = masterFlag;
    this.modifiedBy = modifiedBy;
    this.name = name;
    this.names = names;
    this.replacementId = replacementId;
    this.type = type;
  }
}

export namespace IndexingTerm {

  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type TypeEnum = 'Genre' | 'Subject' | 'Color' | 'Technique';
  export const TypeEnum = {
      Genre: 'Genre' as TypeEnum,
      Subject: 'Subject' as TypeEnum,
      Color: 'Color' as TypeEnum,
      Technique: 'Technique' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.Genre, 
      TypeEnum.Subject, 
      TypeEnum.Color, 
      TypeEnum.Technique
  ];
}


/**
 * IndexingTerm form builder
 *
 */
export class IndexingTermFormBuilder extends AbstractFormBuilder<IndexingTermFormBuilder> {

  className = 'IndexingTerm';

  abstractFormBuilder!: AbstractFormBuilder<IndexingTerm>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): IndexingTerm {
    return new IndexingTerm();
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public gettyAatId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyAatId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyAatId() {
    super.removeField('gettyAatId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public name(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('name', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutName() {
    super.removeField('name');
    return this;
  }


  public names(
    builder: IndexingTermNameFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('names', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutNames() {
    super.removeField('names');
    return this;
  }

  public replacementId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('replacementId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReplacementId() {
    super.removeField('replacementId');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }


  public all(): IndexingTermFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .description() 
    .gettyAatId() 
    .id() 
    .masterFlag() 
    .modifiedBy() 
    .name() 
    .replacementId() 
    .type() 
;
  }
}

