/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { Artwork, ArtworkFormBuilder } from '../models/artwork';
import { IndexingTerm, IndexingTermFormBuilder } from '../models/indexing-term';
import { Media, MediaFormBuilder } from '../models/media';
import { Note, NoteFormBuilder } from '../models/note';
import { OtherId, OtherIdFormBuilder } from '../models/other-id';
import { Place, PlaceFormBuilder } from '../models/place';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Resource {
  access?: string;
  agents?: Array<AgentSelection>;
  artwork?: Artwork;
  biblioRef?: string;
  biographicalNote?: string;
  box?: string;
  children?: Array<Resource>;
  childrenNum?: number;
  cite?: string;
  createdBy?: string;
  creationDateEdtf?: string;
  creationDateMax?: string;
  creationDateMin?: string;
  creationDateText?: string;
  creationDateUseTextFlag?: boolean;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  documentViewFlag?: boolean;
  folder?: string;
  fond?: Resource;
  /** @deprecated */genres?: Array<IndexingTerm>;
  hasChildren?: boolean;
  id?: string;
  indexingTerms?: Array<IndexingTerm>;
  inventoryNumber?: string;
  lastModifier?: User;
  levelOfDescription?: Resource.LevelOfDescriptionEnum;
  media?: Array<Media>;
  mediaNum?: number;
  modifiedBy?: string;
  notes?: Array<Note>;
  otherIds?: Array<OtherId>;
  parentId?: string;
  parents?: Array<Resource>;
  places?: Array<Place>;
  previewMedia?: Media;
  reproduction?: string;
  resArtType?: Resource.ResArtTypeEnum;
  scopeContent?: string;
  searchSummary?: string;
  seqNum?: number;
  slug?: string;
  sortChildrenBy?: Resource.SortChildrenByEnum;
  sortTitle?: string;
  status?: Resource.StatusEnum;
  /** @deprecated */subjects?: Array<IndexingTerm>;
  summary?: string;
  title?: string;
  visibilityFlag?: boolean;

  constructor({
      access, 
      agents, 
      artwork, 
      biblioRef, 
      biographicalNote, 
      box, 
      children, 
      childrenNum, 
      cite, 
      createdBy, 
      creationDateEdtf, 
      creationDateMax, 
      creationDateMin, 
      creationDateText, 
      creationDateUseTextFlag, 
      creator, 
      dateCreated, 
      dateModified, 
      documentViewFlag, 
      folder, 
      fond, 
      /** @deprecated */genres, 
      hasChildren, 
      id, 
      indexingTerms, 
      inventoryNumber, 
      lastModifier, 
      levelOfDescription, 
      media, 
      mediaNum, 
      modifiedBy, 
      notes, 
      otherIds, 
      parentId, 
      parents, 
      places, 
      previewMedia, 
      reproduction, 
      resArtType, 
      scopeContent, 
      searchSummary, 
      seqNum, 
      slug, 
      sortChildrenBy, 
      sortTitle, 
      status, 
      /** @deprecated */subjects, 
      summary, 
      title, 
      visibilityFlag
  }: {
      access?: string;
      agents?: Array<AgentSelection>;
      artwork?: Artwork;
      biblioRef?: string;
      biographicalNote?: string;
      box?: string;
      children?: Array<Resource>;
      childrenNum?: number;
      cite?: string;
      createdBy?: string;
      creationDateEdtf?: string;
      creationDateMax?: string;
      creationDateMin?: string;
      creationDateText?: string;
      creationDateUseTextFlag?: boolean;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      documentViewFlag?: boolean;
      folder?: string;
      fond?: Resource;
      /** @deprecated */genres?: Array<IndexingTerm>;
      hasChildren?: boolean;
      id?: string;
      indexingTerms?: Array<IndexingTerm>;
      inventoryNumber?: string;
      lastModifier?: User;
      levelOfDescription?: Resource.LevelOfDescriptionEnum;
      media?: Array<Media>;
      mediaNum?: number;
      modifiedBy?: string;
      notes?: Array<Note>;
      otherIds?: Array<OtherId>;
      parentId?: string;
      parents?: Array<Resource>;
      places?: Array<Place>;
      previewMedia?: Media;
      reproduction?: string;
      resArtType?: Resource.ResArtTypeEnum;
      scopeContent?: string;
      searchSummary?: string;
      seqNum?: number;
      slug?: string;
      sortChildrenBy?: Resource.SortChildrenByEnum;
      sortTitle?: string;
      status?: Resource.StatusEnum;
      /** @deprecated */subjects?: Array<IndexingTerm>;
      summary?: string;
      title?: string;
      visibilityFlag?: boolean;
  } = {}) {
    this.access = access;
    this.agents = agents;
    this.artwork = artwork;
    this.biblioRef = biblioRef;
    this.biographicalNote = biographicalNote;
    this.box = box;
    this.children = children;
    this.childrenNum = childrenNum;
    this.cite = cite;
    this.createdBy = createdBy;
    this.creationDateEdtf = creationDateEdtf;
    this.creationDateMax = creationDateMax;
    this.creationDateMin = creationDateMin;
    this.creationDateText = creationDateText;
    this.creationDateUseTextFlag = creationDateUseTextFlag;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.documentViewFlag = documentViewFlag;
    this.folder = folder;
    this.fond = fond;
    this.genres = genres;
    this.hasChildren = hasChildren;
    this.id = id;
    this.indexingTerms = indexingTerms;
    this.inventoryNumber = inventoryNumber;
    this.lastModifier = lastModifier;
    this.levelOfDescription = levelOfDescription;
    this.media = media;
    this.mediaNum = mediaNum;
    this.modifiedBy = modifiedBy;
    this.notes = notes;
    this.otherIds = otherIds;
    this.parentId = parentId;
    this.parents = parents;
    this.places = places;
    this.previewMedia = previewMedia;
    this.reproduction = reproduction;
    this.resArtType = resArtType;
    this.scopeContent = scopeContent;
    this.searchSummary = searchSummary;
    this.seqNum = seqNum;
    this.slug = slug;
    this.sortChildrenBy = sortChildrenBy;
    this.sortTitle = sortTitle;
    this.status = status;
    this.subjects = subjects;
    this.summary = summary;
    this.title = title;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace Resource {

  export type LevelOfDescriptionEnum = 'Collection' | 'Series' | 'File' | 'Item';
  export const LevelOfDescriptionEnum = {
      Collection: 'Collection' as LevelOfDescriptionEnum,
      Series: 'Series' as LevelOfDescriptionEnum,
      File: 'File' as LevelOfDescriptionEnum,
      Item: 'Item' as LevelOfDescriptionEnum,
  }
  export const LevelOfDescriptionEnumValues = [
      LevelOfDescriptionEnum.Collection, 
      LevelOfDescriptionEnum.Series, 
      LevelOfDescriptionEnum.File, 
      LevelOfDescriptionEnum.Item
  ];
  export type ResArtTypeEnum = 'dossier' | 'reproduction';
  export const ResArtTypeEnum = {
      Dossier: 'dossier' as ResArtTypeEnum,
      Reproduction: 'reproduction' as ResArtTypeEnum,
  }
  export const ResArtTypeEnumValues = [
      ResArtTypeEnum.Dossier, 
      ResArtTypeEnum.Reproduction
  ];
  export type SortChildrenByEnum = 'Title' | 'Date' | 'Sequence';
  export const SortChildrenByEnum = {
      Title: 'Title' as SortChildrenByEnum,
      Date: 'Date' as SortChildrenByEnum,
      Sequence: 'Sequence' as SortChildrenByEnum,
  }
  export const SortChildrenByEnumValues = [
      SortChildrenByEnum.Title, 
      SortChildrenByEnum.Date, 
      SortChildrenByEnum.Sequence
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Resource form builder
 *
 */
export class ResourceFormBuilder extends AbstractFormBuilder<ResourceFormBuilder> {

  className = 'Resource';

  abstractFormBuilder!: AbstractFormBuilder<Resource>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Resource {
    return new Resource();
  }

  public access(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('access', [null, [Validators.maxLength(255), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAccess() {
    super.removeField('access');
    return this;
  }


  public agents(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgents() {
    super.removeField('agents');
    return this;
  }


  public artwork(
    builder: ArtworkFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artwork', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtwork() {
    super.removeField('artwork');
    return this;
  }

  public biblioRef(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('biblioRef', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBiblioRef() {
    super.removeField('biblioRef');
    return this;
  }

  public biographicalNote(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('biographicalNote', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBiographicalNote() {
    super.removeField('biographicalNote');
    return this;
  }

  public box(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('box', [null, [Validators.maxLength(190), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBox() {
    super.removeField('box');
    return this;
  }


  public children(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('children', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutChildren() {
    super.removeField('children');
    return this;
  }

  public childrenNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('childrenNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutChildrenNum() {
    super.removeField('childrenNum');
    return this;
  }

  public cite(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('cite', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCite() {
    super.removeField('cite');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }

  public creationDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('creationDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreationDateEdtf() {
    super.removeField('creationDateEdtf');
    return this;
  }

  public creationDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('creationDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreationDateMax() {
    super.removeField('creationDateMax');
    return this;
  }

  public creationDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('creationDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreationDateMin() {
    super.removeField('creationDateMin');
    return this;
  }

  public creationDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('creationDateText', [null, [Validators.maxLength(80), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreationDateText() {
    super.removeField('creationDateText');
    return this;
  }

  public creationDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('creationDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreationDateUseTextFlag() {
    super.removeField('creationDateUseTextFlag');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public documentViewFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('documentViewFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDocumentViewFlag() {
    super.removeField('documentViewFlag');
    return this;
  }

  public folder(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('folder', [null, [Validators.maxLength(190), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFolder() {
    super.removeField('folder');
    return this;
  }


  public fond(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('fond', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutFond() {
    super.removeField('fond');
    return this;
  }


  public genres(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('genres', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutGenres() {
    super.removeField('genres');
    return this;
  }

  public hasChildren(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('hasChildren', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHasChildren() {
    super.removeField('hasChildren');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public indexingTerms(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTerms', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTerms() {
    super.removeField('indexingTerms');
    return this;
  }

  public inventoryNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inventoryNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInventoryNumber() {
    super.removeField('inventoryNumber');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public levelOfDescription(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('levelOfDescription', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLevelOfDescription() {
    super.removeField('levelOfDescription');
    return this;
  }


  public media(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('media', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMedia() {
    super.removeField('media');
    return this;
  }

  public mediaNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mediaNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMediaNum() {
    super.removeField('mediaNum');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public notes(
    builder: NoteFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('notes', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutNotes() {
    super.removeField('notes');
    return this;
  }


  public otherIds(
    builder: OtherIdFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('otherIds', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOtherIds() {
    super.removeField('otherIds');
    return this;
  }

  public parentId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentId() {
    super.removeField('parentId');
    return this;
  }


  public parents(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('parents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutParents() {
    super.removeField('parents');
    return this;
  }


  public places(
    builder: PlaceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('places', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlaces() {
    super.removeField('places');
    return this;
  }


  public previewMedia(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('previewMedia', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPreviewMedia() {
    super.removeField('previewMedia');
    return this;
  }

  public reproduction(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('reproduction', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReproduction() {
    super.removeField('reproduction');
    return this;
  }

  public resArtType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('resArtType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutResArtType() {
    super.removeField('resArtType');
    return this;
  }

  public scopeContent(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('scopeContent', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutScopeContent() {
    super.removeField('scopeContent');
    return this;
  }

  public searchSummary(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchSummary', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchSummary() {
    super.removeField('searchSummary');
    return this;
  }

  public seqNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seqNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeqNum() {
    super.removeField('seqNum');
    return this;
  }

  public slug(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('slug', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSlug() {
    super.removeField('slug');
    return this;
  }

  public sortChildrenBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('sortChildrenBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSortChildrenBy() {
    super.removeField('sortChildrenBy');
    return this;
  }

  public sortTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('sortTitle', [null, [Validators.maxLength(190), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSortTitle() {
    super.removeField('sortTitle');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }


  public subjects(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('subjects', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSubjects() {
    super.removeField('subjects');
    return this;
  }

  public summary(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('summary', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSummary() {
    super.removeField('summary');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.maxLength(200), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ResourceFormBuilder {
    return this
    .access() 
    .biblioRef() 
    .biographicalNote() 
    .box() 
    .childrenNum() 
    .cite() 
    .createdBy() 
    .creationDateEdtf() 
    .creationDateMax() 
    .creationDateMin() 
    .creationDateText() 
    .creationDateUseTextFlag() 
    .dateCreated() 
    .dateModified() 
    .documentViewFlag() 
    .folder() 
    .hasChildren() 
    .id() 
    .inventoryNumber() 
    .levelOfDescription() 
    .mediaNum() 
    .modifiedBy() 
    .parentId() 
    .reproduction() 
    .resArtType() 
    .scopeContent() 
    .searchSummary() 
    .seqNum() 
    .slug() 
    .sortChildrenBy() 
    .sortTitle() 
    .status() 
    .summary() 
    .title() 
    .visibilityFlag() 
;
  }
}

