/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Language {
  id?: string;
  languageCode?: string;
  text?: string;

  constructor({
      id, 
      languageCode, 
      text
  }: {
      id?: string;
      languageCode?: string;
      text?: string;
  } = {}) {
    this.id = id;
    this.languageCode = languageCode;
    this.text = text;
  }
}

export namespace Language {

}


/**
 * Language form builder
 *
 */
export class LanguageFormBuilder extends AbstractFormBuilder<LanguageFormBuilder> {

  className = 'Language';

  abstractFormBuilder!: AbstractFormBuilder<Language>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Language {
    return new Language();
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public languageCode(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('languageCode', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLanguageCode() {
    super.removeField('languageCode');
    return this;
  }

  public text(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('text', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutText() {
    super.removeField('text');
    return this;
  }


  public all(): LanguageFormBuilder {
    return this
    .id() 
    .languageCode() 
    .text() 
;
  }
}

