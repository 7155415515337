/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { EnvironmentMeasure, EnvironmentMeasureFormBuilder } from '../models/environment-measure';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Location {
  accessInformation?: string;
  agents?: Array<AgentSelection>;
  conditionInformation?: string;
  conditionInformationDate?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  environmentMeasures?: Array<EnvironmentMeasure>;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  parentId?: string;
  refNo?: string;
  securityInformation?: string;
  title?: string;
  type?: Location.TypeEnum;

  constructor({
      accessInformation, 
      agents, 
      conditionInformation, 
      conditionInformationDate, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      environmentMeasures, 
      id, 
      lastModifier, 
      modifiedBy, 
      parentId, 
      refNo, 
      securityInformation, 
      title, 
      type
  }: {
      accessInformation?: string;
      agents?: Array<AgentSelection>;
      conditionInformation?: string;
      conditionInformationDate?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      environmentMeasures?: Array<EnvironmentMeasure>;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      parentId?: string;
      refNo?: string;
      securityInformation?: string;
      title?: string;
      type?: Location.TypeEnum;
  } = {}) {
    this.accessInformation = accessInformation;
    this.agents = agents;
    this.conditionInformation = conditionInformation;
    this.conditionInformationDate = conditionInformationDate;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.environmentMeasures = environmentMeasures;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.parentId = parentId;
    this.refNo = refNo;
    this.securityInformation = securityInformation;
    this.title = title;
    this.type = type;
  }
}

export namespace Location {

  export type TypeEnum = 'Building' | 'Storage Room' | 'Shelf' | 'Box';
  export const TypeEnum = {
      Building: 'Building' as TypeEnum,
      StorageRoom: 'Storage Room' as TypeEnum,
      Shelf: 'Shelf' as TypeEnum,
      Box: 'Box' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.Building, 
      TypeEnum.StorageRoom, 
      TypeEnum.Shelf, 
      TypeEnum.Box
  ];
}


/**
 * Location form builder
 *
 */
export class LocationFormBuilder extends AbstractFormBuilder<LocationFormBuilder> {

  className = 'Location';

  abstractFormBuilder!: AbstractFormBuilder<Location>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Location {
    return new Location();
  }

  public accessInformation(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('accessInformation', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAccessInformation() {
    super.removeField('accessInformation');
    return this;
  }


  public agents(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgents() {
    super.removeField('agents');
    return this;
  }

  public conditionInformation(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionInformation', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionInformation() {
    super.removeField('conditionInformation');
    return this;
  }

  public conditionInformationDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionInformationDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionInformationDate() {
    super.removeField('conditionInformationDate');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }


  public environmentMeasures(
    builder: EnvironmentMeasureFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('environmentMeasures', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutEnvironmentMeasures() {
    super.removeField('environmentMeasures');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public parentId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentId() {
    super.removeField('parentId');
    return this;
  }

  public refNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNo() {
    super.removeField('refNo');
    return this;
  }

  public securityInformation(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('securityInformation', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSecurityInformation() {
    super.removeField('securityInformation');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.maxLength(200), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }


  public all(): LocationFormBuilder {
    return this
    .accessInformation() 
    .conditionInformation() 
    .conditionInformationDate() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .modifiedBy() 
    .parentId() 
    .refNo() 
    .securityInformation() 
    .title() 
    .type() 
;
  }
}

