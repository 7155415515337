/* tslint:disable */
/* eslint-disable */
import { IndexingTerm, IndexingTermFormBuilder } from '../models/indexing-term';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class IndexingTermAllOrAnyFilter {
  mode?: IndexingTermAllOrAnyFilter.ModeEnum;
  terms?: Array<IndexingTerm>;

  constructor({
      mode, 
      terms
  }: {
      mode?: IndexingTermAllOrAnyFilter.ModeEnum;
      terms?: Array<IndexingTerm>;
  } = {}) {
    this.mode = mode;
    this.terms = terms;
  }
}

export namespace IndexingTermAllOrAnyFilter {

  export type ModeEnum = 'All' | 'Any' | 'Not';
  export const ModeEnum = {
      All: 'All' as ModeEnum,
      Any: 'Any' as ModeEnum,
      Not: 'Not' as ModeEnum,
  }
  export const ModeEnumValues = [
      ModeEnum.All, 
      ModeEnum.Any, 
      ModeEnum.Not
  ];
}


/**
 * IndexingTermAllOrAnyFilter form builder
 *
 */
export class IndexingTermAllOrAnyFilterFormBuilder extends AbstractFormBuilder<IndexingTermAllOrAnyFilterFormBuilder> {

  className = 'IndexingTermAllOrAnyFilter';

  abstractFormBuilder!: AbstractFormBuilder<IndexingTermAllOrAnyFilter>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): IndexingTermAllOrAnyFilter {
    return new IndexingTermAllOrAnyFilter();
  }

  public mode(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mode', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMode() {
    super.removeField('mode');
    return this;
  }


  public terms(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('terms', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutTerms() {
    super.removeField('terms');
    return this;
  }


  public all(): IndexingTermAllOrAnyFilterFormBuilder {
    return this
    .mode() 
;
  }
}

