/* tslint:disable */
/* eslint-disable */
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class TaskQueryFilters {
  assignee?: User;
  createdBy?: string;
  dateCreated?: DateQuery;
  due?: DateQuery;
  entityId?: string;
  entityTypes?: Array<'Artwork' | 'Auction' | 'Agent' | 'ArtEvent' | 'Exhibition' | 'Publication' | 'Resource' | 'ArtworksInArtSet' | 'ArtworksByArtist'>;
  id?: string;
  project?: string;
  status?: Array<string>;
  title?: string;

  constructor({
      assignee, 
      createdBy, 
      dateCreated, 
      due, 
      entityId, 
      entityTypes, 
      id, 
      project, 
      status, 
      title
  }: {
      assignee?: User;
      createdBy?: string;
      dateCreated?: DateQuery;
      due?: DateQuery;
      entityId?: string;
      entityTypes?: Array<'Artwork' | 'Auction' | 'Agent' | 'ArtEvent' | 'Exhibition' | 'Publication' | 'Resource' | 'ArtworksInArtSet' | 'ArtworksByArtist'>;
      id?: string;
      project?: string;
      status?: Array<string>;
      title?: string;
  } = {}) {
    this.assignee = assignee;
    this.createdBy = createdBy;
    this.dateCreated = dateCreated;
    this.due = due;
    this.entityId = entityId;
    this.entityTypes = entityTypes;
    this.id = id;
    this.project = project;
    this.status = status;
    this.title = title;
  }
}

export namespace TaskQueryFilters {

  export type EntityTypesEnum = 'Artwork' | 'Auction' | 'Agent' | 'ArtEvent' | 'Exhibition' | 'Publication' | 'Resource' | 'ArtworksInArtSet' | 'ArtworksByArtist'
  export const EntityTypesEnum = {
      Artwork: 'Artwork' as EntityTypesEnum,
      Auction: 'Auction' as EntityTypesEnum,
      Agent: 'Agent' as EntityTypesEnum,
      ArtEvent: 'ArtEvent' as EntityTypesEnum,
      Exhibition: 'Exhibition' as EntityTypesEnum,
      Publication: 'Publication' as EntityTypesEnum,
      Resource: 'Resource' as EntityTypesEnum,
      ArtworksInArtSet: 'ArtworksInArtSet' as EntityTypesEnum,
      ArtworksByArtist: 'ArtworksByArtist' as EntityTypesEnum,
  }
  export const EntityTypesEnumValues = [
      EntityTypesEnum.Artwork, 
      EntityTypesEnum.Auction, 
      EntityTypesEnum.Agent, 
      EntityTypesEnum.ArtEvent, 
      EntityTypesEnum.Exhibition, 
      EntityTypesEnum.Publication, 
      EntityTypesEnum.Resource, 
      EntityTypesEnum.ArtworksInArtSet, 
      EntityTypesEnum.ArtworksByArtist
  ];
}


/**
 * TaskQueryFilters form builder
 *
 */
export class TaskQueryFiltersFormBuilder extends AbstractFormBuilder<TaskQueryFiltersFormBuilder> {

  className = 'TaskQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<TaskQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): TaskQueryFilters {
    return new TaskQueryFilters();
  }


  public assignee(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('assignee', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAssignee() {
    super.removeField('assignee');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public dateCreated(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('dateCreated', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }


  public due(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('due', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDue() {
    super.removeField('due');
    return this;
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityTypes(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityTypes', [undefined, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityTypes() {
    super.removeField('entityTypes');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public project(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('project', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutProject() {
    super.removeField('project');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }


  public all(): TaskQueryFiltersFormBuilder {
    return this
    .createdBy() 
    .entityId() 
    .entityTypes() 
    .id() 
    .project() 
    .status() 
    .title() 
;
  }
}

