/* tslint:disable */
/* eslint-disable */
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Inscription {
  artId?: string;
  comment?: string;
  commentRenderingFlag?: boolean;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  inscriptionType?: Inscription.InscriptionTypeEnum;
  lastModifier?: User;
  location?: Inscription.LocationEnum;
  locationManual?: string;
  locationOnSide?: Inscription.LocationOnSideEnum;
  modifiedBy?: string;
  renderedText?: string;
  status?: Inscription.StatusEnum;
  tombstoneFlag?: boolean;
  transcribedText?: string;
  visibilityFlag?: boolean;

  constructor({
      artId, 
      comment, 
      commentRenderingFlag, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      inscriptionType, 
      lastModifier, 
      location, 
      locationManual, 
      locationOnSide, 
      modifiedBy, 
      renderedText, 
      status, 
      tombstoneFlag, 
      transcribedText, 
      visibilityFlag
  }: {
      artId?: string;
      comment?: string;
      commentRenderingFlag?: boolean;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      inscriptionType?: Inscription.InscriptionTypeEnum;
      lastModifier?: User;
      location?: Inscription.LocationEnum;
      locationManual?: string;
      locationOnSide?: Inscription.LocationOnSideEnum;
      modifiedBy?: string;
      renderedText?: string;
      status?: Inscription.StatusEnum;
      tombstoneFlag?: boolean;
      transcribedText?: string;
      visibilityFlag?: boolean;
  } = {}) {
    this.artId = artId;
    this.comment = comment;
    this.commentRenderingFlag = commentRenderingFlag;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.inscriptionType = inscriptionType;
    this.lastModifier = lastModifier;
    this.location = location;
    this.locationManual = locationManual;
    this.locationOnSide = locationOnSide;
    this.modifiedBy = modifiedBy;
    this.renderedText = renderedText;
    this.status = status;
    this.tombstoneFlag = tombstoneFlag;
    this.transcribedText = transcribedText;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace Inscription {

  export type InscriptionTypeEnum = 'dated' | 'dedicated' | 'impressed' | 'incised' | 'initialed' | 'inscribed' | 'marked' | 'monogrammed' | 'numbered' | 'printed' | 'signed' | 'silkscreened' | 'stamped_with_signature' | 'stamped' | 'stenciled' | 'titled' | 'watermarked' | 'not_signed' | 'not_dated';
  export const InscriptionTypeEnum = {
      Dated: 'dated' as InscriptionTypeEnum,
      Dedicated: 'dedicated' as InscriptionTypeEnum,
      Impressed: 'impressed' as InscriptionTypeEnum,
      Incised: 'incised' as InscriptionTypeEnum,
      Initialed: 'initialed' as InscriptionTypeEnum,
      Inscribed: 'inscribed' as InscriptionTypeEnum,
      Marked: 'marked' as InscriptionTypeEnum,
      Monogrammed: 'monogrammed' as InscriptionTypeEnum,
      Numbered: 'numbered' as InscriptionTypeEnum,
      Printed: 'printed' as InscriptionTypeEnum,
      Signed: 'signed' as InscriptionTypeEnum,
      Silkscreened: 'silkscreened' as InscriptionTypeEnum,
      StampedWithSignature: 'stamped_with_signature' as InscriptionTypeEnum,
      Stamped: 'stamped' as InscriptionTypeEnum,
      Stenciled: 'stenciled' as InscriptionTypeEnum,
      Titled: 'titled' as InscriptionTypeEnum,
      Watermarked: 'watermarked' as InscriptionTypeEnum,
      NotSigned: 'not_signed' as InscriptionTypeEnum,
      NotDated: 'not_dated' as InscriptionTypeEnum,
  }
  export const InscriptionTypeEnumValues = [
      InscriptionTypeEnum.Dated, 
      InscriptionTypeEnum.Dedicated, 
      InscriptionTypeEnum.Impressed, 
      InscriptionTypeEnum.Incised, 
      InscriptionTypeEnum.Initialed, 
      InscriptionTypeEnum.Inscribed, 
      InscriptionTypeEnum.Marked, 
      InscriptionTypeEnum.Monogrammed, 
      InscriptionTypeEnum.Numbered, 
      InscriptionTypeEnum.Printed, 
      InscriptionTypeEnum.Signed, 
      InscriptionTypeEnum.Silkscreened, 
      InscriptionTypeEnum.StampedWithSignature, 
      InscriptionTypeEnum.Stamped, 
      InscriptionTypeEnum.Stenciled, 
      InscriptionTypeEnum.Titled, 
      InscriptionTypeEnum.Watermarked, 
      InscriptionTypeEnum.NotSigned, 
      InscriptionTypeEnum.NotDated
  ];
  export type LocationEnum = 'front' | 'reverse' | 'top' | 'bottom' | 'left side' | 'right side';
  export const LocationEnum = {
      Front: 'front' as LocationEnum,
      Reverse: 'reverse' as LocationEnum,
      Top: 'top' as LocationEnum,
      Bottom: 'bottom' as LocationEnum,
      LeftSide: 'left side' as LocationEnum,
      RightSide: 'right side' as LocationEnum,
  }
  export const LocationEnumValues = [
      LocationEnum.Front, 
      LocationEnum.Reverse, 
      LocationEnum.Top, 
      LocationEnum.Bottom, 
      LocationEnum.LeftSide, 
      LocationEnum.RightSide
  ];
  export type LocationOnSideEnum = 'top_left' | 'top_center' | 'top_right' | 'center_left' | 'center' | 'center_right' | 'bottom_left' | 'bottom_center' | 'bottom_right';
  export const LocationOnSideEnum = {
      TopLeft: 'top_left' as LocationOnSideEnum,
      TopCenter: 'top_center' as LocationOnSideEnum,
      TopRight: 'top_right' as LocationOnSideEnum,
      CenterLeft: 'center_left' as LocationOnSideEnum,
      Center: 'center' as LocationOnSideEnum,
      CenterRight: 'center_right' as LocationOnSideEnum,
      BottomLeft: 'bottom_left' as LocationOnSideEnum,
      BottomCenter: 'bottom_center' as LocationOnSideEnum,
      BottomRight: 'bottom_right' as LocationOnSideEnum,
  }
  export const LocationOnSideEnumValues = [
      LocationOnSideEnum.TopLeft, 
      LocationOnSideEnum.TopCenter, 
      LocationOnSideEnum.TopRight, 
      LocationOnSideEnum.CenterLeft, 
      LocationOnSideEnum.Center, 
      LocationOnSideEnum.CenterRight, 
      LocationOnSideEnum.BottomLeft, 
      LocationOnSideEnum.BottomCenter, 
      LocationOnSideEnum.BottomRight
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Inscription form builder
 *
 */
export class InscriptionFormBuilder extends AbstractFormBuilder<InscriptionFormBuilder> {

  className = 'Inscription';

  abstractFormBuilder!: AbstractFormBuilder<Inscription>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Inscription {
    return new Inscription();
  }

  public artId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtId() {
    super.removeField('artId');
    return this;
  }

  public comment(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('comment', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutComment() {
    super.removeField('comment');
    return this;
  }

  public commentRenderingFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('commentRenderingFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCommentRenderingFlag() {
    super.removeField('commentRenderingFlag');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public inscriptionType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inscriptionType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInscriptionType() {
    super.removeField('inscriptionType');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public location(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('location', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLocation() {
    super.removeField('location');
    return this;
  }

  public locationManual(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('locationManual', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLocationManual() {
    super.removeField('locationManual');
    return this;
  }

  public locationOnSide(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('locationOnSide', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLocationOnSide() {
    super.removeField('locationOnSide');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public renderedText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('renderedText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRenderedText() {
    super.removeField('renderedText');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public tombstoneFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('tombstoneFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTombstoneFlag() {
    super.removeField('tombstoneFlag');
    return this;
  }

  public transcribedText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('transcribedText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTranscribedText() {
    super.removeField('transcribedText');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): InscriptionFormBuilder {
    return this
    .artId() 
    .comment() 
    .commentRenderingFlag() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .inscriptionType() 
    .location() 
    .locationManual() 
    .locationOnSide() 
    .modifiedBy() 
    .renderedText() 
    .status() 
    .tombstoneFlag() 
    .transcribedText() 
    .visibilityFlag() 
;
  }
}

