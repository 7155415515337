/* tslint:disable */
/* eslint-disable */
import { MediumQueryFilters, MediumQueryFiltersFormBuilder } from '../models/medium-query-filters';
import { PaginationQuery, PaginationQueryFormBuilder } from '../models/pagination-query';
import { SortOption, SortOptionFormBuilder } from '../models/sort-option';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class MediumQuery {
  filters?: MediumQueryFilters;

  /**
   * pagination parameters
   */
  pagination?: PaginationQuery;

  /**
   * list of properties to return
   */
  projection?: Array<string>;
  returnData?: boolean;
  returnPagination?: boolean;

  /**
   * list of sorting properties
   */
  sorting?: Array<SortOption>;

  constructor({
      filters, 
    
  /**
   * pagination parameters
   */
  pagination, 
    
  /**
   * list of properties to return
   */
  projection, 
      returnData, 
      returnPagination, 
    
  /**
   * list of sorting properties
   */
  sorting
  }: {
      filters?: MediumQueryFilters;
    
  /**
   * pagination parameters
   */
  pagination?: PaginationQuery;
    
  /**
   * list of properties to return
   */
  projection?: Array<string>;
      returnData?: boolean;
      returnPagination?: boolean;
    
  /**
   * list of sorting properties
   */
  sorting?: Array<SortOption>;
  } = {}) {
    this.filters = filters;
    this.pagination = pagination;
    this.projection = projection;
    this.returnData = returnData;
    this.returnPagination = returnPagination;
    this.sorting = sorting;
  }
}

export namespace MediumQuery {

}


/**
 * MediumQuery form builder
 *
 */
export class MediumQueryFormBuilder extends AbstractFormBuilder<MediumQueryFormBuilder> {

  className = 'MediumQuery';

  abstractFormBuilder!: AbstractFormBuilder<MediumQuery>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): MediumQuery {
    return new MediumQuery();
  }


  public filters(
    builder: MediumQueryFiltersFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('filters', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutFilters() {
    super.removeField('filters');
    return this;
  }


  public pagination(
    builder: PaginationQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('pagination', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPagination() {
    super.removeField('pagination');
    return this;
  }

  public projection(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('projection', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutProjection() {
    super.removeField('projection');
    return this;
  }

  public returnData(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('returnData', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReturnData() {
    super.removeField('returnData');
    return this;
  }

  public returnPagination(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('returnPagination', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReturnPagination() {
    super.removeField('returnPagination');
    return this;
  }


  public sorting(
    builder: SortOptionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('sorting', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSorting() {
    super.removeField('sorting');
    return this;
  }


  public all(): MediumQueryFormBuilder {
    return this
    .projection() 
    .returnData() 
    .returnPagination() 
;
  }
}

