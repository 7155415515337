/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class LoanQueryFilters {
  agentSelections?: Array<AgentSelection>;
  id?: string;
  loanAuthorizationDate?: DateQuery;
  loanEndDate?: DateQuery;
  loanReason?: LoanQueryFilters.LoanReasonEnum;
  loanRefNo?: string;
  loanStartDate?: DateQuery;
  loanStatus?: LoanQueryFilters.LoanStatusEnum;
  loanStatusDate?: DateQuery;
  loanTitle?: string;
  loanType?: LoanQueryFilters.LoanTypeEnum;

  constructor({
      agentSelections, 
      id, 
      loanAuthorizationDate, 
      loanEndDate, 
      loanReason, 
      loanRefNo, 
      loanStartDate, 
      loanStatus, 
      loanStatusDate, 
      loanTitle, 
      loanType
  }: {
      agentSelections?: Array<AgentSelection>;
      id?: string;
      loanAuthorizationDate?: DateQuery;
      loanEndDate?: DateQuery;
      loanReason?: LoanQueryFilters.LoanReasonEnum;
      loanRefNo?: string;
      loanStartDate?: DateQuery;
      loanStatus?: LoanQueryFilters.LoanStatusEnum;
      loanStatusDate?: DateQuery;
      loanTitle?: string;
      loanType?: LoanQueryFilters.LoanTypeEnum;
  } = {}) {
    this.agentSelections = agentSelections;
    this.id = id;
    this.loanAuthorizationDate = loanAuthorizationDate;
    this.loanEndDate = loanEndDate;
    this.loanReason = loanReason;
    this.loanRefNo = loanRefNo;
    this.loanStartDate = loanStartDate;
    this.loanStatus = loanStatus;
    this.loanStatusDate = loanStatusDate;
    this.loanTitle = loanTitle;
    this.loanType = loanType;
  }
}

export namespace LoanQueryFilters {

  export type LoanReasonEnum = 'Conservation' | 'Research' | 'Exhibition';
  export const LoanReasonEnum = {
      Conservation: 'Conservation' as LoanReasonEnum,
      Research: 'Research' as LoanReasonEnum,
      Exhibition: 'Exhibition' as LoanReasonEnum,
  }
  export const LoanReasonEnumValues = [
      LoanReasonEnum.Conservation, 
      LoanReasonEnum.Research, 
      LoanReasonEnum.Exhibition
  ];
  export type LoanStatusEnum = 'Begun' | 'Ended' | 'Negotiation' | 'Information exchange';
  export const LoanStatusEnum = {
      Begun: 'Begun' as LoanStatusEnum,
      Ended: 'Ended' as LoanStatusEnum,
      Negotiation: 'Negotiation' as LoanStatusEnum,
      InformationExchange: 'Information exchange' as LoanStatusEnum,
  }
  export const LoanStatusEnumValues = [
      LoanStatusEnum.Begun, 
      LoanStatusEnum.Ended, 
      LoanStatusEnum.Negotiation, 
      LoanStatusEnum.InformationExchange
  ];
  export type LoanTypeEnum = 'Loan-in' | 'Loan-out';
  export const LoanTypeEnum = {
      LoanIn: 'Loan-in' as LoanTypeEnum,
      LoanOut: 'Loan-out' as LoanTypeEnum,
  }
  export const LoanTypeEnumValues = [
      LoanTypeEnum.LoanIn, 
      LoanTypeEnum.LoanOut
  ];
}


/**
 * LoanQueryFilters form builder
 *
 */
export class LoanQueryFiltersFormBuilder extends AbstractFormBuilder<LoanQueryFiltersFormBuilder> {

  className = 'LoanQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<LoanQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): LoanQueryFilters {
    return new LoanQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public loanAuthorizationDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('loanAuthorizationDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLoanAuthorizationDate() {
    super.removeField('loanAuthorizationDate');
    return this;
  }


  public loanEndDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('loanEndDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLoanEndDate() {
    super.removeField('loanEndDate');
    return this;
  }

  public loanReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanReason() {
    super.removeField('loanReason');
    return this;
  }

  public loanRefNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanRefNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanRefNo() {
    super.removeField('loanRefNo');
    return this;
  }


  public loanStartDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('loanStartDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLoanStartDate() {
    super.removeField('loanStartDate');
    return this;
  }

  public loanStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanStatus() {
    super.removeField('loanStatus');
    return this;
  }


  public loanStatusDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('loanStatusDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLoanStatusDate() {
    super.removeField('loanStatusDate');
    return this;
  }

  public loanTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanTitle() {
    super.removeField('loanTitle');
    return this;
  }

  public loanType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanType() {
    super.removeField('loanType');
    return this;
  }


  public all(): LoanQueryFiltersFormBuilder {
    return this
    .id() 
    .loanReason() 
    .loanRefNo() 
    .loanStatus() 
    .loanTitle() 
    .loanType() 
;
  }
}

