/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { Auction, AuctionFormBuilder } from '../models/auction';
import { Exhibition, ExhibitionFormBuilder } from '../models/exhibition';
import { Media, MediaFormBuilder } from '../models/media';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Marking {
  agentSelections?: Array<AgentSelection>;
  auction?: Auction;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  description?: string;
  exhibition?: Exhibition;
  globalId?: string;
  id?: string;
  image?: Media;
  lastModifier?: User;
  markingType?: Marking.MarkingTypeEnum;
  modifiedBy?: string;
  name?: string;
  periodDateEdtf?: string;
  periodDateMax?: string;
  periodDateMin?: string;
  periodDateText?: string;
  periodDateUseTextFlag?: boolean;
  researchStatus?: string;
  status?: Marking.StatusEnum;
  technique?: string;
  visibilityFlag?: boolean;

  constructor({
      agentSelections, 
      auction, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      description, 
      exhibition, 
      globalId, 
      id, 
      image, 
      lastModifier, 
      markingType, 
      modifiedBy, 
      name, 
      periodDateEdtf, 
      periodDateMax, 
      periodDateMin, 
      periodDateText, 
      periodDateUseTextFlag, 
      researchStatus, 
      status, 
      technique, 
      visibilityFlag
  }: {
      agentSelections?: Array<AgentSelection>;
      auction?: Auction;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      description?: string;
      exhibition?: Exhibition;
      globalId?: string;
      id?: string;
      image?: Media;
      lastModifier?: User;
      markingType?: Marking.MarkingTypeEnum;
      modifiedBy?: string;
      name?: string;
      periodDateEdtf?: string;
      periodDateMax?: string;
      periodDateMin?: string;
      periodDateText?: string;
      periodDateUseTextFlag?: boolean;
      researchStatus?: string;
      status?: Marking.StatusEnum;
      technique?: string;
      visibilityFlag?: boolean;
  } = {}) {
    this.agentSelections = agentSelections;
    this.auction = auction;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.description = description;
    this.exhibition = exhibition;
    this.globalId = globalId;
    this.id = id;
    this.image = image;
    this.lastModifier = lastModifier;
    this.markingType = markingType;
    this.modifiedBy = modifiedBy;
    this.name = name;
    this.periodDateEdtf = periodDateEdtf;
    this.periodDateMax = periodDateMax;
    this.periodDateMin = periodDateMin;
    this.periodDateText = periodDateText;
    this.periodDateUseTextFlag = periodDateUseTextFlag;
    this.researchStatus = researchStatus;
    this.status = status;
    this.technique = technique;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace Marking {

  export type MarkingTypeEnum = 'branding stamp' | 'ink stamp' | 'embossing stamp' | 'label' | 'plate' | 'tag' | 'watermark' | 'wax seal';
  export const MarkingTypeEnum = {
      BrandingStamp: 'branding stamp' as MarkingTypeEnum,
      InkStamp: 'ink stamp' as MarkingTypeEnum,
      EmbossingStamp: 'embossing stamp' as MarkingTypeEnum,
      Label: 'label' as MarkingTypeEnum,
      Plate: 'plate' as MarkingTypeEnum,
      Tag: 'tag' as MarkingTypeEnum,
      Watermark: 'watermark' as MarkingTypeEnum,
      WaxSeal: 'wax seal' as MarkingTypeEnum,
  }
  export const MarkingTypeEnumValues = [
      MarkingTypeEnum.BrandingStamp, 
      MarkingTypeEnum.InkStamp, 
      MarkingTypeEnum.EmbossingStamp, 
      MarkingTypeEnum.Label, 
      MarkingTypeEnum.Plate, 
      MarkingTypeEnum.Tag, 
      MarkingTypeEnum.Watermark, 
      MarkingTypeEnum.WaxSeal
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Marking form builder
 *
 */
export class MarkingFormBuilder extends AbstractFormBuilder<MarkingFormBuilder> {

  className = 'Marking';

  abstractFormBuilder!: AbstractFormBuilder<Marking>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Marking {
    return new Marking();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public auction(
    builder: AuctionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('auction', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAuction() {
    super.removeField('auction');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }


  public exhibition(
    builder: ExhibitionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exhibition', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExhibition() {
    super.removeField('exhibition');
    return this;
  }

  public globalId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('globalId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGlobalId() {
    super.removeField('globalId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public image(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('image', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutImage() {
    super.removeField('image');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public markingType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('markingType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMarkingType() {
    super.removeField('markingType');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public name(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('name', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutName() {
    super.removeField('name');
    return this;
  }

  public periodDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('periodDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPeriodDateEdtf() {
    super.removeField('periodDateEdtf');
    return this;
  }

  public periodDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('periodDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPeriodDateMax() {
    super.removeField('periodDateMax');
    return this;
  }

  public periodDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('periodDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPeriodDateMin() {
    super.removeField('periodDateMin');
    return this;
  }

  public periodDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('periodDateText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPeriodDateText() {
    super.removeField('periodDateText');
    return this;
  }

  public periodDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('periodDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPeriodDateUseTextFlag() {
    super.removeField('periodDateUseTextFlag');
    return this;
  }

  public researchStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('researchStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutResearchStatus() {
    super.removeField('researchStatus');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public technique(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('technique', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTechnique() {
    super.removeField('technique');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): MarkingFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .description() 
    .globalId() 
    .id() 
    .markingType() 
    .modifiedBy() 
    .name() 
    .periodDateEdtf() 
    .periodDateMax() 
    .periodDateMin() 
    .periodDateText() 
    .periodDateUseTextFlag() 
    .researchStatus() 
    .status() 
    .technique() 
    .visibilityFlag() 
;
  }
}

