/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkSet, ArtworkSetFormBuilder } from '../models/artwork-set';
import { ComplexStringFilter, ComplexStringFilterFormBuilder } from '../models/complex-string-filter';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { DimensionQueryParameter, DimensionQueryParameterFormBuilder } from '../models/dimension-query-parameter';
import { IndexingTerm, IndexingTermFormBuilder } from '../models/indexing-term';
import { IndexingTermAllOrAnyFilter, IndexingTermAllOrAnyFilterFormBuilder } from '../models/indexing-term-all-or-any-filter';
import { Medium, MediumFormBuilder } from '../models/medium';
import { Note, NoteFormBuilder } from '../models/note';
import { OtherId, OtherIdFormBuilder } from '../models/other-id';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtworkQueryFilters {
  agents?: Array<Agent>;
  artist?: Array<Agent>;
  attestNum?: string;
  catNo?: string;
  committeeDate?: DateQuery;
  createdBy?: string;
  detCreationYear?: DateQuery;
  detCreationYearEdtf?: string;
  detDimDepth?: DimensionQueryParameter;
  detDimHeight?: DimensionQueryParameter;
  detDimWidth?: DimensionQueryParameter;
  detTitle?: string;
  dossierNo?: string;
  dossierNoComplex?: ComplexStringFilter;
  eventNotes?: Array<Note>;
  /** @deprecated */genres?: Array<IndexingTerm>;
  /** @deprecated */genresComplex?: IndexingTermAllOrAnyFilter;
  id?: string;
  inArtSet?: string;
  inclusion?: ArtworkQueryFilters.InclusionEnum;
  indexingTerms?: Array<IndexingTerm>;
  indexingTermsComplex?: IndexingTermAllOrAnyFilter;
  indexingTermsComplexMultiple?: Array<IndexingTermAllOrAnyFilter>;
  lastPublishedDate?: DateQuery;
  masterFlag?: ArtworkQueryFilters.MasterFlagEnum;
  medium?: Array<Medium>;
  mediumText?: string;
  modifiedBy?: string;
  notInArtSet?: ArtworkSet;
  notes?: Array<Note>;
  orderNum?: string;
  otherIds?: Array<OtherId>;
  owners?: Array<AgentSelection>;
  refNum?: string;
  seriesType?: ArtworkQueryFilters.SeriesTypeEnum;
  status?: ArtworkQueryFilters.StatusEnum;
  /** @deprecated */subjects?: Array<IndexingTerm>;
  /** @deprecated */subjectsComplex?: IndexingTermAllOrAnyFilter;
  /** @deprecated */subjectsComplexMultiple?: Array<IndexingTermAllOrAnyFilter>;
  visibilityFlag?: boolean;

  constructor({
      agents, 
      artist, 
      attestNum, 
      catNo, 
      committeeDate, 
      createdBy, 
      detCreationYear, 
      detCreationYearEdtf, 
      detDimDepth, 
      detDimHeight, 
      detDimWidth, 
      detTitle, 
      dossierNo, 
      dossierNoComplex, 
      eventNotes, 
      /** @deprecated */genres, 
      /** @deprecated */genresComplex, 
      id, 
      inArtSet, 
      inclusion, 
      indexingTerms, 
      indexingTermsComplex, 
      indexingTermsComplexMultiple, 
      lastPublishedDate, 
      masterFlag, 
      medium, 
      mediumText, 
      modifiedBy, 
      notInArtSet, 
      notes, 
      orderNum, 
      otherIds, 
      owners, 
      refNum, 
      seriesType, 
      status, 
      /** @deprecated */subjects, 
      /** @deprecated */subjectsComplex, 
      /** @deprecated */subjectsComplexMultiple, 
      visibilityFlag
  }: {
      agents?: Array<Agent>;
      artist?: Array<Agent>;
      attestNum?: string;
      catNo?: string;
      committeeDate?: DateQuery;
      createdBy?: string;
      detCreationYear?: DateQuery;
      detCreationYearEdtf?: string;
      detDimDepth?: DimensionQueryParameter;
      detDimHeight?: DimensionQueryParameter;
      detDimWidth?: DimensionQueryParameter;
      detTitle?: string;
      dossierNo?: string;
      dossierNoComplex?: ComplexStringFilter;
      eventNotes?: Array<Note>;
      /** @deprecated */genres?: Array<IndexingTerm>;
      /** @deprecated */genresComplex?: IndexingTermAllOrAnyFilter;
      id?: string;
      inArtSet?: string;
      inclusion?: ArtworkQueryFilters.InclusionEnum;
      indexingTerms?: Array<IndexingTerm>;
      indexingTermsComplex?: IndexingTermAllOrAnyFilter;
      indexingTermsComplexMultiple?: Array<IndexingTermAllOrAnyFilter>;
      lastPublishedDate?: DateQuery;
      masterFlag?: ArtworkQueryFilters.MasterFlagEnum;
      medium?: Array<Medium>;
      mediumText?: string;
      modifiedBy?: string;
      notInArtSet?: ArtworkSet;
      notes?: Array<Note>;
      orderNum?: string;
      otherIds?: Array<OtherId>;
      owners?: Array<AgentSelection>;
      refNum?: string;
      seriesType?: ArtworkQueryFilters.SeriesTypeEnum;
      status?: ArtworkQueryFilters.StatusEnum;
      /** @deprecated */subjects?: Array<IndexingTerm>;
      /** @deprecated */subjectsComplex?: IndexingTermAllOrAnyFilter;
      /** @deprecated */subjectsComplexMultiple?: Array<IndexingTermAllOrAnyFilter>;
      visibilityFlag?: boolean;
  } = {}) {
    this.agents = agents;
    this.artist = artist;
    this.attestNum = attestNum;
    this.catNo = catNo;
    this.committeeDate = committeeDate;
    this.createdBy = createdBy;
    this.detCreationYear = detCreationYear;
    this.detCreationYearEdtf = detCreationYearEdtf;
    this.detDimDepth = detDimDepth;
    this.detDimHeight = detDimHeight;
    this.detDimWidth = detDimWidth;
    this.detTitle = detTitle;
    this.dossierNo = dossierNo;
    this.dossierNoComplex = dossierNoComplex;
    this.eventNotes = eventNotes;
    this.genres = genres;
    this.genresComplex = genresComplex;
    this.id = id;
    this.inArtSet = inArtSet;
    this.inclusion = inclusion;
    this.indexingTerms = indexingTerms;
    this.indexingTermsComplex = indexingTermsComplex;
    this.indexingTermsComplexMultiple = indexingTermsComplexMultiple;
    this.lastPublishedDate = lastPublishedDate;
    this.masterFlag = masterFlag;
    this.medium = medium;
    this.mediumText = mediumText;
    this.modifiedBy = modifiedBy;
    this.notInArtSet = notInArtSet;
    this.notes = notes;
    this.orderNum = orderNum;
    this.otherIds = otherIds;
    this.owners = owners;
    this.refNum = refNum;
    this.seriesType = seriesType;
    this.status = status;
    this.subjects = subjects;
    this.subjectsComplex = subjectsComplex;
    this.subjectsComplexMultiple = subjectsComplexMultiple;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace ArtworkQueryFilters {

  export type InclusionEnum = 'pending' | 'not-included' | 'included';
  export const InclusionEnum = {
      Pending: 'pending' as InclusionEnum,
      NotIncluded: 'not-included' as InclusionEnum,
      Included: 'included' as InclusionEnum,
  }
  export const InclusionEnumValues = [
      InclusionEnum.Pending, 
      InclusionEnum.NotIncluded, 
      InclusionEnum.Included
  ];
  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type SeriesTypeEnum = 'Edition' | 'Unique' | 'Series';
  export const SeriesTypeEnum = {
      Edition: 'Edition' as SeriesTypeEnum,
      Unique: 'Unique' as SeriesTypeEnum,
      Series: 'Series' as SeriesTypeEnum,
  }
  export const SeriesTypeEnumValues = [
      SeriesTypeEnum.Edition, 
      SeriesTypeEnum.Unique, 
      SeriesTypeEnum.Series
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * ArtworkQueryFilters form builder
 *
 */
export class ArtworkQueryFiltersFormBuilder extends AbstractFormBuilder<ArtworkQueryFiltersFormBuilder> {

  className = 'ArtworkQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ArtworkQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtworkQueryFilters {
    return new ArtworkQueryFilters();
  }


  public agents(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgents() {
    super.removeField('agents');
    return this;
  }


  public artist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artist', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtist() {
    super.removeField('artist');
    return this;
  }

  public attestNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('attestNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAttestNum() {
    super.removeField('attestNum');
    return this;
  }

  public catNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('catNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCatNo() {
    super.removeField('catNo');
    return this;
  }


  public committeeDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('committeeDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCommitteeDate() {
    super.removeField('committeeDate');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public detCreationYear(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detCreationYear', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetCreationYear() {
    super.removeField('detCreationYear');
    return this;
  }

  public detCreationYearEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearEdtf() {
    super.removeField('detCreationYearEdtf');
    return this;
  }


  public detDimDepth(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimDepth', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimDepth() {
    super.removeField('detDimDepth');
    return this;
  }


  public detDimHeight(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimHeight', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimHeight() {
    super.removeField('detDimHeight');
    return this;
  }


  public detDimWidth(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimWidth', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimWidth() {
    super.removeField('detDimWidth');
    return this;
  }

  public detTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetTitle() {
    super.removeField('detTitle');
    return this;
  }

  public dossierNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dossierNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDossierNo() {
    super.removeField('dossierNo');
    return this;
  }


  public dossierNoComplex(
    builder: ComplexStringFilterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('dossierNoComplex', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDossierNoComplex() {
    super.removeField('dossierNoComplex');
    return this;
  }


  public eventNotes(
    builder: NoteFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('eventNotes', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutEventNotes() {
    super.removeField('eventNotes');
    return this;
  }


  public genres(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('genres', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutGenres() {
    super.removeField('genres');
    return this;
  }


  public genresComplex(
    builder: IndexingTermAllOrAnyFilterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('genresComplex', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutGenresComplex() {
    super.removeField('genresComplex');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public inArtSet(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inArtSet', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInArtSet() {
    super.removeField('inArtSet');
    return this;
  }

  public inclusion(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inclusion', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInclusion() {
    super.removeField('inclusion');
    return this;
  }


  public indexingTerms(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTerms', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTerms() {
    super.removeField('indexingTerms');
    return this;
  }


  public indexingTermsComplex(
    builder: IndexingTermAllOrAnyFilterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTermsComplex', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTermsComplex() {
    super.removeField('indexingTermsComplex');
    return this;
  }


  public indexingTermsComplexMultiple(
    builder: IndexingTermAllOrAnyFilterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTermsComplexMultiple', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTermsComplexMultiple() {
    super.removeField('indexingTermsComplexMultiple');
    return this;
  }


  public lastPublishedDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastPublishedDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastPublishedDate() {
    super.removeField('lastPublishedDate');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }


  public medium(
    builder: MediumFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('medium', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMedium() {
    super.removeField('medium');
    return this;
  }

  public mediumText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mediumText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMediumText() {
    super.removeField('mediumText');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public notInArtSet(
    builder: ArtworkSetFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('notInArtSet', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutNotInArtSet() {
    super.removeField('notInArtSet');
    return this;
  }


  public notes(
    builder: NoteFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('notes', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutNotes() {
    super.removeField('notes');
    return this;
  }

  public orderNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('orderNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOrderNum() {
    super.removeField('orderNum');
    return this;
  }


  public otherIds(
    builder: OtherIdFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('otherIds', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOtherIds() {
    super.removeField('otherIds');
    return this;
  }


  public owners(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('owners', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOwners() {
    super.removeField('owners');
    return this;
  }

  public refNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNum() {
    super.removeField('refNum');
    return this;
  }

  public seriesType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seriesType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeriesType() {
    super.removeField('seriesType');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }


  public subjects(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('subjects', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSubjects() {
    super.removeField('subjects');
    return this;
  }


  public subjectsComplex(
    builder: IndexingTermAllOrAnyFilterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('subjectsComplex', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSubjectsComplex() {
    super.removeField('subjectsComplex');
    return this;
  }


  public subjectsComplexMultiple(
    builder: IndexingTermAllOrAnyFilterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('subjectsComplexMultiple', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSubjectsComplexMultiple() {
    super.removeField('subjectsComplexMultiple');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ArtworkQueryFiltersFormBuilder {
    return this
    .attestNum() 
    .catNo() 
    .createdBy() 
    .detCreationYearEdtf() 
    .detTitle() 
    .dossierNo() 
    .id() 
    .inArtSet() 
    .inclusion() 
    .masterFlag() 
    .mediumText() 
    .modifiedBy() 
    .orderNum() 
    .refNum() 
    .seriesType() 
    .status() 
    .visibilityFlag() 
;
  }
}

