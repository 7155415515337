/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class GlobalText {
  textKey?: string;
  value?: string;

  constructor({
      textKey, 
      value
  }: {
      textKey?: string;
      value?: string;
  } = {}) {
    this.textKey = textKey;
    this.value = value;
  }
}

export namespace GlobalText {

}


/**
 * GlobalText form builder
 *
 */
export class GlobalTextFormBuilder extends AbstractFormBuilder<GlobalTextFormBuilder> {

  className = 'GlobalText';

  abstractFormBuilder!: AbstractFormBuilder<GlobalText>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): GlobalText {
    return new GlobalText();
  }

  public textKey(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('textKey', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTextKey() {
    super.removeField('textKey');
    return this;
  }

  public value(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('value', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutValue() {
    super.removeField('value');
    return this;
  }


  public all(): GlobalTextFormBuilder {
    return this
    .textKey() 
    .value() 
;
  }
}

