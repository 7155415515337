/* tslint:disable */
/* eslint-disable */
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Media {
  altText?: string;
  /** @deprecated */artReproFlag?: boolean;
  baseUrl?: string;
  copyright?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  description?: string;
  duration?: number;
  fileExt?: string;
  /** @deprecated */frontFlag?: boolean;
  height?: number;
  id?: string;
  lastModifier?: User;
  mediaType?: Media.MediaTypeEnum;
  modifiedBy?: string;
  /** @deprecated */noDeleteFlag?: boolean;
  ocrText?: string;
  pageNum?: number;
  pageNumText?: string;
  phFileNm?: string;
  resId?: string;
  /** @deprecated */secondaryArtReproFlag?: boolean;
  size?: string;
  status?: Media.StatusEnum;
  streamUrl?: string;
  url?: string;
  uuid?: string;
  /** @deprecated */viewText?: string;
  visibilityFlag?: boolean;
  width?: number;

  constructor({
      altText, 
      /** @deprecated */artReproFlag, 
      baseUrl, 
      copyright, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      description, 
      duration, 
      fileExt, 
      /** @deprecated */frontFlag, 
      height, 
      id, 
      lastModifier, 
      mediaType, 
      modifiedBy, 
      /** @deprecated */noDeleteFlag, 
      ocrText, 
      pageNum, 
      pageNumText, 
      phFileNm, 
      resId, 
      /** @deprecated */secondaryArtReproFlag, 
      size, 
      status, 
      streamUrl, 
      url, 
      uuid, 
      /** @deprecated */viewText, 
      visibilityFlag, 
      width
  }: {
      altText?: string;
      /** @deprecated */artReproFlag?: boolean;
      baseUrl?: string;
      copyright?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      description?: string;
      duration?: number;
      fileExt?: string;
      /** @deprecated */frontFlag?: boolean;
      height?: number;
      id?: string;
      lastModifier?: User;
      mediaType?: Media.MediaTypeEnum;
      modifiedBy?: string;
      /** @deprecated */noDeleteFlag?: boolean;
      ocrText?: string;
      pageNum?: number;
      pageNumText?: string;
      phFileNm?: string;
      resId?: string;
      /** @deprecated */secondaryArtReproFlag?: boolean;
      size?: string;
      status?: Media.StatusEnum;
      streamUrl?: string;
      url?: string;
      uuid?: string;
      /** @deprecated */viewText?: string;
      visibilityFlag?: boolean;
      width?: number;
  } = {}) {
    this.altText = altText;
    this.artReproFlag = artReproFlag;
    this.baseUrl = baseUrl;
    this.copyright = copyright;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.description = description;
    this.duration = duration;
    this.fileExt = fileExt;
    this.frontFlag = frontFlag;
    this.height = height;
    this.id = id;
    this.lastModifier = lastModifier;
    this.mediaType = mediaType;
    this.modifiedBy = modifiedBy;
    this.noDeleteFlag = noDeleteFlag;
    this.ocrText = ocrText;
    this.pageNum = pageNum;
    this.pageNumText = pageNumText;
    this.phFileNm = phFileNm;
    this.resId = resId;
    this.secondaryArtReproFlag = secondaryArtReproFlag;
    this.size = size;
    this.status = status;
    this.streamUrl = streamUrl;
    this.url = url;
    this.uuid = uuid;
    this.viewText = viewText;
    this.visibilityFlag = visibilityFlag;
    this.width = width;
  }
}

export namespace Media {

  export type MediaTypeEnum = 'Image' | 'Attachment' | 'Video' | 'Audio';
  export const MediaTypeEnum = {
      Image: 'Image' as MediaTypeEnum,
      Attachment: 'Attachment' as MediaTypeEnum,
      Video: 'Video' as MediaTypeEnum,
      Audio: 'Audio' as MediaTypeEnum,
  }
  export const MediaTypeEnumValues = [
      MediaTypeEnum.Image, 
      MediaTypeEnum.Attachment, 
      MediaTypeEnum.Video, 
      MediaTypeEnum.Audio
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Media form builder
 *
 */
export class MediaFormBuilder extends AbstractFormBuilder<MediaFormBuilder> {

  className = 'Media';

  abstractFormBuilder!: AbstractFormBuilder<Media>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Media {
    return new Media();
  }

  public altText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('altText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAltText() {
    super.removeField('altText');
    return this;
  }

  public artReproFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artReproFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtReproFlag() {
    super.removeField('artReproFlag');
    return this;
  }

  public baseUrl(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('baseUrl', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBaseUrl() {
    super.removeField('baseUrl');
    return this;
  }

  public copyright(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('copyright', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCopyright() {
    super.removeField('copyright');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public duration(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('duration', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDuration() {
    super.removeField('duration');
    return this;
  }

  public fileExt(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('fileExt', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFileExt() {
    super.removeField('fileExt');
    return this;
  }

  public frontFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('frontFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFrontFlag() {
    super.removeField('frontFlag');
    return this;
  }

  public height(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('height', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHeight() {
    super.removeField('height');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public mediaType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mediaType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMediaType() {
    super.removeField('mediaType');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public noDeleteFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('noDeleteFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNoDeleteFlag() {
    super.removeField('noDeleteFlag');
    return this;
  }

  public ocrText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('ocrText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOcrText() {
    super.removeField('ocrText');
    return this;
  }

  public pageNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pageNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPageNum() {
    super.removeField('pageNum');
    return this;
  }

  public pageNumText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pageNumText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPageNumText() {
    super.removeField('pageNumText');
    return this;
  }

  public phFileNm(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('phFileNm', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPhFileNm() {
    super.removeField('phFileNm');
    return this;
  }

  public resId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('resId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutResId() {
    super.removeField('resId');
    return this;
  }

  public secondaryArtReproFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('secondaryArtReproFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSecondaryArtReproFlag() {
    super.removeField('secondaryArtReproFlag');
    return this;
  }

  public size(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('size', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSize() {
    super.removeField('size');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public streamUrl(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('streamUrl', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStreamUrl() {
    super.removeField('streamUrl');
    return this;
  }

  public url(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('url', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUrl() {
    super.removeField('url');
    return this;
  }

  public uuid(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('uuid', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUuid() {
    super.removeField('uuid');
    return this;
  }

  public viewText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('viewText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutViewText() {
    super.removeField('viewText');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }

  public width(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('width', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutWidth() {
    super.removeField('width');
    return this;
  }


  public all(): MediaFormBuilder {
    return this
    .altText() 
    .artReproFlag() 
    .baseUrl() 
    .copyright() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .description() 
    .duration() 
    .fileExt() 
    .frontFlag() 
    .height() 
    .id() 
    .mediaType() 
    .modifiedBy() 
    .noDeleteFlag() 
    .ocrText() 
    .pageNum() 
    .pageNumText() 
    .phFileNm() 
    .resId() 
    .secondaryArtReproFlag() 
    .size() 
    .status() 
    .streamUrl() 
    .url() 
    .uuid() 
    .viewText() 
    .visibilityFlag() 
    .width() 
;
  }
}

