/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AuditInformationQueryFilters {
  agentSelections?: Array<AgentSelection>;
  auditAuthorizationDate?: DateQuery;
  auditMethod?: AuditInformationQueryFilters.AuditMethodEnum;
  auditNumber?: string;
  auditTitle?: string;
  auditType?: AuditInformationQueryFilters.AuditTypeEnum;
  id?: string;

  constructor({
      agentSelections, 
      auditAuthorizationDate, 
      auditMethod, 
      auditNumber, 
      auditTitle, 
      auditType, 
      id
  }: {
      agentSelections?: Array<AgentSelection>;
      auditAuthorizationDate?: DateQuery;
      auditMethod?: AuditInformationQueryFilters.AuditMethodEnum;
      auditNumber?: string;
      auditTitle?: string;
      auditType?: AuditInformationQueryFilters.AuditTypeEnum;
      id?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.auditAuthorizationDate = auditAuthorizationDate;
    this.auditMethod = auditMethod;
    this.auditNumber = auditNumber;
    this.auditTitle = auditTitle;
    this.auditType = auditType;
    this.id = id;
  }
}

export namespace AuditInformationQueryFilters {

  export type AuditMethodEnum = 'Full' | 'Random' | 'Selective';
  export const AuditMethodEnum = {
      Full: 'Full' as AuditMethodEnum,
      Random: 'Random' as AuditMethodEnum,
      Selective: 'Selective' as AuditMethodEnum,
  }
  export const AuditMethodEnumValues = [
      AuditMethodEnum.Full, 
      AuditMethodEnum.Random, 
      AuditMethodEnum.Selective
  ];
  export type AuditTypeEnum = 'Condition' | 'Information' | 'Location';
  export const AuditTypeEnum = {
      Condition: 'Condition' as AuditTypeEnum,
      Information: 'Information' as AuditTypeEnum,
      Location: 'Location' as AuditTypeEnum,
  }
  export const AuditTypeEnumValues = [
      AuditTypeEnum.Condition, 
      AuditTypeEnum.Information, 
      AuditTypeEnum.Location
  ];
}


/**
 * AuditInformationQueryFilters form builder
 *
 */
export class AuditInformationQueryFiltersFormBuilder extends AbstractFormBuilder<AuditInformationQueryFiltersFormBuilder> {

  className = 'AuditInformationQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<AuditInformationQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AuditInformationQueryFilters {
    return new AuditInformationQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public auditAuthorizationDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('auditAuthorizationDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAuditAuthorizationDate() {
    super.removeField('auditAuthorizationDate');
    return this;
  }

  public auditMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditMethod() {
    super.removeField('auditMethod');
    return this;
  }

  public auditNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditNumber() {
    super.removeField('auditNumber');
    return this;
  }

  public auditTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditTitle() {
    super.removeField('auditTitle');
    return this;
  }

  public auditType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditType() {
    super.removeField('auditType');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public all(): AuditInformationQueryFiltersFormBuilder {
    return this
    .auditMethod() 
    .auditNumber() 
    .auditTitle() 
    .auditType() 
    .id() 
;
  }
}

