/* tslint:disable */
/* eslint-disable */
import { Media, MediaFormBuilder } from '../models/media';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtworkEventDetail {
  appearance?: ArtworkEventDetail.AppearanceEnum;
  betweenFlag?: boolean;
  comment?: string;
  commentBeforeFlag?: boolean;
  errors?: ArtworkEventDetail.ErrorsEnum;
  id?: string;
  media?: Media;
  mediaNum?: number;
  pageNum?: string;
  perspective?: ArtworkEventDetail.PerspectiveEnum;
  position?: ArtworkEventDetail.PositionEnum;
  refNumType?: ArtworkEventDetail.RefNumTypeEnum;
  refNumValue?: string;
  renderedText?: string;
  seqNum?: number;
  textRefFlag?: boolean;
  type?: ArtworkEventDetail.TypeEnum;
  unnumberedFlag?: boolean;

  constructor({
      appearance, 
      betweenFlag, 
      comment, 
      commentBeforeFlag, 
      errors, 
      id, 
      media, 
      mediaNum, 
      pageNum, 
      perspective, 
      position, 
      refNumType, 
      refNumValue, 
      renderedText, 
      seqNum, 
      textRefFlag, 
      type, 
      unnumberedFlag
  }: {
      appearance?: ArtworkEventDetail.AppearanceEnum;
      betweenFlag?: boolean;
      comment?: string;
      commentBeforeFlag?: boolean;
      errors?: ArtworkEventDetail.ErrorsEnum;
      id?: string;
      media?: Media;
      mediaNum?: number;
      pageNum?: string;
      perspective?: ArtworkEventDetail.PerspectiveEnum;
      position?: ArtworkEventDetail.PositionEnum;
      refNumType?: ArtworkEventDetail.RefNumTypeEnum;
      refNumValue?: string;
      renderedText?: string;
      seqNum?: number;
      textRefFlag?: boolean;
      type?: ArtworkEventDetail.TypeEnum;
      unnumberedFlag?: boolean;
  } = {}) {
    this.appearance = appearance;
    this.betweenFlag = betweenFlag;
    this.comment = comment;
    this.commentBeforeFlag = commentBeforeFlag;
    this.errors = errors;
    this.id = id;
    this.media = media;
    this.mediaNum = mediaNum;
    this.pageNum = pageNum;
    this.perspective = perspective;
    this.position = position;
    this.refNumType = refNumType;
    this.refNumValue = refNumValue;
    this.renderedText = renderedText;
    this.seqNum = seqNum;
    this.textRefFlag = textRefFlag;
    this.type = type;
    this.unnumberedFlag = unnumberedFlag;
  }
}

export namespace ArtworkEventDetail {

  export type AppearanceEnum = 'unknown' | 'bw' | 'color';
  export const AppearanceEnum = {
      Unknown: 'unknown' as AppearanceEnum,
      Bw: 'bw' as AppearanceEnum,
      Color: 'color' as AppearanceEnum,
  }
  export const AppearanceEnumValues = [
      AppearanceEnum.Unknown, 
      AppearanceEnum.Bw, 
      AppearanceEnum.Color
  ];
  export type ErrorsEnum = 'none' | 'reproduced in reverse' | 'reproduced horizontally' | 'reproduced vertically' | 'reproduced upside down' | 'wrong object illustrated';
  export const ErrorsEnum = {
      None: 'none' as ErrorsEnum,
      ReproducedInReverse: 'reproduced in reverse' as ErrorsEnum,
      ReproducedHorizontally: 'reproduced horizontally' as ErrorsEnum,
      ReproducedVertically: 'reproduced vertically' as ErrorsEnum,
      ReproducedUpsideDown: 'reproduced upside down' as ErrorsEnum,
      WrongObjectIllustrated: 'wrong object illustrated' as ErrorsEnum,
  }
  export const ErrorsEnumValues = [
      ErrorsEnum.None, 
      ErrorsEnum.ReproducedInReverse, 
      ErrorsEnum.ReproducedHorizontally, 
      ErrorsEnum.ReproducedVertically, 
      ErrorsEnum.ReproducedUpsideDown, 
      ErrorsEnum.WrongObjectIllustrated
  ];
  export type PerspectiveEnum = 'full' | 'installation view' | 'framed' | 'detail' | 'verso' | 'first state' | 'earlier state' | 'archival photo' | 'present state';
  export const PerspectiveEnum = {
      Full: 'full' as PerspectiveEnum,
      InstallationView: 'installation view' as PerspectiveEnum,
      Framed: 'framed' as PerspectiveEnum,
      Detail: 'detail' as PerspectiveEnum,
      Verso: 'verso' as PerspectiveEnum,
      FirstState: 'first state' as PerspectiveEnum,
      EarlierState: 'earlier state' as PerspectiveEnum,
      ArchivalPhoto: 'archival photo' as PerspectiveEnum,
      PresentState: 'present state' as PerspectiveEnum,
  }
  export const PerspectiveEnumValues = [
      PerspectiveEnum.Full, 
      PerspectiveEnum.InstallationView, 
      PerspectiveEnum.Framed, 
      PerspectiveEnum.Detail, 
      PerspectiveEnum.Verso, 
      PerspectiveEnum.FirstState, 
      PerspectiveEnum.EarlierState, 
      PerspectiveEnum.ArchivalPhoto, 
      PerspectiveEnum.PresentState
  ];
  export type PositionEnum = 'cover' | 'back cover' | 'frontispiece' | 'foldout' | 'regular';
  export const PositionEnum = {
      Cover: 'cover' as PositionEnum,
      BackCover: 'back cover' as PositionEnum,
      Frontispiece: 'frontispiece' as PositionEnum,
      Foldout: 'foldout' as PositionEnum,
      Regular: 'regular' as PositionEnum,
  }
  export const PositionEnumValues = [
      PositionEnum.Cover, 
      PositionEnum.BackCover, 
      PositionEnum.Frontispiece, 
      PositionEnum.Foldout, 
      PositionEnum.Regular
  ];
  export type RefNumTypeEnum = 'fig' | 'no' | 'pl';
  export const RefNumTypeEnum = {
      Fig: 'fig' as RefNumTypeEnum,
      No: 'no' as RefNumTypeEnum,
      Pl: 'pl' as RefNumTypeEnum,
  }
  export const RefNumTypeEnumValues = [
      RefNumTypeEnum.Fig, 
      RefNumTypeEnum.No, 
      RefNumTypeEnum.Pl
  ];
  export type TypeEnum = 'standard' | 'infrared' | 'uv' | '3d-scan' | 'x-ray';
  export const TypeEnum = {
      Standard: 'standard' as TypeEnum,
      Infrared: 'infrared' as TypeEnum,
      Uv: 'uv' as TypeEnum,
      _3DScan: '3d-scan' as TypeEnum,
      XRay: 'x-ray' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.Standard, 
      TypeEnum.Infrared, 
      TypeEnum.Uv, 
      TypeEnum._3DScan, 
      TypeEnum.XRay
  ];
}


/**
 * ArtworkEventDetail form builder
 *
 */
export class ArtworkEventDetailFormBuilder extends AbstractFormBuilder<ArtworkEventDetailFormBuilder> {

  className = 'ArtworkEventDetail';

  abstractFormBuilder!: AbstractFormBuilder<ArtworkEventDetail>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtworkEventDetail {
    return new ArtworkEventDetail();
  }

  public appearance(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('appearance', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAppearance() {
    super.removeField('appearance');
    return this;
  }

  public betweenFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('betweenFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBetweenFlag() {
    super.removeField('betweenFlag');
    return this;
  }

  public comment(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('comment', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutComment() {
    super.removeField('comment');
    return this;
  }

  public commentBeforeFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('commentBeforeFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCommentBeforeFlag() {
    super.removeField('commentBeforeFlag');
    return this;
  }

  public errors(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('errors', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutErrors() {
    super.removeField('errors');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public media(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('media', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMedia() {
    super.removeField('media');
    return this;
  }

  public mediaNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mediaNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMediaNum() {
    super.removeField('mediaNum');
    return this;
  }

  public pageNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pageNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPageNum() {
    super.removeField('pageNum');
    return this;
  }

  public perspective(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('perspective', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPerspective() {
    super.removeField('perspective');
    return this;
  }

  public position(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('position', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPosition() {
    super.removeField('position');
    return this;
  }

  public refNumType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNumType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNumType() {
    super.removeField('refNumType');
    return this;
  }

  public refNumValue(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNumValue', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNumValue() {
    super.removeField('refNumValue');
    return this;
  }

  public renderedText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('renderedText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRenderedText() {
    super.removeField('renderedText');
    return this;
  }

  public seqNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seqNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeqNum() {
    super.removeField('seqNum');
    return this;
  }

  public textRefFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('textRefFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTextRefFlag() {
    super.removeField('textRefFlag');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }

  public unnumberedFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('unnumberedFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUnnumberedFlag() {
    super.removeField('unnumberedFlag');
    return this;
  }


  public all(): ArtworkEventDetailFormBuilder {
    return this
    .appearance() 
    .betweenFlag() 
    .comment() 
    .commentBeforeFlag() 
    .errors() 
    .id() 
    .mediaNum() 
    .pageNum() 
    .perspective() 
    .position() 
    .refNumType() 
    .refNumValue() 
    .renderedText() 
    .seqNum() 
    .textRefFlag() 
    .type() 
    .unnumberedFlag() 
;
  }
}

