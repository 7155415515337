/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ReportPreviewImageParam {
  copyrightFlag?: boolean;
  descriptionFlag?: boolean;
  reproductionFlag?: boolean;
  size?: ReportPreviewImageParam.SizeEnum;

  constructor({
      copyrightFlag, 
      descriptionFlag, 
      reproductionFlag, 
      size
  }: {
      copyrightFlag?: boolean;
      descriptionFlag?: boolean;
      reproductionFlag?: boolean;
      size?: ReportPreviewImageParam.SizeEnum;
  } = {}) {
    this.copyrightFlag = copyrightFlag;
    this.descriptionFlag = descriptionFlag;
    this.reproductionFlag = reproductionFlag;
    this.size = size;
  }
}

export namespace ReportPreviewImageParam {

  export type SizeEnum = 'Small' | 'Large';
  export const SizeEnum = {
      Small: 'Small' as SizeEnum,
      Large: 'Large' as SizeEnum,
  }
  export const SizeEnumValues = [
      SizeEnum.Small, 
      SizeEnum.Large
  ];
}


/**
 * ReportPreviewImageParam form builder
 *
 */
export class ReportPreviewImageParamFormBuilder extends AbstractFormBuilder<ReportPreviewImageParamFormBuilder> {

  className = 'ReportPreviewImageParam';

  abstractFormBuilder!: AbstractFormBuilder<ReportPreviewImageParam>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ReportPreviewImageParam {
    return new ReportPreviewImageParam();
  }

  public copyrightFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('copyrightFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCopyrightFlag() {
    super.removeField('copyrightFlag');
    return this;
  }

  public descriptionFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('descriptionFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescriptionFlag() {
    super.removeField('descriptionFlag');
    return this;
  }

  public reproductionFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('reproductionFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReproductionFlag() {
    super.removeField('reproductionFlag');
    return this;
  }

  public size(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('size', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSize() {
    super.removeField('size');
    return this;
  }


  public all(): ReportPreviewImageParamFormBuilder {
    return this
    .copyrightFlag() 
    .descriptionFlag() 
    .reproductionFlag() 
    .size() 
;
  }
}

