/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class IndexingTermAttachmentRequest {
  entityId?: string;
  entityType?: IndexingTermAttachmentRequest.EntityTypeEnum;
  termType?: IndexingTermAttachmentRequest.TermTypeEnum;

  constructor({
      entityId, 
      entityType, 
      termType
  }: {
      entityId?: string;
      entityType?: IndexingTermAttachmentRequest.EntityTypeEnum;
      termType?: IndexingTermAttachmentRequest.TermTypeEnum;
  } = {}) {
    this.entityId = entityId;
    this.entityType = entityType;
    this.termType = termType;
  }
}

export namespace IndexingTermAttachmentRequest {

  export type EntityTypeEnum = 'ARTWORK' | 'PUBLICATION' | 'RESOURCE';
  export const EntityTypeEnum = {
      ARTWORK: 'ARTWORK' as EntityTypeEnum,
      PUBLICATION: 'PUBLICATION' as EntityTypeEnum,
      RESOURCE: 'RESOURCE' as EntityTypeEnum,
  }
  export const EntityTypeEnumValues = [
      EntityTypeEnum.ARTWORK, 
      EntityTypeEnum.PUBLICATION, 
      EntityTypeEnum.RESOURCE
  ];
  export type TermTypeEnum = 'Genre' | 'Subject' | 'Color' | 'Technique';
  export const TermTypeEnum = {
      Genre: 'Genre' as TermTypeEnum,
      Subject: 'Subject' as TermTypeEnum,
      Color: 'Color' as TermTypeEnum,
      Technique: 'Technique' as TermTypeEnum,
  }
  export const TermTypeEnumValues = [
      TermTypeEnum.Genre, 
      TermTypeEnum.Subject, 
      TermTypeEnum.Color, 
      TermTypeEnum.Technique
  ];
}


/**
 * IndexingTermAttachmentRequest form builder
 *
 */
export class IndexingTermAttachmentRequestFormBuilder extends AbstractFormBuilder<IndexingTermAttachmentRequestFormBuilder> {

  className = 'IndexingTermAttachmentRequest';

  abstractFormBuilder!: AbstractFormBuilder<IndexingTermAttachmentRequest>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): IndexingTermAttachmentRequest {
    return new IndexingTermAttachmentRequest();
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityType() {
    super.removeField('entityType');
    return this;
  }

  public termType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('termType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTermType() {
    super.removeField('termType');
    return this;
  }


  public all(): IndexingTermAttachmentRequestFormBuilder {
    return this
    .entityId() 
    .entityType() 
    .termType() 
;
  }
}

