/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { Artwork, ArtworkFormBuilder } from '../models/artwork';
import { ExhibitionVenue, ExhibitionVenueFormBuilder } from '../models/exhibition-venue';
import { Publication, PublicationFormBuilder } from '../models/publication';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Exhibition {
  agentSelections?: Array<AgentSelection>;
  artworks?: Array<Artwork>;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  exhDateMax?: string;
  exhDateMin?: string;
  exhExcl?: string;
  exhTitle?: string;
  exhTitleBrief?: string;

  /**
   * replaced by exh_title_translated
   *
   * @deprecated
   */
  exhTitleOriginal?: string;
  exhTitleTranslated?: string;
  exhType?: Exhibition.ExhTypeEnum;
  id?: string;
  lastModifier?: User;
  masterFlag?: Exhibition.MasterFlagEnum;
  modifiedBy?: string;
  publication?: Publication;
  questionableFlag?: boolean;
  renderedText?: string;
  replacementId?: string;
  simultaneousFlag?: boolean;
  status?: Exhibition.StatusEnum;
  venues?: Array<ExhibitionVenue>;
  visibilityFlag?: boolean;

  constructor({
      agentSelections, 
      artworks, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      exhDateMax, 
      exhDateMin, 
      exhExcl, 
      exhTitle, 
      exhTitleBrief, 
    
  /**
   * replaced by exh_title_translated
   *
   * @deprecated
   */
  exhTitleOriginal, 
      exhTitleTranslated, 
      exhType, 
      id, 
      lastModifier, 
      masterFlag, 
      modifiedBy, 
      publication, 
      questionableFlag, 
      renderedText, 
      replacementId, 
      simultaneousFlag, 
      status, 
      venues, 
      visibilityFlag
  }: {
      agentSelections?: Array<AgentSelection>;
      artworks?: Array<Artwork>;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      exhDateMax?: string;
      exhDateMin?: string;
      exhExcl?: string;
      exhTitle?: string;
      exhTitleBrief?: string;
    
  /**
   * replaced by exh_title_translated
   *
   * @deprecated
   */
  exhTitleOriginal?: string;
      exhTitleTranslated?: string;
      exhType?: Exhibition.ExhTypeEnum;
      id?: string;
      lastModifier?: User;
      masterFlag?: Exhibition.MasterFlagEnum;
      modifiedBy?: string;
      publication?: Publication;
      questionableFlag?: boolean;
      renderedText?: string;
      replacementId?: string;
      simultaneousFlag?: boolean;
      status?: Exhibition.StatusEnum;
      venues?: Array<ExhibitionVenue>;
      visibilityFlag?: boolean;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artworks = artworks;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.exhDateMax = exhDateMax;
    this.exhDateMin = exhDateMin;
    this.exhExcl = exhExcl;
    this.exhTitle = exhTitle;
    this.exhTitleBrief = exhTitleBrief;
    this.exhTitleOriginal = exhTitleOriginal;
    this.exhTitleTranslated = exhTitleTranslated;
    this.exhType = exhType;
    this.id = id;
    this.lastModifier = lastModifier;
    this.masterFlag = masterFlag;
    this.modifiedBy = modifiedBy;
    this.publication = publication;
    this.questionableFlag = questionableFlag;
    this.renderedText = renderedText;
    this.replacementId = replacementId;
    this.simultaneousFlag = simultaneousFlag;
    this.status = status;
    this.venues = venues;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace Exhibition {

  export type ExhTypeEnum = 'Solo' | 'Group';
  export const ExhTypeEnum = {
      Solo: 'Solo' as ExhTypeEnum,
      Group: 'Group' as ExhTypeEnum,
  }
  export const ExhTypeEnumValues = [
      ExhTypeEnum.Solo, 
      ExhTypeEnum.Group
  ];
  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Exhibition form builder
 *
 */
export class ExhibitionFormBuilder extends AbstractFormBuilder<ExhibitionFormBuilder> {

  className = 'Exhibition';

  abstractFormBuilder!: AbstractFormBuilder<Exhibition>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Exhibition {
    return new Exhibition();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artworks(
    builder: ArtworkFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artworks', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtworks() {
    super.removeField('artworks');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public exhDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhDateMax() {
    super.removeField('exhDateMax');
    return this;
  }

  public exhDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhDateMin() {
    super.removeField('exhDateMin');
    return this;
  }

  public exhExcl(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhExcl', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhExcl() {
    super.removeField('exhExcl');
    return this;
  }

  public exhTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhTitle() {
    super.removeField('exhTitle');
    return this;
  }

  public exhTitleBrief(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhTitleBrief', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhTitleBrief() {
    super.removeField('exhTitleBrief');
    return this;
  }

  public exhTitleOriginal(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhTitleOriginal', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhTitleOriginal() {
    super.removeField('exhTitleOriginal');
    return this;
  }

  public exhTitleTranslated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhTitleTranslated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhTitleTranslated() {
    super.removeField('exhTitleTranslated');
    return this;
  }

  public exhType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhType() {
    super.removeField('exhType');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public publication(
    builder: PublicationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('publication', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPublication() {
    super.removeField('publication');
    return this;
  }

  public questionableFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('questionableFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutQuestionableFlag() {
    super.removeField('questionableFlag');
    return this;
  }

  public renderedText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('renderedText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRenderedText() {
    super.removeField('renderedText');
    return this;
  }

  public replacementId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('replacementId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReplacementId() {
    super.removeField('replacementId');
    return this;
  }

  public simultaneousFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('simultaneousFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSimultaneousFlag() {
    super.removeField('simultaneousFlag');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }


  public venues(
    builder: ExhibitionVenueFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('venues', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutVenues() {
    super.removeField('venues');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ExhibitionFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .exhDateMax() 
    .exhDateMin() 
    .exhExcl() 
    .exhTitle() 
    .exhTitleBrief() 
    .exhTitleOriginal() 
    .exhTitleTranslated() 
    .exhType() 
    .id() 
    .masterFlag() 
    .modifiedBy() 
    .questionableFlag() 
    .renderedText() 
    .replacementId() 
    .simultaneousFlag() 
    .status() 
    .visibilityFlag() 
;
  }
}

