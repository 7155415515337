/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ObjectEntry {
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  entryDate?: string;
  entryMethod?: ObjectEntry.EntryMethodEnum;
  entryNumber?: string;
  entryReason?: ObjectEntry.EntryReasonEnum;
  entryStatus?: ObjectEntry.EntryStatusEnum;
  entryTitle?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  returnDate?: string;

  constructor({
      agentSelections, 
      artEvents, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      entryDate, 
      entryMethod, 
      entryNumber, 
      entryReason, 
      entryStatus, 
      entryTitle, 
      id, 
      lastModifier, 
      modifiedBy, 
      returnDate
  }: {
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      entryDate?: string;
      entryMethod?: ObjectEntry.EntryMethodEnum;
      entryNumber?: string;
      entryReason?: ObjectEntry.EntryReasonEnum;
      entryStatus?: ObjectEntry.EntryStatusEnum;
      entryTitle?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      returnDate?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.entryDate = entryDate;
    this.entryMethod = entryMethod;
    this.entryNumber = entryNumber;
    this.entryReason = entryReason;
    this.entryStatus = entryStatus;
    this.entryTitle = entryTitle;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.returnDate = returnDate;
  }
}

export namespace ObjectEntry {

  export type EntryMethodEnum = 'In-person' | 'Via post' | 'Via courier' | 'Found on doorstep';
  export const EntryMethodEnum = {
      InPerson: 'In-person' as EntryMethodEnum,
      ViaPost: 'Via post' as EntryMethodEnum,
      ViaCourier: 'Via courier' as EntryMethodEnum,
      FoundOnDoorstep: 'Found on doorstep' as EntryMethodEnum,
  }
  export const EntryMethodEnumValues = [
      EntryMethodEnum.InPerson, 
      EntryMethodEnum.ViaPost, 
      EntryMethodEnum.ViaCourier, 
      EntryMethodEnum.FoundOnDoorstep
  ];
  export type EntryReasonEnum = 'Enquiry' | 'Commission' | 'Loan';
  export const EntryReasonEnum = {
      Enquiry: 'Enquiry' as EntryReasonEnum,
      Commission: 'Commission' as EntryReasonEnum,
      Loan: 'Loan' as EntryReasonEnum,
  }
  export const EntryReasonEnumValues = [
      EntryReasonEnum.Enquiry, 
      EntryReasonEnum.Commission, 
      EntryReasonEnum.Loan
  ];
  export type EntryStatusEnum = 'Todo' | 'Pending' | 'In-Progress' | 'Complete';
  export const EntryStatusEnum = {
      Todo: 'Todo' as EntryStatusEnum,
      Pending: 'Pending' as EntryStatusEnum,
      InProgress: 'In-Progress' as EntryStatusEnum,
      Complete: 'Complete' as EntryStatusEnum,
  }
  export const EntryStatusEnumValues = [
      EntryStatusEnum.Todo, 
      EntryStatusEnum.Pending, 
      EntryStatusEnum.InProgress, 
      EntryStatusEnum.Complete
  ];
}


/**
 * ObjectEntry form builder
 *
 */
export class ObjectEntryFormBuilder extends AbstractFormBuilder<ObjectEntryFormBuilder> {

  className = 'ObjectEntry';

  abstractFormBuilder!: AbstractFormBuilder<ObjectEntry>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ObjectEntry {
    return new ObjectEntry();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public entryDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryDate() {
    super.removeField('entryDate');
    return this;
  }

  public entryMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryMethod() {
    super.removeField('entryMethod');
    return this;
  }

  public entryNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryNumber() {
    super.removeField('entryNumber');
    return this;
  }

  public entryReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryReason() {
    super.removeField('entryReason');
    return this;
  }

  public entryStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryStatus() {
    super.removeField('entryStatus');
    return this;
  }

  public entryTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entryTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntryTitle() {
    super.removeField('entryTitle');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public returnDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('returnDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReturnDate() {
    super.removeField('returnDate');
    return this;
  }


  public all(): ObjectEntryFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .entryDate() 
    .entryMethod() 
    .entryNumber() 
    .entryReason() 
    .entryStatus() 
    .entryTitle() 
    .id() 
    .modifiedBy() 
    .returnDate() 
;
  }
}

