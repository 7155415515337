/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { NamedFormArray } from '..';
import { NamedFormControlTypes } from './abstract-form-builder';
import { NamedFormControl } from './named-form-control';

export class NamedFormGroup extends FormGroup {
  constructor(
    public name: string,
    controls: { [key: string]: NamedFormControlTypes },
    validator?: ValidatorFn,
    asyncValidator?: AsyncValidatorFn,
  ) {
    super(controls, validator, asyncValidator);
  }

  override get(path: Array<string | number> | string): NamedFormGroup | NamedFormControl | NamedFormArray {
    const control = super.get(path);

    if (control instanceof FormControl) {
      return control as NamedFormControl;
    }
    if (control instanceof FormGroup) {
      return control as NamedFormGroup;
    }

    if (control instanceof FormArray) {
      return control as NamedFormArray;
    }
    // FIXME: Check null return instead of throwing error
    throw new Error(`Unknown control for path ${path}`);
  }

  control(path: Array<string | number> | string): NamedFormControl {
    const control = super.get(path);
    if (control instanceof FormControl) {
      return control as NamedFormControl;
    }
    throw new Error(`Control at path ${path} not found`);
  }

  group(path: Array<string | number> | string): NamedFormGroup {
    const control = super.get(path);
    if (control instanceof FormGroup) {
      return control as NamedFormGroup;
    }
    throw new Error(`Control at path ${path} not found`);
  }

  array(path: Array<string | number> | string): NamedFormArray {
    const control = super.get(path);
    if (control instanceof FormArray) {
      return control as NamedFormArray;
    }
    throw new Error(`Array at path ${path} not found`);
  }

  updateFromSource(
    source: any,
    hard = false,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ): NamedFormGroup {
    if (!source) {
      return this;
    }
    Object.keys(this.controls).forEach((key) => {
      const control: NamedFormControlTypes = this.controls[key] as NamedFormControlTypes;
      if ((control instanceof NamedFormControl && source[key] !== undefined) || hard) {
        control.updateFromSource(source[key], hard, options);
      } else {
        if (source[key] !== null && source[key] !== undefined) {
          control.updateFromSource(source[key], hard, options);
        }
      }
    });
    return this;
  }
}
