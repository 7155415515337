/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentRole {
  gettyId?: string;
  id?: string;
  text?: string;

  constructor({
      gettyId, 
      id, 
      text
  }: {
      gettyId?: string;
      id?: string;
      text?: string;
  } = {}) {
    this.gettyId = gettyId;
    this.id = id;
    this.text = text;
  }
}

export namespace AgentRole {

}


/**
 * AgentRole form builder
 *
 */
export class AgentRoleFormBuilder extends AbstractFormBuilder<AgentRoleFormBuilder> {

  className = 'AgentRole';

  abstractFormBuilder!: AbstractFormBuilder<AgentRole>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentRole {
    return new AgentRole();
  }

  public gettyId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyId() {
    super.removeField('gettyId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public text(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('text', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutText() {
    super.removeField('text');
    return this;
  }


  public all(): AgentRoleFormBuilder {
    return this
    .gettyId() 
    .id() 
    .text() 
;
  }
}

