/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class DashboardData {
  datapoint?: DashboardData.DatapointEnum;
  result?: any;
  timestamp?: string;

  constructor({
      datapoint, 
      result, 
      timestamp
  }: {
      datapoint?: DashboardData.DatapointEnum;
      result?: any;
      timestamp?: string;
  } = {}) {
    this.datapoint = datapoint;
    this.result = result;
    this.timestamp = timestamp;
  }
}

export namespace DashboardData {

  export type DatapointEnum = 'auction_sales_catalogues_live' | 'auction_sales_catalogues_not_live' | 'auction_sales_catalogues_total' | 'auction_sales_catalogues_private_scans' | 'auction_sales_catalogues_worldcat' | 'auction_sales_catalogues_not_worldcat' | 'publication_linked_scans' | 'publication_unlinked_scans' | 'publication_total' | 'archive_media_ocr' | 'archive_media_no_ocr' | 'archive_collections_public' | 'archive_collections_private' | 'archive_collections_total' | 'archive_media_extent_by_collection' | 'archive_media_extent_total' | 'archive_media_count_by_collection' | 'archive_media_count_total' | 'archive_media_ocr_by_collection' | 'archive_media_no_ocr_by_collection' | 'archive_media_unlinked_by_collection' | 'archive_media_unlinked_total' | 'archive_media_linked_by_collection' | 'archive_media_linked_total' | 'artworks_total' | 'artworks_total_by_artist' | 'artworks_total_by_artset' | 'artworks_published' | 'artworks_published_by_artist' | 'artworks_published_by_artset' | 'artworks_unpublished' | 'artworks_unpublished_by_artist' | 'artworks_unpublished_by_artset' | 'artworks_private' | 'artworks_private_by_artist' | 'artworks_private_by_artset' | 'artworks_included' | 'artworks_included_by_artist' | 'artworks_included_by_artset' | 'artworks_pending' | 'artworks_pending_by_artist' | 'artworks_pending_by_artset' | 'artworks_not_included' | 'artworks_not_included_by_artist' | 'artworks_not_included_by_artset' | 'artworks_examined' | 'artworks_examined_by_artist' | 'artworks_examined_by_artset' | 'art_event_examination_total_examinations' | 'art_event_examination_total_examinations_by_artist' | 'art_event_examination_total_examinations_by_artset' | 'art_event_examination_total_verification_wo_exam' | 'art_event_examination_total_verification_wo_exam_by_artist' | 'art_event_examination_total_verification_wo_exam_by_artset';
  export const DatapointEnum = {
      AuctionSalesCataloguesLive: 'auction_sales_catalogues_live' as DatapointEnum,
      AuctionSalesCataloguesNotLive: 'auction_sales_catalogues_not_live' as DatapointEnum,
      AuctionSalesCataloguesTotal: 'auction_sales_catalogues_total' as DatapointEnum,
      AuctionSalesCataloguesPrivateScans: 'auction_sales_catalogues_private_scans' as DatapointEnum,
      AuctionSalesCataloguesWorldcat: 'auction_sales_catalogues_worldcat' as DatapointEnum,
      AuctionSalesCataloguesNotWorldcat: 'auction_sales_catalogues_not_worldcat' as DatapointEnum,
      PublicationLinkedScans: 'publication_linked_scans' as DatapointEnum,
      PublicationUnlinkedScans: 'publication_unlinked_scans' as DatapointEnum,
      PublicationTotal: 'publication_total' as DatapointEnum,
      ArchiveMediaOcr: 'archive_media_ocr' as DatapointEnum,
      ArchiveMediaNoOcr: 'archive_media_no_ocr' as DatapointEnum,
      ArchiveCollectionsPublic: 'archive_collections_public' as DatapointEnum,
      ArchiveCollectionsPrivate: 'archive_collections_private' as DatapointEnum,
      ArchiveCollectionsTotal: 'archive_collections_total' as DatapointEnum,
      ArchiveMediaExtentByCollection: 'archive_media_extent_by_collection' as DatapointEnum,
      ArchiveMediaExtentTotal: 'archive_media_extent_total' as DatapointEnum,
      ArchiveMediaCountByCollection: 'archive_media_count_by_collection' as DatapointEnum,
      ArchiveMediaCountTotal: 'archive_media_count_total' as DatapointEnum,
      ArchiveMediaOcrByCollection: 'archive_media_ocr_by_collection' as DatapointEnum,
      ArchiveMediaNoOcrByCollection: 'archive_media_no_ocr_by_collection' as DatapointEnum,
      ArchiveMediaUnlinkedByCollection: 'archive_media_unlinked_by_collection' as DatapointEnum,
      ArchiveMediaUnlinkedTotal: 'archive_media_unlinked_total' as DatapointEnum,
      ArchiveMediaLinkedByCollection: 'archive_media_linked_by_collection' as DatapointEnum,
      ArchiveMediaLinkedTotal: 'archive_media_linked_total' as DatapointEnum,
      ArtworksTotal: 'artworks_total' as DatapointEnum,
      ArtworksTotalByArtist: 'artworks_total_by_artist' as DatapointEnum,
      ArtworksTotalByArtset: 'artworks_total_by_artset' as DatapointEnum,
      ArtworksPublished: 'artworks_published' as DatapointEnum,
      ArtworksPublishedByArtist: 'artworks_published_by_artist' as DatapointEnum,
      ArtworksPublishedByArtset: 'artworks_published_by_artset' as DatapointEnum,
      ArtworksUnpublished: 'artworks_unpublished' as DatapointEnum,
      ArtworksUnpublishedByArtist: 'artworks_unpublished_by_artist' as DatapointEnum,
      ArtworksUnpublishedByArtset: 'artworks_unpublished_by_artset' as DatapointEnum,
      ArtworksPrivate: 'artworks_private' as DatapointEnum,
      ArtworksPrivateByArtist: 'artworks_private_by_artist' as DatapointEnum,
      ArtworksPrivateByArtset: 'artworks_private_by_artset' as DatapointEnum,
      ArtworksIncluded: 'artworks_included' as DatapointEnum,
      ArtworksIncludedByArtist: 'artworks_included_by_artist' as DatapointEnum,
      ArtworksIncludedByArtset: 'artworks_included_by_artset' as DatapointEnum,
      ArtworksPending: 'artworks_pending' as DatapointEnum,
      ArtworksPendingByArtist: 'artworks_pending_by_artist' as DatapointEnum,
      ArtworksPendingByArtset: 'artworks_pending_by_artset' as DatapointEnum,
      ArtworksNotIncluded: 'artworks_not_included' as DatapointEnum,
      ArtworksNotIncludedByArtist: 'artworks_not_included_by_artist' as DatapointEnum,
      ArtworksNotIncludedByArtset: 'artworks_not_included_by_artset' as DatapointEnum,
      ArtworksExamined: 'artworks_examined' as DatapointEnum,
      ArtworksExaminedByArtist: 'artworks_examined_by_artist' as DatapointEnum,
      ArtworksExaminedByArtset: 'artworks_examined_by_artset' as DatapointEnum,
      ArtEventExaminationTotalExaminations: 'art_event_examination_total_examinations' as DatapointEnum,
      ArtEventExaminationTotalExaminationsByArtist: 'art_event_examination_total_examinations_by_artist' as DatapointEnum,
      ArtEventExaminationTotalExaminationsByArtset: 'art_event_examination_total_examinations_by_artset' as DatapointEnum,
      ArtEventExaminationTotalVerificationWoExam: 'art_event_examination_total_verification_wo_exam' as DatapointEnum,
      ArtEventExaminationTotalVerificationWoExamByArtist: 'art_event_examination_total_verification_wo_exam_by_artist' as DatapointEnum,
      ArtEventExaminationTotalVerificationWoExamByArtset: 'art_event_examination_total_verification_wo_exam_by_artset' as DatapointEnum,
  }
  export const DatapointEnumValues = [
      DatapointEnum.AuctionSalesCataloguesLive, 
      DatapointEnum.AuctionSalesCataloguesNotLive, 
      DatapointEnum.AuctionSalesCataloguesTotal, 
      DatapointEnum.AuctionSalesCataloguesPrivateScans, 
      DatapointEnum.AuctionSalesCataloguesWorldcat, 
      DatapointEnum.AuctionSalesCataloguesNotWorldcat, 
      DatapointEnum.PublicationLinkedScans, 
      DatapointEnum.PublicationUnlinkedScans, 
      DatapointEnum.PublicationTotal, 
      DatapointEnum.ArchiveMediaOcr, 
      DatapointEnum.ArchiveMediaNoOcr, 
      DatapointEnum.ArchiveCollectionsPublic, 
      DatapointEnum.ArchiveCollectionsPrivate, 
      DatapointEnum.ArchiveCollectionsTotal, 
      DatapointEnum.ArchiveMediaExtentByCollection, 
      DatapointEnum.ArchiveMediaExtentTotal, 
      DatapointEnum.ArchiveMediaCountByCollection, 
      DatapointEnum.ArchiveMediaCountTotal, 
      DatapointEnum.ArchiveMediaOcrByCollection, 
      DatapointEnum.ArchiveMediaNoOcrByCollection, 
      DatapointEnum.ArchiveMediaUnlinkedByCollection, 
      DatapointEnum.ArchiveMediaUnlinkedTotal, 
      DatapointEnum.ArchiveMediaLinkedByCollection, 
      DatapointEnum.ArchiveMediaLinkedTotal, 
      DatapointEnum.ArtworksTotal, 
      DatapointEnum.ArtworksTotalByArtist, 
      DatapointEnum.ArtworksTotalByArtset, 
      DatapointEnum.ArtworksPublished, 
      DatapointEnum.ArtworksPublishedByArtist, 
      DatapointEnum.ArtworksPublishedByArtset, 
      DatapointEnum.ArtworksUnpublished, 
      DatapointEnum.ArtworksUnpublishedByArtist, 
      DatapointEnum.ArtworksUnpublishedByArtset, 
      DatapointEnum.ArtworksPrivate, 
      DatapointEnum.ArtworksPrivateByArtist, 
      DatapointEnum.ArtworksPrivateByArtset, 
      DatapointEnum.ArtworksIncluded, 
      DatapointEnum.ArtworksIncludedByArtist, 
      DatapointEnum.ArtworksIncludedByArtset, 
      DatapointEnum.ArtworksPending, 
      DatapointEnum.ArtworksPendingByArtist, 
      DatapointEnum.ArtworksPendingByArtset, 
      DatapointEnum.ArtworksNotIncluded, 
      DatapointEnum.ArtworksNotIncludedByArtist, 
      DatapointEnum.ArtworksNotIncludedByArtset, 
      DatapointEnum.ArtworksExamined, 
      DatapointEnum.ArtworksExaminedByArtist, 
      DatapointEnum.ArtworksExaminedByArtset, 
      DatapointEnum.ArtEventExaminationTotalExaminations, 
      DatapointEnum.ArtEventExaminationTotalExaminationsByArtist, 
      DatapointEnum.ArtEventExaminationTotalExaminationsByArtset, 
      DatapointEnum.ArtEventExaminationTotalVerificationWoExam, 
      DatapointEnum.ArtEventExaminationTotalVerificationWoExamByArtist, 
      DatapointEnum.ArtEventExaminationTotalVerificationWoExamByArtset
  ];
}


/**
 * DashboardData form builder
 *
 */
export class DashboardDataFormBuilder extends AbstractFormBuilder<DashboardDataFormBuilder> {

  className = 'DashboardData';

  abstractFormBuilder!: AbstractFormBuilder<DashboardData>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): DashboardData {
    return new DashboardData();
  }

  public datapoint(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('datapoint', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDatapoint() {
    super.removeField('datapoint');
    return this;
  }

  public result(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('result', [undefined, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutResult() {
    super.removeField('result');
    return this;
  }

  public timestamp(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('timestamp', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTimestamp() {
    super.removeField('timestamp');
    return this;
  }


  public all(): DashboardDataFormBuilder {
    return this
    .datapoint() 
    .result() 
    .timestamp() 
;
  }
}

