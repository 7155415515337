/* tslint:disable */
/* eslint-disable */
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Task {
  agentId?: string;
  artEventId?: string;
  artId?: string;
  assignee?: User;
  aucId?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  description?: string;
  due?: string;
  exhId?: string;
  exhVenueId?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  project?: string;
  pubId?: string;
  resId?: string;
  status?: Task.StatusEnum;
  title?: string;

  constructor({
      agentId, 
      artEventId, 
      artId, 
      assignee, 
      aucId, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      description, 
      due, 
      exhId, 
      exhVenueId, 
      id, 
      lastModifier, 
      modifiedBy, 
      project, 
      pubId, 
      resId, 
      status, 
      title
  }: {
      agentId?: string;
      artEventId?: string;
      artId?: string;
      assignee?: User;
      aucId?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      description?: string;
      due?: string;
      exhId?: string;
      exhVenueId?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      project?: string;
      pubId?: string;
      resId?: string;
      status?: Task.StatusEnum;
      title?: string;
  } = {}) {
    this.agentId = agentId;
    this.artEventId = artEventId;
    this.artId = artId;
    this.assignee = assignee;
    this.aucId = aucId;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.description = description;
    this.due = due;
    this.exhId = exhId;
    this.exhVenueId = exhVenueId;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.project = project;
    this.pubId = pubId;
    this.resId = resId;
    this.status = status;
    this.title = title;
  }
}

export namespace Task {

  export type StatusEnum = 'To Do' | 'In Progress' | 'Done' | 'Abandoned';
  export const StatusEnum = {
      ToDo: 'To Do' as StatusEnum,
      InProgress: 'In Progress' as StatusEnum,
      Done: 'Done' as StatusEnum,
      Abandoned: 'Abandoned' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.ToDo, 
      StatusEnum.InProgress, 
      StatusEnum.Done, 
      StatusEnum.Abandoned
  ];
}


/**
 * Task form builder
 *
 */
export class TaskFormBuilder extends AbstractFormBuilder<TaskFormBuilder> {

  className = 'Task';

  abstractFormBuilder!: AbstractFormBuilder<Task>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Task {
    return new Task();
  }

  public agentId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentId() {
    super.removeField('agentId');
    return this;
  }

  public artEventId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artEventId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtEventId() {
    super.removeField('artEventId');
    return this;
  }

  public artId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtId() {
    super.removeField('artId');
    return this;
  }


  public assignee(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('assignee', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAssignee() {
    super.removeField('assignee');
    return this;
  }

  public aucId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('aucId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAucId() {
    super.removeField('aucId');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public due(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('due', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDue() {
    super.removeField('due');
    return this;
  }

  public exhId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhId() {
    super.removeField('exhId');
    return this;
  }

  public exhVenueId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhVenueId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhVenueId() {
    super.removeField('exhVenueId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public project(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('project', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutProject() {
    super.removeField('project');
    return this;
  }

  public pubId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubId() {
    super.removeField('pubId');
    return this;
  }

  public resId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('resId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutResId() {
    super.removeField('resId');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public title(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('title', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTitle() {
    super.removeField('title');
    return this;
  }


  public all(): TaskFormBuilder {
    return this
    .agentId() 
    .artEventId() 
    .artId() 
    .aucId() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .description() 
    .due() 
    .exhId() 
    .exhVenueId() 
    .id() 
    .modifiedBy() 
    .project() 
    .pubId() 
    .resId() 
    .status() 
    .title() 
;
  }
}

