/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Loan {
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  lastModifier?: User;
  loanAuthorizationDate?: string;
  loanConditions?: string;
  loanEndDate?: string;
  loanReason?: Loan.LoanReasonEnum;
  loanRefNo?: string;
  loanStartDate?: string;
  loanStatus?: Loan.LoanStatusEnum;
  loanStatusDate?: string;
  loanTitle?: string;
  loanType?: Loan.LoanTypeEnum;
  modifiedBy?: string;

  constructor({
      agentSelections, 
      artEvents, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      lastModifier, 
      loanAuthorizationDate, 
      loanConditions, 
      loanEndDate, 
      loanReason, 
      loanRefNo, 
      loanStartDate, 
      loanStatus, 
      loanStatusDate, 
      loanTitle, 
      loanType, 
      modifiedBy
  }: {
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      lastModifier?: User;
      loanAuthorizationDate?: string;
      loanConditions?: string;
      loanEndDate?: string;
      loanReason?: Loan.LoanReasonEnum;
      loanRefNo?: string;
      loanStartDate?: string;
      loanStatus?: Loan.LoanStatusEnum;
      loanStatusDate?: string;
      loanTitle?: string;
      loanType?: Loan.LoanTypeEnum;
      modifiedBy?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.lastModifier = lastModifier;
    this.loanAuthorizationDate = loanAuthorizationDate;
    this.loanConditions = loanConditions;
    this.loanEndDate = loanEndDate;
    this.loanReason = loanReason;
    this.loanRefNo = loanRefNo;
    this.loanStartDate = loanStartDate;
    this.loanStatus = loanStatus;
    this.loanStatusDate = loanStatusDate;
    this.loanTitle = loanTitle;
    this.loanType = loanType;
    this.modifiedBy = modifiedBy;
  }
}

export namespace Loan {

  export type LoanReasonEnum = 'Conservation' | 'Research' | 'Exhibition';
  export const LoanReasonEnum = {
      Conservation: 'Conservation' as LoanReasonEnum,
      Research: 'Research' as LoanReasonEnum,
      Exhibition: 'Exhibition' as LoanReasonEnum,
  }
  export const LoanReasonEnumValues = [
      LoanReasonEnum.Conservation, 
      LoanReasonEnum.Research, 
      LoanReasonEnum.Exhibition
  ];
  export type LoanStatusEnum = 'Begun' | 'Ended' | 'Negotiation' | 'Information exchange';
  export const LoanStatusEnum = {
      Begun: 'Begun' as LoanStatusEnum,
      Ended: 'Ended' as LoanStatusEnum,
      Negotiation: 'Negotiation' as LoanStatusEnum,
      InformationExchange: 'Information exchange' as LoanStatusEnum,
  }
  export const LoanStatusEnumValues = [
      LoanStatusEnum.Begun, 
      LoanStatusEnum.Ended, 
      LoanStatusEnum.Negotiation, 
      LoanStatusEnum.InformationExchange
  ];
  export type LoanTypeEnum = 'Loan-in' | 'Loan-out';
  export const LoanTypeEnum = {
      LoanIn: 'Loan-in' as LoanTypeEnum,
      LoanOut: 'Loan-out' as LoanTypeEnum,
  }
  export const LoanTypeEnumValues = [
      LoanTypeEnum.LoanIn, 
      LoanTypeEnum.LoanOut
  ];
}


/**
 * Loan form builder
 *
 */
export class LoanFormBuilder extends AbstractFormBuilder<LoanFormBuilder> {

  className = 'Loan';

  abstractFormBuilder!: AbstractFormBuilder<Loan>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Loan {
    return new Loan();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public loanAuthorizationDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanAuthorizationDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanAuthorizationDate() {
    super.removeField('loanAuthorizationDate');
    return this;
  }

  public loanConditions(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanConditions', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanConditions() {
    super.removeField('loanConditions');
    return this;
  }

  public loanEndDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanEndDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanEndDate() {
    super.removeField('loanEndDate');
    return this;
  }

  public loanReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanReason() {
    super.removeField('loanReason');
    return this;
  }

  public loanRefNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanRefNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanRefNo() {
    super.removeField('loanRefNo');
    return this;
  }

  public loanStartDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanStartDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanStartDate() {
    super.removeField('loanStartDate');
    return this;
  }

  public loanStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanStatus() {
    super.removeField('loanStatus');
    return this;
  }

  public loanStatusDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanStatusDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanStatusDate() {
    super.removeField('loanStatusDate');
    return this;
  }

  public loanTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanTitle() {
    super.removeField('loanTitle');
    return this;
  }

  public loanType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('loanType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLoanType() {
    super.removeField('loanType');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public all(): LoanFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .loanAuthorizationDate() 
    .loanConditions() 
    .loanEndDate() 
    .loanReason() 
    .loanRefNo() 
    .loanStartDate() 
    .loanStatus() 
    .loanStatusDate() 
    .loanTitle() 
    .loanType() 
    .modifiedBy() 
;
  }
}

