/* tslint:disable */
/* eslint-disable */
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class NoteQueryFilters {
  createdBy?: string;
  dateCreated?: DateQuery;
  entityTypes?: Array<'Artwork' | 'Auction' | 'Agent' | 'ArtEvent' | 'Exhibition' | 'Publication' | 'Resource' | 'ArtworksInArtSet' | 'ArtworksByArtist' | 'Task'>;
  note?: string;
  noteType?: string;
  sourceId?: string;
  visibilityFlag?: boolean;

  constructor({
      createdBy, 
      dateCreated, 
      entityTypes, 
      note, 
      noteType, 
      sourceId, 
      visibilityFlag
  }: {
      createdBy?: string;
      dateCreated?: DateQuery;
      entityTypes?: Array<'Artwork' | 'Auction' | 'Agent' | 'ArtEvent' | 'Exhibition' | 'Publication' | 'Resource' | 'ArtworksInArtSet' | 'ArtworksByArtist' | 'Task'>;
      note?: string;
      noteType?: string;
      sourceId?: string;
      visibilityFlag?: boolean;
  } = {}) {
    this.createdBy = createdBy;
    this.dateCreated = dateCreated;
    this.entityTypes = entityTypes;
    this.note = note;
    this.noteType = noteType;
    this.sourceId = sourceId;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace NoteQueryFilters {

  export type EntityTypesEnum = 'Artwork' | 'Auction' | 'Agent' | 'ArtEvent' | 'Exhibition' | 'Publication' | 'Resource' | 'ArtworksInArtSet' | 'ArtworksByArtist' | 'Task'
  export const EntityTypesEnum = {
      Artwork: 'Artwork' as EntityTypesEnum,
      Auction: 'Auction' as EntityTypesEnum,
      Agent: 'Agent' as EntityTypesEnum,
      ArtEvent: 'ArtEvent' as EntityTypesEnum,
      Exhibition: 'Exhibition' as EntityTypesEnum,
      Publication: 'Publication' as EntityTypesEnum,
      Resource: 'Resource' as EntityTypesEnum,
      ArtworksInArtSet: 'ArtworksInArtSet' as EntityTypesEnum,
      ArtworksByArtist: 'ArtworksByArtist' as EntityTypesEnum,
      Task: 'Task' as EntityTypesEnum,
  }
  export const EntityTypesEnumValues = [
      EntityTypesEnum.Artwork, 
      EntityTypesEnum.Auction, 
      EntityTypesEnum.Agent, 
      EntityTypesEnum.ArtEvent, 
      EntityTypesEnum.Exhibition, 
      EntityTypesEnum.Publication, 
      EntityTypesEnum.Resource, 
      EntityTypesEnum.ArtworksInArtSet, 
      EntityTypesEnum.ArtworksByArtist, 
      EntityTypesEnum.Task
  ];
}


/**
 * NoteQueryFilters form builder
 *
 */
export class NoteQueryFiltersFormBuilder extends AbstractFormBuilder<NoteQueryFiltersFormBuilder> {

  className = 'NoteQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<NoteQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): NoteQueryFilters {
    return new NoteQueryFilters();
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public dateCreated(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('dateCreated', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public entityTypes(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityTypes', [undefined, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityTypes() {
    super.removeField('entityTypes');
    return this;
  }

  public note(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('note', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNote() {
    super.removeField('note');
    return this;
  }

  public noteType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('noteType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNoteType() {
    super.removeField('noteType');
    return this;
  }

  public sourceId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('sourceId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSourceId() {
    super.removeField('sourceId');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): NoteQueryFiltersFormBuilder {
    return this
    .createdBy() 
    .entityTypes() 
    .note() 
    .noteType() 
    .sourceId() 
    .visibilityFlag() 
;
  }
}

