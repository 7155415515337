/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ConservationQueryFilters {
  agentSelections?: Array<AgentSelection>;
  conservationAuthorizationDate?: DateQuery;
  conservationNumber?: string;
  conservationStatus?: ConservationQueryFilters.ConservationStatusEnum;
  conservationTitle?: string;
  id?: string;
  recallDate?: DateQuery;

  constructor({
      agentSelections, 
      conservationAuthorizationDate, 
      conservationNumber, 
      conservationStatus, 
      conservationTitle, 
      id, 
      recallDate
  }: {
      agentSelections?: Array<AgentSelection>;
      conservationAuthorizationDate?: DateQuery;
      conservationNumber?: string;
      conservationStatus?: ConservationQueryFilters.ConservationStatusEnum;
      conservationTitle?: string;
      id?: string;
      recallDate?: DateQuery;
  } = {}) {
    this.agentSelections = agentSelections;
    this.conservationAuthorizationDate = conservationAuthorizationDate;
    this.conservationNumber = conservationNumber;
    this.conservationStatus = conservationStatus;
    this.conservationTitle = conservationTitle;
    this.id = id;
    this.recallDate = recallDate;
  }
}

export namespace ConservationQueryFilters {

  export type ConservationStatusEnum = 'To Do' | 'Pending' | 'In-Progress' | 'Complete';
  export const ConservationStatusEnum = {
      ToDo: 'To Do' as ConservationStatusEnum,
      Pending: 'Pending' as ConservationStatusEnum,
      InProgress: 'In-Progress' as ConservationStatusEnum,
      Complete: 'Complete' as ConservationStatusEnum,
  }
  export const ConservationStatusEnumValues = [
      ConservationStatusEnum.ToDo, 
      ConservationStatusEnum.Pending, 
      ConservationStatusEnum.InProgress, 
      ConservationStatusEnum.Complete
  ];
}


/**
 * ConservationQueryFilters form builder
 *
 */
export class ConservationQueryFiltersFormBuilder extends AbstractFormBuilder<ConservationQueryFiltersFormBuilder> {

  className = 'ConservationQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ConservationQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ConservationQueryFilters {
    return new ConservationQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public conservationAuthorizationDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conservationAuthorizationDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConservationAuthorizationDate() {
    super.removeField('conservationAuthorizationDate');
    return this;
  }

  public conservationNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conservationNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConservationNumber() {
    super.removeField('conservationNumber');
    return this;
  }

  public conservationStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conservationStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConservationStatus() {
    super.removeField('conservationStatus');
    return this;
  }

  public conservationTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conservationTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConservationTitle() {
    super.removeField('conservationTitle');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public recallDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('recallDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRecallDate() {
    super.removeField('recallDate');
    return this;
  }


  public all(): ConservationQueryFiltersFormBuilder {
    return this
    .conservationNumber() 
    .conservationStatus() 
    .conservationTitle() 
    .id() 
;
  }
}

