/* tslint:disable */
/* eslint-disable */
import { ReportPreviewImageParam, ReportPreviewImageParamFormBuilder } from '../models/report-preview-image-param';
import { SortOption, SortOptionFormBuilder } from '../models/sort-option';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class GeneralReportInformation {
  coverPageFlag?: boolean;
  layout?: GeneralReportInformation.LayoutEnum;
  previewImage?: ReportPreviewImageParam;
  sorting?: Array<SortOption>;
  tocFlag?: boolean;

  constructor({
      coverPageFlag, 
      layout, 
      previewImage, 
      sorting, 
      tocFlag
  }: {
      coverPageFlag?: boolean;
      layout?: GeneralReportInformation.LayoutEnum;
      previewImage?: ReportPreviewImageParam;
      sorting?: Array<SortOption>;
      tocFlag?: boolean;
  } = {}) {
    this.coverPageFlag = coverPageFlag;
    this.layout = layout;
    this.previewImage = previewImage;
    this.sorting = sorting;
    this.tocFlag = tocFlag;
  }
}

export namespace GeneralReportInformation {

  export type LayoutEnum = 'Text' | 'Table';
  export const LayoutEnum = {
      Text: 'Text' as LayoutEnum,
      Table: 'Table' as LayoutEnum,
  }
  export const LayoutEnumValues = [
      LayoutEnum.Text, 
      LayoutEnum.Table
  ];
}


/**
 * GeneralReportInformation form builder
 *
 */
export class GeneralReportInformationFormBuilder extends AbstractFormBuilder<GeneralReportInformationFormBuilder> {

  className = 'GeneralReportInformation';

  abstractFormBuilder!: AbstractFormBuilder<GeneralReportInformation>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): GeneralReportInformation {
    return new GeneralReportInformation();
  }

  public coverPageFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('coverPageFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCoverPageFlag() {
    super.removeField('coverPageFlag');
    return this;
  }

  public layout(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('layout', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLayout() {
    super.removeField('layout');
    return this;
  }


  public previewImage(
    builder: ReportPreviewImageParamFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('previewImage', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPreviewImage() {
    super.removeField('previewImage');
    return this;
  }


  public sorting(
    builder: SortOptionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('sorting', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSorting() {
    super.removeField('sorting');
    return this;
  }

  public tocFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('tocFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutTocFlag() {
    super.removeField('tocFlag');
    return this;
  }


  public all(): GeneralReportInformationFormBuilder {
    return this
    .coverPageFlag() 
    .layout() 
    .tocFlag() 
;
  }
}

