/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AuditInformation {
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  auditAuthorizationDate?: string;
  auditMethod?: AuditInformation.AuditMethodEnum;
  auditNumber?: string;
  auditTitle?: string;
  auditType?: AuditInformation.AuditTypeEnum;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;

  constructor({
      agentSelections, 
      artEvents, 
      auditAuthorizationDate, 
      auditMethod, 
      auditNumber, 
      auditTitle, 
      auditType, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      id, 
      lastModifier, 
      modifiedBy
  }: {
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      auditAuthorizationDate?: string;
      auditMethod?: AuditInformation.AuditMethodEnum;
      auditNumber?: string;
      auditTitle?: string;
      auditType?: AuditInformation.AuditTypeEnum;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.auditAuthorizationDate = auditAuthorizationDate;
    this.auditMethod = auditMethod;
    this.auditNumber = auditNumber;
    this.auditTitle = auditTitle;
    this.auditType = auditType;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
  }
}

export namespace AuditInformation {

  export type AuditMethodEnum = 'Full' | 'Random' | 'Selective';
  export const AuditMethodEnum = {
      Full: 'Full' as AuditMethodEnum,
      Random: 'Random' as AuditMethodEnum,
      Selective: 'Selective' as AuditMethodEnum,
  }
  export const AuditMethodEnumValues = [
      AuditMethodEnum.Full, 
      AuditMethodEnum.Random, 
      AuditMethodEnum.Selective
  ];
  export type AuditTypeEnum = 'Condition' | 'Information' | 'Location';
  export const AuditTypeEnum = {
      Condition: 'Condition' as AuditTypeEnum,
      Information: 'Information' as AuditTypeEnum,
      Location: 'Location' as AuditTypeEnum,
  }
  export const AuditTypeEnumValues = [
      AuditTypeEnum.Condition, 
      AuditTypeEnum.Information, 
      AuditTypeEnum.Location
  ];
}


/**
 * AuditInformation form builder
 *
 */
export class AuditInformationFormBuilder extends AbstractFormBuilder<AuditInformationFormBuilder> {

  className = 'AuditInformation';

  abstractFormBuilder!: AbstractFormBuilder<AuditInformation>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AuditInformation {
    return new AuditInformation();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }

  public auditAuthorizationDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditAuthorizationDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditAuthorizationDate() {
    super.removeField('auditAuthorizationDate');
    return this;
  }

  public auditMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditMethod() {
    super.removeField('auditMethod');
    return this;
  }

  public auditNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditNumber() {
    super.removeField('auditNumber');
    return this;
  }

  public auditTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditTitle() {
    super.removeField('auditTitle');
    return this;
  }

  public auditType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('auditType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAuditType() {
    super.removeField('auditType');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public all(): AuditInformationFormBuilder {
    return this
    .auditAuthorizationDate() 
    .auditMethod() 
    .auditNumber() 
    .auditTitle() 
    .auditType() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .id() 
    .modifiedBy() 
;
  }
}

