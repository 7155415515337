/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { Place, PlaceFormBuilder } from '../models/place';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Committee {
  agentSelections?: Array<AgentSelection>;
  artist?: Agent;
  comDateEdtf?: string;
  comDateMax?: string;
  comDateMin?: string;
  comDateText?: string;
  details?: string;
  id?: string;
  place?: Place;

  constructor({
      agentSelections, 
      artist, 
      comDateEdtf, 
      comDateMax, 
      comDateMin, 
      comDateText, 
      details, 
      id, 
      place
  }: {
      agentSelections?: Array<AgentSelection>;
      artist?: Agent;
      comDateEdtf?: string;
      comDateMax?: string;
      comDateMin?: string;
      comDateText?: string;
      details?: string;
      id?: string;
      place?: Place;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artist = artist;
    this.comDateEdtf = comDateEdtf;
    this.comDateMax = comDateMax;
    this.comDateMin = comDateMin;
    this.comDateText = comDateText;
    this.details = details;
    this.id = id;
    this.place = place;
  }
}

export namespace Committee {

}


/**
 * Committee form builder
 *
 */
export class CommitteeFormBuilder extends AbstractFormBuilder<CommitteeFormBuilder> {

  className = 'Committee';

  abstractFormBuilder!: AbstractFormBuilder<Committee>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Committee {
    return new Committee();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtist() {
    super.removeField('artist');
    return this;
  }

  public comDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('comDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutComDateEdtf() {
    super.removeField('comDateEdtf');
    return this;
  }

  public comDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('comDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutComDateMax() {
    super.removeField('comDateMax');
    return this;
  }

  public comDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('comDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutComDateMin() {
    super.removeField('comDateMin');
    return this;
  }

  public comDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('comDateText', [null, [Validators.maxLength(80), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutComDateText() {
    super.removeField('comDateText');
    return this;
  }

  public details(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('details', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetails() {
    super.removeField('details');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public place(
    builder: PlaceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('place', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlace() {
    super.removeField('place');
    return this;
  }


  public all(): CommitteeFormBuilder {
    return this
    .comDateEdtf() 
    .comDateMax() 
    .comDateMin() 
    .comDateText() 
    .details() 
    .id() 
;
  }
}

