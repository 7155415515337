/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class IndexingTermQueryFilters {
  description?: string;
  gettyAatId?: string;
  id?: string;

  /**
   * filter by master flag
   */
  masterFlag?: IndexingTermQueryFilters.MasterFlagEnum;
  name?: string;
  searchString?: string;

  constructor({
      description, 
      gettyAatId, 
      id, 
    
  /**
   * filter by master flag
   */
  masterFlag, 
      name, 
      searchString
  }: {
      description?: string;
      gettyAatId?: string;
      id?: string;
    
  /**
   * filter by master flag
   */
  masterFlag?: IndexingTermQueryFilters.MasterFlagEnum;
      name?: string;
      searchString?: string;
  } = {}) {
    this.description = description;
    this.gettyAatId = gettyAatId;
    this.id = id;
    this.masterFlag = masterFlag;
    this.name = name;
    this.searchString = searchString;
  }
}

export namespace IndexingTermQueryFilters {

  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
}


/**
 * IndexingTermQueryFilters form builder
 *
 */
export class IndexingTermQueryFiltersFormBuilder extends AbstractFormBuilder<IndexingTermQueryFiltersFormBuilder> {

  className = 'IndexingTermQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<IndexingTermQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): IndexingTermQueryFilters {
    return new IndexingTermQueryFilters();
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public gettyAatId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyAatId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyAatId() {
    super.removeField('gettyAatId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public name(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('name', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutName() {
    super.removeField('name');
    return this;
  }

  public searchString(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchString', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchString() {
    super.removeField('searchString');
    return this;
  }


  public all(): IndexingTermQueryFiltersFormBuilder {
    return this
    .description() 
    .gettyAatId() 
    .id() 
    .masterFlag() 
    .name() 
    .searchString() 
;
  }
}

