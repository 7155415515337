/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class DateQuery {

  /**
   * the upper boundary of a date filter
   */
  max?: string;

  /**
   * the lower boundary of a date filter
   */
  min?: string;

  constructor({
    
  /**
   * the upper boundary of a date filter
   */
  max, 
    
  /**
   * the lower boundary of a date filter
   */
  min
  }: {
    
  /**
   * the upper boundary of a date filter
   */
  max?: string;
    
  /**
   * the lower boundary of a date filter
   */
  min?: string;
  } = {}) {
    this.max = max;
    this.min = min;
  }
}

export namespace DateQuery {

}


/**
 * DateQuery form builder
 *
 */
export class DateQueryFormBuilder extends AbstractFormBuilder<DateQueryFormBuilder> {

  className = 'DateQuery';

  abstractFormBuilder!: AbstractFormBuilder<DateQuery>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): DateQuery {
    return new DateQuery();
  }

  public max(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('max', [null, [Validators.required, Validators.pattern(/^\d{4}-\d{2}-\d{2}$/), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMax() {
    super.removeField('max');
    return this;
  }

  public min(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('min', [null, [Validators.required, Validators.pattern(/^\d{4}-\d{2}-\d{2}$/), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMin() {
    super.removeField('min');
    return this;
  }


  public all(): DateQueryFormBuilder {
    return this
    .max() 
    .min() 
;
  }
}

