/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { DimensionQueryParameter, DimensionQueryParameterFormBuilder } from '../models/dimension-query-parameter';
import { LogicalQueryOption, LogicalQueryOptionFormBuilder } from '../models/logical-query-option';
import { Medium, MediumFormBuilder } from '../models/medium';
import { OtherId, OtherIdFormBuilder } from '../models/other-id';
import { Place, PlaceFormBuilder } from '../models/place';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PublicArtworkQueryFilters {

  /**
   * search through all fields of the artwork
   */
  allFields?: string;
  artSetId?: string;

  /**
   * search through all fields of the artwork
   */
  artist?: Agent;
  artists?: Array<Agent>;
  catNo?: string;
  collector?: Agent;
  detCreationYearEdtf?: string;
  detDimDepth?: DimensionQueryParameter;
  detDimDepthIn?: DimensionQueryParameter;
  detDimHeight?: DimensionQueryParameter;
  detDimHeightIn?: DimensionQueryParameter;
  detDimWidth?: DimensionQueryParameter;
  detDimWidthIn?: DimensionQueryParameter;
  detTitle?: string;
  dossierNo?: string;
  included?: boolean;
  keywords?: LogicalQueryOption;
  lastPublishedDate?: DateQuery;
  medium?: Array<Medium>;
  nextArtworksFrom?: string;
  otherId?: OtherId;
  owner?: string;
  placeReference?: Place;
  previousArtworksFrom?: string;
  referenceNumbers?: string;
  referenceNumbersGauguin?: string;
  referenceNumbersRefCatNo?: string;
  seriesType?: PublicArtworkQueryFilters.SeriesTypeEnum;

  constructor({
    
  /**
   * search through all fields of the artwork
   */
  allFields, 
      artSetId, 
    
  /**
   * search through all fields of the artwork
   */
  artist, 
      artists, 
      catNo, 
      collector, 
      detCreationYearEdtf, 
      detDimDepth, 
      detDimDepthIn, 
      detDimHeight, 
      detDimHeightIn, 
      detDimWidth, 
      detDimWidthIn, 
      detTitle, 
      dossierNo, 
      included, 
      keywords, 
      lastPublishedDate, 
      medium, 
      nextArtworksFrom, 
      otherId, 
      owner, 
      placeReference, 
      previousArtworksFrom, 
      referenceNumbers, 
      referenceNumbersGauguin, 
      referenceNumbersRefCatNo, 
      seriesType
  }: {
    
  /**
   * search through all fields of the artwork
   */
  allFields?: string;
      artSetId?: string;
    
  /**
   * search through all fields of the artwork
   */
  artist?: Agent;
      artists?: Array<Agent>;
      catNo?: string;
      collector?: Agent;
      detCreationYearEdtf?: string;
      detDimDepth?: DimensionQueryParameter;
      detDimDepthIn?: DimensionQueryParameter;
      detDimHeight?: DimensionQueryParameter;
      detDimHeightIn?: DimensionQueryParameter;
      detDimWidth?: DimensionQueryParameter;
      detDimWidthIn?: DimensionQueryParameter;
      detTitle?: string;
      dossierNo?: string;
      included?: boolean;
      keywords?: LogicalQueryOption;
      lastPublishedDate?: DateQuery;
      medium?: Array<Medium>;
      nextArtworksFrom?: string;
      otherId?: OtherId;
      owner?: string;
      placeReference?: Place;
      previousArtworksFrom?: string;
      referenceNumbers?: string;
      referenceNumbersGauguin?: string;
      referenceNumbersRefCatNo?: string;
      seriesType?: PublicArtworkQueryFilters.SeriesTypeEnum;
  } = {}) {
    this.allFields = allFields;
    this.artSetId = artSetId;
    this.artist = artist;
    this.artists = artists;
    this.catNo = catNo;
    this.collector = collector;
    this.detCreationYearEdtf = detCreationYearEdtf;
    this.detDimDepth = detDimDepth;
    this.detDimDepthIn = detDimDepthIn;
    this.detDimHeight = detDimHeight;
    this.detDimHeightIn = detDimHeightIn;
    this.detDimWidth = detDimWidth;
    this.detDimWidthIn = detDimWidthIn;
    this.detTitle = detTitle;
    this.dossierNo = dossierNo;
    this.included = included;
    this.keywords = keywords;
    this.lastPublishedDate = lastPublishedDate;
    this.medium = medium;
    this.nextArtworksFrom = nextArtworksFrom;
    this.otherId = otherId;
    this.owner = owner;
    this.placeReference = placeReference;
    this.previousArtworksFrom = previousArtworksFrom;
    this.referenceNumbers = referenceNumbers;
    this.referenceNumbersGauguin = referenceNumbersGauguin;
    this.referenceNumbersRefCatNo = referenceNumbersRefCatNo;
    this.seriesType = seriesType;
  }
}

export namespace PublicArtworkQueryFilters {

  export type SeriesTypeEnum = 'Edition' | 'Unique' | 'Series';
  export const SeriesTypeEnum = {
      Edition: 'Edition' as SeriesTypeEnum,
      Unique: 'Unique' as SeriesTypeEnum,
      Series: 'Series' as SeriesTypeEnum,
  }
  export const SeriesTypeEnumValues = [
      SeriesTypeEnum.Edition, 
      SeriesTypeEnum.Unique, 
      SeriesTypeEnum.Series
  ];
}


/**
 * PublicArtworkQueryFilters form builder
 *
 */
export class PublicArtworkQueryFiltersFormBuilder extends AbstractFormBuilder<PublicArtworkQueryFiltersFormBuilder> {

  className = 'PublicArtworkQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PublicArtworkQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PublicArtworkQueryFilters {
    return new PublicArtworkQueryFilters();
  }

  public allFields(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('allFields', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAllFields() {
    super.removeField('allFields');
    return this;
  }

  public artSetId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artSetId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtSetId() {
    super.removeField('artSetId');
    return this;
  }


  public artist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtist() {
    super.removeField('artist');
    return this;
  }


  public artists(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artists', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtists() {
    super.removeField('artists');
    return this;
  }

  public catNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('catNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCatNo() {
    super.removeField('catNo');
    return this;
  }


  public collector(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('collector', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCollector() {
    super.removeField('collector');
    return this;
  }

  public detCreationYearEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearEdtf() {
    super.removeField('detCreationYearEdtf');
    return this;
  }


  public detDimDepth(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimDepth', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimDepth() {
    super.removeField('detDimDepth');
    return this;
  }


  public detDimDepthIn(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimDepthIn', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimDepthIn() {
    super.removeField('detDimDepthIn');
    return this;
  }


  public detDimHeight(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimHeight', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimHeight() {
    super.removeField('detDimHeight');
    return this;
  }


  public detDimHeightIn(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimHeightIn', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimHeightIn() {
    super.removeField('detDimHeightIn');
    return this;
  }


  public detDimWidth(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimWidth', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimWidth() {
    super.removeField('detDimWidth');
    return this;
  }


  public detDimWidthIn(
    builder: DimensionQueryParameterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('detDimWidthIn', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDetDimWidthIn() {
    super.removeField('detDimWidthIn');
    return this;
  }

  public detTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetTitle() {
    super.removeField('detTitle');
    return this;
  }

  public dossierNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dossierNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDossierNo() {
    super.removeField('dossierNo');
    return this;
  }

  public included(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('included', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIncluded() {
    super.removeField('included');
    return this;
  }


  public keywords(
    builder: LogicalQueryOptionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('keywords', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutKeywords() {
    super.removeField('keywords');
    return this;
  }


  public lastPublishedDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastPublishedDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastPublishedDate() {
    super.removeField('lastPublishedDate');
    return this;
  }


  public medium(
    builder: MediumFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('medium', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMedium() {
    super.removeField('medium');
    return this;
  }

  public nextArtworksFrom(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('nextArtworksFrom', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNextArtworksFrom() {
    super.removeField('nextArtworksFrom');
    return this;
  }


  public otherId(
    builder: OtherIdFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('otherId', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutOtherId() {
    super.removeField('otherId');
    return this;
  }

  public owner(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('owner', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOwner() {
    super.removeField('owner');
    return this;
  }


  public placeReference(
    builder: PlaceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('placeReference', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPlaceReference() {
    super.removeField('placeReference');
    return this;
  }

  public previousArtworksFrom(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('previousArtworksFrom', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPreviousArtworksFrom() {
    super.removeField('previousArtworksFrom');
    return this;
  }

  public referenceNumbers(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('referenceNumbers', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReferenceNumbers() {
    super.removeField('referenceNumbers');
    return this;
  }

  public referenceNumbersGauguin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('referenceNumbersGauguin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReferenceNumbersGauguin() {
    super.removeField('referenceNumbersGauguin');
    return this;
  }

  public referenceNumbersRefCatNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('referenceNumbersRefCatNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReferenceNumbersRefCatNo() {
    super.removeField('referenceNumbersRefCatNo');
    return this;
  }

  public seriesType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seriesType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeriesType() {
    super.removeField('seriesType');
    return this;
  }


  public all(): PublicArtworkQueryFiltersFormBuilder {
    return this
    .allFields() 
    .artSetId() 
    .catNo() 
    .detCreationYearEdtf() 
    .detTitle() 
    .dossierNo() 
    .included() 
    .nextArtworksFrom() 
    .owner() 
    .previousArtworksFrom() 
    .referenceNumbers() 
    .referenceNumbersGauguin() 
    .referenceNumbersRefCatNo() 
    .seriesType() 
;
  }
}

