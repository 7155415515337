/* tslint:disable */
/* eslint-disable */
import { AgentFilter, AgentFilterFormBuilder } from '../models/agent-filter';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentQueryFilters {

  /**
   * filter by (street) address
   */
  address?: string;

  /**
   * filter by agent_culture
   */
  agentCulture?: string;

  /**
   * filter by agent_function
   */
  agentFunction?: string;

  /**
   * filter by group
   */
  agentGroup?: string;

  /**
   * filter by agent_nationality
   */
  agentNationality?: string;

  /**
   * filter by agent_occupation
   */
  agentOccupation?: string;

  /**
   * filter by agent_school
   */
  agentSchool?: string;

  /**
   * filter by agent type
   */
  agentType?: AgentQueryFilters.AgentTypeEnum;

  /**
   * filter only for artists
   */
  artistFlag?: boolean;

  /**
   * filter by birth date
   */
  birthDate?: DateQuery;

  /**
   * filter by birth place
   */
  birthPlace?: string;

  /**
   * filter by city
   */
  city?: Array<string>;

  /**
   * filter by country
   */
  country?: Array<string>;

  /**
   * filter by user who created record
   */
  createdBy?: string;

  /**
   * filter by death date
   */
  deathDate?: DateQuery;

  /**
   * filter by death place
   */
  deathPlace?: string;

  /**
   * filter by description
   */
  description?: string;

  /**
   * filter by main name
   */
  displayName?: string;

  /**
   * filter by main name (exact match)
   */
  displayNameExact?: string;

  /**
   * filter by district
   */
  district?: Array<string>;

  /**
   * filter by foundation place
   */
  foundationPlace?: string;

  /**
   * filter by gender
   */
  gender?: AgentQueryFilters.GenderEnum;

  /**
   * filter by Getty ULAN id
   */
  gettyUlanId?: string;

  /**
   * filter by GND id
   */
  gndId?: string;

  /**
   * filter by agent id
   */
  id?: string;

  /**
   * filter by linguistic_group
   */
  linguisticGroup?: string;

  /**
   * filter by master flag
   */
  masterFlag?: AgentQueryFilters.MasterFlagEnum;

  /**
   * filter by mda_code (only for Organizations)
   */
  mdaCode?: string;

  /**
   * filter by user who last modified record
   */
  modifiedBy?: string;

  /**
   * filter by reference number
   */
  refNo?: string;

  /**
   * filter by relationship to an artist
   */
  relatedToArtist?: AgentFilter;

  /**
   * filter by main OR any other name
   */
  searchString?: string;

  /**
   * filter by editorial status
   */
  status?: AgentQueryFilters.StatusEnum;

  /**
   * filter by Wikidata id
   */
  wikidataId?: string;
  /** @deprecated */wpiArtist?: boolean;

  constructor({
    
  /**
   * filter by (street) address
   */
  address, 
    
  /**
   * filter by agent_culture
   */
  agentCulture, 
    
  /**
   * filter by agent_function
   */
  agentFunction, 
    
  /**
   * filter by group
   */
  agentGroup, 
    
  /**
   * filter by agent_nationality
   */
  agentNationality, 
    
  /**
   * filter by agent_occupation
   */
  agentOccupation, 
    
  /**
   * filter by agent_school
   */
  agentSchool, 
    
  /**
   * filter by agent type
   */
  agentType, 
    
  /**
   * filter only for artists
   */
  artistFlag, 
    
  /**
   * filter by birth date
   */
  birthDate, 
    
  /**
   * filter by birth place
   */
  birthPlace, 
    
  /**
   * filter by city
   */
  city, 
    
  /**
   * filter by country
   */
  country, 
    
  /**
   * filter by user who created record
   */
  createdBy, 
    
  /**
   * filter by death date
   */
  deathDate, 
    
  /**
   * filter by death place
   */
  deathPlace, 
    
  /**
   * filter by description
   */
  description, 
    
  /**
   * filter by main name
   */
  displayName, 
    
  /**
   * filter by main name (exact match)
   */
  displayNameExact, 
    
  /**
   * filter by district
   */
  district, 
    
  /**
   * filter by foundation place
   */
  foundationPlace, 
    
  /**
   * filter by gender
   */
  gender, 
    
  /**
   * filter by Getty ULAN id
   */
  gettyUlanId, 
    
  /**
   * filter by GND id
   */
  gndId, 
    
  /**
   * filter by agent id
   */
  id, 
    
  /**
   * filter by linguistic_group
   */
  linguisticGroup, 
    
  /**
   * filter by master flag
   */
  masterFlag, 
    
  /**
   * filter by mda_code (only for Organizations)
   */
  mdaCode, 
    
  /**
   * filter by user who last modified record
   */
  modifiedBy, 
    
  /**
   * filter by reference number
   */
  refNo, 
    
  /**
   * filter by relationship to an artist
   */
  relatedToArtist, 
    
  /**
   * filter by main OR any other name
   */
  searchString, 
    
  /**
   * filter by editorial status
   */
  status, 
    
  /**
   * filter by Wikidata id
   */
  wikidataId, 
      /** @deprecated */wpiArtist
  }: {
    
  /**
   * filter by (street) address
   */
  address?: string;
    
  /**
   * filter by agent_culture
   */
  agentCulture?: string;
    
  /**
   * filter by agent_function
   */
  agentFunction?: string;
    
  /**
   * filter by group
   */
  agentGroup?: string;
    
  /**
   * filter by agent_nationality
   */
  agentNationality?: string;
    
  /**
   * filter by agent_occupation
   */
  agentOccupation?: string;
    
  /**
   * filter by agent_school
   */
  agentSchool?: string;
    
  /**
   * filter by agent type
   */
  agentType?: AgentQueryFilters.AgentTypeEnum;
    
  /**
   * filter only for artists
   */
  artistFlag?: boolean;
    
  /**
   * filter by birth date
   */
  birthDate?: DateQuery;
    
  /**
   * filter by birth place
   */
  birthPlace?: string;
    
  /**
   * filter by city
   */
  city?: Array<string>;
    
  /**
   * filter by country
   */
  country?: Array<string>;
    
  /**
   * filter by user who created record
   */
  createdBy?: string;
    
  /**
   * filter by death date
   */
  deathDate?: DateQuery;
    
  /**
   * filter by death place
   */
  deathPlace?: string;
    
  /**
   * filter by description
   */
  description?: string;
    
  /**
   * filter by main name
   */
  displayName?: string;
    
  /**
   * filter by main name (exact match)
   */
  displayNameExact?: string;
    
  /**
   * filter by district
   */
  district?: Array<string>;
    
  /**
   * filter by foundation place
   */
  foundationPlace?: string;
    
  /**
   * filter by gender
   */
  gender?: AgentQueryFilters.GenderEnum;
    
  /**
   * filter by Getty ULAN id
   */
  gettyUlanId?: string;
    
  /**
   * filter by GND id
   */
  gndId?: string;
    
  /**
   * filter by agent id
   */
  id?: string;
    
  /**
   * filter by linguistic_group
   */
  linguisticGroup?: string;
    
  /**
   * filter by master flag
   */
  masterFlag?: AgentQueryFilters.MasterFlagEnum;
    
  /**
   * filter by mda_code (only for Organizations)
   */
  mdaCode?: string;
    
  /**
   * filter by user who last modified record
   */
  modifiedBy?: string;
    
  /**
   * filter by reference number
   */
  refNo?: string;
    
  /**
   * filter by relationship to an artist
   */
  relatedToArtist?: AgentFilter;
    
  /**
   * filter by main OR any other name
   */
  searchString?: string;
    
  /**
   * filter by editorial status
   */
  status?: AgentQueryFilters.StatusEnum;
    
  /**
   * filter by Wikidata id
   */
  wikidataId?: string;
      /** @deprecated */wpiArtist?: boolean;
  } = {}) {
    this.address = address;
    this.agentCulture = agentCulture;
    this.agentFunction = agentFunction;
    this.agentGroup = agentGroup;
    this.agentNationality = agentNationality;
    this.agentOccupation = agentOccupation;
    this.agentSchool = agentSchool;
    this.agentType = agentType;
    this.artistFlag = artistFlag;
    this.birthDate = birthDate;
    this.birthPlace = birthPlace;
    this.city = city;
    this.country = country;
    this.createdBy = createdBy;
    this.deathDate = deathDate;
    this.deathPlace = deathPlace;
    this.description = description;
    this.displayName = displayName;
    this.displayNameExact = displayNameExact;
    this.district = district;
    this.foundationPlace = foundationPlace;
    this.gender = gender;
    this.gettyUlanId = gettyUlanId;
    this.gndId = gndId;
    this.id = id;
    this.linguisticGroup = linguisticGroup;
    this.masterFlag = masterFlag;
    this.mdaCode = mdaCode;
    this.modifiedBy = modifiedBy;
    this.refNo = refNo;
    this.relatedToArtist = relatedToArtist;
    this.searchString = searchString;
    this.status = status;
    this.wikidataId = wikidataId;
    this.wpiArtist = wpiArtist;
  }
}

export namespace AgentQueryFilters {

  export type AgentTypeEnum = 'Person' | 'Family' | 'Corporate Body' | 'People' | 'Unknown';
  export const AgentTypeEnum = {
      Person: 'Person' as AgentTypeEnum,
      Family: 'Family' as AgentTypeEnum,
      CorporateBody: 'Corporate Body' as AgentTypeEnum,
      People: 'People' as AgentTypeEnum,
      Unknown: 'Unknown' as AgentTypeEnum,
  }
  export const AgentTypeEnumValues = [
      AgentTypeEnum.Person, 
      AgentTypeEnum.Family, 
      AgentTypeEnum.CorporateBody, 
      AgentTypeEnum.People, 
      AgentTypeEnum.Unknown
  ];
  export type GenderEnum = 'M' | 'F' | 'U';
  export const GenderEnum = {
      M: 'M' as GenderEnum,
      F: 'F' as GenderEnum,
      U: 'U' as GenderEnum,
  }
  export const GenderEnumValues = [
      GenderEnum.M, 
      GenderEnum.F, 
      GenderEnum.U
  ];
  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * AgentQueryFilters form builder
 *
 */
export class AgentQueryFiltersFormBuilder extends AbstractFormBuilder<AgentQueryFiltersFormBuilder> {

  className = 'AgentQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<AgentQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentQueryFilters {
    return new AgentQueryFilters();
  }

  public address(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('address', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAddress() {
    super.removeField('address');
    return this;
  }

  public agentCulture(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentCulture', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentCulture() {
    super.removeField('agentCulture');
    return this;
  }

  public agentFunction(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentFunction', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentFunction() {
    super.removeField('agentFunction');
    return this;
  }

  public agentGroup(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentGroup', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentGroup() {
    super.removeField('agentGroup');
    return this;
  }

  public agentNationality(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentNationality', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentNationality() {
    super.removeField('agentNationality');
    return this;
  }

  public agentOccupation(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentOccupation', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentOccupation() {
    super.removeField('agentOccupation');
    return this;
  }

  public agentSchool(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentSchool', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentSchool() {
    super.removeField('agentSchool');
    return this;
  }

  public agentType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('agentType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAgentType() {
    super.removeField('agentType');
    return this;
  }

  public artistFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('artistFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutArtistFlag() {
    super.removeField('artistFlag');
    return this;
  }


  public birthDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('birthDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutBirthDate() {
    super.removeField('birthDate');
    return this;
  }

  public birthPlace(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('birthPlace', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBirthPlace() {
    super.removeField('birthPlace');
    return this;
  }

  public city(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('city', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCity() {
    super.removeField('city');
    return this;
  }

  public country(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('country', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCountry() {
    super.removeField('country');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public deathDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('deathDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDeathDate() {
    super.removeField('deathDate');
    return this;
  }

  public deathPlace(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deathPlace', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeathPlace() {
    super.removeField('deathPlace');
    return this;
  }

  public description(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('description', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDescription() {
    super.removeField('description');
    return this;
  }

  public displayName(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('displayName', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisplayName() {
    super.removeField('displayName');
    return this;
  }

  public displayNameExact(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('displayNameExact', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisplayNameExact() {
    super.removeField('displayNameExact');
    return this;
  }

  public district(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('district', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDistrict() {
    super.removeField('district');
    return this;
  }

  public foundationPlace(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('foundationPlace', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFoundationPlace() {
    super.removeField('foundationPlace');
    return this;
  }

  public gender(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gender', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGender() {
    super.removeField('gender');
    return this;
  }

  public gettyUlanId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gettyUlanId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGettyUlanId() {
    super.removeField('gettyUlanId');
    return this;
  }

  public gndId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('gndId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGndId() {
    super.removeField('gndId');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public linguisticGroup(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('linguisticGroup', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLinguisticGroup() {
    super.removeField('linguisticGroup');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public mdaCode(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mdaCode', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMdaCode() {
    super.removeField('mdaCode');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public refNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNo() {
    super.removeField('refNo');
    return this;
  }


  public relatedToArtist(
    builder: AgentFilterFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('relatedToArtist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRelatedToArtist() {
    super.removeField('relatedToArtist');
    return this;
  }

  public searchString(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchString', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchString() {
    super.removeField('searchString');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public wikidataId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('wikidataId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutWikidataId() {
    super.removeField('wikidataId');
    return this;
  }

  public wpiArtist(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('wpiArtist', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutWpiArtist() {
    super.removeField('wpiArtist');
    return this;
  }


  public all(): AgentQueryFiltersFormBuilder {
    return this
    .address() 
    .agentCulture() 
    .agentFunction() 
    .agentGroup() 
    .agentNationality() 
    .agentOccupation() 
    .agentSchool() 
    .agentType() 
    .artistFlag() 
    .birthPlace() 
    .city() 
    .country() 
    .createdBy() 
    .deathPlace() 
    .description() 
    .displayName() 
    .displayNameExact() 
    .district() 
    .foundationPlace() 
    .gender() 
    .gettyUlanId() 
    .gndId() 
    .id() 
    .linguisticGroup() 
    .masterFlag() 
    .mdaCode() 
    .modifiedBy() 
    .refNo() 
    .searchString() 
    .status() 
    .wikidataId() 
    .wpiArtist() 
;
  }
}

