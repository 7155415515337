/* tslint:disable */
/* eslint-disable */
import { NavigationFilters, NavigationFiltersFormBuilder } from '../models/navigation-filters';
import { PaginationQuery, PaginationQueryFormBuilder } from '../models/pagination-query';
import { SortOption, SortOptionFormBuilder } from '../models/sort-option';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ResourceNavigationRequest {
  filters?: NavigationFilters;
  pagination?: PaginationQuery;
  sorting?: Array<SortOption>;

  constructor({
      filters, 
      pagination, 
      sorting
  }: {
      filters?: NavigationFilters;
      pagination?: PaginationQuery;
      sorting?: Array<SortOption>;
  } = {}) {
    this.filters = filters;
    this.pagination = pagination;
    this.sorting = sorting;
  }
}

export namespace ResourceNavigationRequest {

}


/**
 * ResourceNavigationRequest form builder
 *
 */
export class ResourceNavigationRequestFormBuilder extends AbstractFormBuilder<ResourceNavigationRequestFormBuilder> {

  className = 'ResourceNavigationRequest';

  abstractFormBuilder!: AbstractFormBuilder<ResourceNavigationRequest>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ResourceNavigationRequest {
    return new ResourceNavigationRequest();
  }


  public filters(
    builder: NavigationFiltersFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('filters', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutFilters() {
    super.removeField('filters');
    return this;
  }


  public pagination(
    builder: PaginationQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('pagination', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPagination() {
    super.removeField('pagination');
    return this;
  }


  public sorting(
    builder: SortOptionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('sorting', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSorting() {
    super.removeField('sorting');
    return this;
  }


  public all(): ResourceNavigationRequestFormBuilder {
    return this
;
  }
}

