/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtworkSetTypeQueryFilters {
  type?: string;

  constructor({
      type
  }: {
      type?: string;
  } = {}) {
    this.type = type;
  }
}

export namespace ArtworkSetTypeQueryFilters {

}


/**
 * ArtworkSetTypeQueryFilters form builder
 *
 */
export class ArtworkSetTypeQueryFiltersFormBuilder extends AbstractFormBuilder<ArtworkSetTypeQueryFiltersFormBuilder> {

  className = 'ArtworkSetTypeQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ArtworkSetTypeQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtworkSetTypeQueryFilters {
    return new ArtworkSetTypeQueryFilters();
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }


  public all(): ArtworkSetTypeQueryFiltersFormBuilder {
    return this
    .type() 
;
  }
}

