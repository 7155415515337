/* tslint:disable */
/* eslint-disable */
import { Resource, ResourceFormBuilder } from '../models/resource';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ResourceStatusChangeRequest {

  /**
   * Filter if only resources with a certain status should be affected
   */
  currentStatus?: ResourceStatusChangeRequest.CurrentStatusEnum;

  /**
   * Filter if only resources with a certain visibility should be affected
   */
  currentVisibility?: boolean;

  /**
   * Flag that determines if the status change should affect child resources
   */
  includeSubResFlag?: boolean;

  /**
   * Enum that deterimes if status of media should be changed
   */
  mediaChange?: ResourceStatusChangeRequest.MediaChangeEnum;

  /**
   * The new status of all affected resources
   */
  newStatus?: ResourceStatusChangeRequest.NewStatusEnum;

  /**
   * The new visibility of all affected resources
   */
  newVisibility?: boolean;

  /**
   * An array of resources where the status should be changed
   */
  resources?: Array<Resource>;

  constructor({
    
  /**
   * Filter if only resources with a certain status should be affected
   */
  currentStatus, 
    
  /**
   * Filter if only resources with a certain visibility should be affected
   */
  currentVisibility, 
    
  /**
   * Flag that determines if the status change should affect child resources
   */
  includeSubResFlag, 
    
  /**
   * Enum that deterimes if status of media should be changed
   */
  mediaChange, 
    
  /**
   * The new status of all affected resources
   */
  newStatus, 
    
  /**
   * The new visibility of all affected resources
   */
  newVisibility, 
    
  /**
   * An array of resources where the status should be changed
   */
  resources
  }: {
    
  /**
   * Filter if only resources with a certain status should be affected
   */
  currentStatus?: ResourceStatusChangeRequest.CurrentStatusEnum;
    
  /**
   * Filter if only resources with a certain visibility should be affected
   */
  currentVisibility?: boolean;
    
  /**
   * Flag that determines if the status change should affect child resources
   */
  includeSubResFlag?: boolean;
    
  /**
   * Enum that deterimes if status of media should be changed
   */
  mediaChange?: ResourceStatusChangeRequest.MediaChangeEnum;
    
  /**
   * The new status of all affected resources
   */
  newStatus?: ResourceStatusChangeRequest.NewStatusEnum;
    
  /**
   * The new visibility of all affected resources
   */
  newVisibility?: boolean;
    
  /**
   * An array of resources where the status should be changed
   */
  resources?: Array<Resource>;
  } = {}) {
    this.currentStatus = currentStatus;
    this.currentVisibility = currentVisibility;
    this.includeSubResFlag = includeSubResFlag;
    this.mediaChange = mediaChange;
    this.newStatus = newStatus;
    this.newVisibility = newVisibility;
    this.resources = resources;
  }
}

export namespace ResourceStatusChangeRequest {

  export type CurrentStatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const CurrentStatusEnum = {
      Draft: 'draft' as CurrentStatusEnum,
      Ready: 'ready' as CurrentStatusEnum,
      Checked: 'checked' as CurrentStatusEnum,
      Archived: 'archived' as CurrentStatusEnum,
  }
  export const CurrentStatusEnumValues = [
      CurrentStatusEnum.Draft, 
      CurrentStatusEnum.Ready, 
      CurrentStatusEnum.Checked, 
      CurrentStatusEnum.Archived
  ];
  export type MediaChangeEnum = 'none' | 'public' | 'private';
  export const MediaChangeEnum = {
      None: 'none' as MediaChangeEnum,
      Public: 'public' as MediaChangeEnum,
      Private: 'private' as MediaChangeEnum,
  }
  export const MediaChangeEnumValues = [
      MediaChangeEnum.None, 
      MediaChangeEnum.Public, 
      MediaChangeEnum.Private
  ];
  export type NewStatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const NewStatusEnum = {
      Draft: 'draft' as NewStatusEnum,
      Ready: 'ready' as NewStatusEnum,
      Checked: 'checked' as NewStatusEnum,
      Archived: 'archived' as NewStatusEnum,
  }
  export const NewStatusEnumValues = [
      NewStatusEnum.Draft, 
      NewStatusEnum.Ready, 
      NewStatusEnum.Checked, 
      NewStatusEnum.Archived
  ];
}


/**
 * ResourceStatusChangeRequest form builder
 *
 */
export class ResourceStatusChangeRequestFormBuilder extends AbstractFormBuilder<ResourceStatusChangeRequestFormBuilder> {

  className = 'ResourceStatusChangeRequest';

  abstractFormBuilder!: AbstractFormBuilder<ResourceStatusChangeRequest>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ResourceStatusChangeRequest {
    return new ResourceStatusChangeRequest();
  }

  public currentStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('currentStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCurrentStatus() {
    super.removeField('currentStatus');
    return this;
  }

  public currentVisibility(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('currentVisibility', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCurrentVisibility() {
    super.removeField('currentVisibility');
    return this;
  }

  public includeSubResFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('includeSubResFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIncludeSubResFlag() {
    super.removeField('includeSubResFlag');
    return this;
  }

  public mediaChange(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mediaChange', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMediaChange() {
    super.removeField('mediaChange');
    return this;
  }

  public newStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('newStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNewStatus() {
    super.removeField('newStatus');
    return this;
  }

  public newVisibility(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('newVisibility', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNewVisibility() {
    super.removeField('newVisibility');
    return this;
  }


  public resources(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('resources', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutResources() {
    super.removeField('resources');
    return this;
  }


  public all(): ResourceStatusChangeRequestFormBuilder {
    return this
    .currentStatus() 
    .currentVisibility() 
    .includeSubResFlag() 
    .mediaChange() 
    .newStatus() 
    .newVisibility() 
;
  }
}

