/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AdditionalReportInformation {
  internalLinksFlag?: boolean;
  notes?: AdditionalReportInformation.NotesEnum;
  publicDisplayLinkFlag?: boolean;

  constructor({
      internalLinksFlag, 
      notes, 
      publicDisplayLinkFlag
  }: {
      internalLinksFlag?: boolean;
      notes?: AdditionalReportInformation.NotesEnum;
      publicDisplayLinkFlag?: boolean;
  } = {}) {
    this.internalLinksFlag = internalLinksFlag;
    this.notes = notes;
    this.publicDisplayLinkFlag = publicDisplayLinkFlag;
  }
}

export namespace AdditionalReportInformation {

  export type NotesEnum = 'All' | 'Live' | 'Private';
  export const NotesEnum = {
      All: 'All' as NotesEnum,
      Live: 'Live' as NotesEnum,
      Private: 'Private' as NotesEnum,
  }
  export const NotesEnumValues = [
      NotesEnum.All, 
      NotesEnum.Live, 
      NotesEnum.Private
  ];
}


/**
 * AdditionalReportInformation form builder
 *
 */
export class AdditionalReportInformationFormBuilder extends AbstractFormBuilder<AdditionalReportInformationFormBuilder> {

  className = 'AdditionalReportInformation';

  abstractFormBuilder!: AbstractFormBuilder<AdditionalReportInformation>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AdditionalReportInformation {
    return new AdditionalReportInformation();
  }

  public internalLinksFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('internalLinksFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInternalLinksFlag() {
    super.removeField('internalLinksFlag');
    return this;
  }

  public notes(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('notes', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNotes() {
    super.removeField('notes');
    return this;
  }

  public publicDisplayLinkFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('publicDisplayLinkFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPublicDisplayLinkFlag() {
    super.removeField('publicDisplayLinkFlag');
    return this;
  }


  public all(): AdditionalReportInformationFormBuilder {
    return this
    .internalLinksFlag() 
    .notes() 
    .publicDisplayLinkFlag() 
;
  }
}

