/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtworkEvent, ArtworkEventFormBuilder } from '../models/artwork-event';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ObjectExit {
  agentSelections?: Array<AgentSelection>;
  artEvents?: Array<ArtworkEvent>;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  deliveryDate?: string;
  exitAuthorisationDate?: string;
  exitDate?: string;
  exitMethod?: ObjectExit.ExitMethodEnum;
  exitNumber?: string;
  exitReason?: ObjectExit.ExitReasonEnum;
  exitStatus?: ObjectExit.ExitStatusEnum;
  exitTitle?: string;
  expectedReturnDate?: string;
  expectedReturnMethod?: ObjectExit.ExpectedReturnMethodEnum;
  id?: string;
  lastModifier?: User;
  modifiedBy?: string;
  returnDate?: string;

  constructor({
      agentSelections, 
      artEvents, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      deliveryDate, 
      exitAuthorisationDate, 
      exitDate, 
      exitMethod, 
      exitNumber, 
      exitReason, 
      exitStatus, 
      exitTitle, 
      expectedReturnDate, 
      expectedReturnMethod, 
      id, 
      lastModifier, 
      modifiedBy, 
      returnDate
  }: {
      agentSelections?: Array<AgentSelection>;
      artEvents?: Array<ArtworkEvent>;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      deliveryDate?: string;
      exitAuthorisationDate?: string;
      exitDate?: string;
      exitMethod?: ObjectExit.ExitMethodEnum;
      exitNumber?: string;
      exitReason?: ObjectExit.ExitReasonEnum;
      exitStatus?: ObjectExit.ExitStatusEnum;
      exitTitle?: string;
      expectedReturnDate?: string;
      expectedReturnMethod?: ObjectExit.ExpectedReturnMethodEnum;
      id?: string;
      lastModifier?: User;
      modifiedBy?: string;
      returnDate?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artEvents = artEvents;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.deliveryDate = deliveryDate;
    this.exitAuthorisationDate = exitAuthorisationDate;
    this.exitDate = exitDate;
    this.exitMethod = exitMethod;
    this.exitNumber = exitNumber;
    this.exitReason = exitReason;
    this.exitStatus = exitStatus;
    this.exitTitle = exitTitle;
    this.expectedReturnDate = expectedReturnDate;
    this.expectedReturnMethod = expectedReturnMethod;
    this.id = id;
    this.lastModifier = lastModifier;
    this.modifiedBy = modifiedBy;
    this.returnDate = returnDate;
  }
}

export namespace ObjectExit {

  export type ExitMethodEnum = 'collected by shipper' | 'in-person' | 'by package';
  export const ExitMethodEnum = {
      CollectedByShipper: 'collected by shipper' as ExitMethodEnum,
      InPerson: 'in-person' as ExitMethodEnum,
      ByPackage: 'by package' as ExitMethodEnum,
  }
  export const ExitMethodEnumValues = [
      ExitMethodEnum.CollectedByShipper, 
      ExitMethodEnum.InPerson, 
      ExitMethodEnum.ByPackage
  ];
  export type ExitReasonEnum = 'exhibition' | 'disposal' | 'use';
  export const ExitReasonEnum = {
      Exhibition: 'exhibition' as ExitReasonEnum,
      Disposal: 'disposal' as ExitReasonEnum,
      Use: 'use' as ExitReasonEnum,
  }
  export const ExitReasonEnumValues = [
      ExitReasonEnum.Exhibition, 
      ExitReasonEnum.Disposal, 
      ExitReasonEnum.Use
  ];
  export type ExitStatusEnum = 'To do' | 'Pending' | 'In-progress' | 'Complete';
  export const ExitStatusEnum = {
      ToDo: 'To do' as ExitStatusEnum,
      Pending: 'Pending' as ExitStatusEnum,
      InProgress: 'In-progress' as ExitStatusEnum,
      Complete: 'Complete' as ExitStatusEnum,
  }
  export const ExitStatusEnumValues = [
      ExitStatusEnum.ToDo, 
      ExitStatusEnum.Pending, 
      ExitStatusEnum.InProgress, 
      ExitStatusEnum.Complete
  ];
  export type ExpectedReturnMethodEnum = 'courier' | 'registered post' | 'hand delivery' | 'in-person';
  export const ExpectedReturnMethodEnum = {
      Courier: 'courier' as ExpectedReturnMethodEnum,
      RegisteredPost: 'registered post' as ExpectedReturnMethodEnum,
      HandDelivery: 'hand delivery' as ExpectedReturnMethodEnum,
      InPerson: 'in-person' as ExpectedReturnMethodEnum,
  }
  export const ExpectedReturnMethodEnumValues = [
      ExpectedReturnMethodEnum.Courier, 
      ExpectedReturnMethodEnum.RegisteredPost, 
      ExpectedReturnMethodEnum.HandDelivery, 
      ExpectedReturnMethodEnum.InPerson
  ];
}


/**
 * ObjectExit form builder
 *
 */
export class ObjectExitFormBuilder extends AbstractFormBuilder<ObjectExitFormBuilder> {

  className = 'ObjectExit';

  abstractFormBuilder!: AbstractFormBuilder<ObjectExit>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ObjectExit {
    return new ObjectExit();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEvents(
    builder: ArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEvents() {
    super.removeField('artEvents');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public deliveryDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('deliveryDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDeliveryDate() {
    super.removeField('deliveryDate');
    return this;
  }

  public exitAuthorisationDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitAuthorisationDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitAuthorisationDate() {
    super.removeField('exitAuthorisationDate');
    return this;
  }

  public exitDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitDate() {
    super.removeField('exitDate');
    return this;
  }

  public exitMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitMethod() {
    super.removeField('exitMethod');
    return this;
  }

  public exitNumber(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitNumber', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitNumber() {
    super.removeField('exitNumber');
    return this;
  }

  public exitReason(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitReason', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitReason() {
    super.removeField('exitReason');
    return this;
  }

  public exitStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitStatus() {
    super.removeField('exitStatus');
    return this;
  }

  public exitTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exitTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExitTitle() {
    super.removeField('exitTitle');
    return this;
  }

  public expectedReturnDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('expectedReturnDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExpectedReturnDate() {
    super.removeField('expectedReturnDate');
    return this;
  }

  public expectedReturnMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('expectedReturnMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExpectedReturnMethod() {
    super.removeField('expectedReturnMethod');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public returnDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('returnDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReturnDate() {
    super.removeField('returnDate');
    return this;
  }


  public all(): ObjectExitFormBuilder {
    return this
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .deliveryDate() 
    .exitAuthorisationDate() 
    .exitDate() 
    .exitMethod() 
    .exitNumber() 
    .exitReason() 
    .exitStatus() 
    .exitTitle() 
    .expectedReturnDate() 
    .expectedReturnMethod() 
    .id() 
    .modifiedBy() 
    .returnDate() 
;
  }
}

