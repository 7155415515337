/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { Artwork, ArtworkFormBuilder } from '../models/artwork';
import { Exhibition, ExhibitionFormBuilder } from '../models/exhibition';
import { IndexingTerm, IndexingTermFormBuilder } from '../models/indexing-term';
import { Media, MediaFormBuilder } from '../models/media';
import { Resource, ResourceFormBuilder } from '../models/resource';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class Publication {
  agentSelections?: Array<AgentSelection>;
  artworks?: Array<Artwork>;
  biblioCat?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  edition?: string;
  exhibitions?: Array<Exhibition>;
  fairUseFlag?: boolean;
  fragFlag?: boolean;
  /** @deprecated */genres?: Array<IndexingTerm>;
  grandParentTitle?: string;
  hard?: boolean;
  hasOcr?: boolean;
  id?: string;
  ill?: boolean;
  illBw?: boolean;
  illColor?: boolean;
  illFig?: boolean;
  illPlate?: boolean;
  illustrations?: Publication.IllustrationsEnum;
  indexingTerms?: Array<IndexingTerm>;
  isbn?: string;
  issNo?: string;
  lastModifier?: User;
  masterFlag?: Publication.MasterFlagEnum;
  mediaCount?: number;
  modifiedBy?: string;
  numVolumes?: string;
  oclc?: string;
  pageCount?: number;
  pageRange?: string;
  parent?: Publication;
  parentMediaStartId?: string;
  parentTitle?: string;
  pcopyFlag?: boolean;
  previewMedia?: Media;
  primaryResource?: Resource;
  pubDateEdtf?: string;
  pubDateMax?: string;
  pubDateMin?: string;
  pubDateText?: string;
  pubDateUseTextFlag?: boolean;
  pubNum?: string;
  pubParentId?: string;
  pubTitle?: string;
  pubType?: Publication.PubTypeEnum;
  pubTypeValue?: string;
  renderedText?: string;
  replacementId?: string;
  series?: string;
  status?: Publication.StatusEnum;
  /** @deprecated */subjects?: Array<IndexingTerm>;
  urlText?: string;
  visibilityFlag?: boolean;
  volume?: string;

  constructor({
      agentSelections, 
      artworks, 
      biblioCat, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      edition, 
      exhibitions, 
      fairUseFlag, 
      fragFlag, 
      /** @deprecated */genres, 
      grandParentTitle, 
      hard, 
      hasOcr, 
      id, 
      ill, 
      illBw, 
      illColor, 
      illFig, 
      illPlate, 
      illustrations, 
      indexingTerms, 
      isbn, 
      issNo, 
      lastModifier, 
      masterFlag, 
      mediaCount, 
      modifiedBy, 
      numVolumes, 
      oclc, 
      pageCount, 
      pageRange, 
      parent, 
      parentMediaStartId, 
      parentTitle, 
      pcopyFlag, 
      previewMedia, 
      primaryResource, 
      pubDateEdtf, 
      pubDateMax, 
      pubDateMin, 
      pubDateText, 
      pubDateUseTextFlag, 
      pubNum, 
      pubParentId, 
      pubTitle, 
      pubType, 
      pubTypeValue, 
      renderedText, 
      replacementId, 
      series, 
      status, 
      /** @deprecated */subjects, 
      urlText, 
      visibilityFlag, 
      volume
  }: {
      agentSelections?: Array<AgentSelection>;
      artworks?: Array<Artwork>;
      biblioCat?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      edition?: string;
      exhibitions?: Array<Exhibition>;
      fairUseFlag?: boolean;
      fragFlag?: boolean;
      /** @deprecated */genres?: Array<IndexingTerm>;
      grandParentTitle?: string;
      hard?: boolean;
      hasOcr?: boolean;
      id?: string;
      ill?: boolean;
      illBw?: boolean;
      illColor?: boolean;
      illFig?: boolean;
      illPlate?: boolean;
      illustrations?: Publication.IllustrationsEnum;
      indexingTerms?: Array<IndexingTerm>;
      isbn?: string;
      issNo?: string;
      lastModifier?: User;
      masterFlag?: Publication.MasterFlagEnum;
      mediaCount?: number;
      modifiedBy?: string;
      numVolumes?: string;
      oclc?: string;
      pageCount?: number;
      pageRange?: string;
      parent?: Publication;
      parentMediaStartId?: string;
      parentTitle?: string;
      pcopyFlag?: boolean;
      previewMedia?: Media;
      primaryResource?: Resource;
      pubDateEdtf?: string;
      pubDateMax?: string;
      pubDateMin?: string;
      pubDateText?: string;
      pubDateUseTextFlag?: boolean;
      pubNum?: string;
      pubParentId?: string;
      pubTitle?: string;
      pubType?: Publication.PubTypeEnum;
      pubTypeValue?: string;
      renderedText?: string;
      replacementId?: string;
      series?: string;
      status?: Publication.StatusEnum;
      /** @deprecated */subjects?: Array<IndexingTerm>;
      urlText?: string;
      visibilityFlag?: boolean;
      volume?: string;
  } = {}) {
    this.agentSelections = agentSelections;
    this.artworks = artworks;
    this.biblioCat = biblioCat;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.edition = edition;
    this.exhibitions = exhibitions;
    this.fairUseFlag = fairUseFlag;
    this.fragFlag = fragFlag;
    this.genres = genres;
    this.grandParentTitle = grandParentTitle;
    this.hard = hard;
    this.hasOcr = hasOcr;
    this.id = id;
    this.ill = ill;
    this.illBw = illBw;
    this.illColor = illColor;
    this.illFig = illFig;
    this.illPlate = illPlate;
    this.illustrations = illustrations;
    this.indexingTerms = indexingTerms;
    this.isbn = isbn;
    this.issNo = issNo;
    this.lastModifier = lastModifier;
    this.masterFlag = masterFlag;
    this.mediaCount = mediaCount;
    this.modifiedBy = modifiedBy;
    this.numVolumes = numVolumes;
    this.oclc = oclc;
    this.pageCount = pageCount;
    this.pageRange = pageRange;
    this.parent = parent;
    this.parentMediaStartId = parentMediaStartId;
    this.parentTitle = parentTitle;
    this.pcopyFlag = pcopyFlag;
    this.previewMedia = previewMedia;
    this.primaryResource = primaryResource;
    this.pubDateEdtf = pubDateEdtf;
    this.pubDateMax = pubDateMax;
    this.pubDateMin = pubDateMin;
    this.pubDateText = pubDateText;
    this.pubDateUseTextFlag = pubDateUseTextFlag;
    this.pubNum = pubNum;
    this.pubParentId = pubParentId;
    this.pubTitle = pubTitle;
    this.pubType = pubType;
    this.pubTypeValue = pubTypeValue;
    this.renderedText = renderedText;
    this.replacementId = replacementId;
    this.series = series;
    this.status = status;
    this.subjects = subjects;
    this.urlText = urlText;
    this.visibilityFlag = visibilityFlag;
    this.volume = volume;
  }
}

export namespace Publication {

  export type IllustrationsEnum = 'color' | 'bw' | 'illustration' | 'unknown' | 'none';
  export const IllustrationsEnum = {
      Color: 'color' as IllustrationsEnum,
      Bw: 'bw' as IllustrationsEnum,
      Illustration: 'illustration' as IllustrationsEnum,
      Unknown: 'unknown' as IllustrationsEnum,
      None: 'none' as IllustrationsEnum,
  }
  export const IllustrationsEnumValues = [
      IllustrationsEnum.Color, 
      IllustrationsEnum.Bw, 
      IllustrationsEnum.Illustration, 
      IllustrationsEnum.Unknown, 
      IllustrationsEnum.None
  ];
  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type PubTypeEnum = 'Archive' | 'Article' | 'Artist Monograph' | 'Book' | 'Book Series' | 'Catalogue Raisonn\xE9' | 'Collection Catalogue' | 'Dissertation' | 'Exhibition Catalogue' | 'Issue' | 'Media' | 'Periodical' | 'Popular Periodical' | 'Professional Periodical' | 'Publication' | 'Sales Catalogue' | 'Web';
  export const PubTypeEnum = {
      Archive: 'Archive' as PubTypeEnum,
      Article: 'Article' as PubTypeEnum,
      ArtistMonograph: 'Artist Monograph' as PubTypeEnum,
      Book: 'Book' as PubTypeEnum,
      BookSeries: 'Book Series' as PubTypeEnum,
      CatalogueRaisonn: 'Catalogue Raisonné' as PubTypeEnum,
      CollectionCatalogue: 'Collection Catalogue' as PubTypeEnum,
      Dissertation: 'Dissertation' as PubTypeEnum,
      ExhibitionCatalogue: 'Exhibition Catalogue' as PubTypeEnum,
      Issue: 'Issue' as PubTypeEnum,
      Media: 'Media' as PubTypeEnum,
      Periodical: 'Periodical' as PubTypeEnum,
      PopularPeriodical: 'Popular Periodical' as PubTypeEnum,
      ProfessionalPeriodical: 'Professional Periodical' as PubTypeEnum,
      Publication: 'Publication' as PubTypeEnum,
      SalesCatalogue: 'Sales Catalogue' as PubTypeEnum,
      Web: 'Web' as PubTypeEnum,
  }
  export const PubTypeEnumValues = [
      PubTypeEnum.Archive, 
      PubTypeEnum.Article, 
      PubTypeEnum.ArtistMonograph, 
      PubTypeEnum.Book, 
      PubTypeEnum.BookSeries, 
      PubTypeEnum.CatalogueRaisonn, 
      PubTypeEnum.CollectionCatalogue, 
      PubTypeEnum.Dissertation, 
      PubTypeEnum.ExhibitionCatalogue, 
      PubTypeEnum.Issue, 
      PubTypeEnum.Media, 
      PubTypeEnum.Periodical, 
      PubTypeEnum.PopularPeriodical, 
      PubTypeEnum.ProfessionalPeriodical, 
      PubTypeEnum.Publication, 
      PubTypeEnum.SalesCatalogue, 
      PubTypeEnum.Web
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * Publication form builder
 *
 */
export class PublicationFormBuilder extends AbstractFormBuilder<PublicationFormBuilder> {

  className = 'Publication';

  abstractFormBuilder!: AbstractFormBuilder<Publication>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): Publication {
    return new Publication();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artworks(
    builder: ArtworkFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artworks', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtworks() {
    super.removeField('artworks');
    return this;
  }

  public biblioCat(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('biblioCat', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutBiblioCat() {
    super.removeField('biblioCat');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public edition(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('edition', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEdition() {
    super.removeField('edition');
    return this;
  }


  public exhibitions(
    builder: ExhibitionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exhibitions', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExhibitions() {
    super.removeField('exhibitions');
    return this;
  }

  public fairUseFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('fairUseFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFairUseFlag() {
    super.removeField('fairUseFlag');
    return this;
  }

  public fragFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('fragFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutFragFlag() {
    super.removeField('fragFlag');
    return this;
  }


  public genres(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('genres', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutGenres() {
    super.removeField('genres');
    return this;
  }

  public grandParentTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('grandParentTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutGrandParentTitle() {
    super.removeField('grandParentTitle');
    return this;
  }

  public hard(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('hard', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHard() {
    super.removeField('hard');
    return this;
  }

  public hasOcr(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('hasOcr', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHasOcr() {
    super.removeField('hasOcr');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public ill(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('ill', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIll() {
    super.removeField('ill');
    return this;
  }

  public illBw(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('illBw', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIllBw() {
    super.removeField('illBw');
    return this;
  }

  public illColor(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('illColor', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIllColor() {
    super.removeField('illColor');
    return this;
  }

  public illFig(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('illFig', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIllFig() {
    super.removeField('illFig');
    return this;
  }

  public illPlate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('illPlate', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIllPlate() {
    super.removeField('illPlate');
    return this;
  }

  public illustrations(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('illustrations', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIllustrations() {
    super.removeField('illustrations');
    return this;
  }


  public indexingTerms(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('indexingTerms', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutIndexingTerms() {
    super.removeField('indexingTerms');
    return this;
  }

  public isbn(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('isbn', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIsbn() {
    super.removeField('isbn');
    return this;
  }

  public issNo(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('issNo', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIssNo() {
    super.removeField('issNo');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public mediaCount(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('mediaCount', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMediaCount() {
    super.removeField('mediaCount');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public numVolumes(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('numVolumes', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutNumVolumes() {
    super.removeField('numVolumes');
    return this;
  }

  public oclc(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('oclc', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOclc() {
    super.removeField('oclc');
    return this;
  }

  public pageCount(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pageCount', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPageCount() {
    super.removeField('pageCount');
    return this;
  }

  public pageRange(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pageRange', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPageRange() {
    super.removeField('pageRange');
    return this;
  }


  public parent(
    builder: PublicationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('parent', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutParent() {
    super.removeField('parent');
    return this;
  }

  public parentMediaStartId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentMediaStartId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentMediaStartId() {
    super.removeField('parentMediaStartId');
    return this;
  }

  public parentTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('parentTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutParentTitle() {
    super.removeField('parentTitle');
    return this;
  }

  public pcopyFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pcopyFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPcopyFlag() {
    super.removeField('pcopyFlag');
    return this;
  }


  public previewMedia(
    builder: MediaFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('previewMedia', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPreviewMedia() {
    super.removeField('previewMedia');
    return this;
  }


  public primaryResource(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('primaryResource', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPrimaryResource() {
    super.removeField('primaryResource');
    return this;
  }

  public pubDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubDateEdtf() {
    super.removeField('pubDateEdtf');
    return this;
  }

  public pubDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubDateMax() {
    super.removeField('pubDateMax');
    return this;
  }

  public pubDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubDateMin() {
    super.removeField('pubDateMin');
    return this;
  }

  public pubDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubDateText', [null, [Validators.maxLength(80), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubDateText() {
    super.removeField('pubDateText');
    return this;
  }

  public pubDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubDateUseTextFlag() {
    super.removeField('pubDateUseTextFlag');
    return this;
  }

  public pubNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubNum() {
    super.removeField('pubNum');
    return this;
  }

  public pubParentId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubParentId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubParentId() {
    super.removeField('pubParentId');
    return this;
  }

  public pubTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubTitle() {
    super.removeField('pubTitle');
    return this;
  }

  public pubType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubType() {
    super.removeField('pubType');
    return this;
  }

  public pubTypeValue(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubTypeValue', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubTypeValue() {
    super.removeField('pubTypeValue');
    return this;
  }

  public renderedText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('renderedText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRenderedText() {
    super.removeField('renderedText');
    return this;
  }

  public replacementId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('replacementId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutReplacementId() {
    super.removeField('replacementId');
    return this;
  }

  public series(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('series', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeries() {
    super.removeField('series');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }


  public subjects(
    builder: IndexingTermFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('subjects', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutSubjects() {
    super.removeField('subjects');
    return this;
  }

  public urlText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('urlText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUrlText() {
    super.removeField('urlText');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }

  public volume(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('volume', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVolume() {
    super.removeField('volume');
    return this;
  }


  public all(): PublicationFormBuilder {
    return this
    .biblioCat() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .edition() 
    .fairUseFlag() 
    .fragFlag() 
    .grandParentTitle() 
    .hard() 
    .hasOcr() 
    .id() 
    .ill() 
    .illBw() 
    .illColor() 
    .illFig() 
    .illPlate() 
    .illustrations() 
    .isbn() 
    .issNo() 
    .masterFlag() 
    .mediaCount() 
    .modifiedBy() 
    .numVolumes() 
    .oclc() 
    .pageCount() 
    .pageRange() 
    .parentMediaStartId() 
    .parentTitle() 
    .pcopyFlag() 
    .pubDateEdtf() 
    .pubDateMax() 
    .pubDateMin() 
    .pubDateText() 
    .pubDateUseTextFlag() 
    .pubNum() 
    .pubParentId() 
    .pubTitle() 
    .pubType() 
    .pubTypeValue() 
    .renderedText() 
    .replacementId() 
    .series() 
    .status() 
    .urlText() 
    .visibilityFlag() 
    .volume() 
;
  }
}

