/* tslint:disable */
/* eslint-disable */
import { AcquisitionInformation, AcquisitionInformationFormBuilder } from '../models/acquisition-information';
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { ArtEventType, ArtEventTypeFormBuilder } from '../models/art-event-type';
import { Artwork, ArtworkFormBuilder } from '../models/artwork';
import { ArtworkEventDetail, ArtworkEventDetailFormBuilder } from '../models/artwork-event-detail';
import { ArtworkTitle, ArtworkTitleFormBuilder } from '../models/artwork-title';
import { Auction, AuctionFormBuilder } from '../models/auction';
import { AuditInformation, AuditInformationFormBuilder } from '../models/audit-information';
import { CataloguingDataEntry, CataloguingDataEntryFormBuilder } from '../models/cataloguing-data-entry';
import { CollectionsReview, CollectionsReviewFormBuilder } from '../models/collections-review';
import { Committee, CommitteeFormBuilder } from '../models/committee';
import { ConditionCheck, ConditionCheckFormBuilder } from '../models/condition-check';
import { Conservation, ConservationFormBuilder } from '../models/conservation';
import { DisposalInformation, DisposalInformationFormBuilder } from '../models/disposal-information';
import { Exhibition, ExhibitionFormBuilder } from '../models/exhibition';
import { ExhibitionVenue, ExhibitionVenueFormBuilder } from '../models/exhibition-venue';
import { ExhibitionVenueArtworkEvent, ExhibitionVenueArtworkEventFormBuilder } from '../models/exhibition-venue-artwork-event';
import { InsuranceInformation, InsuranceInformationFormBuilder } from '../models/insurance-information';
import { Loan, LoanFormBuilder } from '../models/loan';
import { Location, LocationFormBuilder } from '../models/location';
import { Medium, MediumFormBuilder } from '../models/medium';
import { MovementInformation, MovementInformationFormBuilder } from '../models/movement-information';
import { Note, NoteFormBuilder } from '../models/note';
import { ObjectEntry, ObjectEntryFormBuilder } from '../models/object-entry';
import { ObjectExit, ObjectExitFormBuilder } from '../models/object-exit';
import { Price, PriceFormBuilder } from '../models/price';
import { Publication, PublicationFormBuilder } from '../models/publication';
import { Resource, ResourceFormBuilder } from '../models/resource';
import { User, UserFormBuilder } from '../models/user';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ArtworkEvent {
  acquisitionInformation?: AcquisitionInformation;
  agentSelections?: Array<AgentSelection>;
  artEventDetails?: Array<ArtworkEventDetail>;
  artwork?: Artwork;
  auction?: Auction;
  auditInformation?: AuditInformation;
  catEntries?: Array<CataloguingDataEntry>;
  catEntriesGenerated?: Array<CataloguingDataEntry>;
  collectionsReview?: CollectionsReview;
  collectionsReviewAction?: ArtworkEvent.CollectionsReviewActionEnum;
  collectionsReviewCriterion?: ArtworkEvent.CollectionsReviewCriterionEnum;
  collectionsReviewResult?: ArtworkEvent.CollectionsReviewResultEnum;
  committee?: Committee;
  conditionCheck?: ConditionCheck;
  conditionCheckCompletenessStatus?: ArtworkEvent.ConditionCheckCompletenessStatusEnum;
  conditionCheckCompletenessStatusDate?: string;
  conditionCheckCompletenessStatusNote?: string;
  conditionCheckCondition?: string;
  conditionCheckConditionDate?: string;
  conditionCheckConditionNote?: string;
  conditionCheckConservationTreatmentPriority?: ArtworkEvent.ConditionCheckConservationTreatmentPriorityEnum;
  conditionCheckEnvironmentalConditionNote?: string;
  conditionCheckEnvironmentalConditionNoteDate?: string;
  conditionCheckHazard?: string;
  conditionCheckHazardDate?: string;
  conditionCheckHazardsNote?: string;
  conditionCheckNextDate?: string;
  conditionCheckTechnicalAssessment?: string;
  conditionCheckTechnicalAssessmentDate?: string;
  conservation?: Conservation;
  conservationMaterial?: string;
  conservationMethod?: ArtworkEvent.ConservationMethodEnum;
  conservationTreatmentBegin?: string;
  conservationTreatmentEnd?: string;
  createdBy?: string;
  creator?: User;
  dateCreated?: string;
  dateModified?: string;
  detCreationYearEdtf?: string;
  detCreationYearMax?: string;
  detCreationYearMin?: string;
  detCreationYearText?: string;
  detCreationYearUseTextFlag?: boolean;
  detDimDepth?: number;
  detDimDepthIn?: number;
  detDimHeight?: number;
  detDimHeightIn?: number;
  /** @deprecated */detDimMetricIsCalculatedFlag?: boolean;
  detDimShapeText?: string;
  detDimWidth?: number;
  detDimWidthIn?: number;
  /** @deprecated */detTitle?: string;
  /** @deprecated */detTitleDisplayFlag?: boolean;
  disposalInformation?: DisposalInformation;
  evDateEdtf?: string;
  evDateMax?: string;
  evDateMin?: string;
  evDateText?: string;
  evDateUseTextFlag?: boolean;
  eventType?: ArtEventType;
  /** @deprecated */examAdvStatus?: ArtworkEvent.ExamAdvStatusEnum;
  examAdvice?: ArtworkEvent.ExamAdviceEnum;
  /** @deprecated */examCertIssFlag?: boolean;
  /** @deprecated */examDupeCertFlag?: boolean;
  /** @deprecated */examIcrIssFlag?: boolean;
  examInfoOrigin?: ArtworkEvent.ExamInfoOriginEnum;
  /** @deprecated */examIssDateEdtf?: string;
  /** @deprecated */examIssDateMax?: string;
  /** @deprecated */examIssDateMin?: string;
  /** @deprecated */examIssDateText?: string;
  /** @deprecated */examNonInclFlag?: boolean;
  /** @deprecated */examResIssFlag?: boolean;
  examStatus?: ArtworkEvent.ExamStatusEnum;
  /** @deprecated */examWorkView?: boolean;
  exhDispFlag?: boolean;
  /** @deprecated */exhDtlText?: string;
  exhPrintFlag?: boolean;
  exhibition?: Exhibition;
  exhibitionVenue?: ExhibitionVenue;
  highlightFlag?: boolean;
  id?: string;
  insuranceInformation?: InsuranceInformation;
  lastModifier?: User;
  loan?: Loan;
  medium?: Medium;
  modifiedBy?: string;
  movementInformation?: MovementInformation;
  movementTargetLocation?: Location;
  movementTargetLocationFitness?: string;
  movementTargetLocationNote?: string;
  notes?: Array<Note>;
  objectAuditCategory?: ArtworkEvent.ObjectAuditCategoryEnum;
  objectAuditInfoUnit?: ArtworkEvent.ObjectAuditInfoUnitEnum;
  objectAuditResult?: ArtworkEvent.ObjectAuditResultEnum;
  objectEntry?: ObjectEntry;
  objectExit?: ObjectExit;
  objectStatus?: ArtworkEvent.ObjectStatusEnum;
  objectStatusDate?: string;
  orderNum?: string;
  otherIdLabel?: string;
  otherIdPublicFlag?: boolean;
  otherIdValue?: string;
  overrideRendering?: string;
  phnoPublicFlag?: boolean;
  photoNum?: string;
  price?: Array<Price>;
  provCreditLine?: string;
  provDisplayAuctionTitleFlag?: boolean;
  provOutcome?: ArtworkEvent.ProvOutcomeEnum;
  provType?: ArtworkEvent.ProvTypeEnum;
  /** @deprecated */pubTableNum?: string;
  publication?: Publication;
  questionableFlag?: boolean;
  refLink?: string;
  refNum?: string;
  refTitles?: Array<ArtworkTitle>;
  resources?: Array<Resource>;
  seqNum?: number;
  status?: ArtworkEvent.StatusEnum;
  unnumberedFlag?: boolean;
  useOverrideRenderingFlag?: boolean;
  venueEvents?: Array<ExhibitionVenueArtworkEvent>;
  visibilityFlag?: boolean;

  constructor({
      acquisitionInformation, 
      agentSelections, 
      artEventDetails, 
      artwork, 
      auction, 
      auditInformation, 
      catEntries, 
      catEntriesGenerated, 
      collectionsReview, 
      collectionsReviewAction, 
      collectionsReviewCriterion, 
      collectionsReviewResult, 
      committee, 
      conditionCheck, 
      conditionCheckCompletenessStatus, 
      conditionCheckCompletenessStatusDate, 
      conditionCheckCompletenessStatusNote, 
      conditionCheckCondition, 
      conditionCheckConditionDate, 
      conditionCheckConditionNote, 
      conditionCheckConservationTreatmentPriority, 
      conditionCheckEnvironmentalConditionNote, 
      conditionCheckEnvironmentalConditionNoteDate, 
      conditionCheckHazard, 
      conditionCheckHazardDate, 
      conditionCheckHazardsNote, 
      conditionCheckNextDate, 
      conditionCheckTechnicalAssessment, 
      conditionCheckTechnicalAssessmentDate, 
      conservation, 
      conservationMaterial, 
      conservationMethod, 
      conservationTreatmentBegin, 
      conservationTreatmentEnd, 
      createdBy, 
      creator, 
      dateCreated, 
      dateModified, 
      detCreationYearEdtf, 
      detCreationYearMax, 
      detCreationYearMin, 
      detCreationYearText, 
      detCreationYearUseTextFlag, 
      detDimDepth, 
      detDimDepthIn, 
      detDimHeight, 
      detDimHeightIn, 
      /** @deprecated */detDimMetricIsCalculatedFlag, 
      detDimShapeText, 
      detDimWidth, 
      detDimWidthIn, 
      /** @deprecated */detTitle, 
      /** @deprecated */detTitleDisplayFlag, 
      disposalInformation, 
      evDateEdtf, 
      evDateMax, 
      evDateMin, 
      evDateText, 
      evDateUseTextFlag, 
      eventType, 
      /** @deprecated */examAdvStatus, 
      examAdvice, 
      /** @deprecated */examCertIssFlag, 
      /** @deprecated */examDupeCertFlag, 
      /** @deprecated */examIcrIssFlag, 
      examInfoOrigin, 
      /** @deprecated */examIssDateEdtf, 
      /** @deprecated */examIssDateMax, 
      /** @deprecated */examIssDateMin, 
      /** @deprecated */examIssDateText, 
      /** @deprecated */examNonInclFlag, 
      /** @deprecated */examResIssFlag, 
      examStatus, 
      /** @deprecated */examWorkView, 
      exhDispFlag, 
      /** @deprecated */exhDtlText, 
      exhPrintFlag, 
      exhibition, 
      exhibitionVenue, 
      highlightFlag, 
      id, 
      insuranceInformation, 
      lastModifier, 
      loan, 
      medium, 
      modifiedBy, 
      movementInformation, 
      movementTargetLocation, 
      movementTargetLocationFitness, 
      movementTargetLocationNote, 
      notes, 
      objectAuditCategory, 
      objectAuditInfoUnit, 
      objectAuditResult, 
      objectEntry, 
      objectExit, 
      objectStatus, 
      objectStatusDate, 
      orderNum, 
      otherIdLabel, 
      otherIdPublicFlag, 
      otherIdValue, 
      overrideRendering, 
      phnoPublicFlag, 
      photoNum, 
      price, 
      provCreditLine, 
      provDisplayAuctionTitleFlag, 
      provOutcome, 
      provType, 
      /** @deprecated */pubTableNum, 
      publication, 
      questionableFlag, 
      refLink, 
      refNum, 
      refTitles, 
      resources, 
      seqNum, 
      status, 
      unnumberedFlag, 
      useOverrideRenderingFlag, 
      venueEvents, 
      visibilityFlag
  }: {
      acquisitionInformation?: AcquisitionInformation;
      agentSelections?: Array<AgentSelection>;
      artEventDetails?: Array<ArtworkEventDetail>;
      artwork?: Artwork;
      auction?: Auction;
      auditInformation?: AuditInformation;
      catEntries?: Array<CataloguingDataEntry>;
      catEntriesGenerated?: Array<CataloguingDataEntry>;
      collectionsReview?: CollectionsReview;
      collectionsReviewAction?: ArtworkEvent.CollectionsReviewActionEnum;
      collectionsReviewCriterion?: ArtworkEvent.CollectionsReviewCriterionEnum;
      collectionsReviewResult?: ArtworkEvent.CollectionsReviewResultEnum;
      committee?: Committee;
      conditionCheck?: ConditionCheck;
      conditionCheckCompletenessStatus?: ArtworkEvent.ConditionCheckCompletenessStatusEnum;
      conditionCheckCompletenessStatusDate?: string;
      conditionCheckCompletenessStatusNote?: string;
      conditionCheckCondition?: string;
      conditionCheckConditionDate?: string;
      conditionCheckConditionNote?: string;
      conditionCheckConservationTreatmentPriority?: ArtworkEvent.ConditionCheckConservationTreatmentPriorityEnum;
      conditionCheckEnvironmentalConditionNote?: string;
      conditionCheckEnvironmentalConditionNoteDate?: string;
      conditionCheckHazard?: string;
      conditionCheckHazardDate?: string;
      conditionCheckHazardsNote?: string;
      conditionCheckNextDate?: string;
      conditionCheckTechnicalAssessment?: string;
      conditionCheckTechnicalAssessmentDate?: string;
      conservation?: Conservation;
      conservationMaterial?: string;
      conservationMethod?: ArtworkEvent.ConservationMethodEnum;
      conservationTreatmentBegin?: string;
      conservationTreatmentEnd?: string;
      createdBy?: string;
      creator?: User;
      dateCreated?: string;
      dateModified?: string;
      detCreationYearEdtf?: string;
      detCreationYearMax?: string;
      detCreationYearMin?: string;
      detCreationYearText?: string;
      detCreationYearUseTextFlag?: boolean;
      detDimDepth?: number;
      detDimDepthIn?: number;
      detDimHeight?: number;
      detDimHeightIn?: number;
      /** @deprecated */detDimMetricIsCalculatedFlag?: boolean;
      detDimShapeText?: string;
      detDimWidth?: number;
      detDimWidthIn?: number;
      /** @deprecated */detTitle?: string;
      /** @deprecated */detTitleDisplayFlag?: boolean;
      disposalInformation?: DisposalInformation;
      evDateEdtf?: string;
      evDateMax?: string;
      evDateMin?: string;
      evDateText?: string;
      evDateUseTextFlag?: boolean;
      eventType?: ArtEventType;
      /** @deprecated */examAdvStatus?: ArtworkEvent.ExamAdvStatusEnum;
      examAdvice?: ArtworkEvent.ExamAdviceEnum;
      /** @deprecated */examCertIssFlag?: boolean;
      /** @deprecated */examDupeCertFlag?: boolean;
      /** @deprecated */examIcrIssFlag?: boolean;
      examInfoOrigin?: ArtworkEvent.ExamInfoOriginEnum;
      /** @deprecated */examIssDateEdtf?: string;
      /** @deprecated */examIssDateMax?: string;
      /** @deprecated */examIssDateMin?: string;
      /** @deprecated */examIssDateText?: string;
      /** @deprecated */examNonInclFlag?: boolean;
      /** @deprecated */examResIssFlag?: boolean;
      examStatus?: ArtworkEvent.ExamStatusEnum;
      /** @deprecated */examWorkView?: boolean;
      exhDispFlag?: boolean;
      /** @deprecated */exhDtlText?: string;
      exhPrintFlag?: boolean;
      exhibition?: Exhibition;
      exhibitionVenue?: ExhibitionVenue;
      highlightFlag?: boolean;
      id?: string;
      insuranceInformation?: InsuranceInformation;
      lastModifier?: User;
      loan?: Loan;
      medium?: Medium;
      modifiedBy?: string;
      movementInformation?: MovementInformation;
      movementTargetLocation?: Location;
      movementTargetLocationFitness?: string;
      movementTargetLocationNote?: string;
      notes?: Array<Note>;
      objectAuditCategory?: ArtworkEvent.ObjectAuditCategoryEnum;
      objectAuditInfoUnit?: ArtworkEvent.ObjectAuditInfoUnitEnum;
      objectAuditResult?: ArtworkEvent.ObjectAuditResultEnum;
      objectEntry?: ObjectEntry;
      objectExit?: ObjectExit;
      objectStatus?: ArtworkEvent.ObjectStatusEnum;
      objectStatusDate?: string;
      orderNum?: string;
      otherIdLabel?: string;
      otherIdPublicFlag?: boolean;
      otherIdValue?: string;
      overrideRendering?: string;
      phnoPublicFlag?: boolean;
      photoNum?: string;
      price?: Array<Price>;
      provCreditLine?: string;
      provDisplayAuctionTitleFlag?: boolean;
      provOutcome?: ArtworkEvent.ProvOutcomeEnum;
      provType?: ArtworkEvent.ProvTypeEnum;
      /** @deprecated */pubTableNum?: string;
      publication?: Publication;
      questionableFlag?: boolean;
      refLink?: string;
      refNum?: string;
      refTitles?: Array<ArtworkTitle>;
      resources?: Array<Resource>;
      seqNum?: number;
      status?: ArtworkEvent.StatusEnum;
      unnumberedFlag?: boolean;
      useOverrideRenderingFlag?: boolean;
      venueEvents?: Array<ExhibitionVenueArtworkEvent>;
      visibilityFlag?: boolean;
  } = {}) {
    this.acquisitionInformation = acquisitionInformation;
    this.agentSelections = agentSelections;
    this.artEventDetails = artEventDetails;
    this.artwork = artwork;
    this.auction = auction;
    this.auditInformation = auditInformation;
    this.catEntries = catEntries;
    this.catEntriesGenerated = catEntriesGenerated;
    this.collectionsReview = collectionsReview;
    this.collectionsReviewAction = collectionsReviewAction;
    this.collectionsReviewCriterion = collectionsReviewCriterion;
    this.collectionsReviewResult = collectionsReviewResult;
    this.committee = committee;
    this.conditionCheck = conditionCheck;
    this.conditionCheckCompletenessStatus = conditionCheckCompletenessStatus;
    this.conditionCheckCompletenessStatusDate = conditionCheckCompletenessStatusDate;
    this.conditionCheckCompletenessStatusNote = conditionCheckCompletenessStatusNote;
    this.conditionCheckCondition = conditionCheckCondition;
    this.conditionCheckConditionDate = conditionCheckConditionDate;
    this.conditionCheckConditionNote = conditionCheckConditionNote;
    this.conditionCheckConservationTreatmentPriority = conditionCheckConservationTreatmentPriority;
    this.conditionCheckEnvironmentalConditionNote = conditionCheckEnvironmentalConditionNote;
    this.conditionCheckEnvironmentalConditionNoteDate = conditionCheckEnvironmentalConditionNoteDate;
    this.conditionCheckHazard = conditionCheckHazard;
    this.conditionCheckHazardDate = conditionCheckHazardDate;
    this.conditionCheckHazardsNote = conditionCheckHazardsNote;
    this.conditionCheckNextDate = conditionCheckNextDate;
    this.conditionCheckTechnicalAssessment = conditionCheckTechnicalAssessment;
    this.conditionCheckTechnicalAssessmentDate = conditionCheckTechnicalAssessmentDate;
    this.conservation = conservation;
    this.conservationMaterial = conservationMaterial;
    this.conservationMethod = conservationMethod;
    this.conservationTreatmentBegin = conservationTreatmentBegin;
    this.conservationTreatmentEnd = conservationTreatmentEnd;
    this.createdBy = createdBy;
    this.creator = creator;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.detCreationYearEdtf = detCreationYearEdtf;
    this.detCreationYearMax = detCreationYearMax;
    this.detCreationYearMin = detCreationYearMin;
    this.detCreationYearText = detCreationYearText;
    this.detCreationYearUseTextFlag = detCreationYearUseTextFlag;
    this.detDimDepth = detDimDepth;
    this.detDimDepthIn = detDimDepthIn;
    this.detDimHeight = detDimHeight;
    this.detDimHeightIn = detDimHeightIn;
    this.detDimMetricIsCalculatedFlag = detDimMetricIsCalculatedFlag;
    this.detDimShapeText = detDimShapeText;
    this.detDimWidth = detDimWidth;
    this.detDimWidthIn = detDimWidthIn;
    this.detTitle = detTitle;
    this.detTitleDisplayFlag = detTitleDisplayFlag;
    this.disposalInformation = disposalInformation;
    this.evDateEdtf = evDateEdtf;
    this.evDateMax = evDateMax;
    this.evDateMin = evDateMin;
    this.evDateText = evDateText;
    this.evDateUseTextFlag = evDateUseTextFlag;
    this.eventType = eventType;
    this.examAdvStatus = examAdvStatus;
    this.examAdvice = examAdvice;
    this.examCertIssFlag = examCertIssFlag;
    this.examDupeCertFlag = examDupeCertFlag;
    this.examIcrIssFlag = examIcrIssFlag;
    this.examInfoOrigin = examInfoOrigin;
    this.examIssDateEdtf = examIssDateEdtf;
    this.examIssDateMax = examIssDateMax;
    this.examIssDateMin = examIssDateMin;
    this.examIssDateText = examIssDateText;
    this.examNonInclFlag = examNonInclFlag;
    this.examResIssFlag = examResIssFlag;
    this.examStatus = examStatus;
    this.examWorkView = examWorkView;
    this.exhDispFlag = exhDispFlag;
    this.exhDtlText = exhDtlText;
    this.exhPrintFlag = exhPrintFlag;
    this.exhibition = exhibition;
    this.exhibitionVenue = exhibitionVenue;
    this.highlightFlag = highlightFlag;
    this.id = id;
    this.insuranceInformation = insuranceInformation;
    this.lastModifier = lastModifier;
    this.loan = loan;
    this.medium = medium;
    this.modifiedBy = modifiedBy;
    this.movementInformation = movementInformation;
    this.movementTargetLocation = movementTargetLocation;
    this.movementTargetLocationFitness = movementTargetLocationFitness;
    this.movementTargetLocationNote = movementTargetLocationNote;
    this.notes = notes;
    this.objectAuditCategory = objectAuditCategory;
    this.objectAuditInfoUnit = objectAuditInfoUnit;
    this.objectAuditResult = objectAuditResult;
    this.objectEntry = objectEntry;
    this.objectExit = objectExit;
    this.objectStatus = objectStatus;
    this.objectStatusDate = objectStatusDate;
    this.orderNum = orderNum;
    this.otherIdLabel = otherIdLabel;
    this.otherIdPublicFlag = otherIdPublicFlag;
    this.otherIdValue = otherIdValue;
    this.overrideRendering = overrideRendering;
    this.phnoPublicFlag = phnoPublicFlag;
    this.photoNum = photoNum;
    this.price = price;
    this.provCreditLine = provCreditLine;
    this.provDisplayAuctionTitleFlag = provDisplayAuctionTitleFlag;
    this.provOutcome = provOutcome;
    this.provType = provType;
    this.pubTableNum = pubTableNum;
    this.publication = publication;
    this.questionableFlag = questionableFlag;
    this.refLink = refLink;
    this.refNum = refNum;
    this.refTitles = refTitles;
    this.resources = resources;
    this.seqNum = seqNum;
    this.status = status;
    this.unnumberedFlag = unnumberedFlag;
    this.useOverrideRenderingFlag = useOverrideRenderingFlag;
    this.venueEvents = venueEvents;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace ArtworkEvent {

  export type CollectionsReviewActionEnum = 'Consider for disposal' | 'Conservation work needed' | 'Research needed';
  export const CollectionsReviewActionEnum = {
      ConsiderForDisposal: 'Consider for disposal' as CollectionsReviewActionEnum,
      ConservationWorkNeeded: 'Conservation work needed' as CollectionsReviewActionEnum,
      ResearchNeeded: 'Research needed' as CollectionsReviewActionEnum,
  }
  export const CollectionsReviewActionEnumValues = [
      CollectionsReviewActionEnum.ConsiderForDisposal, 
      CollectionsReviewActionEnum.ConservationWorkNeeded, 
      CollectionsReviewActionEnum.ResearchNeeded
  ];
  export type CollectionsReviewCriterionEnum = 'relevant to collecting policy' | 'historical significance' | 'display potential' | 'educational potential' | 'research potential' | 'documentation status' | 'condition' | 'duplicate';
  export const CollectionsReviewCriterionEnum = {
      RelevantToCollectingPolicy: 'relevant to collecting policy' as CollectionsReviewCriterionEnum,
      HistoricalSignificance: 'historical significance' as CollectionsReviewCriterionEnum,
      DisplayPotential: 'display potential' as CollectionsReviewCriterionEnum,
      EducationalPotential: 'educational potential' as CollectionsReviewCriterionEnum,
      ResearchPotential: 'research potential' as CollectionsReviewCriterionEnum,
      DocumentationStatus: 'documentation status' as CollectionsReviewCriterionEnum,
      Condition: 'condition' as CollectionsReviewCriterionEnum,
      Duplicate: 'duplicate' as CollectionsReviewCriterionEnum,
  }
  export const CollectionsReviewCriterionEnumValues = [
      CollectionsReviewCriterionEnum.RelevantToCollectingPolicy, 
      CollectionsReviewCriterionEnum.HistoricalSignificance, 
      CollectionsReviewCriterionEnum.DisplayPotential, 
      CollectionsReviewCriterionEnum.EducationalPotential, 
      CollectionsReviewCriterionEnum.ResearchPotential, 
      CollectionsReviewCriterionEnum.DocumentationStatus, 
      CollectionsReviewCriterionEnum.Condition, 
      CollectionsReviewCriterionEnum.Duplicate
  ];
  export type CollectionsReviewResultEnum = 'poor' | 'good' | 'yes' | 'no';
  export const CollectionsReviewResultEnum = {
      Poor: 'poor' as CollectionsReviewResultEnum,
      Good: 'good' as CollectionsReviewResultEnum,
      Yes: 'yes' as CollectionsReviewResultEnum,
      No: 'no' as CollectionsReviewResultEnum,
  }
  export const CollectionsReviewResultEnumValues = [
      CollectionsReviewResultEnum.Poor, 
      CollectionsReviewResultEnum.Good, 
      CollectionsReviewResultEnum.Yes, 
      CollectionsReviewResultEnum.No
  ];
  export type ConditionCheckCompletenessStatusEnum = 'To Do' | 'Pending' | 'In-Progress' | 'Complete';
  export const ConditionCheckCompletenessStatusEnum = {
      ToDo: 'To Do' as ConditionCheckCompletenessStatusEnum,
      Pending: 'Pending' as ConditionCheckCompletenessStatusEnum,
      InProgress: 'In-Progress' as ConditionCheckCompletenessStatusEnum,
      Complete: 'Complete' as ConditionCheckCompletenessStatusEnum,
  }
  export const ConditionCheckCompletenessStatusEnumValues = [
      ConditionCheckCompletenessStatusEnum.ToDo, 
      ConditionCheckCompletenessStatusEnum.Pending, 
      ConditionCheckCompletenessStatusEnum.InProgress, 
      ConditionCheckCompletenessStatusEnum.Complete
  ];
  export type ConditionCheckConservationTreatmentPriorityEnum = 'Urgent' | 'High' | 'Medium' | 'Low';
  export const ConditionCheckConservationTreatmentPriorityEnum = {
      Urgent: 'Urgent' as ConditionCheckConservationTreatmentPriorityEnum,
      High: 'High' as ConditionCheckConservationTreatmentPriorityEnum,
      Medium: 'Medium' as ConditionCheckConservationTreatmentPriorityEnum,
      Low: 'Low' as ConditionCheckConservationTreatmentPriorityEnum,
  }
  export const ConditionCheckConservationTreatmentPriorityEnumValues = [
      ConditionCheckConservationTreatmentPriorityEnum.Urgent, 
      ConditionCheckConservationTreatmentPriorityEnum.High, 
      ConditionCheckConservationTreatmentPriorityEnum.Medium, 
      ConditionCheckConservationTreatmentPriorityEnum.Low
  ];
  export type ConservationMethodEnum = 'cleaned' | 'relined' | 'rehoused';
  export const ConservationMethodEnum = {
      Cleaned: 'cleaned' as ConservationMethodEnum,
      Relined: 'relined' as ConservationMethodEnum,
      Rehoused: 'rehoused' as ConservationMethodEnum,
  }
  export const ConservationMethodEnumValues = [
      ConservationMethodEnum.Cleaned, 
      ConservationMethodEnum.Relined, 
      ConservationMethodEnum.Rehoused
  ];
  export type ExamAdvStatusEnum = 'no determination' | 'pending' | 'accepted' | 'refused';
  export const ExamAdvStatusEnum = {
      NoDetermination: 'no determination' as ExamAdvStatusEnum,
      Pending: 'pending' as ExamAdvStatusEnum,
      Accepted: 'accepted' as ExamAdvStatusEnum,
      Refused: 'refused' as ExamAdvStatusEnum,
  }
  export const ExamAdvStatusEnumValues = [
      ExamAdvStatusEnum.NoDetermination, 
      ExamAdvStatusEnum.Pending, 
      ExamAdvStatusEnum.Accepted, 
      ExamAdvStatusEnum.Refused
  ];
  export type ExamAdviceEnum = 'no determination' | 'pending' | 'accepted' | 'refused';
  export const ExamAdviceEnum = {
      NoDetermination: 'no determination' as ExamAdviceEnum,
      Pending: 'pending' as ExamAdviceEnum,
      Accepted: 'accepted' as ExamAdviceEnum,
      Refused: 'refused' as ExamAdviceEnum,
  }
  export const ExamAdviceEnumValues = [
      ExamAdviceEnum.NoDetermination, 
      ExamAdviceEnum.Pending, 
      ExamAdviceEnum.Accepted, 
      ExamAdviceEnum.Refused
  ];
  export type ExamInfoOriginEnum = 'presenter' | 'committee' | 'presenter (edited by committee)';
  export const ExamInfoOriginEnum = {
      Presenter: 'presenter' as ExamInfoOriginEnum,
      Committee: 'committee' as ExamInfoOriginEnum,
      PresenterEditedByCommittee: 'presenter (edited by committee)' as ExamInfoOriginEnum,
  }
  export const ExamInfoOriginEnumValues = [
      ExamInfoOriginEnum.Presenter, 
      ExamInfoOriginEnum.Committee, 
      ExamInfoOriginEnum.PresenterEditedByCommittee
  ];
  export type ExamStatusEnum = 'examined by committee' | 'verified without examination';
  export const ExamStatusEnum = {
      ExaminedByCommittee: 'examined by committee' as ExamStatusEnum,
      VerifiedWithoutExamination: 'verified without examination' as ExamStatusEnum,
  }
  export const ExamStatusEnumValues = [
      ExamStatusEnum.ExaminedByCommittee, 
      ExamStatusEnum.VerifiedWithoutExamination
  ];
  export type ObjectAuditCategoryEnum = 'Urgent' | 'High' | 'Medium' | 'Low';
  export const ObjectAuditCategoryEnum = {
      Urgent: 'Urgent' as ObjectAuditCategoryEnum,
      High: 'High' as ObjectAuditCategoryEnum,
      Medium: 'Medium' as ObjectAuditCategoryEnum,
      Low: 'Low' as ObjectAuditCategoryEnum,
  }
  export const ObjectAuditCategoryEnumValues = [
      ObjectAuditCategoryEnum.Urgent, 
      ObjectAuditCategoryEnum.High, 
      ObjectAuditCategoryEnum.Medium, 
      ObjectAuditCategoryEnum.Low
  ];
  export type ObjectAuditInfoUnitEnum = 'Title' | 'Medium' | 'Dimensions' | 'Inscriptions';
  export const ObjectAuditInfoUnitEnum = {
      Title: 'Title' as ObjectAuditInfoUnitEnum,
      Medium: 'Medium' as ObjectAuditInfoUnitEnum,
      Dimensions: 'Dimensions' as ObjectAuditInfoUnitEnum,
      Inscriptions: 'Inscriptions' as ObjectAuditInfoUnitEnum,
  }
  export const ObjectAuditInfoUnitEnumValues = [
      ObjectAuditInfoUnitEnum.Title, 
      ObjectAuditInfoUnitEnum.Medium, 
      ObjectAuditInfoUnitEnum.Dimensions, 
      ObjectAuditInfoUnitEnum.Inscriptions
  ];
  export type ObjectAuditResultEnum = 'Present' | 'Missing' | 'Lost';
  export const ObjectAuditResultEnum = {
      Present: 'Present' as ObjectAuditResultEnum,
      Missing: 'Missing' as ObjectAuditResultEnum,
      Lost: 'Lost' as ObjectAuditResultEnum,
  }
  export const ObjectAuditResultEnumValues = [
      ObjectAuditResultEnum.Present, 
      ObjectAuditResultEnum.Missing, 
      ObjectAuditResultEnum.Lost
  ];
  export type ObjectStatusEnum = 'Requested' | 'Authorised' | 'Agreed' | 'Conservation' | 'Sent' | 'Photography requested' | 'Refused' | 'Returned';
  export const ObjectStatusEnum = {
      Requested: 'Requested' as ObjectStatusEnum,
      Authorised: 'Authorised' as ObjectStatusEnum,
      Agreed: 'Agreed' as ObjectStatusEnum,
      Conservation: 'Conservation' as ObjectStatusEnum,
      Sent: 'Sent' as ObjectStatusEnum,
      PhotographyRequested: 'Photography requested' as ObjectStatusEnum,
      Refused: 'Refused' as ObjectStatusEnum,
      Returned: 'Returned' as ObjectStatusEnum,
  }
  export const ObjectStatusEnumValues = [
      ObjectStatusEnum.Requested, 
      ObjectStatusEnum.Authorised, 
      ObjectStatusEnum.Agreed, 
      ObjectStatusEnum.Conservation, 
      ObjectStatusEnum.Sent, 
      ObjectStatusEnum.PhotographyRequested, 
      ObjectStatusEnum.Refused, 
      ObjectStatusEnum.Returned
  ];
  export type ProvOutcomeEnum = 'Bought-in' | 'Canceled' | 'Sold' | 'Unknown' | 'Withdrawn';
  export const ProvOutcomeEnum = {
      BoughtIn: 'Bought-in' as ProvOutcomeEnum,
      Canceled: 'Canceled' as ProvOutcomeEnum,
      Sold: 'Sold' as ProvOutcomeEnum,
      Unknown: 'Unknown' as ProvOutcomeEnum,
      Withdrawn: 'Withdrawn' as ProvOutcomeEnum,
  }
  export const ProvOutcomeEnumValues = [
      ProvOutcomeEnum.BoughtIn, 
      ProvOutcomeEnum.Canceled, 
      ProvOutcomeEnum.Sold, 
      ProvOutcomeEnum.Unknown, 
      ProvOutcomeEnum.Withdrawn
  ];
  export type ProvTypeEnum = 'Acquisition' | 'Artist Studio' | 'Bequest' | 'By descent' | 'By exchange' | 'Commissioned' | 'Confiscation' | 'Consigned' | 'Destroyed' | 'Donation' | 'Found' | 'Forced sale' | 'Fractional gift' | 'Gift' | 'In Possession' | 'Inherited' | 'Location unknown' | 'Looting' | 'On deposit' | 'On loan' | 'Partial gift' | 'Promised gift' | 'Received in payment' | 'Return' | 'Restitution' | 'Sale' | 'Seized' | 'Sous r\xE9serve d\u2019usufruit' | 'Stolen' | 'Stored' | 'Transfer';
  export const ProvTypeEnum = {
      Acquisition: 'Acquisition' as ProvTypeEnum,
      ArtistStudio: 'Artist Studio' as ProvTypeEnum,
      Bequest: 'Bequest' as ProvTypeEnum,
      ByDescent: 'By descent' as ProvTypeEnum,
      ByExchange: 'By exchange' as ProvTypeEnum,
      Commissioned: 'Commissioned' as ProvTypeEnum,
      Confiscation: 'Confiscation' as ProvTypeEnum,
      Consigned: 'Consigned' as ProvTypeEnum,
      Destroyed: 'Destroyed' as ProvTypeEnum,
      Donation: 'Donation' as ProvTypeEnum,
      Found: 'Found' as ProvTypeEnum,
      ForcedSale: 'Forced sale' as ProvTypeEnum,
      FractionalGift: 'Fractional gift' as ProvTypeEnum,
      Gift: 'Gift' as ProvTypeEnum,
      InPossession: 'In Possession' as ProvTypeEnum,
      Inherited: 'Inherited' as ProvTypeEnum,
      LocationUnknown: 'Location unknown' as ProvTypeEnum,
      Looting: 'Looting' as ProvTypeEnum,
      OnDeposit: 'On deposit' as ProvTypeEnum,
      OnLoan: 'On loan' as ProvTypeEnum,
      PartialGift: 'Partial gift' as ProvTypeEnum,
      PromisedGift: 'Promised gift' as ProvTypeEnum,
      ReceivedInPayment: 'Received in payment' as ProvTypeEnum,
      Return: 'Return' as ProvTypeEnum,
      Restitution: 'Restitution' as ProvTypeEnum,
      Sale: 'Sale' as ProvTypeEnum,
      Seized: 'Seized' as ProvTypeEnum,
      SousRserveDusufruit: 'Sous réserve d’usufruit' as ProvTypeEnum,
      Stolen: 'Stolen' as ProvTypeEnum,
      Stored: 'Stored' as ProvTypeEnum,
      Transfer: 'Transfer' as ProvTypeEnum,
  }
  export const ProvTypeEnumValues = [
      ProvTypeEnum.Acquisition, 
      ProvTypeEnum.ArtistStudio, 
      ProvTypeEnum.Bequest, 
      ProvTypeEnum.ByDescent, 
      ProvTypeEnum.ByExchange, 
      ProvTypeEnum.Commissioned, 
      ProvTypeEnum.Confiscation, 
      ProvTypeEnum.Consigned, 
      ProvTypeEnum.Destroyed, 
      ProvTypeEnum.Donation, 
      ProvTypeEnum.Found, 
      ProvTypeEnum.ForcedSale, 
      ProvTypeEnum.FractionalGift, 
      ProvTypeEnum.Gift, 
      ProvTypeEnum.InPossession, 
      ProvTypeEnum.Inherited, 
      ProvTypeEnum.LocationUnknown, 
      ProvTypeEnum.Looting, 
      ProvTypeEnum.OnDeposit, 
      ProvTypeEnum.OnLoan, 
      ProvTypeEnum.PartialGift, 
      ProvTypeEnum.PromisedGift, 
      ProvTypeEnum.ReceivedInPayment, 
      ProvTypeEnum.Return, 
      ProvTypeEnum.Restitution, 
      ProvTypeEnum.Sale, 
      ProvTypeEnum.Seized, 
      ProvTypeEnum.SousRserveDusufruit, 
      ProvTypeEnum.Stolen, 
      ProvTypeEnum.Stored, 
      ProvTypeEnum.Transfer
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * ArtworkEvent form builder
 *
 */
export class ArtworkEventFormBuilder extends AbstractFormBuilder<ArtworkEventFormBuilder> {

  className = 'ArtworkEvent';

  abstractFormBuilder!: AbstractFormBuilder<ArtworkEvent>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ArtworkEvent {
    return new ArtworkEvent();
  }


  public acquisitionInformation(
    builder: AcquisitionInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('acquisitionInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAcquisitionInformation() {
    super.removeField('acquisitionInformation');
    return this;
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }


  public artEventDetails(
    builder: ArtworkEventDetailFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artEventDetails', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtEventDetails() {
    super.removeField('artEventDetails');
    return this;
  }


  public artwork(
    builder: ArtworkFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artwork', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtwork() {
    super.removeField('artwork');
    return this;
  }


  public auction(
    builder: AuctionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('auction', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAuction() {
    super.removeField('auction');
    return this;
  }


  public auditInformation(
    builder: AuditInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('auditInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAuditInformation() {
    super.removeField('auditInformation');
    return this;
  }


  public catEntries(
    builder: CataloguingDataEntryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('catEntries', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCatEntries() {
    super.removeField('catEntries');
    return this;
  }


  public catEntriesGenerated(
    builder: CataloguingDataEntryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('catEntriesGenerated', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCatEntriesGenerated() {
    super.removeField('catEntriesGenerated');
    return this;
  }


  public collectionsReview(
    builder: CollectionsReviewFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('collectionsReview', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCollectionsReview() {
    super.removeField('collectionsReview');
    return this;
  }

  public collectionsReviewAction(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewAction', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewAction() {
    super.removeField('collectionsReviewAction');
    return this;
  }

  public collectionsReviewCriterion(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewCriterion', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewCriterion() {
    super.removeField('collectionsReviewCriterion');
    return this;
  }

  public collectionsReviewResult(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('collectionsReviewResult', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCollectionsReviewResult() {
    super.removeField('collectionsReviewResult');
    return this;
  }


  public committee(
    builder: CommitteeFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('committee', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCommittee() {
    super.removeField('committee');
    return this;
  }


  public conditionCheck(
    builder: ConditionCheckFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conditionCheck', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConditionCheck() {
    super.removeField('conditionCheck');
    return this;
  }

  public conditionCheckCompletenessStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckCompletenessStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckCompletenessStatus() {
    super.removeField('conditionCheckCompletenessStatus');
    return this;
  }

  public conditionCheckCompletenessStatusDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckCompletenessStatusDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckCompletenessStatusDate() {
    super.removeField('conditionCheckCompletenessStatusDate');
    return this;
  }

  public conditionCheckCompletenessStatusNote(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckCompletenessStatusNote', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckCompletenessStatusNote() {
    super.removeField('conditionCheckCompletenessStatusNote');
    return this;
  }

  public conditionCheckCondition(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckCondition', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckCondition() {
    super.removeField('conditionCheckCondition');
    return this;
  }

  public conditionCheckConditionDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckConditionDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckConditionDate() {
    super.removeField('conditionCheckConditionDate');
    return this;
  }

  public conditionCheckConditionNote(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckConditionNote', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckConditionNote() {
    super.removeField('conditionCheckConditionNote');
    return this;
  }

  public conditionCheckConservationTreatmentPriority(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckConservationTreatmentPriority', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckConservationTreatmentPriority() {
    super.removeField('conditionCheckConservationTreatmentPriority');
    return this;
  }

  public conditionCheckEnvironmentalConditionNote(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckEnvironmentalConditionNote', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckEnvironmentalConditionNote() {
    super.removeField('conditionCheckEnvironmentalConditionNote');
    return this;
  }

  public conditionCheckEnvironmentalConditionNoteDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckEnvironmentalConditionNoteDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckEnvironmentalConditionNoteDate() {
    super.removeField('conditionCheckEnvironmentalConditionNoteDate');
    return this;
  }

  public conditionCheckHazard(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckHazard', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckHazard() {
    super.removeField('conditionCheckHazard');
    return this;
  }

  public conditionCheckHazardDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckHazardDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckHazardDate() {
    super.removeField('conditionCheckHazardDate');
    return this;
  }

  public conditionCheckHazardsNote(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckHazardsNote', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckHazardsNote() {
    super.removeField('conditionCheckHazardsNote');
    return this;
  }

  public conditionCheckNextDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckNextDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckNextDate() {
    super.removeField('conditionCheckNextDate');
    return this;
  }

  public conditionCheckTechnicalAssessment(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckTechnicalAssessment', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckTechnicalAssessment() {
    super.removeField('conditionCheckTechnicalAssessment');
    return this;
  }

  public conditionCheckTechnicalAssessmentDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conditionCheckTechnicalAssessmentDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConditionCheckTechnicalAssessmentDate() {
    super.removeField('conditionCheckTechnicalAssessmentDate');
    return this;
  }


  public conservation(
    builder: ConservationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('conservation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutConservation() {
    super.removeField('conservation');
    return this;
  }

  public conservationMaterial(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conservationMaterial', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConservationMaterial() {
    super.removeField('conservationMaterial');
    return this;
  }

  public conservationMethod(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conservationMethod', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConservationMethod() {
    super.removeField('conservationMethod');
    return this;
  }

  public conservationTreatmentBegin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conservationTreatmentBegin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConservationTreatmentBegin() {
    super.removeField('conservationTreatmentBegin');
    return this;
  }

  public conservationTreatmentEnd(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('conservationTreatmentEnd', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutConservationTreatmentEnd() {
    super.removeField('conservationTreatmentEnd');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public creator(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('creator', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutCreator() {
    super.removeField('creator');
    return this;
  }

  public dateCreated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateCreated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public dateModified(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('dateModified', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDateModified() {
    super.removeField('dateModified');
    return this;
  }

  public detCreationYearEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearEdtf() {
    super.removeField('detCreationYearEdtf');
    return this;
  }

  public detCreationYearMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearMax() {
    super.removeField('detCreationYearMax');
    return this;
  }

  public detCreationYearMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearMin() {
    super.removeField('detCreationYearMin');
    return this;
  }

  public detCreationYearText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearText() {
    super.removeField('detCreationYearText');
    return this;
  }

  public detCreationYearUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detCreationYearUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetCreationYearUseTextFlag() {
    super.removeField('detCreationYearUseTextFlag');
    return this;
  }

  public detDimDepth(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimDepth', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimDepth() {
    super.removeField('detDimDepth');
    return this;
  }

  public detDimDepthIn(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimDepthIn', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimDepthIn() {
    super.removeField('detDimDepthIn');
    return this;
  }

  public detDimHeight(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimHeight', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimHeight() {
    super.removeField('detDimHeight');
    return this;
  }

  public detDimHeightIn(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimHeightIn', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimHeightIn() {
    super.removeField('detDimHeightIn');
    return this;
  }

  public detDimMetricIsCalculatedFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimMetricIsCalculatedFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimMetricIsCalculatedFlag() {
    super.removeField('detDimMetricIsCalculatedFlag');
    return this;
  }

  public detDimShapeText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimShapeText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimShapeText() {
    super.removeField('detDimShapeText');
    return this;
  }

  public detDimWidth(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimWidth', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimWidth() {
    super.removeField('detDimWidth');
    return this;
  }

  public detDimWidthIn(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detDimWidthIn', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetDimWidthIn() {
    super.removeField('detDimWidthIn');
    return this;
  }

  public detTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detTitle', [null, [Validators.maxLength(240), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetTitle() {
    super.removeField('detTitle');
    return this;
  }

  public detTitleDisplayFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('detTitleDisplayFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDetTitleDisplayFlag() {
    super.removeField('detTitleDisplayFlag');
    return this;
  }


  public disposalInformation(
    builder: DisposalInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('disposalInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDisposalInformation() {
    super.removeField('disposalInformation');
    return this;
  }

  public evDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('evDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEvDateEdtf() {
    super.removeField('evDateEdtf');
    return this;
  }

  public evDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('evDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEvDateMax() {
    super.removeField('evDateMax');
    return this;
  }

  public evDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('evDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEvDateMin() {
    super.removeField('evDateMin');
    return this;
  }

  public evDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('evDateText', [null, [Validators.maxLength(80), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEvDateText() {
    super.removeField('evDateText');
    return this;
  }

  public evDateUseTextFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('evDateUseTextFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEvDateUseTextFlag() {
    super.removeField('evDateUseTextFlag');
    return this;
  }


  public eventType(
    builder: ArtEventTypeFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('eventType', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutEventType() {
    super.removeField('eventType');
    return this;
  }

  public examAdvStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examAdvStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamAdvStatus() {
    super.removeField('examAdvStatus');
    return this;
  }

  public examAdvice(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examAdvice', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamAdvice() {
    super.removeField('examAdvice');
    return this;
  }

  public examCertIssFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examCertIssFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamCertIssFlag() {
    super.removeField('examCertIssFlag');
    return this;
  }

  public examDupeCertFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examDupeCertFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamDupeCertFlag() {
    super.removeField('examDupeCertFlag');
    return this;
  }

  public examIcrIssFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examIcrIssFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamIcrIssFlag() {
    super.removeField('examIcrIssFlag');
    return this;
  }

  public examInfoOrigin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examInfoOrigin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamInfoOrigin() {
    super.removeField('examInfoOrigin');
    return this;
  }

  public examIssDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examIssDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamIssDateEdtf() {
    super.removeField('examIssDateEdtf');
    return this;
  }

  public examIssDateMax(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examIssDateMax', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamIssDateMax() {
    super.removeField('examIssDateMax');
    return this;
  }

  public examIssDateMin(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examIssDateMin', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamIssDateMin() {
    super.removeField('examIssDateMin');
    return this;
  }

  public examIssDateText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examIssDateText', [null, [Validators.maxLength(80), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamIssDateText() {
    super.removeField('examIssDateText');
    return this;
  }

  public examNonInclFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examNonInclFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamNonInclFlag() {
    super.removeField('examNonInclFlag');
    return this;
  }

  public examResIssFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examResIssFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamResIssFlag() {
    super.removeField('examResIssFlag');
    return this;
  }

  public examStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamStatus() {
    super.removeField('examStatus');
    return this;
  }

  public examWorkView(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('examWorkView', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExamWorkView() {
    super.removeField('examWorkView');
    return this;
  }

  public exhDispFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhDispFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhDispFlag() {
    super.removeField('exhDispFlag');
    return this;
  }

  public exhDtlText(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhDtlText', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhDtlText() {
    super.removeField('exhDtlText');
    return this;
  }

  public exhPrintFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhPrintFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhPrintFlag() {
    super.removeField('exhPrintFlag');
    return this;
  }


  public exhibition(
    builder: ExhibitionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exhibition', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExhibition() {
    super.removeField('exhibition');
    return this;
  }


  public exhibitionVenue(
    builder: ExhibitionVenueFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exhibitionVenue', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExhibitionVenue() {
    super.removeField('exhibitionVenue');
    return this;
  }

  public highlightFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('highlightFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHighlightFlag() {
    super.removeField('highlightFlag');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public insuranceInformation(
    builder: InsuranceInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('insuranceInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutInsuranceInformation() {
    super.removeField('insuranceInformation');
    return this;
  }


  public lastModifier(
    builder: UserFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('lastModifier', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLastModifier() {
    super.removeField('lastModifier');
    return this;
  }


  public loan(
    builder: LoanFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('loan', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLoan() {
    super.removeField('loan');
    return this;
  }


  public medium(
    builder: MediumFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('medium', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMedium() {
    super.removeField('medium');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }


  public movementInformation(
    builder: MovementInformationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('movementInformation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMovementInformation() {
    super.removeField('movementInformation');
    return this;
  }


  public movementTargetLocation(
    builder: LocationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('movementTargetLocation', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMovementTargetLocation() {
    super.removeField('movementTargetLocation');
    return this;
  }

  public movementTargetLocationFitness(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('movementTargetLocationFitness', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMovementTargetLocationFitness() {
    super.removeField('movementTargetLocationFitness');
    return this;
  }

  public movementTargetLocationNote(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('movementTargetLocationNote', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMovementTargetLocationNote() {
    super.removeField('movementTargetLocationNote');
    return this;
  }


  public notes(
    builder: NoteFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('notes', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutNotes() {
    super.removeField('notes');
    return this;
  }

  public objectAuditCategory(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('objectAuditCategory', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutObjectAuditCategory() {
    super.removeField('objectAuditCategory');
    return this;
  }

  public objectAuditInfoUnit(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('objectAuditInfoUnit', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutObjectAuditInfoUnit() {
    super.removeField('objectAuditInfoUnit');
    return this;
  }

  public objectAuditResult(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('objectAuditResult', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutObjectAuditResult() {
    super.removeField('objectAuditResult');
    return this;
  }


  public objectEntry(
    builder: ObjectEntryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('objectEntry', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutObjectEntry() {
    super.removeField('objectEntry');
    return this;
  }


  public objectExit(
    builder: ObjectExitFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('objectExit', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutObjectExit() {
    super.removeField('objectExit');
    return this;
  }

  public objectStatus(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('objectStatus', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutObjectStatus() {
    super.removeField('objectStatus');
    return this;
  }

  public objectStatusDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('objectStatusDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutObjectStatusDate() {
    super.removeField('objectStatusDate');
    return this;
  }

  public orderNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('orderNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOrderNum() {
    super.removeField('orderNum');
    return this;
  }

  public otherIdLabel(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('otherIdLabel', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOtherIdLabel() {
    super.removeField('otherIdLabel');
    return this;
  }

  public otherIdPublicFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('otherIdPublicFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOtherIdPublicFlag() {
    super.removeField('otherIdPublicFlag');
    return this;
  }

  public otherIdValue(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('otherIdValue', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOtherIdValue() {
    super.removeField('otherIdValue');
    return this;
  }

  public overrideRendering(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('overrideRendering', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutOverrideRendering() {
    super.removeField('overrideRendering');
    return this;
  }

  public phnoPublicFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('phnoPublicFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPhnoPublicFlag() {
    super.removeField('phnoPublicFlag');
    return this;
  }

  public photoNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('photoNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPhotoNum() {
    super.removeField('photoNum');
    return this;
  }


  public price(
    builder: PriceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('price', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPrice() {
    super.removeField('price');
    return this;
  }

  public provCreditLine(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('provCreditLine', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutProvCreditLine() {
    super.removeField('provCreditLine');
    return this;
  }

  public provDisplayAuctionTitleFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('provDisplayAuctionTitleFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutProvDisplayAuctionTitleFlag() {
    super.removeField('provDisplayAuctionTitleFlag');
    return this;
  }

  public provOutcome(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('provOutcome', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutProvOutcome() {
    super.removeField('provOutcome');
    return this;
  }

  public provType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('provType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutProvType() {
    super.removeField('provType');
    return this;
  }

  public pubTableNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('pubTableNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPubTableNum() {
    super.removeField('pubTableNum');
    return this;
  }


  public publication(
    builder: PublicationFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('publication', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutPublication() {
    super.removeField('publication');
    return this;
  }

  public questionableFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('questionableFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutQuestionableFlag() {
    super.removeField('questionableFlag');
    return this;
  }

  public refLink(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refLink', [null, [Validators.maxLength(200), Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefLink() {
    super.removeField('refLink');
    return this;
  }

  public refNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('refNum', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutRefNum() {
    super.removeField('refNum');
    return this;
  }


  public refTitles(
    builder: ArtworkTitleFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('refTitles', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRefTitles() {
    super.removeField('refTitles');
    return this;
  }


  public resources(
    builder: ResourceFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('resources', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutResources() {
    super.removeField('resources');
    return this;
  }

  public seqNum(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('seqNum', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSeqNum() {
    super.removeField('seqNum');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public unnumberedFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('unnumberedFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUnnumberedFlag() {
    super.removeField('unnumberedFlag');
    return this;
  }

  public useOverrideRenderingFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('useOverrideRenderingFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUseOverrideRenderingFlag() {
    super.removeField('useOverrideRenderingFlag');
    return this;
  }


  public venueEvents(
    builder: ExhibitionVenueArtworkEventFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('venueEvents', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutVenueEvents() {
    super.removeField('venueEvents');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ArtworkEventFormBuilder {
    return this
    .collectionsReviewAction() 
    .collectionsReviewCriterion() 
    .collectionsReviewResult() 
    .conditionCheckCompletenessStatus() 
    .conditionCheckCompletenessStatusDate() 
    .conditionCheckCompletenessStatusNote() 
    .conditionCheckCondition() 
    .conditionCheckConditionDate() 
    .conditionCheckConditionNote() 
    .conditionCheckConservationTreatmentPriority() 
    .conditionCheckEnvironmentalConditionNote() 
    .conditionCheckEnvironmentalConditionNoteDate() 
    .conditionCheckHazard() 
    .conditionCheckHazardDate() 
    .conditionCheckHazardsNote() 
    .conditionCheckNextDate() 
    .conditionCheckTechnicalAssessment() 
    .conditionCheckTechnicalAssessmentDate() 
    .conservationMaterial() 
    .conservationMethod() 
    .conservationTreatmentBegin() 
    .conservationTreatmentEnd() 
    .createdBy() 
    .dateCreated() 
    .dateModified() 
    .detCreationYearEdtf() 
    .detCreationYearMax() 
    .detCreationYearMin() 
    .detCreationYearText() 
    .detCreationYearUseTextFlag() 
    .detDimDepth() 
    .detDimDepthIn() 
    .detDimHeight() 
    .detDimHeightIn() 
    .detDimMetricIsCalculatedFlag() 
    .detDimShapeText() 
    .detDimWidth() 
    .detDimWidthIn() 
    .detTitle() 
    .detTitleDisplayFlag() 
    .evDateEdtf() 
    .evDateMax() 
    .evDateMin() 
    .evDateText() 
    .evDateUseTextFlag() 
    .examAdvStatus() 
    .examAdvice() 
    .examCertIssFlag() 
    .examDupeCertFlag() 
    .examIcrIssFlag() 
    .examInfoOrigin() 
    .examIssDateEdtf() 
    .examIssDateMax() 
    .examIssDateMin() 
    .examIssDateText() 
    .examNonInclFlag() 
    .examResIssFlag() 
    .examStatus() 
    .examWorkView() 
    .exhDispFlag() 
    .exhDtlText() 
    .exhPrintFlag() 
    .highlightFlag() 
    .id() 
    .modifiedBy() 
    .movementTargetLocationFitness() 
    .movementTargetLocationNote() 
    .objectAuditCategory() 
    .objectAuditInfoUnit() 
    .objectAuditResult() 
    .objectStatus() 
    .objectStatusDate() 
    .orderNum() 
    .otherIdLabel() 
    .otherIdPublicFlag() 
    .otherIdValue() 
    .overrideRendering() 
    .phnoPublicFlag() 
    .photoNum() 
    .provCreditLine() 
    .provDisplayAuctionTitleFlag() 
    .provOutcome() 
    .provType() 
    .pubTableNum() 
    .questionableFlag() 
    .refLink() 
    .refNum() 
    .seqNum() 
    .status() 
    .unnumberedFlag() 
    .useOverrideRenderingFlag() 
    .visibilityFlag() 
;
  }
}

