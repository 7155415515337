/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class ExhibitionQueryFilters {
  agentSelections?: Array<AgentSelection>;
  city?: Array<string>;
  country?: Array<string>;
  createdBy?: string;
  exhDate?: DateQuery;
  exhDateEdtf?: string;
  exhTitle?: string;
  exhTitleTranslated?: string;
  exhType?: ExhibitionQueryFilters.ExhTypeEnum;
  hasPublication?: boolean;
  id?: string;
  inArtSet?: string;
  masterFlag?: ExhibitionQueryFilters.MasterFlagEnum;
  modifiedBy?: string;
  publicationFairUseFlag?: boolean;
  publicationMediaAvailable?: boolean;
  relatedToArtist?: Agent;
  searchString?: string;
  status?: ExhibitionQueryFilters.StatusEnum;
  venues?: Array<Agent>;
  visibilityFlag?: boolean;

  constructor({
      agentSelections, 
      city, 
      country, 
      createdBy, 
      exhDate, 
      exhDateEdtf, 
      exhTitle, 
      exhTitleTranslated, 
      exhType, 
      hasPublication, 
      id, 
      inArtSet, 
      masterFlag, 
      modifiedBy, 
      publicationFairUseFlag, 
      publicationMediaAvailable, 
      relatedToArtist, 
      searchString, 
      status, 
      venues, 
      visibilityFlag
  }: {
      agentSelections?: Array<AgentSelection>;
      city?: Array<string>;
      country?: Array<string>;
      createdBy?: string;
      exhDate?: DateQuery;
      exhDateEdtf?: string;
      exhTitle?: string;
      exhTitleTranslated?: string;
      exhType?: ExhibitionQueryFilters.ExhTypeEnum;
      hasPublication?: boolean;
      id?: string;
      inArtSet?: string;
      masterFlag?: ExhibitionQueryFilters.MasterFlagEnum;
      modifiedBy?: string;
      publicationFairUseFlag?: boolean;
      publicationMediaAvailable?: boolean;
      relatedToArtist?: Agent;
      searchString?: string;
      status?: ExhibitionQueryFilters.StatusEnum;
      venues?: Array<Agent>;
      visibilityFlag?: boolean;
  } = {}) {
    this.agentSelections = agentSelections;
    this.city = city;
    this.country = country;
    this.createdBy = createdBy;
    this.exhDate = exhDate;
    this.exhDateEdtf = exhDateEdtf;
    this.exhTitle = exhTitle;
    this.exhTitleTranslated = exhTitleTranslated;
    this.exhType = exhType;
    this.hasPublication = hasPublication;
    this.id = id;
    this.inArtSet = inArtSet;
    this.masterFlag = masterFlag;
    this.modifiedBy = modifiedBy;
    this.publicationFairUseFlag = publicationFairUseFlag;
    this.publicationMediaAvailable = publicationMediaAvailable;
    this.relatedToArtist = relatedToArtist;
    this.searchString = searchString;
    this.status = status;
    this.venues = venues;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace ExhibitionQueryFilters {

  export type ExhTypeEnum = 'Solo' | 'Group';
  export const ExhTypeEnum = {
      Solo: 'Solo' as ExhTypeEnum,
      Group: 'Group' as ExhTypeEnum,
  }
  export const ExhTypeEnumValues = [
      ExhTypeEnum.Solo, 
      ExhTypeEnum.Group
  ];
  export type MasterFlagEnum = 'master' | 'duplicate';
  export const MasterFlagEnum = {
      Master: 'master' as MasterFlagEnum,
      Duplicate: 'duplicate' as MasterFlagEnum,
  }
  export const MasterFlagEnumValues = [
      MasterFlagEnum.Master, 
      MasterFlagEnum.Duplicate
  ];
  export type StatusEnum = 'draft' | 'ready' | 'checked' | 'archived';
  export const StatusEnum = {
      Draft: 'draft' as StatusEnum,
      Ready: 'ready' as StatusEnum,
      Checked: 'checked' as StatusEnum,
      Archived: 'archived' as StatusEnum,
  }
  export const StatusEnumValues = [
      StatusEnum.Draft, 
      StatusEnum.Ready, 
      StatusEnum.Checked, 
      StatusEnum.Archived
  ];
}


/**
 * ExhibitionQueryFilters form builder
 *
 */
export class ExhibitionQueryFiltersFormBuilder extends AbstractFormBuilder<ExhibitionQueryFiltersFormBuilder> {

  className = 'ExhibitionQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<ExhibitionQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): ExhibitionQueryFilters {
    return new ExhibitionQueryFilters();
  }


  public agentSelections(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentSelections', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentSelections() {
    super.removeField('agentSelections');
    return this;
  }

  public city(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('city', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCity() {
    super.removeField('city');
    return this;
  }

  public country(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('country', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCountry() {
    super.removeField('country');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public exhDate(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('exhDate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutExhDate() {
    super.removeField('exhDate');
    return this;
  }

  public exhDateEdtf(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhDateEdtf', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhDateEdtf() {
    super.removeField('exhDateEdtf');
    return this;
  }

  public exhTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhTitle() {
    super.removeField('exhTitle');
    return this;
  }

  public exhTitleTranslated(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhTitleTranslated', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhTitleTranslated() {
    super.removeField('exhTitleTranslated');
    return this;
  }

  public exhType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhType() {
    super.removeField('exhType');
    return this;
  }

  public hasPublication(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('hasPublication', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutHasPublication() {
    super.removeField('hasPublication');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public inArtSet(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('inArtSet', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutInArtSet() {
    super.removeField('inArtSet');
    return this;
  }

  public masterFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('masterFlag', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutMasterFlag() {
    super.removeField('masterFlag');
    return this;
  }

  public modifiedBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('modifiedBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutModifiedBy() {
    super.removeField('modifiedBy');
    return this;
  }

  public publicationFairUseFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('publicationFairUseFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPublicationFairUseFlag() {
    super.removeField('publicationFairUseFlag');
    return this;
  }

  public publicationMediaAvailable(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('publicationMediaAvailable', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPublicationMediaAvailable() {
    super.removeField('publicationMediaAvailable');
    return this;
  }


  public relatedToArtist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('relatedToArtist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRelatedToArtist() {
    super.removeField('relatedToArtist');
    return this;
  }

  public searchString(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchString', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchString() {
    super.removeField('searchString');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }


  public venues(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('venues', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutVenues() {
    super.removeField('venues');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): ExhibitionQueryFiltersFormBuilder {
    return this
    .city() 
    .country() 
    .createdBy() 
    .exhDateEdtf() 
    .exhTitle() 
    .exhTitleTranslated() 
    .exhType() 
    .hasPublication() 
    .id() 
    .inArtSet() 
    .masterFlag() 
    .modifiedBy() 
    .publicationFairUseFlag() 
    .publicationMediaAvailable() 
    .searchString() 
    .status() 
    .visibilityFlag() 
;
  }
}

