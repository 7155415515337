/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class MediaSearchResponseEntryRelation {
  displayName?: string;
  entityId?: string;
  entityType?: MediaSearchResponseEntryRelation.EntityTypeEnum;

  constructor({
      displayName, 
      entityId, 
      entityType
  }: {
      displayName?: string;
      entityId?: string;
      entityType?: MediaSearchResponseEntryRelation.EntityTypeEnum;
  } = {}) {
    this.displayName = displayName;
    this.entityId = entityId;
    this.entityType = entityType;
  }
}

export namespace MediaSearchResponseEntryRelation {

  export type EntityTypeEnum = 'Publication';
  export const EntityTypeEnum = {
      Publication: 'Publication' as EntityTypeEnum,
  }
  export const EntityTypeEnumValues = [
      EntityTypeEnum.Publication
  ];
}


/**
 * MediaSearchResponseEntryRelation form builder
 *
 */
export class MediaSearchResponseEntryRelationFormBuilder extends AbstractFormBuilder<MediaSearchResponseEntryRelationFormBuilder> {

  className = 'MediaSearchResponseEntryRelation';

  abstractFormBuilder!: AbstractFormBuilder<MediaSearchResponseEntryRelation>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): MediaSearchResponseEntryRelation {
    return new MediaSearchResponseEntryRelation();
  }

  public displayName(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('displayName', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDisplayName() {
    super.removeField('displayName');
    return this;
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityType', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityType() {
    super.removeField('entityType');
    return this;
  }


  public all(): MediaSearchResponseEntryRelationFormBuilder {
    return this
    .displayName() 
    .entityId() 
    .entityType() 
;
  }
}

