/* tslint:disable */
/* eslint-disable */
import { AgentSelection, AgentSelectionFormBuilder } from '../models/agent-selection';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class SubAgentMapping {
  action?: SubAgentMapping.ActionEnum;
  duplicate?: AgentSelection;
  master?: AgentSelection;

  constructor({
      action, 
      duplicate, 
      master
  }: {
      action?: SubAgentMapping.ActionEnum;
      duplicate?: AgentSelection;
      master?: AgentSelection;
  } = {}) {
    this.action = action;
    this.duplicate = duplicate;
    this.master = master;
  }
}

export namespace SubAgentMapping {

  export type ActionEnum = 'replace' | 'delete' | 'add';
  export const ActionEnum = {
      Replace: 'replace' as ActionEnum,
      Delete: 'delete' as ActionEnum,
      Add: 'add' as ActionEnum,
  }
  export const ActionEnumValues = [
      ActionEnum.Replace, 
      ActionEnum.Delete, 
      ActionEnum.Add
  ];
}


/**
 * SubAgentMapping form builder
 *
 */
export class SubAgentMappingFormBuilder extends AbstractFormBuilder<SubAgentMappingFormBuilder> {

  className = 'SubAgentMapping';

  abstractFormBuilder!: AbstractFormBuilder<SubAgentMapping>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): SubAgentMapping {
    return new SubAgentMapping();
  }

  public action(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('action', [null, [Validators.required, Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAction() {
    super.removeField('action');
    return this;
  }


  public duplicate(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('duplicate', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDuplicate() {
    super.removeField('duplicate');
    return this;
  }


  public master(
    builder: AgentSelectionFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('master', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutMaster() {
    super.removeField('master');
    return this;
  }


  public all(): SubAgentMappingFormBuilder {
    return this
    .action() 
;
  }
}

