/* tslint:disable */
/* eslint-disable */
import { Language, LanguageFormBuilder } from '../models/language';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class IndexingTermName {
  id?: string;
  language?: Language;
  name?: string;
  primaryFlag?: boolean;

  constructor({
      id, 
      language, 
      name, 
      primaryFlag
  }: {
      id?: string;
      language?: Language;
      name?: string;
      primaryFlag?: boolean;
  } = {}) {
    this.id = id;
    this.language = language;
    this.name = name;
    this.primaryFlag = primaryFlag;
  }
}

export namespace IndexingTermName {

}


/**
 * IndexingTermName form builder
 *
 */
export class IndexingTermNameFormBuilder extends AbstractFormBuilder<IndexingTermNameFormBuilder> {

  className = 'IndexingTermName';

  abstractFormBuilder!: AbstractFormBuilder<IndexingTermName>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): IndexingTermName {
    return new IndexingTermName();
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public language(
    builder: LanguageFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('language', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutLanguage() {
    super.removeField('language');
    return this;
  }

  public name(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('name', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutName() {
    super.removeField('name');
    return this;
  }

  public primaryFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('primaryFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutPrimaryFlag() {
    super.removeField('primaryFlag');
    return this;
  }


  public all(): IndexingTermNameFormBuilder {
    return this
    .id() 
    .name() 
    .primaryFlag() 
;
  }
}

