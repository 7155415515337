/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class EnvironmentMeasure {
  date?: string;
  id?: string;
  locationId?: string;
  qualifier?: EnvironmentMeasure.QualifierEnum;
  type?: EnvironmentMeasure.TypeEnum;
  unit?: EnvironmentMeasure.UnitEnum;
  value?: number;

  constructor({
      date, 
      id, 
      locationId, 
      qualifier, 
      type, 
      unit, 
      value
  }: {
      date?: string;
      id?: string;
      locationId?: string;
      qualifier?: EnvironmentMeasure.QualifierEnum;
      type?: EnvironmentMeasure.TypeEnum;
      unit?: EnvironmentMeasure.UnitEnum;
      value?: number;
  } = {}) {
    this.date = date;
    this.id = id;
    this.locationId = locationId;
    this.qualifier = qualifier;
    this.type = type;
    this.unit = unit;
    this.value = value;
  }
}

export namespace EnvironmentMeasure {

  export type QualifierEnum = '+' | '-' | 'approximate' | 'average';
  export const QualifierEnum = {
      Plus: '+' as QualifierEnum,
      Minus: '-' as QualifierEnum,
      Approximate: 'approximate' as QualifierEnum,
      Average: 'average' as QualifierEnum,
  }
  export const QualifierEnumValues = [
      QualifierEnum.Plus, 
      QualifierEnum.Minus, 
      QualifierEnum.Approximate, 
      QualifierEnum.Average
  ];
  export type TypeEnum = 'temperature' | 'relative humidity' | 'visible light' | 'ultra-violet light';
  export const TypeEnum = {
      Temperature: 'temperature' as TypeEnum,
      RelativeHumidity: 'relative humidity' as TypeEnum,
      VisibleLight: 'visible light' as TypeEnum,
      UltraVioletLight: 'ultra-violet light' as TypeEnum,
  }
  export const TypeEnumValues = [
      TypeEnum.Temperature, 
      TypeEnum.RelativeHumidity, 
      TypeEnum.VisibleLight, 
      TypeEnum.UltraVioletLight
  ];
  export type UnitEnum = 'celsius' | 'farenheit' | '%' | 'lux';
  export const UnitEnum = {
      Celsius: 'celsius' as UnitEnum,
      Farenheit: 'farenheit' as UnitEnum,
      Percent: '%' as UnitEnum,
      Lux: 'lux' as UnitEnum,
  }
  export const UnitEnumValues = [
      UnitEnum.Celsius, 
      UnitEnum.Farenheit, 
      UnitEnum.Percent, 
      UnitEnum.Lux
  ];
}


/**
 * EnvironmentMeasure form builder
 *
 */
export class EnvironmentMeasureFormBuilder extends AbstractFormBuilder<EnvironmentMeasureFormBuilder> {

  className = 'EnvironmentMeasure';

  abstractFormBuilder!: AbstractFormBuilder<EnvironmentMeasure>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): EnvironmentMeasure {
    return new EnvironmentMeasure();
  }

  public date(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('date', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutDate() {
    super.removeField('date');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }

  public locationId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('locationId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutLocationId() {
    super.removeField('locationId');
    return this;
  }

  public qualifier(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('qualifier', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutQualifier() {
    super.removeField('qualifier');
    return this;
  }

  public type(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('type', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutType() {
    super.removeField('type');
    return this;
  }

  public unit(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('unit', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutUnit() {
    super.removeField('unit');
    return this;
  }

  public value(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('value', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutValue() {
    super.removeField('value');
    return this;
  }


  public all(): EnvironmentMeasureFormBuilder {
    return this
    .date() 
    .id() 
    .locationId() 
    .qualifier() 
    .type() 
    .unit() 
    .value() 
;
  }
}

