import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule],
})
export class NamedFormsModule {}

export * from './abstract-form-builder';
export * from './named-form-array';
export * from './named-form-control';
export * from './named-form-group';
