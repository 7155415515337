/* tslint:disable */
/* eslint-disable */
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class OtherIdNameQueryFilters {
  searchString?: string;

  constructor({
      searchString
  }: {
      searchString?: string;
  } = {}) {
    this.searchString = searchString;
  }
}

export namespace OtherIdNameQueryFilters {

}


/**
 * OtherIdNameQueryFilters form builder
 *
 */
export class OtherIdNameQueryFiltersFormBuilder extends AbstractFormBuilder<OtherIdNameQueryFiltersFormBuilder> {

  className = 'OtherIdNameQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<OtherIdNameQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): OtherIdNameQueryFilters {
    return new OtherIdNameQueryFilters();
  }

  public searchString(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('searchString', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutSearchString() {
    super.removeField('searchString');
    return this;
  }


  public all(): OtherIdNameQueryFiltersFormBuilder {
    return this
    .searchString() 
;
  }
}

