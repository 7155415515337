/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class PublicExhibitionQueryFilters {
  allFields?: string;

  /**
   * DEPRECATED: use "artist" filter property.
   *
   * @deprecated
   */
  artist?: Agent;
  artists?: Array<Agent>;
  exhDate?: string;
  exhTitle?: string;
  exhType?: PublicExhibitionQueryFilters.ExhTypeEnum;
  id?: string;

  constructor({
      allFields, 
    
  /**
   * DEPRECATED: use "artist" filter property.
   *
   * @deprecated
   */
  artist, 
      artists, 
      exhDate, 
      exhTitle, 
      exhType, 
      id
  }: {
      allFields?: string;
    
  /**
   * DEPRECATED: use "artist" filter property.
   *
   * @deprecated
   */
  artist?: Agent;
      artists?: Array<Agent>;
      exhDate?: string;
      exhTitle?: string;
      exhType?: PublicExhibitionQueryFilters.ExhTypeEnum;
      id?: string;
  } = {}) {
    this.allFields = allFields;
    this.artist = artist;
    this.artists = artists;
    this.exhDate = exhDate;
    this.exhTitle = exhTitle;
    this.exhType = exhType;
    this.id = id;
  }
}

export namespace PublicExhibitionQueryFilters {

  export type ExhTypeEnum = 'Solo' | 'Group';
  export const ExhTypeEnum = {
      Solo: 'Solo' as ExhTypeEnum,
      Group: 'Group' as ExhTypeEnum,
  }
  export const ExhTypeEnumValues = [
      ExhTypeEnum.Solo, 
      ExhTypeEnum.Group
  ];
}


/**
 * PublicExhibitionQueryFilters form builder
 *
 */
export class PublicExhibitionQueryFiltersFormBuilder extends AbstractFormBuilder<PublicExhibitionQueryFiltersFormBuilder> {

  className = 'PublicExhibitionQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<PublicExhibitionQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): PublicExhibitionQueryFilters {
    return new PublicExhibitionQueryFilters();
  }

  public allFields(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('allFields', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutAllFields() {
    super.removeField('allFields');
    return this;
  }


  public artist(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artist', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtist() {
    super.removeField('artist');
    return this;
  }


  public artists(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('artists', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutArtists() {
    super.removeField('artists');
    return this;
  }

  public exhDate(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhDate', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhDate() {
    super.removeField('exhDate');
    return this;
  }

  public exhTitle(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhTitle', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhTitle() {
    super.removeField('exhTitle');
    return this;
  }

  public exhType(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('exhType', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutExhType() {
    super.removeField('exhType');
    return this;
  }

  public id(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('id', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutId() {
    super.removeField('id');
    return this;
  }


  public all(): PublicExhibitionQueryFiltersFormBuilder {
    return this
    .allFields() 
    .exhDate() 
    .exhTitle() 
    .exhType() 
    .id() 
;
  }
}

