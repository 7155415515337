/* tslint:disable */
/* eslint-disable */
import { Agent, AgentFormBuilder } from '../models/agent';
import { AgentAddress, AgentAddressFormBuilder } from '../models/agent-address';
import { AgentOtherName, AgentOtherNameFormBuilder } from '../models/agent-other-name';
import { AgentRole, AgentRoleFormBuilder } from '../models/agent-role';
import { DateQuery, DateQueryFormBuilder } from '../models/date-query';
import { AsyncValidatorFn, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { AbstractFormBuilder, BuilderValidatorConfig } from '@navigatingart/named-forms';

export class AgentSelectionQueryFilters {
  agent?: Agent;
  agentAddress?: Array<AgentAddress>;
  agentOtherName?: Array<AgentOtherName>;
  createdBy?: string;
  dateCreated?: DateQuery;
  entityId?: string;
  entityTypes?: Array<'Agent' | 'Artwork' | 'ArtEvent' | 'Auction' | 'Exhibition' | 'ExhibitionVenue' | 'Publication' | 'Committee' | 'Resource' | 'ObjectEntry' | 'ObjectExit' | 'AcquisitionInformation' | 'DisposalInformation' | 'AuditInformation' | 'Marking' | 'MovementInformation' | 'Loan' | 'CollectionsReview' | 'Place' | 'Location' | 'ConditionCheck' | 'Conservation' | 'InsuranceInformation'>;
  isDealer?: boolean;
  isPrivateCollection?: boolean;
  isQuestionable?: boolean;
  roles?: Array<AgentRole>;
  status?: string;
  visibilityFlag?: boolean;

  constructor({
      agent, 
      agentAddress, 
      agentOtherName, 
      createdBy, 
      dateCreated, 
      entityId, 
      entityTypes, 
      isDealer, 
      isPrivateCollection, 
      isQuestionable, 
      roles, 
      status, 
      visibilityFlag
  }: {
      agent?: Agent;
      agentAddress?: Array<AgentAddress>;
      agentOtherName?: Array<AgentOtherName>;
      createdBy?: string;
      dateCreated?: DateQuery;
      entityId?: string;
      entityTypes?: Array<'Agent' | 'Artwork' | 'ArtEvent' | 'Auction' | 'Exhibition' | 'ExhibitionVenue' | 'Publication' | 'Committee' | 'Resource' | 'ObjectEntry' | 'ObjectExit' | 'AcquisitionInformation' | 'DisposalInformation' | 'AuditInformation' | 'Marking' | 'MovementInformation' | 'Loan' | 'CollectionsReview' | 'Place' | 'Location' | 'ConditionCheck' | 'Conservation' | 'InsuranceInformation'>;
      isDealer?: boolean;
      isPrivateCollection?: boolean;
      isQuestionable?: boolean;
      roles?: Array<AgentRole>;
      status?: string;
      visibilityFlag?: boolean;
  } = {}) {
    this.agent = agent;
    this.agentAddress = agentAddress;
    this.agentOtherName = agentOtherName;
    this.createdBy = createdBy;
    this.dateCreated = dateCreated;
    this.entityId = entityId;
    this.entityTypes = entityTypes;
    this.isDealer = isDealer;
    this.isPrivateCollection = isPrivateCollection;
    this.isQuestionable = isQuestionable;
    this.roles = roles;
    this.status = status;
    this.visibilityFlag = visibilityFlag;
  }
}

export namespace AgentSelectionQueryFilters {

  export type EntityTypesEnum = 'Agent' | 'Artwork' | 'ArtEvent' | 'Auction' | 'Exhibition' | 'ExhibitionVenue' | 'Publication' | 'Committee' | 'Resource' | 'ObjectEntry' | 'ObjectExit' | 'AcquisitionInformation' | 'DisposalInformation' | 'AuditInformation' | 'Marking' | 'MovementInformation' | 'Loan' | 'CollectionsReview' | 'Place' | 'Location' | 'ConditionCheck' | 'Conservation' | 'InsuranceInformation'
  export const EntityTypesEnum = {
      Agent: 'Agent' as EntityTypesEnum,
      Artwork: 'Artwork' as EntityTypesEnum,
      ArtEvent: 'ArtEvent' as EntityTypesEnum,
      Auction: 'Auction' as EntityTypesEnum,
      Exhibition: 'Exhibition' as EntityTypesEnum,
      ExhibitionVenue: 'ExhibitionVenue' as EntityTypesEnum,
      Publication: 'Publication' as EntityTypesEnum,
      Committee: 'Committee' as EntityTypesEnum,
      Resource: 'Resource' as EntityTypesEnum,
      ObjectEntry: 'ObjectEntry' as EntityTypesEnum,
      ObjectExit: 'ObjectExit' as EntityTypesEnum,
      AcquisitionInformation: 'AcquisitionInformation' as EntityTypesEnum,
      DisposalInformation: 'DisposalInformation' as EntityTypesEnum,
      AuditInformation: 'AuditInformation' as EntityTypesEnum,
      Marking: 'Marking' as EntityTypesEnum,
      MovementInformation: 'MovementInformation' as EntityTypesEnum,
      Loan: 'Loan' as EntityTypesEnum,
      CollectionsReview: 'CollectionsReview' as EntityTypesEnum,
      Place: 'Place' as EntityTypesEnum,
      Location: 'Location' as EntityTypesEnum,
      ConditionCheck: 'ConditionCheck' as EntityTypesEnum,
      Conservation: 'Conservation' as EntityTypesEnum,
      InsuranceInformation: 'InsuranceInformation' as EntityTypesEnum,
  }
  export const EntityTypesEnumValues = [
      EntityTypesEnum.Agent, 
      EntityTypesEnum.Artwork, 
      EntityTypesEnum.ArtEvent, 
      EntityTypesEnum.Auction, 
      EntityTypesEnum.Exhibition, 
      EntityTypesEnum.ExhibitionVenue, 
      EntityTypesEnum.Publication, 
      EntityTypesEnum.Committee, 
      EntityTypesEnum.Resource, 
      EntityTypesEnum.ObjectEntry, 
      EntityTypesEnum.ObjectExit, 
      EntityTypesEnum.AcquisitionInformation, 
      EntityTypesEnum.DisposalInformation, 
      EntityTypesEnum.AuditInformation, 
      EntityTypesEnum.Marking, 
      EntityTypesEnum.MovementInformation, 
      EntityTypesEnum.Loan, 
      EntityTypesEnum.CollectionsReview, 
      EntityTypesEnum.Place, 
      EntityTypesEnum.Location, 
      EntityTypesEnum.ConditionCheck, 
      EntityTypesEnum.Conservation, 
      EntityTypesEnum.InsuranceInformation
  ];
}


/**
 * AgentSelectionQueryFilters form builder
 *
 */
export class AgentSelectionQueryFiltersFormBuilder extends AbstractFormBuilder<AgentSelectionQueryFiltersFormBuilder> {

  className = 'AgentSelectionQueryFilters';

  abstractFormBuilder!: AbstractFormBuilder<AgentSelectionQueryFilters>;
  builderValidatorConfig!: BuilderValidatorConfig;

  constructor(public formBuilder: FormBuilder) {
    super();
  }

  protected createObject(): AgentSelectionQueryFilters {
    return new AgentSelectionQueryFilters();
  }


  public agent(
    builder: AgentFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agent', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgent() {
    super.removeField('agent');
    return this;
  }


  public agentAddress(
    builder: AgentAddressFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentAddress', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentAddress() {
    super.removeField('agentAddress');
    return this;
  }


  public agentOtherName(
    builder: AgentOtherNameFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('agentOtherName', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutAgentOtherName() {
    super.removeField('agentOtherName');
    return this;
  }

  public createdBy(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('createdBy', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutCreatedBy() {
    super.removeField('createdBy');
    return this;
  }


  public dateCreated(
    builder: DateQueryFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('dateCreated', {
        builder: builder,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutDateCreated() {
    super.removeField('dateCreated');
    return this;
  }

  public entityId(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityId', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityId() {
    super.removeField('entityId');
    return this;
  }

  public entityTypes(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('entityTypes', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutEntityTypes() {
    super.removeField('entityTypes');
    return this;
  }

  public isDealer(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('isDealer', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIsDealer() {
    super.removeField('isDealer');
    return this;
  }

  public isPrivateCollection(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('isPrivateCollection', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIsPrivateCollection() {
    super.removeField('isPrivateCollection');
    return this;
  }

  public isQuestionable(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('isQuestionable', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutIsQuestionable() {
    super.removeField('isQuestionable');
    return this;
  }


  public roles(
    builder: AgentRoleFormBuilder,
    validatorConfig?: BuilderValidatorConfig) {
      super.addField('roles', {
        builder: builder,
        asArray: true,
        validatorConfig: validatorConfig,
      });
      return this;
  }

  public withoutRoles() {
    super.removeField('roles');
    return this;
  }

  public status(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('status', [null, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutStatus() {
    super.removeField('status');
    return this;
  }

  public visibilityFlag(
    syncValidators: Array<ValidatorFn> = [],
    asyncValidators: Array<AsyncValidatorFn> = []) {
      super.addField('visibilityFlag', [undefined, [Validators.nullValidator].concat(syncValidators), asyncValidators]);
      return this;
  }

  public withoutVisibilityFlag() {
    super.removeField('visibilityFlag');
    return this;
  }


  public all(): AgentSelectionQueryFiltersFormBuilder {
    return this
    .createdBy() 
    .entityId() 
    .entityTypes() 
    .isDealer() 
    .isPrivateCollection() 
    .isQuestionable() 
    .status() 
    .visibilityFlag() 
;
  }
}

